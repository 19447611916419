import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ControlTypeEnum, IControl, IControlResponse } from 'app/interface/controls.interface';
import { IBareListResult } from 'app/interface/list-result.interface';
import { map, Observable } from 'rxjs';

import { InfrastructuresService } from './infrastructures.service';

@Injectable()
export class ControlsService {
  constructor(
    private http: HttpClient,
    private infrastructureService: InfrastructuresService
  ) {}

  getAll(
    filters: {
      sort?: string;
      offset?: string;
      limit?: string;
      q?: string;
      audit_id?: string;
      status?: string;
    } = {}
  ): Observable<IBareListResult<IControl>> {
    return this.http.get<IBareListResult<IControlResponse>>('api://controls', { params: filters }).pipe(
      map((res) => {
        const list: IBareListResult<IControl> = {
          ...res,
          items: res.items.map((e) => this.parseControlResponse(e))
        };
        return list;
      })
    );
  }

  getAllForms() {
    return this.http.get(`api://control-forms`) as any;
  }

  private parseControlResponse(res: IControlResponse): IControl {
    const control: IControl = { ...res } as IControl;
    if (control.type !== ControlTypeEnum.BEACON && control.infrastructure) {
      control.infrastructure = this.infrastructureService.parseInfrastructureResponse(res.infrastructure);
    }
    return control;
  }

  getById(id: string): Observable<IControl> {
    return this.http.get<IControlResponse>(`api://controls/${id}`).pipe(map((res) => this.parseControlResponse(res)));
  }

  /**
   * Remove an control
   *
   * @param {string} _id
   * @returns {Observable}
   */
  delete(_id: string): Observable<void> {
    return this.http.delete(`api://controls/${_id}`) as any;
  }
}
