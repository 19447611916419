import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  selector: 'app-submit-button',
  imports: [CommonModule, TranslateModule, MatButtonModule, MatDividerModule, MatIconModule],
  templateUrl: './submit-button.component.html',
  styleUrls: ['./submit-button.component.scss']
})
export class SubmitButtonComponent {
  @Input() public pformGroup: UntypedFormGroup;
  @Input() public disabled?: boolean = false;
  @Input() public label?: string;
}
