<h1 class="mainTitle" translate>{{ viewMode | async | uppercase }}.TITLE</h1>

<app-list
  [listParameters]="listParameters"
  [items]="items"
  [total]="total"
  [loaded]="loaded"
  searchType="dynamic"
  (refresh)="getFluFlrList($event)"
>
</app-list>
