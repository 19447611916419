<div class="location-card">
  <div *ngIf="loaded === true" class="location-view {{ report.type.priority }}">
    <div class="top-container">
      <div class="left-container"><img class="icon report-icon" src="{{ report.type.picto }}" /></div>
      <div class="right-container">
        <mat-icon
          class="renew-archive"
          *ngIf="isRenewable()"
          (click)="renewArchive()"
          matTooltip="{{ 'GLOBAL.REGENERATE_CAID_ARCHIVE' | translate }}"
          >sync_alt</mat-icon
        >

        <div class="report-id" *ngIf="report.source">{{ report.source_id }} ({{ report.source }})</div>
        <div>
          <span>{{ report.type.label | uppercase }}</span
          ><span *ngIf="report.subtype"> - {{ report.subtype.label }}</span>
        </div>
        <div class="highway-infos">
          <div class="highway-name">
            {{ report.location.highway_name }} - {{ 'HIGHWAYS.KILOMETER_POINT' | translate }}
            {{ report.location.display }}
          </div>
          <div class="highway-direction">
            <span *ngIf="report.location.direction === 1">{{ highway.origin }}</span>
            <span *ngIf="report.location.direction === 2">{{ highway.destination }}</span>
            <div class="arrow">
              <mat-icon class="arrow-icon">arrow_right_alt</mat-icon>
            </div>
            <span *ngIf="report.location.direction === 1">{{ highway.destination }}</span>
            <span *ngIf="report.location.direction === 2">{{ highway.origin }}</span>
          </div>
        </div>

        <div class="report-state">
          <span>{{ getReportState(report.state) }} </span>
        </div>
      </div>
    </div>
    <div class="bottom-container">
      <p class="description">{{ report.description }}</p>
      <p class="date">{{ report.created_at | date: 'shortDate' }} - {{ report.created_at | date: 'shortTime' }}</p>
    </div>
    <span class="reportingsActions">
      <button mat-icon-button [matMenuTriggerFor]="menuActions">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menuActions="matMenu" class="menuActionsReport">
        <button
          mat-menu-item
          (click)="handleEditClick(report._id)"
          [disabled]="!isRightReporting || !isEditable(report)"
        >
          <mat-icon>edit</mat-icon>
          <span>{{ 'GLOBAL.EDIT' | translate }}</span>
        </button>
        <!-- <button mat-menu-item>
          <mat-icon>help</mat-icon>
          <span>{{ 'REPORTING.LOCATION_CARD.UNKNOWN_ACCIDENT' | translate }}</span>
        </button> -->
        <button mat-menu-item (click)="handlePdfClick(report._id)">
          <mat-icon>picture_as_pdf</mat-icon>
          <span>{{ 'REPORTING.PDF.OPEN_PDF_VIEWER' | translate }}</span>
        </button>
        <button mat-menu-item *ngIf="!isClosed(report) && isOperator" (click)="handleCloseClick(report._id)">
          <mat-icon class="close-icon">task_alt</mat-icon>
          <span>{{ 'GLOBAL.CLOSE' | translate }}</span>
        </button>
        <button mat-menu-item *ngIf="isOperator" (click)="handleExternalEvents(report._id)">
          <mat-icon>link</mat-icon>
          <span>{{ 'GLOBAL.EXTERNAL_EVENTS' | translate }}</span>
        </button>
      </mat-menu>
    </span>
  </div>
</div>
