import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HighwayOption, IHighway } from 'src/app/interface/highway.interface';
import {
  IInfrastructure,
  InfrastructureToCreate,
  InfrastructureType
} from 'src/app/interface/infrastructure.interface';
import { Coordinates } from 'src/app/interface/location.interface';
import { HighwaysService } from 'src/app/services/highways.service';
import { InfrastructuresService } from 'src/app/services/infrastructures.service';

import { QuestionBase } from '../../generic/questions/question-base';
import { DropdownQuestion } from '../../generic/questions/question-dropdown';
import { TextboxQuestion } from '../../generic/questions/question-textbox';
import { LocationFormHelper } from '../../locations/location-form/location-form.helper';

export interface NewInfrastructureDialogData {
  coordinates: Coordinates;
  highways: HighwayOption[];
}

export type NewInfrastructureDialogRes = { result: 'valid'; data: IInfrastructure } | { result: 'cancel' };

@Component({
  selector: 'app-new-infrastructure.dialog',
  templateUrl: './new-infrastructure.dialog.component.html',
  styleUrls: ['./new-infrastructure.dialog.component.scss']
})
export class NewInfrastructureDialogComponent implements OnInit {
  public loading = false;
  public infrastructureTypes: InfrastructureType[];
  public questions: QuestionBase<any>[][];
  public coordinates: Coordinates;
  public highways: IHighway[];

  @ViewChild('dynamicForm') dynamicForm: any;

  constructor(
    private dialogRef: MatDialogRef<NewInfrastructureDialogComponent, NewInfrastructureDialogRes>,
    private infrastructureService: InfrastructuresService,
    public locationFormHelper: LocationFormHelper,
    private highwayService: HighwaysService,
    @Inject(MAT_DIALOG_DATA) public data: NewInfrastructureDialogData
  ) {
    this.loading = true;
    this.infrastructureTypes = this.infrastructureService.getInfrastructureTypesSync();
  }

  ngOnInit() {
    const coordinates: Coordinates = this.data.coordinates;
    this.coordinates = coordinates;
    this.loadData().then(() => {
      this.generateForm();
      setTimeout(() => this.locationFormHelper.setFormGroup(this.dynamicForm.form));
    });
  }

  async loadData(): Promise<void> {
    this.loading = true;
    const highwayList = await this.highwayService.getAll();
    this.highways = highwayList.items;
    this.loading = false;
  }

  private generateForm(): void {
    this.questions = [
      [
        new DropdownQuestion({
          key: 'typeId',
          label: 'INFRASTRUCTURES.TYPE',
          controls: [{ key: 'required' }],
          value: '',
          options: this.infrastructureTypes.map((e) => ({
            key: e.id,
            value: e.name
          })),
          pristine: true,
          size: '1-1',
          disabled: false
        })
      ],
      [
        new TextboxQuestion({
          key: 'name',
          label: 'INFRASTRUCTURES.NAME',
          controls: [{ key: 'required' }],
          value: '',
          pristine: true,
          size: '1-1',
          disabled: false
        })
      ],
      [
        // Row number four
        new TextboxQuestion({
          key: 'description',
          label: 'INFRASTRUCTURES.DESCRIPTION',
          value: '',
          pristine: true,
          size: '1-1',
          disabled: false
        })
      ],
      ...this.locationFormHelper.generateQuestions(this.highways)
    ];
  }

  public handleMarkerMove(coords: Coordinates): void {
    this.coordinates = coords;
    this.locationFormHelper.handleMarkerMoved(coords);
  }

  public async handleSubmit(form: UntypedFormGroup): Promise<void> {
    const pk = Number(form.get('pk')!.value.replace('+', '.'));
    if (isNaN(pk)) {
      throw Error('Wrong pk');
    }
    const values: InfrastructureToCreate = {
      name: form.get('name')!.value,
      description: form.get('description')!.value,
      highwayId: form.get('highway')!.value,
      pk,
      direction: parseInt(form.get('direction')!.value, 10),
      typeId: form.get('typeId')!.value,
      coordinates: this.coordinates
    };
    const infrastructure = await this.infrastructureService.createInfrastructure(values);
    this.dialogRef.close({
      result: 'valid',
      data: infrastructure
    });
  }

  public handleCancel(): void {
    this.dialogRef.close({ result: 'cancel' });
  }
}
