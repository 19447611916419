/**
 * Returns the current version of Internet Explorer as a number, or FALSE if a
 * real brower is detected.
 *
 * If a version number is provided, this method will return FALSE if it differs
 * from the detected one. Note that currently, this method does not make any
 * distinction between IE10 and its older versions; the same applies for Edge
 * and newer.
 *
 * @param version The version number to target.
 */
export const isIE = (version?: number) => {
  const ua = window.navigator.userAgent;

  // IE 10 or older detection
  const msie = ua.indexOf('MSIE ');
  if (msie > 0 && (!version || version < 11)) {
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }

  // IE 11 detection
  const trident = ua.indexOf('Trident/');
  if (trident > 0 && (!version || version === 11)) {
    const rv = ua.indexOf('rv:');

    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }

  // Edge (IE 12+) detection
  const edge = ua.indexOf('Edge/');
  if (edge > 0 && (!version || version > 11)) {
    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
  }

  // Real browser detected
  return false;
};
