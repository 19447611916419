import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ErrorHandler } from 'app/components/generic/error-handler/error-handler.service';
import { IconService } from 'app/services/icon.service';
import { LoadingService } from 'app/services/loading.service';
import _ from 'lodash';
import { lastValueFrom, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { APIError, ExtendedAPIError } from '../error-handler/error.interface';

@Component({
  selector: 'app-generic-dialog',
  templateUrl: './generic-dialog.component.html',
  styleUrls: ['./generic-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GenericDialogComponent implements OnInit {
  form: UntypedFormGroup = {} as any;
  public progression = 0;
  public isImg = false;
  public isForm = false;
  public isHtml = false;
  public isFile = false;
  public isSignedDrivingLog = false;
  public isError = false;
  public fileName = '';
  public htmlTemplate = '';
  public hideCancelButton = false;
  @ViewChild('fileInput', { static: true }) fileInput: ElementRef = {} as any;

  /**
   * @param dialogRef
   * @param data
   *
   * data.content defines a type of content generated by the modal
   * TYPE 'form', a 'keys' a 'titles' and an 'input'
   *
   * The KEYS are the name of the input, returned by the form in a JSON
   *
   * The 'type' accepts actually 7 types
   * /!\ => 'text', 'email', 'tel', 'textarea', 'password', 'address', 'date', 'file'
   *
   * IMPORTANT : There must be the SAME number as KEYS, TITLES and INPUT
   * IMPORTANT : The endpoint param is the target point in the API, not the full URL
   *
   * Example FORM
   data: {
        content: {
            endpoint: '/imports/users',
            type: 'form',
            fields: [{
                key: 'fileToUpload',
                title: 'Choisir un fichier',
                type: 'file',
            },
            {
                key: 'password',
                title: 'Mot de passe',
                type: 'password',
            }],
            button: {
              text: 'Enregistrer',
              class: 'validate',
            }
        }
     }
   *
   * TYPE 'img', require a src
   * Example IMG --> TODO
   data.content = {
        type: 'img',
        src: [
         'C:/myfolder/profil.png',
         'C:/newfolder/signature.png
         ],
        alt?: [
         'Photo de profil',
         'Signature'
         ]
        }
   *
   * TYPE 'file'
   * Example FILE
   data: {
      content: {
        type: 'file',
        hideCancelButtonOnSubmit: true,
        endpoint: '/imports/users',
        button: {
              text: 'Envoyer',
              class: 'validate',
          }
        }
      }
   * TYPE 'html'
   * Example HTML
   * Write html in data.content.html = '', you can add style in.
   data: {
      content: {
        type: 'html',
        html: '<p>My HTML Content</p>'
        }
     }
   * TYPE 'error'
   * Example ERROR
   * Write Error list with translation.
   data: {
      content: {
        type: 'error',
        errorList: {
          message: `ERRORS.${statusCode}`,
          details: [errorDetails]
        }
      }
    }
   * @param fb
   * @param sanitizer
   * @param http
   * @param translate
   * @param loading
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<GenericDialogComponent>,
    public loading: LoadingService,
    private http: HttpClient,
    private fb: UntypedFormBuilder,
    public dialog: MatDialog,
    private iconService: IconService,
    private translate: TranslateService,
    private errorHandler: ErrorHandler,
    private snackbar: MatSnackBar,
    private _sanitizer: DomSanitizer
  ) {
    this.createForm();
    const obj: { [key: string]: [string, Validators] | string } = {};
    this.iconService.register('visibility', '/assets/icon/visibility.svg');
    this.iconService.register('visibility-off', '/assets/icon/visibility_off.svg');

    this.structure = data.structure;
    try {
      for (const el of this.data.content.fields) {
        if (el.required) {
          obj[el.key] = ['', Validators.required];
        } else {
          obj[el.key] = '';
        }
      }
      this.mainForm = fb.group(obj);
    } catch (e) {
      /* Fails silently */
    }
  }

  genericFunction: { [key: string]: any } = {};
  structure;
  emailValidator = new UntypedFormControl('', [Validators.required, Validators.email]);
  hide = true;
  mainForm: UntypedFormGroup | undefined;
  status: string | undefined;
  isUploading = false;

  /**
   * On Init set the type of Modal wich is going to be openned
   */
  ngOnInit() {
    // Buttons translation
    this.loading.get('importFile').off();
    this.isUploading = false;
    this.genericFunction.close = (rf: MatDialogRef<any>) => rf.close();
    if (this.data.content) {
      if (this.data.content.type === 'form' && this.data.content.fields.length > 0) {
        this.isForm = true;
      } else if (this.data.content.type === 'img' && this.data.content.src.length > 0) {
        this.isImg = true;
      } else if (this.data.content.type === 'file') {
        this.isFile = true;
        this.isSignedDrivingLog = /(\/signed-pdf)$/.test(this.data.content.endpoint);
      } else if (this.data.content.type === 'html') {
        this.isHtml = true;
        this.pushHtmlTemplate();
      } else if (this.data.content.type === 'error') {
        this.isError = true;
      } else {
        if (this.structure.buttons) {
          for (const button of this.structure.buttons) {
            button.text = this.translate.instant(button.text);
          }
        }
      }
    }
  }

  sanitize(url: string) {
    return this._sanitizer.bypassSecurityTrustUrl(url);
  }

  closePopup() {
    this.dialogRef.close();
  }

  exec(f: { target: string; params: { [key: string]: any } }) {
    if (f.target === 'generic' && f.params.id !== undefined) {
      this.genericFunction[f.params.id](this.dialogRef);
    } else {
      f.params.function();
    }
  }

  // --------- Gestion d'envoi d'un fichier Début ---------
  createForm() {
    this.form = this.fb.group({
      name: ['', Validators.required],
      fileToUpload: null
    });
  }

  onFileChange(event: any) {
    const el = document.getElementById('fileToUpload') as HTMLInputElement;
    const parts = el.value.split('\\');
    this.fileName = parts.pop() || '';
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const control = this.form.get('fileToUpload');
      if (control) {
        control.setValue(file);
      }
    }
  }

  private prepareSave(): any {
    const input = new FormData();
    const control = this.form.get('fileToUpload');
    if (control) {
      input.append('fileToUpload', control.value);
    }

    return input;
  }
  // --------- Gestion d'envoi d'un fichier Fin---------

  // --------- Gestion du formulaire Début ---------
  /**
   * Gestion dynamique du nom du fichier uploader
   * @param {string} id
   */
  getFileName(id: string) {
    const el = document.getElementById(id) as HTMLInputElement;
    const parts = el.value.split('\\');
    this.fileName = parts.pop() || '';
  }

  getErrorMessage() {
    return this.emailValidator.hasError('required')
      ? 'GLOBAL.ERROR.REQUIRED'
      : this.emailValidator.hasError('email')
      ? 'ERRORS.FORM.INVALID_EMAIL'
      : '';
  }

  pushHtmlTemplate() {
    this.htmlTemplate = this.data.content.html;

    return this.htmlTemplate;
  }

  openErrorModal(error: ExtendedAPIError, titleError: string) {
    setTimeout(() => {
      this.dialogRef = this.dialog.open(GenericDialogComponent, {
        width: '35vw',
        minWidth: '250px',
        data: {
          structure: {
            title: this.translate.instant(`ERRORS.${titleError}`)
          },
          content: {
            type: 'error',
            error: error
          }
        },
        disableClose: true
      });
    }, 100);
  }

  /**
   * Build dynamically the request
   * @param data
   * @param {string} endpoint
   * @return {Promise<Object>}
   */
  sendForm(data: any, endpoint: string, method: string) {
    const url = endpoint.indexOf('://') !== -1 ? endpoint : `api://${endpoint}`;
    if (typeof data !== 'object') {
      data = JSON.parse(data);
    }
    if (method === 'patch') {
      return this.http.patch(url, data).pipe(
        catchError((res) => {
          const error = this.errorHandler.extendAPIError(res.error);
          this.progression = 0;
          this.dialogRef.close();
          this.openErrorModal(error, 'UPDATE');
          this.snackbar.dismiss();

          return observableThrowError(res);
        })
      );
    } else {
      return this.http.post(url, data).pipe(
        catchError((res) => {
          const error = this.errorHandler.extendAPIError(res.error);
          this.progression = 0;
          this.dialogRef.close();
          this.openErrorModal(error, 'CREATION');
          this.snackbar.dismiss();

          return observableThrowError(res);
        })
      );
    }
  }

  onSubmit(endpoint: string, content: any) {
    this.isUploading = true;
    if (this.isFile) {
      this.hideCancelButton = !!this.data.hideCancelButtonOnSubmit;
      this.progression = 0;
      this.loading.get('importFile').on();
      const formModel = this.prepareSave();
      if (this.isSignedDrivingLog) {
        localStorage.setItem('current_import', 'signedDrivingLog');
      } else {
        localStorage.setItem('current_import', 'users');
      }
      this.sendForm(formModel, endpoint, content.method).subscribe((result: any) => {
        if (!this.isSignedDrivingLog) {
          this.getImportProgress(endpoint, result);
        } else {
          this.getUploadManualProgress(endpoint, result, content);
        }
      });
    } else {
      if (this.mainForm !== undefined) {
        const values = this.mainForm.value || '';
        let data = values;
        const treatValuesBeforeSubmit = this.data?.content?.treatValuesBeforeSubmit;
        if (treatValuesBeforeSubmit && _.isFunction(treatValuesBeforeSubmit)) {
          data = treatValuesBeforeSubmit(values);
        }
        const body = JSON.stringify(data);
        this.sendForm(body, endpoint, content.method).subscribe((res) => {
          const onSuccess = this.data?.content?.onSuccess;
          if (onSuccess && _.isFunction(onSuccess)) {
            onSuccess(res);
          }
          if (this.data?.content?.doNotReloadOnSuccess) {
            return;
          }
          document.location.reload();
        });
        this.dialogRef.close();
      }
    }
  }

  private async getImportProgress(endpoint: string, importId: any): Promise<void> {
    if (!importId) {
      return;
    }
    const response = await lastValueFrom(
      this.http.get<{ status?: string; error?: APIError }>(`api://${endpoint}/${importId}`)
    );
    const error = response.error ? this.errorHandler.extendAPIError(response.error) : undefined;
    const handleErrors = () => this.openErrorModal(error!, 'IMPORT');
    this.status = response.status || '';
    if (!['success', 'failed', 'deleted'].includes(this.status)) {
      setTimeout(() => this.getImportProgress(endpoint, importId), 1000);
    }
    this._getImportProgress(response, handleErrors);
  }

  private async getUploadManualProgress(endpoint: string, uploadId: any, content: any): Promise<void> {
    if (!uploadId) {
      return;
    }
    const response = await lastValueFrom(
      this.http.get<{ status?: string; error?: any }>(`api://${content.baseURL}/${uploadId}/upload_status`)
    );
    const error = response.error ? this.errorHandler.extendAPIError(response.error) : undefined;
    const handleErrors = () => this.openErrorModal(error!, 'CREATION');
    this.status = response.status || '';
    if (!['success', 'failed', 'deleted'].includes(this.status!)) {
      setTimeout(() => this.getUploadManualProgress(endpoint, uploadId, content), 1000);
    }
    this._getImportProgress(response, handleErrors);
  }

  private _getImportProgress(response: { status?: string; error?: APIError }, onError: () => void): void {
    switch (this.status) {
      case 'in queue':
        this.progression = 33;
        break;
      case 'ongoing':
        this.progression = 66;
        break;
      case 'success':
        this.progression = 100;
        localStorage.removeItem('current_import');
        this.dialogRef.close(response);
        this.isUploading = false;
        break;
      case 'failed':
        this.progression = 0;
        localStorage.removeItem('current_import');
        this.dialogRef.close();
        this.isUploading = false;
        onError?.();
        break;
      case 'deleted':
        this.progression = 100;
        localStorage.removeItem('current_import');
        this.isUploading = false;
        break;
      case 'unknown':
        this.progression = 0;
        this.isUploading = false;
        break;
      default:
        this.progression = 0;
        break;
    }
  }
}
