import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  selector: 'app-textarea',
  imports: [CommonModule, TranslateModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatIconModule],
  templateUrl: './textarea.component.html'
})
export class TextareaComponent {
  @Input() public pformGroup: UntypedFormGroup;
  @Input() public pformControlName: string;
  @Input() public disabled?: boolean = false;
  @Input() public label?: string;
  @Input() public icon?: string;
}
