<div class="block w-full min-h-100vh login-bg">
  <div class="flex w-full min-h-100vh justify-center items-center">
    <div class="login-modal">
      <div class="login-logo">
        <img src="{{ srcLogo }}" />
      </div>
      <div class="form-row button-container">
        <span class="main-buttons-span">
          <button type="button" (click)="login()" class="validate-button">
            <span>{{ 'GLOBAL.LOGIN_WITH_IDP' | translate }}</span>
          </button>
          <div class="links">
            <button (click)="forgottenPassword()">{{ 'USERS.FORGETTEN_PASSWORD' | translate }}</button>
            <button (click)="profileService.handleClearProfileClick()">
              {{ 'PROFILES.CLEAR_PROFILE_CODE' | translate }}
            </button>
          </div>
        </span>
      </div>
    </div>
  </div>
</div>
