<ng-container [formGroup]="pformGroup">
  <mat-form-field class="w-full">
    <mat-label>{{ label }}</mat-label>
    <!-- If search: Select -->
    <ng-container *ngIf="!isSearch">
      <mat-select [formControlName]="pformControlName" [compareWith]="isSameOption">
        <app-select-options [options]="filteredOptions">
          <ng-template *ngIf="optionTemplateRef" appOptionTemplate let-option>
            <ng-container
              *ngTemplateOutlet="
                optionTemplateRef;
                context: { $implicit: option, option: option, value: option.value, original: option.original }
              "
            />
          </ng-template>
        </app-select-options>
      </mat-select>
    </ng-container>
    <!-- If no search: Autocomplete -->
    <ng-container *ngIf="isSearch">
      <input type="text" matInput [formControlName]="pformControlName" [matAutocomplete]="autoGroup" />
      <!-- <mat-icon matSuffix>keyboard_arrow_down</mat-icon> -->
      <mat-autocomplete #autoGroup="matAutocomplete" [displayWith]="_displayValue">
        <app-select-options [options]="filteredOptions" [isLoadingOptions]="isLoadingOptions">
          <ng-template *ngIf="optionTemplateRef" appOptionTemplate let-option>
            <ng-container
              *ngTemplateOutlet="
                optionTemplateRef;
                context: { $implicit: option, option: option, value: option.value, original: option.original }
              "
            />
          </ng-template>
        </app-select-options>
      </mat-autocomplete>
    </ng-container>
  </mat-form-field>
</ng-container>
