import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import {
  DrivingLogEvent,
  DrivingLogEventType,
  IDrivingLog,
  IDrivingLogArchive
} from 'app/interface/driving-logs.interface';
import { IListResult } from 'app/interface/list-result.interface';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { lastValueFrom } from 'rxjs';

import { DrivingLogoutDialogComponent } from '../components/driving-logs/driving-logout/driving-logout.dialog.component';
import { GenericDialogComponent } from '../components/generic/generic-dialog/generic-dialog.component';
import { SessionService } from '../components/generic/services/session/session.service';

export type IDrivingLogsResponse = IListResult<IDrivingLog, { max_age_days?: number }>;

@Injectable()
export class DrivingLogsService {
  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
    private translate: TranslateService,
    private sessionService: SessionService
  ) {}

  public getExpiredFromTeams(teams: string[]): Promise<IDrivingLogsResponse> {
    return lastValueFrom(
      this.http.get<IDrivingLogsResponse>(`api://driving-logs`, { params: { teams, expired: 'true' } })
    );
  }

  getById(id: string) {
    return this.http.get<IDrivingLog>(`api://driving-logs/${id}`);
  }

  getArchives(params: any) {
    return this.http.get<IListResult<IDrivingLogArchive>>('api://driving-logs/archives', { params });
  }

  getDrivingLogs(params: any) {
    return this.http.get<IDrivingLogsResponse>('api://driving-logs', { params });
  }

  public async downloadMyPdfBlob(): Promise<HttpResponse<Blob>> {
    return lastValueFrom(this.http.get(`api://driving-logs/pdf`, { observe: 'response', responseType: 'blob' }));
  }

  public async downloadPdfBlob(id: string): Promise<HttpResponse<Blob>> {
    return lastValueFrom(this.http.get(`api://driving-logs/${id}/pdf`, { observe: 'response', responseType: 'blob' }));
  }

  public async downloadPdf(id: string): Promise<void> {
    const response = await this.downloadPdfBlob(id);
    const blob = response.body!;
    const downloadDate = dayjs().format('DD-MM-YYYY-hhmm');
    const defaultDownloadedFilename = `${id}-${downloadDate}.pdf`;
    const filename = response.headers.get('File-Name') || defaultDownloadedFilename;
    saveAs(blob, filename);
  }

  public async downloadArchivedPdfBlob(id: string): Promise<HttpResponse<Blob>> {
    return lastValueFrom(
      this.http.get(`api://driving-logs/archives/${id}/pdf`, { responseType: 'blob', observe: 'response' })
    );
  }

  public async downloadArchivedPdf(id: string): Promise<void> {
    const blob = await this.downloadArchivedPdfBlob(id);
    const downloadDate = dayjs().format('DD-MM-YYYY-hhmm');
    const downloadedFilename = `${id}-${downloadDate}.pdf`;

    saveAs(blob.body!, blob.headers.get('File-Name') || downloadedFilename);
  }

  public async getGPX(id: string, archived?: boolean): Promise<void> {
    const downloadDate = dayjs().format('DD-MM-YYYY-hhmm');
    const downloadedFilename = `${id}-${downloadDate}.gpx`;
    const url = archived ? `api://driving-logs/${id}/gpx?archived=true` : `api://driving-logs/${id}/gpx`;
    const blob = await lastValueFrom(this.http.get(url, { responseType: 'blob' }));
    saveAs(blob, downloadedFilename);
  }

  getManualUploadStatus(id: string) {
    return this.http.get<{ status: string; time?: string; error?: any }>(`api://driving-logs/${id}/upload_status`);
  }

  getUploadStatus(id: string) {
    return this.http.get<{ status: string; time?: string; error?: any }>(`api://driving-logs/${id}/status`);
  }

  public async close(id: string): Promise<void> {
    await lastValueFrom(this.http.post(`api://driving-logs/${id}/close`, {}));
  }

  public async closeDrivingLog(): Promise<void> {
    await lastValueFrom(this.http.post(`api://driving-logs/close`, {}));
  }

  public async closeExpired(id: string): Promise<void> {
    await lastValueFrom(this.http.post(`api://driving-logs/${id}/close_expired`, {}));
  }

  public async addEvent(event: DrivingLogEvent): Promise<void> {
    await lastValueFrom(this.http.post(`api://driving-logs/events`, event));
  }

  addCommentStructure() {
    return {
      structure: {
        title: 'GLOBAL.ADD_COMMENT'
      },
      content: {
        endpoint: `/driving-logs/events`,
        method: 'post',
        treatValuesBeforeSubmit: (values: { comment: string }) => {
          const event: DrivingLogEvent = {
            type: DrivingLogEventType.COMMENT,
            comment: values.comment,
            horodate: new Date()
          };
          return event;
        },
        onSuccess: () => {
          this.closeDialog();
        },
        doNotReloadOnSuccess: true,
        type: 'form',
        fields: [
          {
            key: 'comment',
            title: 'GLOBAL.COMMENT',
            value: '',
            type: 'text',
            required: true
          }
        ],
        button: {
          text: this.translate.instant('GLOBAL.CONFIRM'),
          class: 'validate'
        }
      }
    };
  }

  public openAddCommentDialog(): void {
    this.dialog.open(GenericDialogComponent, {
      width: '50%',
      data: this.addCommentStructure(),
      disableClose: true
    });
  }

  public openDrivingLogoutDialog(): void {
    this.dialog.open(DrivingLogoutDialogComponent, { width: '70%' });
  }

  public closeDialog() {
    this.dialog.closeAll();
  }

  public async closeCurrentDrivingLogAndLogout(): Promise<void> {
    await this.addEvent({ type: DrivingLogEventType.LOGOUT, horodate: new Date() });
    await this.closeDrivingLog();
    await this.sessionService.logout();
  }
}
