<h1 class="mainTitle">{{ 'GLOBAL.BREADCRUMB.INFRASTRUCTURE_TYPES.LIST' | translate }}</h1>

<app-loading [active]="!loaded" />
<app-list
  *ngIf="loaded"
  addButton="dynamic"
  [listParameters]="listParameters"
  [items]="items"
  [total]="total"
  (refresh)="handleRefresh($event)"
  (action)="handleAction($event)"
/>
