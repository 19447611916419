import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DatepickerQuestion } from 'app/components/generic/questions/question-datepicker';
import { DropdownQuestion } from 'app/components/generic/questions/question-dropdown';
import { TabQuestion } from 'app/components/generic/questions/question-tab';
import { TextareaQuestion } from 'app/components/generic/questions/question-textarea';
import { TextboxQuestion } from 'app/components/generic/questions/question-textbox';
import { ITeam } from 'app/interface/team.interface';
import { IUser } from 'app/interface/user.interface';
import { InstructionsService } from 'app/services/instructions.service';
import { TeamsService } from 'app/services/teams.service';
import { TitleService } from 'app/services/title.service';
import { UsersService } from 'app/services/users.service';
import dayjs from 'dayjs';
import { forkJoin as observableForkJoin } from 'rxjs';

@Component({
  selector: 'app-repositories',
  templateUrl: './new-instruction.component.html',
  styleUrls: ['./new-instruction.component.scss']
})
export class NewInstructionComponent implements OnInit {
  public questions: any[] = [];
  public exportsChild: any = null;

  usersList: { id: string; value: string }[] | IUser[] = [];
  teamsList: { id: string; value: string }[] | ITeam[] = [];
  selectedTab: any;
  selectedAssigned: any[] = [];
  disabledSubmit = true;
  loaded = false;

  /*
   * Change the disabledSubmit param.
   * The form is valid if all validators passed + disabledSubmit is equal false.
   * Allow to check valid fields for particular fields like datePicker dependencies or TabQuestion
   */
  checkLocalRequiredSubmit: any = {
    from_date: '',
    to_date: '',
    assigned: {}
  };

  constructor(
    private title: TitleService,
    private instructionsService: InstructionsService,
    private router: Router,
    private usersService: UsersService,
    private teamsService: TeamsService,
    private translate: TranslateService,
    private snackbar: MatSnackBar
  ) {
    dayjs().locale('fr');
    this.title.setTitle('INSTRUCTIONS.LABEL_P');
  }

  ngOnInit() {
    const serviceNumber = localStorage.getItem('service_number');
    if (serviceNumber) {
      this.usersService.getById(serviceNumber).subscribe((res: any) => {
        if (res.teams) {
          const myTeams = res.teams.map((team: any) => team.id);
          observableForkJoin([
            this.teamsService.getAll({ sort: 'name', fields: 'id,name', id: myTeams.join(',') }),
            this.usersService.getAll({
              sort: 'last_name,first_name',
              fields: 'service_number,last_name,first_name',
              teams: myTeams.join(',')
            })
          ]).subscribe(([teams, users]) => {
            this.usersList = users.items.map((user) => ({
              id: user.service_number,
              value: `${user.last_name} ${user.first_name}`
            }));
            this.teamsList = teams.items.map((team) => ({
              id: team.id,
              value: `${team.name}`
            }));
            this.exportsChild = this;
            this.loaded = true;
            this.generateForm();
          });
        } else {
          this.instructionsService.openSnackbar('NO_ASSIGNED_TEAM_ERROR');
        }
      });
      this.checkLocalRequiredSubmit.from_date = dayjs();
      this.checkLocalRequiredSubmit.to_date = dayjs().add(3, 'months');
    }
  }

  formSubmitted(form: UntypedFormGroup) {
    const formSubmit = this.instructionsService.verificationFormData(form.value);
    if (formSubmit !== false) {
      this.instructionsService.add(formSubmit).subscribe((res) => {
        if (res.hasOwnProperty('_id')) {
          const params = {
            start: dayjs(res.start_date).format('L'),
            end: dayjs(res.end_date).format('L')
          };
          this.snackbar.open(
            this.translate.instant('INSTRUCTIONS.CREATION_SUCCESSFUL', params),
            this.translate.instant('GLOBAL.CLOSE_LABEL')
          );

          this.router.navigate([`instructions`]);
        }
      });
    }
  }

  manageSelectedItems(tab: any, item: any) {
    const keys = Object.keys(this.checkLocalRequiredSubmit.assigned);
    if (!keys.some((searchTab) => searchTab === tab.value)) {
      this.checkLocalRequiredSubmit.assigned[tab.value] = [item.id];
    } else {
      const index = this.checkLocalRequiredSubmit.assigned[tab.value].indexOf(item.id);
      if (index === -1) {
        this.checkLocalRequiredSubmit.assigned[tab.value].push(item.id);
      } else {
        this.checkLocalRequiredSubmit.assigned[tab.value].splice(index, 1);
      }

      if (this.checkLocalRequiredSubmit.assigned[tab.value].length === 0) {
        delete this.checkLocalRequiredSubmit.assigned[tab.value];
      }
    }
  }

  checkLocalFormValid() {
    this.disabledSubmit = false;
    if (Object.keys(this.checkLocalRequiredSubmit.assigned).length === 0) {
      this.disabledSubmit = true;
    } else if (this.checkLocalRequiredSubmit.from_date && !this.checkLocalRequiredSubmit.to_date) {
      this.disabledSubmit = true;
    } else if (!this.checkLocalRequiredSubmit.from_date && this.checkLocalRequiredSubmit.to_date) {
      this.disabledSubmit = true;
    }
  }

  generateForm(): any {
    this.questions = [
      [
        new TextboxQuestion({
          key: 'title',
          label: 'INSTRUCTIONS.TITLE',
          value: '',
          controls: [{ key: 'required' }],
          pristine: true,
          order: 5,
          size: '1-1',
          disabled: false
        })
      ],
      [
        new TextareaQuestion({
          key: 'description',
          label: 'INSTRUCTIONS.DESCRIPTION',
          value: '',
          controls: [],
          pristine: true,
          order: 7,
          size: '1-1',
          disabled: false
        })
      ],
      [
        new DropdownQuestion({
          key: 'priority',
          label: 'INSTRUCTIONS.PRIORITY',
          value: '',
          controls: [{ key: 'required' }],
          pristine: true,
          order: 3,
          size: '1-2',
          disabled: false,
          options: [
            {
              key: 'high',
              value: 'INSTRUCTIONS.PRIORITY_VALUES.HIGH'
            },
            {
              key: 'normal',
              value: 'INSTRUCTIONS.PRIORITY_VALUES.NORMAL'
            }
          ]
        })
      ],
      [
        new DatepickerQuestion({
          readonly: true,
          key: 'from_date',
          label: 'INSTRUCTIONS.DISPLAYED_FROM',
          size: '1-2',
          value: new Date(),
          min: new Date(),
          events: {
            change: (value: string, _event: any) => {
              this.checkLocalRequiredSubmit.from_date = value;
              if (value === null) {
                this.questions[3][1].min = dayjs().endOf('days');
              } else {
                this.questions[3][1].min = dayjs(value).endOf('days');
              }
              this.checkLocalFormValid();
            }
          }
        }),
        new DatepickerQuestion({
          readonly: true,
          key: 'to_date',
          label: 'INSTRUCTIONS.DISPLAYED_TO',
          size: '1-2',
          value: new Date(),
          min: new Date(),
          events: {
            change: (value: string, _event: any) => {
              this.checkLocalRequiredSubmit.to_date = value;
              if (value === null) {
                this.questions[3][0].max = null;
              } else {
                this.questions[3][0].max = dayjs(value).endOf('days');
              }
              this.checkLocalFormValid();
            }
          }
        })
      ],
      [
        new TabQuestion({
          key: 'assigned',
          size: '1-1',
          value: {},
          options: {
            disableOtherTab: true
          },
          tabs: [
            {
              value: 'teams',
              disabled: false,
              icon: 'group',
              label: 'INSTRUCTIONS.MY_TEAMS',
              type: 'teams',
              items: this.teamsList
            },
            {
              value: 'users',
              disabled: false,
              icon: 'person',
              label: 'INSTRUCTIONS.MY_USERS_TEAMS',
              type: 'users',
              items: this.usersList
            }
          ],
          events: {
            click: (tab: string, item: any, _event: any) => {
              this.manageSelectedItems(tab, item);
              this.checkLocalFormValid();
            }
          }
        })
      ]
    ];
  }
}
