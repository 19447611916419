import { Component, ViewEncapsulation } from '@angular/core';
import { SessionService } from 'app/components/generic/services/session/session.service';
import { DrivingLogsService } from 'src/app/services/driving-logs.service';
import { RolesService } from 'src/app/services/roles.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LogoutComponent {
  constructor(
    private session: SessionService,
    private drivingLogsService: DrivingLogsService,
    private rolesService: RolesService
  ) {}

  public handleClick(): void {
    this.openDialog();
  }

  openDialog(): void {
    const isOperator = this.rolesService.isOperator();
    if (isOperator) {
      this.drivingLogsService.openDrivingLogoutDialog();
      return;
    }
    this.session.openLogoutConfirmDialog();
  }
}
