import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-teams-dialog',
  templateUrl: './teams-dialog.component.html',
  styleUrls: ['./teams-dialog.component.scss']
})
export class TeamsDialogComponent {
  public usersList: any = {};
  constructor(
    public dialogRef: MatDialogRef<TeamsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.usersList = data.users;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
