import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { DateFilterFn, MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  selector: 'app-date-input',
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule
  ],
  templateUrl: './date-input.component.html'
})
export class DateInputComponent {
  @Input() public pformGroup: UntypedFormGroup;
  @Input() public pformControlName: string;
  @Input() public disabled?: boolean = false;
  @Input() public label?: string;
  @Input() public placeholder?: string;
  @Input() public filter?: DateFilterFn<Date>;
  @Input() public min?: Date;
  @Input() public max?: Date;
}
