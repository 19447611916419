import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { IGenericListParameters } from 'app/components/generic/list/list.interface';
import { InfrastructuresService } from 'app/services/infrastructures.service';
import { LoadingService } from 'app/services/loading.service';
import { TitleService } from 'app/services/title.service';
import { openGenericDeleteDialog } from 'src/app/components/generic/generic-dialog/generic-delete.dialog';
import { GenericDialogComponent } from 'src/app/components/generic/generic-dialog/generic-dialog.component';
import {
  generateEditButton,
  generateRemoveButton,
  ListActionEvent
} from 'src/app/components/generic/list/list.component';

@Component({
  templateUrl: './infrastructure-types.list.component.html',
  selector: 'app-infrastructure-types-list'
})
export class InfrastructureTypeListComponent implements OnInit {
  public items: any[] = [];
  public total = 0;
  public loaded = false;

  public listParameters: IGenericListParameters = {
    paginator: true,
    clickable: false,
    listHead: [
      {
        key: 'name',
        type: 'text',
        title: 'INFRASTRUCTURE_TYPES.NAME',
        color: 'main-color-light',
        styles: { fontWeight: 'bold' }
      },
      {
        key: 'picto',
        type: 'imageSrc',
        title: 'INFRASTRUCTURE_TYPES.PICTO'
      },
      {
        key: 'pin',
        type: 'imageSrc',
        title: 'INFRASTRUCTURE_TYPES.PIN'
      },
      {
        key: 'actions',
        type: 'button'
      }
    ]
  };

  constructor(
    public loading: LoadingService,
    private translate: TranslateService,
    private title: TitleService,
    public dialog: MatDialog,
    private infrastructures: InfrastructuresService
  ) {
    this.title.setTitle('GLOBAL.BREADCRUMB.INFRASTRUCTURE_TYPES.LIST');
  }

  async ngOnInit() {
    this.fetchData();
  }

  private fetchData(): void {
    this.loaded = false;
    this.loading.on();
    this.infrastructures.getInfrastructureTypesAndCount().then(([items, total]) => {
      this.loading.off();
      this.items = this.parseData(items);
      this.total = total;
      this.loaded = true;
    });
  }

  private parseData(items: any[]): any[] {
    return items.map((e) => {
      return {
        ...e,
        actions: [generateEditButton, generateRemoveButton],
        original: e
      };
    });
  }

  private async deleteOne(id: string): Promise<void> {
    this.infrastructures.deleteOneInfrastructureType(id).then(() => this.fetchData());
  }

  public handleRefresh(): void {
    this.fetchData();
  }

  public handleAction(event: ListActionEvent): void {
    const { type } = event;
    if (!type) {
      return;
    }
    switch (type) {
      case 'addButtonClick':
        this.dialog.open(GenericDialogComponent, {
          width: '40%',
          data: this.addModalStructure(),
          disableClose: true
        });
        break;
      case 'edit':
        this.dialog.open(GenericDialogComponent, {
          width: '40%',
          data: this.editModalStructure(event.data.id, event.data),
          disableClose: true
        });
        break;
      case 'delete':
        openGenericDeleteDialog(this.dialog, 'CONFIRM.DELETE_INFRASTRUCTURE_TYPE', () => this.deleteOne(event.data.id));
        break;
    }
  }

  private addModalStructure() {
    return {
      structure: {
        title: 'GLOBAL.BREADCRUMB.INFRASTRUCTURE_TYPES.NEW',
        text: 'GLOBAL.BREADCRUMB.INFRASTRUCTURE_TYPES.NEW'
      },
      content: {
        endpoint: `/infrastructure-types`,
        method: 'post',
        treatValuesBeforeSubmit: (values: any) => {
          const obj = { ...JSON.parse(values.json) };
          return obj;
        },
        onSuccess: () => this.fetchData(),
        doNotReloadOnSuccess: true,
        type: 'form',
        fields: [
          {
            key: 'json',
            title: 'JSON',
            value: '',
            type: 'textarea',
            cdkAutosizeMaxRows: '12'
          }
        ],
        button: {
          text: this.translate.instant('GLOBAL.SUBMIT_BUTTON_LABEL'),
          class: 'validate'
        }
      }
    };
  }

  private editModalStructure(id: string, data: any) {
    const initialValues = { ...data.original };
    delete initialValues.id;
    delete initialValues.label;
    return {
      structure: {
        title: 'GLOBAL.BREADCRUMB.INFRASTRUCTURE_TYPES.EDIT',
        text: 'GLOBAL.BREADCRUMB.INFRASTRUCTURE_TYPES.EDIT'
      },
      content: {
        endpoint: `/infrastructure-types/${id}`,
        method: 'patch',
        treatValuesBeforeSubmit: (values: any) => {
          const obj = { ...JSON.parse(values.json) };
          return obj;
        },
        onSuccess: () => this.fetchData(),
        doNotReloadOnSuccess: true,
        type: 'form',
        fields: [
          {
            key: 'json',
            title: 'JSON',
            value: JSON.stringify(initialValues, null, 2),
            type: 'textarea',
            cdkAutosizeMaxRows: '12'
          }
        ],
        button: {
          text: this.translate.instant('GLOBAL.SUBMIT_BUTTON_LABEL'),
          class: 'validate'
        },
        disableClose: true
      }
    };
  }
}
