import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DatepickerQuestion } from 'app/components/generic/questions/question-datepicker';
import { DropdownQuestion } from 'app/components/generic/questions/question-dropdown';
import { TabQuestion } from 'app/components/generic/questions/question-tab';
import { TextareaQuestion } from 'app/components/generic/questions/question-textarea';
import { TextboxQuestion } from 'app/components/generic/questions/question-textbox';
import { IInstruction } from 'app/interface/instruction.interface';
import { ITeam } from 'app/interface/team.interface';
import { IUser } from 'app/interface/user.interface';
import { InstructionsService } from 'app/services/instructions.service';
import { TeamsService } from 'app/services/teams.service';
import { TitleService } from 'app/services/title.service';
import { UsersService } from 'app/services/users.service';
import dayjs from 'dayjs';
import _ from 'lodash';
import { forkJoin as observableForkJoin } from 'rxjs';

@Component({
  selector: 'app-repositories',
  templateUrl: './edit-instruction.component.html',
  styleUrls: ['./edit-instruction.component.scss']
})
export class EditInstructionComponent implements OnInit {
  public questions: any[] = [];
  public exportsChild: any = null;

  usersList: { id: string; value: string }[] | IUser[] = [];
  teamsList: { id: string; value: string }[] | ITeam[] = [];
  selectedTab: any;
  selectedAssigned: any[] = [];
  disabledSubmit = true;
  id: string;
  loaded = false;
  instruction: IInstruction | any;
  // Dynamic values (edit / detail)
  titleForm = '';
  edit = false;
  displayValidateButton = false;
  cancelButton = {
    activated: false,
    backTo: ''
  };

  /*
   * Change the disabledSubmit param.
   * The form is valid if all validators passed + disabledSubmit is equal false.
   * Allow to check valid fields for particular fields like datePicker dependencies or TabQuestion
   */
  checkLocalRequiredSubmit: any = {
    from_date: '',
    to_date: '',
    assigned: {}
  };

  constructor(
    private title: TitleService,
    private instructionsService: InstructionsService,
    private router: Router,
    private route: ActivatedRoute,
    private usersService: UsersService,
    private teamsService: TeamsService,
    private translate: TranslateService
  ) {
    this.title.setTitle('INSTRUCTIONS.LABEL_P');
    this.route.params.subscribe((params) => (this.id = params.id));

    route.data.subscribe((data) => {
      title.setTitle(data.breadcrumb);

      this.titleForm = data.breadcrumb;
      this.edit = data.edit;

      if (this.edit) {
        this.displayValidateButton = true;
        this.cancelButton = {
          activated: true,
          backTo: 'instructions'
        };
      }
    });
  }

  ngOnInit() {
    this.exportsChild = this;
    const serviceNumber = localStorage.getItem('service_number');

    if (serviceNumber) {
      this.usersService.getById(serviceNumber).subscribe((res: any) => {
        if (res.teams) {
          const myTeams = res.teams.map((team: any) => team.id);
          observableForkJoin([
            this.teamsService.getAll({ sort: 'name', fields: 'id,name', id: myTeams }),
            this.usersService.getAll({
              sort: 'last_name,first_name',
              fields: 'service_number,last_name,first_name',
              teams: myTeams
            })
          ]).subscribe(([teams, users]) => {
            this.usersList = users.items.map((user) => ({
              id: user.service_number,
              value: `${user.last_name} ${user.first_name}`
            }));
            this.teamsList = teams.items.map((team) => ({
              id: team.id,
              value: `${team.name}`
            }));

            this.instructionsService.getOne(this.id).subscribe((resInstruction: any) => {
              this.instruction = resInstruction;
              this.checkLocalRequiredSubmit.assigned[resInstruction.assigned.type] = resInstruction.assigned.data || {};
              this.checkLocalRequiredSubmit.from_date = resInstruction.start_date || dayjs();
              this.checkLocalRequiredSubmit.to_date = resInstruction.end_date || dayjs().add(3, 'months');
              this.checkLocalFormValid();
              this.generateForm();
              this.loaded = true;
            });
          });
        } else {
          this.instructionsService.openSnackbar('NO_ASSIGNED_TEAM_ERROR');
        }
      });
    }
  }

  goToEdit() {
    if (!this.edit) {
      this.router.navigate([`instructions/${this.id}/edit`]);
    }
  }

  checkModifiedAttributes(formSubmit: any) {
    const simpleCompare = ['title', 'description', 'important'];
    for (const compare of simpleCompare) {
      if (formSubmit[compare] === this.instruction[compare]) {
        delete formSubmit[compare];
      }
    }

    const newStartDate = dayjs(formSubmit.start_date).startOf('day');
    const startDate = dayjs(this.instruction.start_date).startOf('day');
    if (newStartDate.isSame(startDate)) {
      delete formSubmit.start_date;
    }

    const newEndDate = dayjs(formSubmit.end_date).endOf('day');
    const endDate = dayjs(this.instruction.end_date).endOf('day');
    if (newEndDate.isSame(endDate)) {
      delete formSubmit.end_date;
    }

    if (
      formSubmit.assigned.type === this.instruction.assigned.type &&
      _.intersection(formSubmit.assigned.data, this.instruction.assigned.data).length === 0
    ) {
      delete formSubmit.assigned;
    }

    return formSubmit;
  }

  formSubmitted(form: UntypedFormGroup) {
    let formSubmit = this.instructionsService.verificationFormData(form.value);

    if (formSubmit !== false) {
      formSubmit = this.checkModifiedAttributes(formSubmit);

      if (Object.keys(formSubmit).length > 0) {
        this.instructionsService.patch(formSubmit, this.id).subscribe((res) => {
          if (res.hasOwnProperty('_id')) {
            this.router.navigate([`instructions`]);
          }
        });
      } else {
        this.router.navigate([`instructions`]);
      }
    }
  }

  manageSelectedItems(tab: any, item: any) {
    const keys = Object.keys(this.checkLocalRequiredSubmit.assigned);
    if (!keys.some((searchTab) => searchTab === tab.value)) {
      this.checkLocalRequiredSubmit.assigned[tab.value] = [item.id];
    } else {
      const index = this.checkLocalRequiredSubmit.assigned[tab.value].indexOf(item.id);
      if (index === -1) {
        this.checkLocalRequiredSubmit.assigned[tab.value].push(item.id);
      } else {
        this.checkLocalRequiredSubmit.assigned[tab.value].splice(index, 1);
      }
      if (this.checkLocalRequiredSubmit.assigned[tab.value].length === 0) {
        delete this.checkLocalRequiredSubmit.assigned[tab.value];
      }
    }
  }

  checkLocalFormValid() {
    this.disabledSubmit = false;
    if (Object.keys(this.checkLocalRequiredSubmit.assigned).length === 0) {
      this.disabledSubmit = true;
    } else if (!this.checkLocalRequiredSubmit.from_date) {
      this.disabledSubmit = true;
    } else if (!this.checkLocalRequiredSubmit.to_date) {
      this.disabledSubmit = true;
    }
  }

  getPriorityValue() {
    const priority = this.instruction.type === 'PRIORITY' ? 'high' : 'normal';
    if (this.edit) {
      return priority;
    }

    return this.translate.instant(`INSTRUCTIONS.PRIORITY_VALUES.${priority.toUpperCase()}`);
  }

  generateForm(): any {
    const startDate = this.edit ? this.instruction.start_date : dayjs(this.instruction.start_date).format('DD/MM/YYYY');
    const endDate = this.edit ? this.instruction.end_date : dayjs(this.instruction.end_date).format('DD/MM/YYYY');

    this.questions = [
      [
        new TextboxQuestion({
          key: 'title',
          label: 'INSTRUCTIONS.TITLE',
          value: this.instruction.title,
          controls: [{ key: 'required' }],
          pristine: true,
          order: 5,
          size: '1-1',
          disabled: false,
          readonly: !this.edit
        })
      ],
      [
        new TextareaQuestion({
          key: 'description',
          label: 'INSTRUCTIONS.DESCRIPTION',
          value: this.instruction.description,
          controls: [],
          pristine: true,
          order: 7,
          size: '1-1',
          disabled: false,
          readonly: !this.edit
        })
      ],
      [
        new DropdownQuestion({
          key: 'priority',
          label: 'INSTRUCTIONS.PRIORITY',
          value: this.getPriorityValue(),
          controls: [{ key: 'required' }],
          pristine: true,
          order: 3,
          size: '1-2',
          disabled: false,
          options: [
            {
              key: 'high',
              value: 'INSTRUCTIONS.PRIORITY_VALUES.HIGH'
            },
            {
              key: 'normal',
              value: 'INSTRUCTIONS.PRIORITY_VALUES.NORMAL'
            }
          ],
          readonly: !this.edit
        })
      ],
      [
        new DatepickerQuestion({
          readonly: true,
          edit: this.edit,
          key: 'from_date',
          label: 'INSTRUCTIONS.DISPLAYED_FROM',
          size: '1-2',
          value: startDate,
          filter: (date: Date) => {
            const isStartDate = dayjs(date).startOf('day').isSame(dayjs(this.instruction.start_date).startOf('day'));
            const isAfterToday = dayjs(date).isAfter(dayjs().add(-1, 'day').startOf('day'));

            return isStartDate || isAfterToday;
          },
          events: {
            change: (value: string, _event: any) => {
              this.checkLocalRequiredSubmit.from_date = value;
              if (value === null) {
                this.questions[3][1].min = dayjs().endOf('days');
              } else {
                if (dayjs(value).isBefore(dayjs().startOf('day'))) {
                  this.questions[3][1].min = dayjs().endOf('days');
                } else {
                  this.questions[3][1].min = dayjs(value).endOf('days');
                }
              }
              this.checkLocalFormValid();
            }
          }
        }),
        new DatepickerQuestion({
          readonly: true,
          edit: this.edit,
          key: 'to_date',
          label: 'INSTRUCTIONS.DISPLAYED_TO',
          size: '1-2',
          value: endDate,
          min: dayjs(),
          events: {
            change: (value: string, _event: any) => {
              this.checkLocalRequiredSubmit.to_date = value;
              if (value === null) {
                this.questions[3][0].max = null;
              } else {
                this.questions[3][0].max = dayjs(value).endOf('days');
              }
              this.checkLocalFormValid();
            }
          }
        })
      ],
      [
        new TabQuestion({
          key: 'assigned',
          size: '1-1',
          value: _.cloneDeep(this.checkLocalRequiredSubmit.assigned),
          options: {
            disableOtherTab: true
          },
          tabs: [
            {
              value: 'teams',
              disabled: false,
              icon: 'group',
              label: 'INSTRUCTIONS.MY_TEAMS',
              type: 'teams',
              items: this.teamsList
            },
            {
              value: 'users',
              disabled: false,
              icon: 'person',
              label: 'INSTRUCTIONS.MY_USERS_TEAMS',
              type: 'users',
              items: this.usersList
            }
          ],
          events: {
            click: (tab: string, item: any, _event: any) => {
              this.manageSelectedItems(tab, item);
              this.checkLocalFormValid();
            }
          },
          readonly: !this.edit
        })
      ]
    ];
  }
}
