import { CommonModule, Location } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  selector: 'app-cancel-button',
  imports: [CommonModule, TranslateModule, MatButtonModule],
  templateUrl: './cancel-button.component.html'
})
export class CancelButtonComponent {
  @Input() public pformGroup: UntypedFormGroup;
  @Input() public back?: boolean;
  @Input() public backTo?: string;

  @Output() cancel = new EventEmitter<void>();

  constructor(
    private location: Location,
    private router: Router
  ) {}

  public handleCancelClick() {
    this.cancelForm();
  }

  private cancelForm() {
    // From old dynamic form
    // this.questions.map((el: QuestionBase<any>) => {
    //   if (el.controlType && el.controlType.toLowerCase() === 'file') {
    //     el.fileName = '';
    //   }
    // });

    this.pformGroup.reset();
    if (this.back) {
      this.location.back();
    } else if (this.backTo) {
      this.router.navigate([this.backTo]);
    }
    this.cancel.emit();
  }
}
