<div id="crossing-point-manager-cmp" *ngIf="mapsApiLoaded">
  <div id="types-container">
    <div
      class="typeItem"
      *ngFor="let type of types"
      [key]="type.name"
      [ngClass]="{ active: isActiveFilter(type.name) }"
      title="{{ 'CROSSING_POINTS.TYPES.' + type.name.toUpperCase() | translate }}"
      (click)="toggleFilter(type)"
    >
      <img [src]="type.picto" alt="" />
    </div>
  </div>
  <div id="map-container">
    <div id="filters">
      <span class="title" *ngIf="loaded">
        {{ 'GLOBAL.BREADCRUMB.CROSSING_POINTS.MANAGER' | translate }}
        <mat-icon matTooltip="{{ 'CROSSING_POINTS.MANAGER.TOOLTIP' | translate }}">help_outline</mat-icon>
      </span>

      <div class="fill"></div>

      <mat-form-field *ngIf="loaded">
        <mat-label>{{ 'CROSSING_POINTS.MANAGER.SECTORS' | translate }}</mat-label>
        <mat-select [formControl]="sectorsList" multiple (selectionChange)="filterData()">
          <mat-option *ngFor="let sector of sectors" [value]="sector.id">{{ sector.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div id="map">
      <google-map
        width="100%"
        height="100%"
        [options]="{
          zoom: zoom,
          center: { lat, lng },
          mapTypeControl: true,
          disableDoubleClickZoom: true
        }"
        (mapDblclick)="handleMapDblclick($event)"
        (zoomChanged)="handleZoomChange($event)"
      >
        <map-marker
          *ngFor="let cp of crossingPointsFiltered; let i = index"
          [options]="{
            position: {
              lat: cp.coordinates.latitude,
              lng: cp.coordinates.longitude
            },
            title: cp.title,
            icon: cp.style.url,
            draggable: isEditable(i)
          }"
          (mapClick)="editable(cp, i)"
          (mapDblclick)="openCPInfoWindow(cp, i)"
          (mapDragend)="handleCPMarkerMoved(i, $event)"
        />

        <map-circle
          *ngFor="let cp of crossingPointsFiltered; let i = index"
          [center]="{
            lat: cp.coordinates.latitude,
            lng: cp.coordinates.longitude
          }"
          [options]="{
            fillColor: cp.style.color,
            fillOpacity: '0.35',
            strokeColor: cp.style.color,
            strokeWeight: 3,
            radius: cp.radius,
            clickable: true,
            draggable: false,
            editable: isEditable(i)
          }"
          (circleClick)="editable(cp, i)"
          (circleDblclick)="openCPInfoWindow(cp, i)"
          (centerChanged)="handleCPCircleMoved(i)"
          (radiusChanged)="handleCPRadiusChanged(i)"
        />

        <map-info-window (closeclick)="closeCPInfoWindow()">
          <div id="infoWindow" *ngIf="infoWindow.open">
            <div *ngIf="infoWindow.type === 'crossing-point'">
              <img *ngIf="infoWindow.content.style !== undefined" [src]="infoWindow.content.style.url" alt="" />
              <strong>{{ 'CROSSING_POINTS.ID' | translate }} :</strong> {{ infoWindow.content.data.id }}
              <br />
              <strong>{{ 'CROSSING_POINTS.DATABASE_ID' | translate }} :</strong> {{ infoWindow.content.data.bddid }}
              <br />
              <strong>{{ 'CROSSING_POINTS.NAME' | translate }} :</strong> {{ infoWindow.content.title }}
              <br />
              <strong>{{ 'CROSSING_POINTS.TYPE' | translate }} :</strong> {{ infoWindow.content.data.type }}
              <br />
              <strong>{{ 'GLOBAL.HIGHWAY_U' | translate }} :</strong>
              {{ infoWindow.content.data.location.highway_name }}
              <br />
              <strong>{{ 'CROSSING_POINTS.PR_ID' | translate }} :</strong>
              {{ infoWindow.content.data.location.display }}
              <br />
              <strong>{{ 'CROSSING_POINTS.DIRECTION' | translate }} :</strong> {{ infoWindow.content.data.direction }}
              <br />
              <strong>Secteur (id) :</strong> {{ infoWindow.content.data.location.sector_name }} ({{
                infoWindow.content.data.location.sector_id
              }})<br /><br />
              <button
                *ngIf="!deletionInProgress"
                matTooltip="{{ 'GLOBAL.DELETE' | translate }}"
                matTooltipPosition="after"
                (click)="deletionConfirm(infoWindow.content.data.id)"
                mat-flat-button
                color="warn"
              >
                <mat-icon>delete_forever</mat-icon>
              </button>

              <div *ngIf="deletionInProgress" class="deletion">
                <strong>{{ 'CONFIRM.DELETE_CROSSING_POINT' | translate }} </strong><br />
                <button (click)="removeCP(infoWindow.content.data.id)" mat-flat-button color="warn">
                  {{ 'GLOBAL.CONFIRM' | translate }}
                </button>
                <button (click)="cancelDeletion()" mat-flat-button>{{ 'GLOBAL.CANCEL' | translate }}</button>
              </div>
            </div>
          </div>
        </map-info-window>
      </google-map>
    </div>
  </div>
</div>
