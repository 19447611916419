<h1 mat-dialog-title>{{ 'REPOSITORIES.REPORTING_TYPES' | translate }}</h1>
<mat-dialog-content class="dialog-padding">
  <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="display-table-row">
    <ng-container matColumnDef="{{ column }}" *ngFor="let column of columnsToDisplay">
      <th mat-header-cell *matHeaderCellDef>{{ columnsToDisplayLabels[column] | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="column !== 'picto'">{{ element[column] }}</span
        ><img *ngIf="column === 'picto'" src="{{ element[column] }}" class="icon report-icon" />
      </td>
    </ng-container>
    <ng-container matColumnDef="expand">
      <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
      <td mat-cell *matCellDef="let element">
        <button
          *ngIf="element.subtypes.length > 0"
          mat-icon-button
          aria-label="expand row"
          (click)="expandedElement = expandedElement === element ? null : element; $event.stopPropagation()"
        >
          <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
          <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
        </button>
      </td>
    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
        <div
          class="example-element-detail"
          [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'"
          *ngIf="element.subtypes.length > 0"
        >
          <table class="sub-table">
            <tr>
              <th mat-header-cell *ngFor="let column of subColumnsToDisplay">{{ column | translate }}</th>
            </tr>
            <tr *ngFor="let subtype of element.subtypes">
              <td mat-cell class="no-border">{{ subtype.label }}</td>
            </tr>
          </table>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
    <tr
      mat-row
      *matRowDef="let element; columns: columnsToDisplayWithExpand"
      class="example-element-row"
      [class.example-expanded-row]="expandedElement === element"
      (click)="expandedElement = expandedElement === element ? null : element"
    ></tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
  </table>
</mat-dialog-content>
