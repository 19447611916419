<div
  class="carousel-view"
  [ngClass]="{
    isCarousel: mode === 'carousel',
    isGallery: mode === 'gallery'
  }"
>
  <ng-container *ngIf="loadingInstance.disabled === true">
    <div class="carousel-empty" *ngIf="!galleryView.length"></div>
    <ng-container *ngIf="galleryView.length">
      <!-- Carousel -->
      <div class="carousel-slider" *ngIf="mode === 'carousel'">
        <figure class="carousel-slider-item">
          <img
            class="carousel-slider-item-picture"
            (click)="zoomIn(selectedPicture)"
            [attr.src]="selectedPicture.url | secure | async"
            [alt]="selectedPicture.alt"
          />
        </figure>
      </div>

      <!-- Gallery -->
      <!-- <div *ngIf="mode === 'gallery'" class="imagesCount">
        {{ galleryView.length }}
        {{ 'GLOBAL.IMAGE' | translate | lowercase }}<span *ngIf="galleryView.length > 1">s</span>
      </div> -->

      <!-- Both Carousel and Gallery -->
      <div class="carousel-gallery" *ngIf="(galleryView.length > 1 && mode === 'carousel') || mode === 'gallery'">
        <figure *ngFor="let picture of galleryView" class="carousel-gallery-item">
          <img
            [attr.src]="picture.local ? picture.url : (picture.url | secure | async)"
            class="carousel-gallery-item-picture"
            [ngClass]="{
              'is-selected': picture === selectedPicture,
              gallery: mode === 'gallery',
              cursor: picture.type === 'image'
            }"
            [id]="picture.id"
            [alt]="picture.alt"
            (click)="actionPicture(picture)"
            matTooltip="{{ picture.original_object ? picture.original_object.name : '' }}"
            matTooltipPosition="above"
          />
          <button
            type="button"
            mat-raised-button
            color="warn"
            class="icon-only-button delete-action"
            *ngIf="actions && actions.indexOf('delete') !== -1"
            (click)="openDeleteConfirmation(picture)"
            matTooltip="{{ 'GLOBAL.DELETE' | translate }}"
            matTooltipPosition="above"
          >
            <mat-icon>close</mat-icon>
          </button>
          <!-- <span
            class="info-picture"
            *ngIf="picture.local"
            matTooltip="{{ 'GLOBAL.VALIDATE_TO_UPLOAD' | translate }}"
            matTooltipPosition="below"
          >
            <mat-icon>file_upload</mat-icon>
          </span> -->
        </figure>
      </div>
    </ng-container>
  </ng-container>
</div>
