import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { getTranslationWithFallback } from 'src/app/tools/translate';

import { APIError, APIErrorDetail, ExtendedAPIError, IError } from './error.interface';

//TODO: this file needs further investigation and refactoring
@Injectable()
export class ErrorHandler {
  constructor(private translate: TranslateService) {}

  getError(error: APIError): IError {
    const statusData = error.status;
    const status = !statusData || isNaN(statusData) ? undefined : Number(statusData);

    const code = error.code || 0;

    const message =
      error.localeMessage ||
      this.getStatusErrorTranslation(status, 'message') ||
      this.translate.instant(`ERRORS.UNKNOWN.message`);

    const title =
      error.localeTitle ||
      this.getStatusErrorTranslation(status, 'title') ||
      this.translate.instant(`ERRORS.UNKNOWN.title`);

    const iError: IError = {
      code,
      message,
      title
    };
    return iError;
  }

  private getStatusErrorTranslation(status: number | undefined, key: 'message' | 'title'): string | undefined {
    if (status && status < 400) {
      return undefined;
    }
    const statusInTranslations = status! >= 400 ? status! : 0;
    return (
      getTranslationWithFallback(`ERRORS.STATUS_ERRORS.${statusInTranslations}.${key}`, '', this.translate) || undefined
    );
  }

  public extendAPIError(error: APIError): ExtendedAPIError {
    const statusData = error.status;
    const status = !statusData || isNaN(statusData) ? undefined : Number(statusData);

    const code = error.code || 0;

    const localeMessage =
      error.localeMessage ||
      this.getStatusErrorTranslation(status, 'message') ||
      this.translate.instant(`ERRORS.UNKNOWN.message`);

    const localeTitle =
      error.localeTitle ||
      this.getStatusErrorTranslation(status, 'title') ||
      this.translate.instant(`ERRORS.UNKNOWN.title`);

    const treatCtx = (ctx?: Record<string, unknown>): APIErrorDetail[] => {
      const ctxDetails = Object.entries(ctx || {}).map(([key, value]) => {
        const localeKey = getTranslationWithFallback(`ERRORS.DETAILS.${key.toUpperCase()}`, key, this.translate);
        const stringValue = _.isArray(value) ? value.join(', ') : String(value);
        return { key, value, localeKey, stringValue };
      });
      return ctxDetails;
    };

    const causes = (error.causes || []).map((err) => this.extendAPIError(err));
    causes.forEach((err, i) => {
      const prev = (!!i && causes[i - 1]) || undefined;
      if (prev && prev.code === err.code) {
        err.isSameAsPrevious = true;
      }
    });

    const extended: ExtendedAPIError = {
      message: error.message,
      ctx: error.ctx,
      code,
      status,
      localeMessage,
      localeTitle,
      ctxDetails: treatCtx(error.ctx),
      causes
    };
    return extended;
  }
}
