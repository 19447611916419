<h1 class="mainTitle">{{ 'GLOBAL.BREADCRUMB.REPORTING_SUBTYPES.LIST' | translate }}</h1>

<app-loading [active]="!loaded" />
<app-list
  *ngIf="loaded"
  [addButton]="isAdmin ? 'dynamic' : ''"
  [listParameters]="listParameters"
  [items]="items"
  [total]="total"
  (refresh)="handleRefresh($event)"
  (action)="handleAction($event)"
/>
