import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Permissions } from 'app/interface/permissions.enum';
import { RolesService } from 'app/services/roles.service';
import pkg from 'package.json';
import { Profile, ProfileService } from 'src/app/services/profile.service';

import { DrivingLogsService } from '../../services/driving-logs.service';

interface Entry {
  icon: string;
  text: string;
  url: string;
  altUrls?: string[];
  isActive?: boolean;
  requirePermissions: false | Permissions;
  moduleActivated: boolean;
}

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss']
})
export class SidemenuComponent {
  private profile: Profile | null = ProfileService.getCurrentProfile();
  public entries: Entry[] = [
    {
      icon: 'map',
      text: 'REPORTING.MAP',
      url: '/reporting',
      requirePermissions: false,
      moduleActivated: true
    },
    {
      icon: 'storage',
      text: 'GLOBAL.REPOSITORIES',
      url: '/repositories',
      requirePermissions: false,
      moduleActivated: true
    },
    {
      icon: 'balisage',
      text: 'GLOBAL.CONTROLS_BALISAGE',
      url: '/controls',
      requirePermissions: false,
      moduleActivated: this.profile!.activeModules.controls
    },
    {
      icon: 'notifications',
      text: 'INSTRUCTIONS.LABEL_P',
      url: '/instructions',
      requirePermissions: false,
      moduleActivated: this.profile!.activeModules.instructions
    },
    {
      icon: 'assignment',
      text: 'TASKS.LABEL',
      url: '/tasks',
      requirePermissions: false,
      moduleActivated: this.profile!.activeModules.tasks
    },
    {
      icon: 'library_books',
      text: 'DRIVING_LOGS.LABEL',
      url: '/driving-logs',
      requirePermissions: Permissions.BACK_OFFICE_TEAMS_DRIVING_LOGS,
      moduleActivated: true
    }
  ];

  apiVersion = '';
  frontVersion = pkg.version;
  apiUrl = ProfileService.getAPIUrl();

  srcTopSidemenu = '../../assets/images/applicatif.svg';

  get initials(): string {
    return `${this.firstName.charAt(0)}${this.lastName.charAt(0)}`.toUpperCase();
  }

  get firstName(): string {
    return localStorage.getItem('first_name') || '';
  }

  get lastName(): string {
    return localStorage.getItem('last_name') || '';
  }

  get userName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  constructor(
    private rolesService: RolesService,
    private http: HttpClient,
    private drivingLogsService: DrivingLogsService
  ) {
    this.getAPIVersion();
  }

  getAPIVersion(): void {
    this.http.get<string>(`api://auth/version`).subscribe({
      next: (res) => {
        this.apiVersion = res;
      }
    });
  }

  isActive(entry: Entry): boolean {
    const entryUrls = [entry.url, ...(entry.altUrls || [])];
    const pathname = location.pathname;

    return entryUrls.some((url) => pathname.startsWith(url) || url.startsWith(pathname));
  }

  isVisible(entry: Entry) {
    return (
      entry.moduleActivated &&
      (entry.requirePermissions === false || this.rolesService.checkPermission(entry.requirePermissions))
    );
  }

  isOperator() {
    return this.rolesService.isOperator();
  }

  addCommentDialog() {
    this.drivingLogsService.openAddCommentDialog();
  }
}
