import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { IEvent } from 'app/interface/event.interface';
import { lastValueFrom } from 'rxjs';
import { GenericDialogComponent } from 'src/app/components/generic/generic-dialog/generic-dialog.component';
import { IKeycloakDecodedToken } from 'src/app/interface/auth.interface';
import { LocalStorageService } from 'src/app/services/local-storage.service';

import { ProfileService } from '../../../../services/profile.service';

export interface ILoginResponse {
  token: string;
  user: {
    first_name: string;
    last_name: string;
    teams: { id: string; name: string }[];
    sectors: { id: string; name: string }[];
    service_number: string;
    sectorsHighways: { _id: string; UID: string; name: string }[];
    role: string;
  };
  permissions: any[];
}

@Injectable()
export class SessionService {
  jwtHelper = new JwtHelperService();

  changes: EventEmitter<IEvent<{ [key: string]: any } | null>> = new EventEmitter();

  constructor(
    private http: HttpClient,
    private oidcSecurityService: OidcSecurityService,
    private router: Router,
    private dialog: MatDialog
  ) {}

  notify(event: string) {
    this.changes.emit({
      type: event,
      data: this.getDecoded()
    });
  }

  setToken(value: string) {
    localStorage.setItem('token', value);
    this.notify('update');
  }

  getToken(): string {
    return localStorage.getItem('token') || '';
  }

  getDecoded(): { [key: string]: any } | null {
    const token = localStorage.getItem('token');
    if (token !== null) {
      return this.jwtHelper.decodeToken(token);
    }

    return null;
  }

  isTokenValid(): boolean {
    return this.getDecoded() !== null;
  }

  removeToken(): void {
    localStorage.removeItem('token');
    this.notify('remove');
  }

  setSession(sessionInfos: ILoginResponse) {
    localStorage.setItem('first_name', sessionInfos.user.first_name);
    localStorage.setItem('service_number', sessionInfos.user.service_number);
    localStorage.setItem('last_name', sessionInfos.user.last_name);
    localStorage.setItem('permissions', JSON.stringify(sessionInfos.permissions || {}));
    localStorage.setItem('teams', JSON.stringify(sessionInfos.user.teams || []));
    localStorage.setItem('sectors', JSON.stringify(sessionInfos.user.sectors || []));
    localStorage.setItem('sectorsHighways', JSON.stringify(sessionInfos.user.sectorsHighways));
    localStorage.setItem('role', sessionInfos.user.role);
    this.notify('update');
  }

  unsetSession() {
    this.removeToken();
    LocalStorageService.clearAllExceptProfile();
  }

  public async checkAuthOnLoading(isProfile: boolean): Promise<void> {
    const isAuthenticated = isProfile && (await lastValueFrom(this.oidcSecurityService.checkAuth())).isAuthenticated;
    if (!isAuthenticated) {
      this.unsetSession();
    } else {
      const token = await lastValueFrom(this.oidcSecurityService.getAccessToken());
      this.setToken(token);
      const jwtHelperService = new JwtHelperService();
      const decodedToken = jwtHelperService.decodeToken<IKeycloakDecodedToken>(token);
      if (!decodedToken) throw Error('Bad token');
      try {
        const user = await this.sendLogin({ login: decodedToken.preferred_username });
        user.token = token;
        this.setSession(user);
        const redirectUri = localStorage.getItem('redirectUri');
        if (redirectUri) {
          localStorage.removeItem('redirectUri');
          this.router.navigateByUrl(redirectUri);
        }
      } catch (err) {
        // Logout from SSO when an error is sent from the back-end to avoid being stuck
        this.logout();
      }
    }
  }

  public async sendLogin(form: { login: string }): Promise<ILoginResponse> {
    const profile = ProfileService.getCurrentProfile();
    return lastValueFrom(
      this.http.post<ILoginResponse>(
        'api://auth/login',
        {
          login: form.login.toString(),
          password: '', // Not needed anymore, keycloak is doing the auth
          code: profile?.code
        },
        { headers: { Authorization: `Bearer ${this.getToken()}` } }
      )
    );
  }

  public async logout(): Promise<void> {
    await lastValueFrom(this.oidcSecurityService.logoff());
    this.unsetSession();
    LocalStorageService.clearAllExceptProfile();
  }

  public async openLogoutConfirmDialog() {
    this.dialog.open(GenericDialogComponent, {
      width: '50%',
      data: {
        structure: {
          title: 'GLOBAL.CONFIRMATION',
          text: 'CONFIRM.DISCONNECTION',
          buttons: [
            {
              text: 'GLOBAL.DISCONNECTION',
              class: 'validation',
              action: {
                target: 'custom',
                params: {
                  id: 'logout',
                  function: () => this.logout()
                }
              }
            },
            {
              text: 'GLOBAL.CANCEL',
              class: 'cancel',
              action: {
                target: 'generic',
                params: {
                  id: 'close',
                  function: undefined
                }
              }
            }
          ]
        }
      },
      disableClose: true
    });
  }
}
