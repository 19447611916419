import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IReportingEvent, ReportingState } from 'src/app/interface/report.interface';
import { RolesService } from 'src/app/services/roles.service';

import { NewEventDialogComponent, NewEventDialogResult } from '../../../event/new-event.dialog.component';

@Component({
  selector: 'app-operations-card',
  templateUrl: './operations-card.component.html',
  styleUrls: ['./operations-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OperationsCardComponent {
  public myUserId: string;
  public hasOperatorPermission: boolean;

  private newEventDialogRef?: MatDialogRef<NewEventDialogComponent>;

  @Input() private reportingId: string;
  @Input() private reportState: any;

  private _reportingEvents: IReportingEvent[] = [];
  @Input() set reportingEvents(newValue: IReportingEvent[]) {
    newValue = [...newValue];
    newValue.sort((a, b) => (a.date < b.date ? 1 : -1));
    this._reportingEvents = newValue;
  }
  get reportingEvents(): IReportingEvent[] {
    return this._reportingEvents;
  }

  @Output() createdEvent = new EventEmitter<IReportingEvent>();
  @Output() deletedEvent = new EventEmitter<string>();

  constructor(
    private dialog: MatDialog,
    private rolesService: RolesService
  ) {
    this.myUserId = localStorage.getItem('service_number') || '';
    this.hasOperatorPermission = this.rolesService.isOperator();
  }

  public handleAddEventClick(): void {
    this.openNewEventDialog();
  }

  public handleEditEventClick(reportingEvent: IReportingEvent): void {
    this.newEventDialogRef = this.dialog.open(NewEventDialogComponent, {
      disableClose: true,
      width: '40%',
      maxWidth: '400px',
      data: { reportingId: this.reportingId, reportingEventToEdit: reportingEvent }
    });

    this.newEventDialogRef.afterClosed().subscribe((res: NewEventDialogResult) => {
      if (res === 'cancelled') {
        return;
      }
      this.deletedEvent.emit(reportingEvent.id);
      this.createdEvent.emit(res);
    });
  }

  private openNewEventDialog() {
    this.newEventDialogRef = this.dialog.open(NewEventDialogComponent, {
      disableClose: true,
      width: '40%',
      maxWidth: '400px',
      data: { reportingId: this.reportingId }
    });

    this.newEventDialogRef.afterClosed().subscribe((res: NewEventDialogResult) => {
      if (res === 'cancelled') {
        return;
      }
      this.createdEvent.emit(res);
    });
  }

  canAddOperations(): boolean {
    return this.hasOperatorPermission && this.reportState !== ReportingState.CLOSED;
  }
}
