import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import {
  IReportingStateInformations,
  ReportingFilters,
  ReportingType,
  ReportingTypePriority
} from 'app/interface/report.interface';
import { HighwaysService } from 'app/services/highways.service';
import { ReportsService } from 'app/services/reports.service';
import _ from 'lodash';
import { InfrastructuresService } from 'src/app/services/infrastructures.service';
import { spliceValue } from 'src/app/tools/array.helper';

import { IHighway } from '../../../interface/highway.interface';
import { SelectOption } from '../../generic/form/select/select.component';

@Component({
  selector: 'app-classic-filters-dialog',
  templateUrl: './dialogFilter.component.html',
  styleUrls: ['./dialogFilter.component.scss']
})
export class ClassicFiltersDialogComponent implements OnInit {
  public isLoaded: boolean = false;
  public ReportingTypePriority = ReportingTypePriority;
  public reportingTypes: ReportingType[];
  public content: any = {};
  public families: any;
  public parent: any;
  public localStorageFilter: ReportingFilters;
  public highwaysDetailsFilter: any;
  public activatedFilters: {
    state: any[];
    type: string[];
    subtype: string[];
    highwaysList: IHighway[];
  };

  public subtypeSelectModelByType: { [reportingTypeId: string]: string[] } = {};

  public knownAccidentAssociation = true;
  public unknownAccidentAssociation = true;
  public noAccidentAssociation = true;

  public datesForm: UntypedFormGroup;
  public highwayDetailsForm: UntypedFormGroup;

  public reportingStates: IReportingStateInformations[] = [];

  // Toggle
  public allSubtypeToggle: { [reportingTypeId: string]: boolean } = {};
  public highwaysList: IHighway[];
  public highwayListLoaded = false;
  public toggle: { state: number } = { state: 0 };

  public priorityCheckboxes: { [id: string]: boolean } = {
    [ReportingTypePriority.RED]: false,
    [ReportingTypePriority.ORANGE]: false,
    [ReportingTypePriority.BLUE]: false
  };

  public directionOptions: SelectOption[] = [];

  constructor(
    public dialogRef: MatDialogRef<ClassicFiltersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private reportingService: ReportsService,
    private infrastructureService: InfrastructuresService,
    private highwayService: HighwaysService,
    private fb: UntypedFormBuilder
  ) {
    this.activatedFilters = {
      state: [],
      type: [],
      subtype: [],
      highwaysList: []
    };

    this.datesForm = this.fb.group({
      start: '',
      end: ''
    });

    this.highwayDetailsForm = this.fb.group({
      highwayForm: '',
      startPr: ['', Validators.pattern(/^[0-9]+[.,+][0-9]{3}$/)],
      endPr: ['', Validators.pattern(/^[0-9]+[.,+][0-9]{3}$/)],
      direction: [{ value: '', disabled: true }]
    });

    this.reportingStates = this.reportingService.getStates();

    this.content = data.content;
    this.parent = data.content.parent;
    this.families = this.content.families;
    this.highwaysDetailsFilter = JSON.parse(localStorage.getItem('highwayFilters') || '{}');
    this.localStorageFilter = this.reportingService.getStoredFiltersSync() || {};
  }

  public updateStatus(id: string, _event: any) {
    const index = _.findIndex(this.activatedFilters.state, (i) => i === id);
    if (index !== -1) {
      this.activatedFilters.state.splice(index, 1);
    } else {
      this.activatedFilters.state.push(id);
    }
  }

  public toggleStatesList() {
    const activeStatesCount: number = this.activatedFilters.state.length;
    this.activatedFilters.state.splice(0, activeStatesCount);
    if (activeStatesCount <= 1) {
      this.activatedFilters.state.push(...this.reportingStates.map((state) => state.id));
    }
  }

  public toggleOriginsList() {
    let count = 0;
    const origins = [this.unknownAccidentAssociation, this.knownAccidentAssociation, this.noAccidentAssociation];
    for (const origin of origins) {
      if (origin) {
        count++;
      }
    }
    this.unknownAccidentAssociation = !(count > 1);
    this.knownAccidentAssociation = !(count > 1);
    this.noAccidentAssociation = !(count > 1);
  }

  async ngOnInit(): Promise<void> {
    this.toggle = { state: 0 };

    Promise.all([this.reportingService.getReportingTypes(), this.infrastructureService.getInfrastructureTypes()]).then(
      ([reportingTypes]) => {
        this.reportingTypes = reportingTypes;

        // Highways filter
        // Get the possible sectors filters
        const userSectors = JSON.parse(localStorage.getItem('sectors') || '[]');
        const reportingFilters = this.reportingService.getStoredFiltersSync() || {};
        const sectorsFilter = _.get(reportingFilters, 'sector_id');

        // Then, apply a sectors filter by using the map filter by default, and the user's sectors otherwise
        const sectorsId = sectorsFilter ? sectorsFilter : userSectors.map((s: { id: string }) => s.id);
        const requestFilter: { fields: string; sector_id?: string } = {
          fields: 'UID,name,origin,destination,locations'
        };
        if (sectorsId.length > 0) {
          requestFilter.sector_id = sectorsId;
        }

        this.highwayService.getAll(requestFilter).then((result: any) => {
          this.highwayListLoaded = true;
          this.highwaysList = result.items;
          this.fillFiltersFromStorage();
          this.reloadCheckboxes();

          this.isLoaded = true;

          this.initializeDirections();
        });
      }
    );
  }

  fillFiltersFromStorage() {
    const filters = this.localStorageFilter;

    if (filters.type) {
      filters.type.forEach((reportingTypeId: string) => {
        this.activatedFilters.type.push(reportingTypeId);
      });
    }

    if (filters.sub_type) {
      this.activatedFilters.subtype = [...filters.sub_type];
      this.reportingTypes
        .filter((e) => e.subtypes.length)
        .forEach((e) => {
          this.allSubtypeToggle[e.id] = e.subtypes.every((reportingSubtype) =>
            this.activatedFilters.subtype.includes(reportingSubtype.id)
          );
        });
    }

    // Init this.subtypeSelectModelByType which is a necessary duplicate of this.activatedFilters.subtype
    //   to handle per-reportingType mat-select values
    this.reportingTypes.forEach((e) => this.applySubtypeFilterToModelByType(e.id));

    if (filters.state) {
      this.reportingStates.forEach((e) => {
        if (filters.state?.includes(e.id)) {
          this.activatedFilters.state.push(e.id);
        }
      });
      if (this.reportingStates.length === this.activatedFilters.state.length) {
        this.toggle.state = 1;
      }
    }

    if (localStorage.getItem('unknownAccidentAssociation')) {
      this.unknownAccidentAssociation = localStorage.getItem('unknownAccidentAssociation') === 'true';
    }

    if (localStorage.getItem('knownAccidentAssociation')) {
      this.knownAccidentAssociation = localStorage.getItem('knownAccidentAssociation') === 'true';
    }

    if (localStorage.getItem('noAccidentAssociation')) {
      this.noAccidentAssociation = localStorage.getItem('noAccidentAssociation') === 'true';
    }

    if (localStorage.getItem('mapStartDate')) {
      this.datesForm.get('start')!.setValue(new Date(localStorage.getItem('mapStartDate')!));
    }

    if (localStorage.getItem('mapEndDate')) {
      this.datesForm.get('end')!.setValue(new Date(localStorage.getItem('mapEndDate')!));
    }

    if (filters.highway_name) {
      this.highwayDetailsForm.get('highwayForm')!.setValue(filters.highway_name);
    }

    if (filters.direction) {
      this.highwayDetailsForm.get('direction')!.setValue(filters.direction);
    }

    if (filters.start_pr) {
      this.highwayDetailsForm.get('startPr')!.setValue(decodeURIComponent(filters.start_pr));
    }

    if (filters.end_pr) {
      this.highwayDetailsForm.get('endPr')!.setValue(decodeURIComponent(filters.end_pr));
    }

    return this.activatedFilters;
  }

  isActiveFilter(filter: string) {
    if (this.activatedFilters.type) {
      return this.activatedFilters.type.includes(filter);
    }

    return false;
  }

  toggleList(list: 'direction' | 'highway') {
    switch (list) {
      case 'direction':
        if (this.highwayDetailsForm.get('direction')!.value.length > 0) {
          this.highwayDetailsForm.get('direction')!.setValue([]);
        } else {
          this.highwayDetailsForm.get('direction')!.setValue(this.directionOptions.map((option) => option.value.key));
        }
        break;
      case 'highway':
        if (this.highwayDetailsForm.get('highwayForm')!.value.length > 0) {
          this.highwayDetailsForm.get('highwayForm')!.setValue([]);
        } else {
          this.highwayDetailsForm.get('highwayForm')!.setValue(this.highwaysList.map((s: any) => s.UID));
          this.updateDirectionOptions(this.highwayDetailsForm.get('highwayForm')!.value);
        }
    }
  }

  addType(reportingTypeId: string) {
    if (!this.activatedFilters.type.includes(reportingTypeId)) {
      this.activatedFilters.type.push(reportingTypeId);
    }
  }

  removeType(reportingTypeId: string) {
    spliceValue(this.activatedFilters.type, reportingTypeId);
  }

  activateReportingTypeFilter(reportingTypeId: string) {
    const reportingType = this.reportingService.getReportingTypeByIdSync(reportingTypeId);
    const reportingSubtypeIds = reportingType.subtypes.map((e) => e.id) || [];
    const hasSubtypes = !!reportingType.subtypes.length;
    this.addType(reportingTypeId);
    if (hasSubtypes) {
      const missingSubtypeIds = reportingSubtypeIds.filter((e) => !this.activatedFilters.subtype.includes(e));
      this.activatedFilters.subtype = [...this.activatedFilters.subtype].concat(...missingSubtypeIds);
      this.allSubtypeToggle[reportingTypeId] = true;
      this.applySubtypeFilterToModelByType(reportingTypeId);
    }
    this.reloadCheckboxByPriority(reportingType.priority);
  }

  desactivateReportingTypeFilter(reportingTypeId: string) {
    const reportingType = this.reportingService.getReportingTypeByIdSync(reportingTypeId);
    const reportingSubtypeIds = reportingType.subtypes.map((e) => e.id) || [];
    const hasSubtypes = !!reportingType.subtypes.length;
    this.removeType(reportingTypeId);
    if (hasSubtypes) {
      this.activatedFilters.subtype = this.activatedFilters.subtype.filter(
        (reportingSubtypeId) => !reportingSubtypeIds.includes(reportingSubtypeId)
      );
      this.allSubtypeToggle[reportingTypeId] = false;
      this.applySubtypeFilterToModelByType(reportingTypeId);
    }
    this.reloadCheckboxByPriority(reportingType.priority);
  }

  toggleReportingTypeFilter(reportingTypeId: string) {
    const wasActive = this.activatedFilters.type.includes(reportingTypeId);
    if (!wasActive) {
      this.activateReportingTypeFilter(reportingTypeId);
    } else {
      this.desactivateReportingTypeFilter(reportingTypeId);
    }
  }

  /**
   * this.subtypeSelectModelByType is a necessary duplicate of this.activatedFilters.subtype
   *
   * When this.activatedFilters.subtype changes programmatically, we need to apply those changes to this.subtypeSelectModelByType
   *
   * e.g. we load the subtypes from the localStorage
   *
   * e.g. the user clicks on the "select all" button
   */
  applySubtypeFilterToModelByType(reportingTypeId: string) {
    const reportingType = this.reportingService.getReportingTypeByIdSync(reportingTypeId);
    if (!reportingType.subtypes.length) {
      return;
    }
    const activatedSubtypeIds = reportingType.subtypes
      .map((e) => e.id)
      .filter((e) => this.activatedFilters.subtype.includes(e));
    this.subtypeSelectModelByType[reportingType.id] = activatedSubtypeIds;
  }

  /**
   * this.subtypeSelectModelByType is a necessary duplicate of this.activatedFilters.subtype
   *
   * When this.activatedFilters.subtype changes, we need to apply those changes to this.subtypeSelectModelByType
   *
   * i.e. the user checks or unchecks a checkbox
   */
  handleSubtypeSelectChange(reportingTypeId: string, event: MatSelectChange) {
    const reportingType = this.reportingService.getReportingTypeByIdSync(reportingTypeId);
    const allMySubtypeIds = reportingType.subtypes.map((e) => e.id);
    const newSubtypeIds = event.value as string[];
    const subtypeIdsToAdd = newSubtypeIds.filter((e) => !this.activatedFilters.subtype.includes(e));
    const subtypeIdsToRemove = allMySubtypeIds.filter((e) => !newSubtypeIds.includes(e));
    this.activatedFilters.subtype = this.activatedFilters.subtype
      .filter((e) => !subtypeIdsToRemove.includes(e))
      .concat(...subtypeIdsToAdd);
  }

  addLocalStorage(storage: any, values: string[] | string, key: string) {
    if (_.isArray(values) && values.length > 0) {
      storage[key] = [];
      storage[key] = storage[key].concat(values);
    } else if (typeof values === 'string') {
      storage[key] = values;
    } else {
      delete storage[key];
    }
  }

  canValidate() {
    const hasAtLeastOneType = !!this.activatedFilters.type[0] || !!this.activatedFilters.subtype[0];
    const hasAtLeastOneState = !!this.activatedFilters.state[0];

    return hasAtLeastOneType && hasAtLeastOneState;
  }

  /**
   * Updates the 3 categories checkbox "checked" status
   */
  reloadCheckboxes() {
    this.reloadCheckboxByPriority(ReportingTypePriority.RED);
    this.reloadCheckboxByPriority(ReportingTypePriority.ORANGE);
    this.reloadCheckboxByPriority(ReportingTypePriority.BLUE);
  }

  /**
   * Reloads a specific category checkbox, using the selected filters as reference
   */
  reloadCheckboxByPriority(priority: ReportingTypePriority) {
    const reportingTypes = this.reportingTypes.filter((e) => e.priority === priority);
    const unchecked = reportingTypes.filter((e) => !this.activatedFilters.type.includes(e.id));
    this.priorityCheckboxes[priority] = !unchecked.length;
  }

  toggleSelectAll(priority: ReportingTypePriority): void {
    const reportingTypes = this.reportingTypes.filter((e) => e.priority === priority);
    const reportingTypeIds = reportingTypes.map((e) => e.id);
    const isChecked = this.priorityCheckboxes[priority];
    if (isChecked) {
      reportingTypeIds.forEach((id) => this.activateReportingTypeFilter(id));
    } else {
      reportingTypeIds.forEach((id) => this.desactivateReportingTypeFilter(id));
    }
  }

  updateStorageFilters(): void {
    this.addLocalStorage(this.localStorageFilter, this.activatedFilters.type, 'type');
    this.addLocalStorage(this.localStorageFilter, this.activatedFilters.subtype, 'sub_type');
    this.addLocalStorage(this.localStorageFilter, this.activatedFilters.state, 'state');
    localStorage.setItem('unknownAccidentAssociation', this.unknownAccidentAssociation.toString());
    localStorage.setItem('knownAccidentAssociation', this.knownAccidentAssociation.toString());
    localStorage.setItem('noAccidentAssociation', this.noAccidentAssociation.toString());

    if (
      this.datesForm.get('start') &&
      this.datesForm.get('start')!.value !== '' &&
      this.datesForm.get('start')!.value !== null &&
      this.datesForm.get('end') &&
      this.datesForm.get('end')!.value !== '' &&
      this.datesForm.get('end')!.value !== null
    ) {
      localStorage.setItem('mapStartDate', this.datesForm.get('start')!.value.toString());
      localStorage.setItem('mapEndDate', this.datesForm.get('end')!.value.toString());
    } else {
      localStorage.removeItem('mapStartDate');
      localStorage.removeItem('mapEndDate');
    }

    this.addLocalStorage(this.localStorageFilter, this.highwayDetailsForm.get('highwayForm')!.value, 'highway_name');
    this.addLocalStorage(this.localStorageFilter, this.highwayDetailsForm.get('direction')!.value, 'direction');
    this.addLocalStorage(
      this.localStorageFilter,
      encodeURIComponent(this.highwayDetailsForm.get('startPr')!.value),
      'start_pr'
    );
    this.addLocalStorage(
      this.localStorageFilter,
      encodeURIComponent(this.highwayDetailsForm.get('endPr')!.value),
      'end_pr'
    );
    this.reportingService.storeFilters(this.localStorageFilter || {});
  }

  validateDialog(): void {
    this.updateStorageFilters();
    if (this.canValidate()) {
      this.dialogRef.close();
    } else {
      this.dialogRef.close('bad-filters');
    }
  }

  closeDialog(): void {
    this.dialogRef.close('cancel');
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.closeDialog();
    }
  }

  isPrDisable() {
    return this.highwayDetailsForm.get('direction')!.value?.length;
  }

  public updateDirectionControlState(): void {
    const directionControl = this.highwayDetailsForm.get('direction');
    const highwayFormControl = this.highwayDetailsForm.get('highwayForm');

    if (highwayFormControl?.value?.length) {
      directionControl?.enable();
    } else {
      directionControl?.disable();
      directionControl?.reset([]);
    }
  }

  public onHighwaySelectionChange(values: string[]): void {
    const selectedValues: string[] = this.highwayDetailsForm.get('direction')!.value.filter((direction: string) => {
      const lastHyphenIndex: number = direction.lastIndexOf('-');

      const highwayId: string = direction.substring(0, lastHyphenIndex);

      return values.includes(highwayId);
    });

    this.highwayDetailsForm.get('direction')?.setValue(selectedValues);
    const oldDirectionOptions: SelectOption[] = this.directionOptions;
    this.updateDirectionOptions(values);
    this.preselectDirectionValues(oldDirectionOptions);
  }

  public onDirectionSelectionChange(values: string[]): void {
    const selectedHighways = values.map((value) => {
      const lastHyphenIndex: number = value.lastIndexOf('-');
      const highwayId: string = value.substring(0, lastHyphenIndex);

      return highwayId;
    });

    const uniqueSelectedHighways = selectedHighways.filter((item, index) => selectedHighways.indexOf(item) === index);
    this.highwayDetailsForm.get('highwayForm')?.setValue(uniqueSelectedHighways);
  }

  // Backend is waiting for key = HighwayId + direction. ex: A46-1
  // we need to link each direction to a specific highway instead of sending direction 1 or 2
  private makeDirectionOption(direction: string, highway: IHighway): SelectOption {
    return direction === '1'
      ? {
          value: {
            key: `${highway.UID}-1`,
            label: `${highway.name} : ${highway.origin} > ${highway.destination}`
          }
        }
      : {
          value: {
            key: `${highway.UID}-2`,
            label: `${highway.name} : ${highway.destination} > ${highway.origin}`
          }
        };
  }

  private initializeDirections() {
    this.updateDirectionControlState();

    const highwayFormValue: string[] = this.highwayDetailsForm.get('highwayForm')?.value;

    if (highwayFormValue) {
      this.updateDirectionOptions(highwayFormValue);
    }

    const highwayFormDirectionValue: string[] = this.highwayDetailsForm.get('direction')!.value;
    const selectedDirectionsOptions: string[] = this.directionOptions
      .filter((option) => highwayFormDirectionValue.includes(option.value.key as string))
      .map((option) => option.value.key) as string[];

    this.highwayDetailsForm.get('direction')?.setValue(selectedDirectionsOptions);

    this.highwayDetailsForm.get('highwayForm')?.valueChanges.subscribe(() => {
      this.updateDirectionControlState();
    });
  }

  private updateDirectionOptions(selectedHighwaysIDs: string[]): void {
    // Create direction options
    this.directionOptions = [];
    for (const highwayUID of selectedHighwaysIDs) {
      const highway: IHighway | undefined = this.highwaysList.find((highway: any) => highway.UID === highwayUID);
      if (!highway) {
        return;
      }
      this.directionOptions.push(this.makeDirectionOption('1', highway), this.makeDirectionOption('2', highway));
    }
  }

  private preselectDirectionValues(oldDirectionOptions: SelectOption[]) {
    // Preselect new directions from new selected highways
    const newDirectionValues: string[] = this.directionOptions
      .map((value) => value.value.key)
      .filter((x) => !oldDirectionOptions.map((value) => value.value.key).includes(x)) as string[];
    if (newDirectionValues.length > 0) {
      const directionValues: string[] = this.highwayDetailsForm.get('direction')?.value;
      if (directionValues) {
        directionValues.push(...newDirectionValues);
        this.highwayDetailsForm.get('direction')?.setValue(directionValues);
      }
    }
  }
}
