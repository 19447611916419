import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { from } from 'rxjs';
import { ReportsService } from 'src/app/services/reports.service';

import { InfrastructureType } from '../../../interface/infrastructure.interface';
import { InfrastructuresService } from '../../../services/infrastructures.service';

@Component({
  selector: 'app-infrastructure-dialog',
  templateUrl: './dialogInfrastructure.component.html',
  styleUrls: ['./dialogInfrastructure.component.scss']
})
export class InfrastructureDialogComponent implements OnInit {
  public itemsPerRow: number = 3;
  public content: any = {};
  public localStorageFilter: any;
  public filtersList: any;
  public activatedFilters: { infrastructure: string[] } = { infrastructure: [] };
  private infrastructuresTypes: InfrastructureType[];

  constructor(
    public dialogRef: MatDialogRef<InfrastructureDialogComponent>,
    private reportingService: ReportsService,
    private infrastructureTypesService: InfrastructuresService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.content = data.content;
  }

  ngOnInit(): void {
    from(this.infrastructureTypesService.getInfrastructureTypes()).subscribe((res) => {
      this.infrastructuresTypes = res;
      this.filtersList = this.convertArrayToRows(res);
    });
    this.localStorageFilter = this.reportingService.getStoredFiltersSync() || {};

    this.fillFiltersFromStorage();
  }

  fillFiltersFromStorage() {
    const filters = this.localStorageFilter;
    if (filters.infrastructure) {
      filters.infrastructure.forEach((infra: string) => {
        this.activatedFilters.infrastructure.push(infra);
      });
    }

    return this.activatedFilters;
  }

  isActiveFilter(infra: string) {
    if (this.activatedFilters.infrastructure) {
      return this.activatedFilters.infrastructure.includes(infra);
    }

    return false;
  }

  manageFilterSimple(infra: string): void {
    const index = this.activatedFilters.infrastructure.indexOf(infra);
    if (index === -1) {
      this.activatedFilters.infrastructure.push(infra);
    } else {
      this.activatedFilters.infrastructure.splice(index, 1);
    }
  }

  addLocalStorage(values: string[], key: string) {
    if (values.length > 0) {
      this.localStorageFilter[key] = [];
      this.localStorageFilter[key] = this.localStorageFilter[key].concat(values);
    } else {
      delete this.localStorageFilter[key];
    }
  }

  updateStorageFilters(): void {
    this.addLocalStorage(this.activatedFilters.infrastructure, 'infrastructure');
    this.reportingService.storeFilters(this.localStorageFilter || {});
  }

  validateDialog() {
    this.updateStorageFilters();
    this.dialogRef.close();
  }

  closeDialog(): void {
    this.dialogRef.close('cancel');
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.closeDialog();
    }
  }

  convertArrayToRows(filtersList: any[]) {
    let i = 0;
    const filtersListRows: any[] = [];
    filtersList.forEach((filter, index) => {
      if (index > 0 && index % this.itemsPerRow == 0) {
        i++;
      }
      if (!filtersListRows[i]) {
        filtersListRows[i] = [];
      }

      filtersListRows[i].push(filter);
    });
    return filtersListRows;
  }

  public toggleList() {
    const activeTypesCount: number = this.activatedFilters.infrastructure.length;
    this.activatedFilters.infrastructure.splice(0, activeTypesCount);
    if (activeTypesCount <= 1) {
      this.activatedFilters.infrastructure.push(...this.infrastructuresTypes.map((type) => type.id));
    }
  }
}
