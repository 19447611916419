import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DEFAULT_PAGE_SIZE, IGenericListParameters } from 'app/components/generic/list/list.interface';
import { IHighway, KilometerPointFilter } from 'app/interface/highway.interface';
import { ILocation } from 'app/interface/location.interface';
import { HighwaysService } from 'app/services/highways.service';
import { LoadingService } from 'app/services/loading.service';
import { TitleService } from 'app/services/title.service';

@Component({
  selector: 'app-highways-detail',
  templateUrl: './highways.detail.component.html',
  styleUrls: ['./highways.detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HighwaysDetailComponent implements OnInit {
  currentHighway:
    | (IHighway & {
        [key: string]: any;
      })
    | undefined;
  highwaysList: IHighway[] = [];
  total = 0;
  kilometerPointsList: ILocation[] = [];

  filterParameters = {
    prMin: null as number | null,
    prMax: null as number | null,
    prFrom: {
      value: null as number | null,
      label: 'HIGHWAYS.PR_MIN_LABEL'
    },
    prTo: {
      value: null as number | null,
      label: 'HIGHWAYS.PR_MAX_LABEL'
    },
    direction: {
      value: ['1'],
      label: 'HIGHWAYS.DIRECTION_LABEL',
      option1: '',
      option2: ''
    },
    minPrValidator: new UntypedFormControl(),
    maxPrValidator: new UntypedFormControl()
  };

  listParameters: IGenericListParameters = {
    paginator: true,
    listHead: [
      {
        key: 'landmark',
        type: 'text',
        title: 'HIGHWAYS.PR_LABEL',
        apiKey: 'kilometerPoint'
      },
      {
        key: 'direction',
        type: 'text',
        title: 'HIGHWAYS.DIRECTION_LABEL'
      },
      {
        key: 'lanes',
        type: 'text',
        title: 'HIGHWAYS.LANES_LABEL'
      },
      {
        key: 'latitude',
        type: 'text',
        title: 'HIGHWAYS.LATITUDE_LABEL',
        options: {
          sortable: false
        }
      },
      {
        key: 'longitude',
        type: 'text',
        title: 'HIGHWAYS.LONGITUDE_LABEL',
        options: {
          sortable: false
        }
      },
      {
        key: 'type',
        type: 'text',
        title: 'HIGHWAYS.TYPE'
      },
      {
        key: 'sector_name',
        type: 'text',
        title: 'HIGHWAYS.SECTOR_LABEL',
        apiKey: 'sector.name'
      }
    ]
  };

  constructor(
    private title: TitleService,
    private highwayService: HighwaysService,
    public loading: LoadingService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer
  ) {
    this.title.setTitle('GLOBAL.BREADCRUMB.HIGHWAYS');
    iconRegistry.addSvgIcon('upload', sanitizer.bypassSecurityTrustResourceUrl('/assets/icon/file_upload.svg'));
  }

  ngOnInit() {
    this.loading.on();
    this.activatedRoute.params.subscribe((params) => {
      this.getCurrentHighway(params.UID);
    });
  }

  /**
   * Retrieve highwaysList
   */
  async getHighwaysList(): Promise<void> {
    // Retrieve highway datas with the computed filters
    const res = await this.highwayService.getAll({ fields: 'UID,name' });
    this.highwaysList = res.items;
  }

  /**
   * Retrieve or refresh the highway
   *
   * @param event  An event object emitted by ListComponent when a refresh is
   *   required.
   */
  async getCurrentHighway(UID: string): Promise<void> {
    // Retrieve highway datas with the computed filters
    const res = await this.highwayService.get(UID);
    this.currentHighway = res;
    this.currentHighway.pr = res.locations.length;
    this.filterParameters = {
      prMin: res.minValue,
      prMax: res.maxValue,
      prFrom: {
        value: res.minValue,
        label: 'HIGHWAYS.PR_MIN_LABEL'
      },
      prTo: {
        value: res.maxValue,
        label: 'HIGHWAYS.PR_MAX_LABEL'
      },
      direction: {
        value: ['1'],
        label: 'HIGHWAYS.DIRECTION_LABEL',
        option1: `${this.currentHighway.origin} &rarr;` + ` ${this.currentHighway.destination}`,
        option2: `${this.currentHighway.destination} &rarr;` + ` ${this.currentHighway.origin}`
      },
      minPrValidator: new UntypedFormControl(res.minValue, [
        Validators.required,
        Validators.min(res.minValue),
        Validators.max(res.maxValue)
      ]),
      maxPrValidator: new UntypedFormControl(res.maxValue, [
        Validators.required,
        Validators.min(res.minValue),
        Validators.max(res.maxValue)
      ])
    };
    this.getHighwaysList();
    this.getKilometerPoints({ params: { limit: DEFAULT_PAGE_SIZE, offset: 0 } });
  }

  /**
   * Retrieve or refresh the kilometer points list
   *
   * @param event  An event object emitted by ListComponent when a refresh is
   *   required.
   */
  getKilometerPoints(event?: { type?: string; data?: any; params?: any }) {
    // Extract filters from event if available
    // Note: For each filter from the event, we must separate cases where the
    // value is undefined (keep current filter state) from those where the value
    // is empty (remove the filter)
    let filters: KilometerPointFilter = {};

    if (event && event.data && ['search', 'filter'].includes(event.type ?? '')) {
      if (event.data.search) {
        filters.q = String(event.data.search);
      } else if (event.data.search === '') {
        delete filters.q;
      }

      if (event.data.filter) {
        for (const param in event.data.filter) {
          if (param === 'value_min') {
            if (event.data.filter[param]) {
              filters.value_min = event.data.filter[param];
            } else {
              delete filters.value_min;
            }
          } else if (param === 'value_max') {
            if (event.data.filter[param]) {
              filters.value_max = event.data.filter[param];
            } else {
              delete filters.value_max;
            }
          } else if (param === 'direction') {
            filters.direction = event.data.filter[param];
          }
        }
      }
    }
    filters.fields = 'coordinates,direction,display,landmark,lanes,sector_name,type';
    if (this.filterParameters.direction.value.length === 1) {
      filters.direction = this.filterParameters.direction.value[0].toString();
    } else {
      delete filters.direction;
    }
    if (event && event.params) {
      filters = { ...filters, ...event.params };
    }
    // Retrieve kilometer point datas with the computed filters
    if (this.currentHighway !== undefined) {
      this.highwayService.getKilometerPoints(this.currentHighway.UID, filters).subscribe((res) => {
        this.kilometerPointsList = this.parseKilometerPoints(res.items);
        this.total = res.total;
        this.loading.off();
      });
    }
  }

  parseKilometerPoints(items: any[]): any[] {
    if (this.currentHighway !== undefined) {
      const origin = this.currentHighway.origin;
      const dest = this.currentHighway.destination;
      const res = items.map((item) => ({
        landmark: item.display,
        direction: item.direction === 1 ? `${origin} &rarr; ${dest}` : `${dest} &rarr; ${origin}`,
        lanes: item.lanes,
        latitude: item.coordinates.latitude,
        longitude: item.coordinates.longitude,
        type: item.type,
        sector_name: item.sector_name
      }));

      return res;
    } else {
      // SHALL WE RETURN ERROR ?
      return [];
    }
  }

  onSubmit(): void {
    this.getKilometerPoints({
      type: 'filter',
      data: {
        filter: {
          value_min: this.filterParameters.prFrom.value,
          value_max: this.filterParameters.prTo.value
        }
      }
    });
  }

  onHighwayChange(event: any): void {
    this.router.navigate([`repositories/highways/${event.value}`]);
  }
}
