import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';

@Injectable()
export class RepositoriesService {
  constructor(private http: HttpClient) {
    /* Noop */
  }

  downloadCSV(type: string, filename: string): void {
    const downloadedFilename = filename && typeof filename === 'string' ? `${filename}.csv` : '';

    this.http
      .get(`api://imports/${type}/file`, { responseType: 'blob' })
      .subscribe((data) => saveAs(data, downloadedFilename));
  }
}
