import { Component, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IAccidentDetail, IAccidentForm } from 'app/interface/accident-reporting.interface';
import { IconService } from 'app/services/icon.service';
import * as minimal from 'app/tools/objectMinimal';

import { DamagesFormDialogComponent } from './damages-form-dialog/damages-form-dialog.component';

interface IDamagesViewItem {
  icon: string;
  title: string;
  form: {
    type: string;
    data: IAccidentForm;
  };
  formAvailable: boolean;
  total: number;
  items: {
    label: string;
    count: number;
  };
}

@Component({
  selector: 'app-damages-card',
  templateUrl: './damages-card.component.html',
  styleUrls: ['./damages-card.component.scss']
})
export class DamagesCardComponent {
  /** Display-ready representation of the impacted lanes. */
  damagesView: IDamagesViewItem[] = [];
  @Input() reportId: any;

  /** Opened dialog reference for automatic updates. */
  private openedDamagesFormDialog?: MatDialogRef<DamagesFormDialogComponent>;

  /** @see set details() */
  private _details?: IAccidentDetail;

  /**
   * Intercepts updates on the reporting details and parses the damages forms
   * for display.
   */
  @Input() set details(newValue) {
    this._details = newValue;

    minimal.replace(this.damagesView, [
      this.parseSection('vehicles', 'vehicles'),
      this.parseSection('victims', 'victims'),
      this.parseSection('materials', 'materials'),
      this.parseSection('markings', 'markings'),
      this.parseSection('weather', 'weather')
    ]);

    this.updateDamagesFormDialog();
  }
  get details(): IAccidentDetail | undefined {
    return this._details;
  }

  constructor(
    private dialog: MatDialog,
    iconService: IconService
  ) {
    iconService.register('vehicles', '/assets/icon/reportings/details/vehicles.svg');
    iconService.register('victims', '/assets/icon/reportings/details/victims.svg');
    iconService.register('materials', '/assets/icon/reportings/details/materials.svg');
    iconService.register('markings', '/assets/icon/reportings/details/markings.svg');
    iconService.register('weather', '/assets/icon/reportings/details/weather.svg');
    iconService.register('document', '/assets/icon/reportings/details/document.svg');
  }

  isEmpty(section: any): boolean {
    return section.total === 0 && (!section.form || !section.form.data || Object.keys(section.form.data).length === 0);
  }

  /**
   * Creates a new instance of the damages form dialog to display the provided
   * form data.
   */
  openDamagesFormDialog(form: { type: string; data: IAccidentForm; reportId?: string }) {
    if (Object.keys(form.data).length === 0) {
      return;
    }
    form.reportId = this.reportId;
    this.openedDamagesFormDialog = this.dialog.open(DamagesFormDialogComponent, {
      width: '80%',
      maxWidth: '654px',
      data: form || {},
      disableClose: false
    });

    // Reset the opened dialog reference on closing
    this.openedDamagesFormDialog.afterClosed().subscribe(() => {
      this.openedDamagesFormDialog = undefined;
    });
  }

  /**
   * Updates the opened damages form dialog (if any) with the currently loaded
   * data. This method does not check whether the currently loaded form datas
   * are up-to-date or not.
   *
   * @see set details()
   */
  private updateDamagesFormDialog() {
    if (this.openedDamagesFormDialog !== undefined) {
      const component = this.openedDamagesFormDialog.componentInstance;

      // Retrieve the current form data from the damages view
      const openedSection = this.damagesView.find((section) =>
        section && section.form && section.form.type && section.form.data
          ? section.form.type.toUpperCase() === component.type
          : false
      );

      // Ensure the current opened form exists and call its updated method
      if (openedSection !== undefined) {
        component.updateFormData(openedSection.form.data);
      }
    }
  }

  /**
   * Extracts a form informations from the reporting details and returns a
   * display-ready object of the computed datas (form title, subitems count and
   * form data).
   *
   * If the details are not loaded, this method does not do anything.
   *
   * @param sectionName The section to lookup in the reporting details; e.g. for
   *   the value "vehicles", the section form is located under "formVehicles"
   *   and the form subitems (if any) are located under "vehicles".
   * @param icon The name of the SVG icon to display next to the section label;
   *   see constructor for available icons.
   */
  private parseSection(sectionName: string, icon = '') {
    if (!this.details) {
      return;
    }

    const sectionKey = sectionName
      .toUpperCase()
      .replace(/[^A-Z]/, '_')
      .replace(/^FORM/, '');
    const title = `REPORTING.DAMAGES_CARD.SECTIONS.${sectionKey}.LABEL`;

    // Parse form items if any is present
    let total = 0;
    let items: any[] | undefined;
    if (this.details.hasOwnProperty(sectionName)) {
      const section: any = this.details[sectionName as keyof IAccidentDetail];

      items = Object.keys(section).map((key) => {
        const itemKey = key.toUpperCase().replace(/[^A-Z]/, '_');
        const label = `REPORTING.DAMAGES_CARD.SECTIONS.${sectionKey}.${itemKey}`;
        const count = section[key];
        if (key !== 'animal' && key !== 'pedestrian') {
          total += count;
        }

        return { label, count };
      });
    }

    /*
     * Pass the form down the view if it exists; the actual parsing of the form
     * when present is delegated to the dialog displaying it
     * @see DamagesFormDialogComponent
     */
    const ucSectionName = `${sectionName[0].toUpperCase()}${sectionName.slice(1)}`;
    const formName = `form${ucSectionName}` as keyof IAccidentDetail;
    let form: { type: string; data: IAccidentForm } | undefined;
    // Does this section have a specified form object?
    let formAvailable = false;
    // Is this object filled?
    let filledForm = false;
    if (this.details.hasOwnProperty(formName) && !!Object.keys(this.details[formName]).length) {
      form = {
        type: sectionName,
        data: this.details[formName] as IAccidentForm
      };
      filledForm = Object.keys(form.data).length > 0;
      formAvailable = !!form.data;
    }

    return { icon, title, form, formAvailable, filledForm, total, items };
  }
}
