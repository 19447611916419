<div class="file-input" [formGroup]="pformGroup">
  <input
    #fileInput
    multiple
    name="ques"
    type="file"
    style="display: none"
    [id]="inputId"
    [accept]="accept"
    (change)="handleChange($event)"
  />
  <div
    class="drop-zone"
    [ngClass]="{ isHighlighted: isHighlighted }"
    (click)="handleClick($event)"
    (dragenter)="handleDragEnter($event)"
    (dragover)="handleDragEnter($event)"
    (dragleave)="handleDragLeave($event)"
    (drop)="handleDragLeave($event); handleDrop($event)"
  >
    <!--
      (focus)="applyEvent('focus')"
      (click)="applyEvent('click', $event)" -->
    <button type="button" mat-raised-button color="warn">
      <!-- <mat-icon>add_a_photo</mat-icon> -->
      <label [for]="inputId">
        {{ 'REPOSITORIES.SELECT_FILE' | translate }}
      </label>
    </button>
    <span>
      <span *ngIf="!fileLength">{{ 'GLOBAL.DROP_FILES_HERE' | translate }}</span>
      <span *ngIf="fileLength === 1">{{ fileLength }} {{ 'GLOBAL.ADDED_FILE' | translate }}</span>
      <span *ngIf="fileLength > 1">{{ fileLength }} {{ 'GLOBAL.ADDED_FILES' | translate }}</span>
    </span>
    <p *ngIf="authorizedFilesFormat" class="file-format">
      {{ 'GLOBAL.AUTHORIZED_FORMAT' | translate }} : {{ authorizedFilesFormat }}
    </p>
  </div>

  <app-carousel
    [pictures]="pictures"
    [mode]="'gallery'"
    [actions]="['delete']"
    [removeImage]="removeImage.bind(this)"
  />
</div>
