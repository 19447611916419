import { IUser } from './user.interface';

export interface IDrivingLog {
  _id: string;
  service_number: string;
  downloaded: boolean;
  events: { horodate: string }[];
  user: IUser;
  last_activity_at: string;
  created_at: string;
  updated_at: string;
}

export interface DrivingLogEvent {
  type: DrivingLogEventType;
  horodate: Date;
  comment?: string;
}

export enum DrivingLogEventType {
  LOGOUT = 'LOGOUT',
  SESSION_EXPIRATION = 'SESSION_EXPIRATION',
  COMMENT = 'COMMENT'
}

export interface IDrivingLogArchive {
  _id: string;
  export_path: string;
  sectors: { id: string; horodate: string }[];
  dates: string[];
  last_active_sector: { id: string; horodate: string };
  last_event_date: string;
  archived_at: string;
  created_at: string;
  updated_at: string;
  signed?: boolean;
  user: {
    last_name: string;
    first_name: string;
    service_number: string;
  };
  started_at: string;
  reads: Array<{
    service_number: string;
    first_name: string;
    last_name: string;
  }>;
  analyze?: {
    service_number: string;
    date: Date;
    events: Array<any>;
    issues: number;
    issuesAlerts: Array<{
      first: any;
      second: any;
    }>;
    issuesWarning: Array<{
      first: any;
      second: any;
    }>;
  };
}
