import { HttpClient } from '@angular/common/http';
import _ from 'lodash';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

import { Coordinates } from '../interface/location.interface';
import { ScriptHelper } from './script.helper';

export class MapsHelper {
  constructor(private httpClient: HttpClient) {}

  private static loadingPromise: Promise<void>;

  public async loadMapsApi(): Promise<void> {
    if (MapsHelper.loadingPromise) {
      return MapsHelper.loadingPromise;
    }

    MapsHelper.loadingPromise = Promise.all([
      lastValueFrom(
        this.httpClient.jsonp(
          `https://maps.googleapis.com/maps/api/js?key=${environment.GOOGLE_MAP_API_KEY}`,
          'callback'
        )
      ),
      ScriptHelper.loadScript(`https://unpkg.com/@googlemaps/markerclustererplus@1.2.10/dist/index.min.js`)
    ]).then(() => {});

    return MapsHelper.loadingPromise;
  }

  public static coordsToGMCoords(coords: Coordinates): google.maps.LatLngLiteral {
    return { lat: coords.latitude, lng: coords.longitude };
  }

  public static gmCoordsToCoords(latLng?: google.maps.LatLng | google.maps.LatLngLiteral | null): Coordinates | null {
    if (!latLng) return null;
    const coords: Coordinates = {
      latitude: _.isNumber(latLng.lat) ? latLng.lat : latLng.lat(),
      longitude: _.isNumber(latLng.lng) ? latLng.lng : latLng.lng()
    };
    return coords;
  }
}
