import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GenericDialogComponent } from 'app/components/generic/generic-dialog/generic-dialog.component';
import { IReporting, ReportingState } from 'app/interface/report.interface';
import { ReportsService } from 'app/services/reports.service';
import dayjs from 'dayjs';

@Component({
  selector: 'app-infos-card',
  templateUrl: './infos-card.component.html',
  styleUrls: ['./infos-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InfosCardComponent implements OnInit {
  @Input() report: IReporting = {} as any;
  public loaded = false;
  public highway: any = null;
  public data: any[] = [];
  public description = '';
  public isRegaCesar = false;
  public isEditable = false;
  public toolTipInfos = '';
  public parent: Array<any> = [];

  constructor(
    private translate: TranslateService,
    private reportService: ReportsService,
    private router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.loaded = true;
    const reportingType = this.report.type;

    this.isEditable =
      !reportingType.critical &&
      ![ReportingState.DONE, ReportingState.RAS, ReportingState.CLOSED].includes(this.report.state);

    if (this.report.source === 'REGA' || this.report.source === 'CESAR') {
      const date = dayjs(this.report.creation_date).format('L - LT');

      this.isRegaCesar = true;
      this.toolTipInfos = `${this.translate.instant('GLOBAL.TOOLTIP_REGA_CESAR')} : ${date}`;
    }

    this.description = this.report.description || '';

    if (this.report.linked) {
      this.parent = this.report.linked.filter((r: any) => r.type === 'PARENT');
    }

    this.data = this.buildData();
  }

  buildData() {
    const data = [];
    const sector = `${this.report.location.sector_name} (${this.report.location.sector_id})`;
    const creatorFullname =
      this.report.created_by !== undefined
        ? `${this.report.created_by.first_name} ${this.report.created_by.last_name}`
        : `${this.report.source}`;

    data.push({
      key: 'GLOBAL.CREATION_DATE',
      value: dayjs(this.report.created_at).format('L - LT'),
      isRegaCesar: this.isRegaCesar
    });
    data.push({
      key: 'GLOBAL.SECTOR',
      value: sector
    });
    data.push({
      key: 'GLOBAL.TYPE',
      value: this.report.type.label,
      isNoTranslation: true
    });
    if (this.report.subtype) {
      data.push({
        key: 'GLOBAL.SUBTYPE',
        value: this.report.subtype.label,
        isNoTranslation: true
      });
    }
    data.push({
      key: 'GLOBAL.REPORTED_BY',
      value: creatorFullname
    });

    if (this.report.unknownAccident) {
      data.push({
        key: 'GLOBAL.UNKNOWN_ACCIDENT',
        value: dayjs(this.report.unknownAccident.date).format('L - LT')
      });
    }

    if (this.report.autodone) {
      data.push({
        key: 'GLOBAL.AUTODONE',
        value: 'Oui'
      });
    } else if (this.report.state === 'DONE' || this.report.state === 'DONE_UNFINALIZED') {
      data.push({
        key: 'GLOBAL.AUTODONE',
        value: 'Non'
      });
    }

    if (this.report.affected_to) {
      const affectedFullname =
        typeof this.report.affected_to === 'object'
          ? `${this.report.affected_to.first_name} ${this.report.affected_to.last_name}`
          : `${this.report.affected_to}`;

      data.push({
        key: 'TASKS.AFFECTED_TO',
        value: affectedFullname
      });
    }

    if (this.report.teams && !this.report.affected_to) {
      const teamName =
        typeof this.report.teams === 'object' ? this.report.teams.map((t) => t.name).join(', ') : this.report.teams;

      data.push({
        key: 'TASKS.AFFECTED_TO',
        value: teamName
      });
    }

    return data;
  }

  openParent(parent: any) {
    window.open(`/reporting/${parent[0].id}/details`);
  }

  edit() {
    this.router.navigate(['./edit']);
  }

  public isFinalizable(reporting: IReporting): boolean {
    return this.reportService.isFinalizable(reporting);
  }

  public handleFinalizeClick(reportingId: string): void {
    this.reportService.openFinalizeReportingDialog(reportingId, () => {
      this.report.state = ReportingState.DONE;
    });
  }

  public couldBeUnknownAccident(reporting: any): boolean {
    return (
      parseInt(reporting.state !== 'DONE' && reporting.type, 10) >= 30 &&
      !reporting.unknownAccident &&
      (!reporting.linked || (reporting.linked && reporting.linked.filter((r: any) => r.type === 'PARENT').length === 0))
    );
  }

  private async unknownAccident(reporting: any): Promise<void> {
    await this.reportService.unknownAccident(reporting._id, localStorage.getItem('service_number')!);
    this.report.unknownAccident = {
      user: localStorage.getItem('service_number')!,
      date: new Date()
    };

    this.data.push({
      key: 'GLOBAL.UNKNOWN_ACCIDENT',
      value: dayjs().format('L - LT')
    });

    this.dialog.closeAll();
  }

  openUnknownAccident(reporting: any) {
    if (!this.couldBeUnknownAccident(reporting)) {
      return;
    }

    this.dialog.open(GenericDialogComponent, {
      width: '50%',
      data: {
        structure: {
          title: 'GLOBAL.CONFIRMATION',
          text: 'CONFIRM.UNKNOWN_ACCIDENT',
          buttons: [
            {
              text: 'GLOBAL.VALID_UNKNOWN',
              class: 'validation',
              action: {
                target: 'custom',
                params: {
                  id: 'unknown',
                  function: () => this.unknownAccident(reporting)
                }
              }
            },
            {
              text: 'GLOBAL.CANCEL',
              class: 'cancel',
              action: {
                target: 'generic',
                params: {
                  id: 'close',
                  function: undefined
                }
              }
            }
          ]
        }
      },
      disableClose: true
    });
  }
}
