import { AfterViewChecked, Component, ElementRef, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IAccidentLane, IAccidentLanes, LaneType } from 'app/interface/accident-reporting.interface';
import { IconService } from 'app/services/icon.service';
import { isIE } from 'app/tools/browserDetection';
import { ILocationComponent } from 'src/app/interface/location.interface';

import { LanesOtherDialogComponent } from './lanes-other-dialog/lanes-other-dialog.component';

type LaneInView = {
  index: number;
  isImpacted: boolean;
  classSuffix?: string;
  name: string;
};

type LanesView = {
  regular: LaneInView[];
  opposite: LaneInView[];
};

@Component({
  selector: 'app-lanes-card',
  templateUrl: './lanes-card.component.html',
  styleUrls: ['./lanes-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LanesCardComponent implements AfterViewChecked {
  public hasLanes = false;
  public hasOthersCurrent = false;
  public hasOthersOpposite = false;

  @Input() public location: ILocationComponent;

  private _lanes: IAccidentLanes | undefined;
  @Input() private set lanes(lanes: IAccidentLanes | undefined) {
    this._lanes = lanes;
    this.makeLanesView(lanes);
  }
  private get lanes() {
    return this._lanes;
  }

  /** Display-ready representation of the impacted lanes. */
  public lanesView: LanesView = { regular: [], opposite: [] };

  @ViewChild('lanesViewElement', { static: true }) private lanesViewElement?: ElementRef;

  constructor(
    private dialog: MatDialog,
    iconService: IconService
  ) {
    iconService.register('warning_circle', '/assets/icon/reportings/details/warning_white_bg.svg');
  }

  ngAfterViewChecked() {
    if (isIE(11) && this.lanesViewElement) {
      const element = this.lanesViewElement.nativeElement;
      element.style.paddingBottom = element.scrollWidth > element.offsetWidth + 1 ? '20px' : '0';
    }
  }

  private makeLanesView(lanes: IAccidentLanes | undefined): void {
    if (lanes) {
      const isAnyExplicitLane =
        lanes.currentDirection.impacted.some((e) => e.type === LaneType.LANE) ||
        lanes.oppositeDirection.impacted.some((e) => e.type === LaneType.LANE);

      this.hasOthersOpposite = lanes.oppositeDirection.impacted.some((e) => e.type !== LaneType.LANE);
      this.hasOthersCurrent = lanes.currentDirection.impacted.some((e) => e.type !== LaneType.LANE);

      this.lanesView = {
        regular: this.makeLanesInView(
          'regular',
          isAnyExplicitLane ? lanes.currentDirection.impacted.filter((e) => e.type === LaneType.LANE) : undefined,
          isAnyExplicitLane ? undefined : this.location.lanes
        ),
        opposite: this.makeLanesInView(
          'opposite',
          isAnyExplicitLane ? lanes.oppositeDirection.impacted.filter((e) => e.type === LaneType.LANE) : undefined,
          isAnyExplicitLane ? undefined : this.location.opposite_lanes
        )
      };
      this.hasLanes = true;
    } else {
      this.hasOthersCurrent = false;
      this.hasOthersOpposite = false;
      this.lanesView = {
        regular: this.makeLanesInView('regular', undefined, this.location.lanes),
        opposite: this.makeLanesInView('opposite', undefined, this.location.opposite_lanes)
      };
      this.hasLanes = true;
    }
  }

  private makeLanesInView(direction: 'opposite' | 'regular', lanes?: IAccidentLane[], total?: number): LaneInView[] {
    const actualTotal = lanes ? lanes.length : total !== undefined ? total : 1;
    const lanesInView: LaneInView[] = Array(actualTotal)
      .fill(0)
      .map((e, index) => {
        const { name, classSuffix } = this.getLaneNameAndClass(index, actualTotal);
        return { index, name, classSuffix, isImpacted: false };
      });
    lanes?.forEach((e, i) => {
      if (e.selected) {
        lanesInView[i].isImpacted = true;
      }
    });
    return direction === 'opposite' ? lanesInView : lanesInView.reverse();
  }

  private getLaneNameAndClass(index: number, total: number): { name: string; classSuffix: string } {
    let name: string;
    let classSuffix: string;
    switch (index) {
      case 0:
        name = 'REPORTING.LANES_CARD.EMERGENCY_STOP_STRIP_SHORT';
        classSuffix = '-bau';
        break;
      case 1:
        name = 'REPORTING.LANES_CARD.RIGHT_SHORT';
        classSuffix = '-vd';
        break;
      case total - 1:
        name = 'REPORTING.LANES_CARD.LEFT_SHORT';
        classSuffix = '-vg';
        break;
      default:
        name = 'REPORTING.LANES_CARD.MEDIAN_SHORT';
        classSuffix = '';
    }
    return { name, classSuffix };
  }

  /**
   * Creates a new instance of the damages form dialog to display the provided
   * form data.
   */
  public openLanesOtherDialog(target: boolean): void {
    this.dialog.open(LanesOtherDialogComponent, {
      width: '80%',
      maxWidth: '654px',
      data: target === true ? this.lanes!.currentDirection : this.lanes!.oppositeDirection,
      disableClose: false
    });
  }
}
