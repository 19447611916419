<div class="info-window infrastructure-info-window">
  <div class="info-window--main">
    <div class="info-window--icon">
      <img src="{{ infrastructure.type.pin }}" class="over-icon" />
    </div>
    <div class="info-window--detail">
      <p>
        {{ infrastructure.location.highway_name }} - {{ infrastructure.location.display }}
        (
        {{ 'FLU-FLR.DIRECTION' | translate }} {{ infrastructure.location.direction }}
        )
      </p>
      <p>{{ infrastructure.type.name }}</p>
      <p>{{ infrastructure.name }}</p>
      <p>{{ infrastructure.description }}</p>
    </div>
  </div>

  <!-- Actions -->
  <div *ngIf="showDelete" class="info-window--actions">
    <div class="buttons">
      <button
        *ngIf="!showDeleteConfirm"
        class="icon-only-button"
        mat-flat-button
        color="basic"
        matTooltip="{{ 'GLOBAL.EDIT' | translate }}"
        matTooltipPosition="after"
        (click)="handleEditInfrastructureClick(infrastructure._id)"
      >
        <mat-icon>edit</mat-icon>
      </button>
      <button
        *ngIf="!showDeleteConfirm"
        class="icon-only-button"
        mat-flat-button
        color="warn"
        matTooltip="{{ 'GLOBAL.DELETE' | translate }}"
        matTooltipPosition="after"
        (click)="handleDeleteInfrastructureClick()"
      >
        <mat-icon>delete_forever</mat-icon>
      </button>
    </div>

    <div *ngIf="showDeleteConfirm" class="info-window--deletion-box">
      <div class="warning-msg">{{ 'CONFIRM.DELETE_INFRASTRUCTURE' | translate }}</div>
      <div class="buttons">
        <button (click)="handleDeleteInfrastructureConfirmationClick(infrastructure._id)" mat-flat-button color="warn">
          {{ 'GLOBAL.DELETE' | translate }}
        </button>
        <button (click)="handleCancelInfrastructureDeletionClick()" mat-flat-button>
          {{ 'GLOBAL.CANCEL' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
