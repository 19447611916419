<!-- Option template -->
<ng-template #defaultOptionTemplate let-option>
  <div class="flex items-center gap-4">
    <img *ngIf="option.value.icon" width="25" height="25" src="{{ option.value.icon }}" />
    {{ option.value.label | translate }}
  </div>
</ng-template>

<!-- None special option -->
<mat-option *ngIf="options && !options.length" class="none-option" [value]="null" disabled>
  <ng-container *ngIf="isLoadingOptions">
    <app-loading className="small" [showText]="false" />
  </ng-container>
  <ng-container *ngIf="!isLoadingOptions">
    {{ noOptionLabel || 'No option' | translate }}
  </ng-container>
</mat-option>

<!-- Actual options -->
<ng-container *ngIf="options && options.length">
  <ng-container *ngFor="let item of options">
    <!-- Simple option list -->
    <mat-option *ngIf="!item.options" [value]="item.value" [disabled]="item.disabled || item.value.disabled">
      <ng-container
        *ngTemplateOutlet="
          optionTemplateRef || defaultOptionTemplate;
          context: { $implicit: item, option: item, value: item.value, original: item.original }
        "
      />
    </mat-option>
    <!-- Option group list -->
    <mat-optgroup *ngIf="item.options" label="{{ item.label | translate }}">
      <mat-option *ngFor="let option of item.options" [value]="option.value" [disabled]="option.value.disabled">
        <ng-container
          *ngTemplateOutlet="
            optionTemplateRef || defaultOptionTemplate;
            context: { $implicit: option, option: option, value: option.value, original: option.original }
          "
        />
      </mat-option>
    </mat-optgroup>
  </ng-container>
</ng-container>
