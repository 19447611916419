import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IDrivingLogArchive } from 'app/interface/driving-logs.interface';
import { DrivingLogsService } from 'app/services/driving-logs.service';
import { LoadingService } from 'app/services/loading.service';
import { SectorsService } from 'app/services/sectors.service';
import { TitleService } from 'app/services/title.service';
import dayjs from 'dayjs';
import _ from 'lodash';
import { tap } from 'rxjs/operators';

export interface IDrivingLogArchiveDisplay {
  _id: string;
  archived_at: Date;
  service_number: string;
  last_name: string;
  first_name: string;
  signed: boolean;
  actions: { [key: string]: any }[];
  reads: Array<{ first_name: string; last_name: string; service_number?: string }>;
  analyze?: {
    service_number: string;
    date: Date;
    events: Array<any>;
    issues: number;
    issuesAlerts: Array<{
      first: any;
      second: any;
    }>;
    issuesWarning: Array<{
      first: any;
      second: any;
    }>;
  };
}

@Component({
  selector: 'app-readers-dialog',
  templateUrl: './readers.dialog.html',
  styleUrls: ['./readers.dialog.scss']
})
export class ReadersDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ReadersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-driving-logs-archives',
  templateUrl: './driving-logs-archives-list.component.html',
  styleUrls: ['./driving-logs-archives-list.component.scss']
})
export class DrivingLogsArchivesListComponent implements OnInit {
  public loaded = false;
  items: IDrivingLogArchiveDisplay[] = [];
  total = 0;
  selectedUpdateDate?: 'LAST_WEEK' | 'LAST_MONTH' | 'LAST_YEAR' | 'OLD_AF';
  selectedMadeDate?: 'LAST_WEEK' | 'LAST_MONTH' | 'LAST_YEAR' | 'OLD_AF';

  filterData = {
    sectors: {
      items: [] as { value: string; label: string }[],
      default: '' // EVO Multiple sectors: Set default value as an array
    },
    from: {
      max: new Date(),
      default: dayjs().subtract(1, 'week').toDate()
    },
    to: {
      min: dayjs().subtract(1, 'week').toDate(),
      default: dayjs().endOf('day')
    }
  };

  selectedSectors: string[] = [];
  selectedPermissions: string[] = [];
  drivingLogList: any[] = [];

  rawFilters = {
    sectors: '',
    from: dayjs().subtract(1, 'week').toDate(),
    to: new Date()
  };

  public allSectors: any;

  private filters: {
    q?: string;
    sectors?: string[];
    from?: string;
    to?: string;
    updated_before?: string;
    updated_after?: string;
    made_after?: string;
    made_before?: string;
    permissions?: string[];
  } = {};

  private localFiltersKey = 'filtersDrivingLogArchives';

  constructor(
    private sectorService: SectorsService,
    private drivingLogs: DrivingLogsService,
    private router: Router,
    public dialog: MatDialog,
    public loading: LoadingService,
    title: TitleService
  ) {
    title.setTitle('DRIVING_LOGS.ARCHIVED_LIST.TITLE');
  }

  public setDefaultSector(): void {
    let userSectors = [];
    if (JSON.parse(localStorage.getItem('sectorsSelected') || '[]').sectors) {
      userSectors = JSON.parse(localStorage.getItem('sectorsSelected') || '[]').sectors.map((s: any) => s);
    }
    if (!this.filters.sectors || this.filters.sectors.length === 0) {
      this.filters.sectors = userSectors;
      this.selectedSectors = userSectors;
    }
  }

  /**
   * Initialize generic list filters and retrieve driving log archives.
   */
  async ngOnInit() {
    if (localStorage.getItem('role') === 'ADM') {
      this.sectorService.getAll().subscribe((sectors) => {
        this.allSectors = _.sortBy(
          sectors.items.map((s) => ({
            value: s.id,
            label: s.name
          })),
          (e) => e.label
        );
      });
    } else {
      // Get user's sectors
      const teams = localStorage.getItem('teams');
      this.allSectors = [];
      if (teams) {
        await JSON.parse(teams).map(async (el: any) => {
          await this.sectorService.getByTeamsId(el.id).subscribe((sectors: any) => {
            const found = this.allSectors.some((sector: { label: any }) => sector.label === sectors[0].name);
            if (!found) {
              this.allSectors.push({
                value: sectors[0].id,
                label: sectors[0].name
              });
            }
          });
        });
      }
    }
    this.setDefaultSector();
    this.updateFilters();
    this.getArchives();
  }

  public async download(id: string) {
    await this.drivingLogs.downloadArchivedPdf(id);
    const index = _.findIndex(this.items, (i: any) => i._id === id);
    if (
      this.items[index] &&
      this.items[index].reads.filter(
        (item: any): boolean => item.service_number === localStorage.getItem('service_number')
      ).length === 0
    ) {
      this.items[index].reads.push({
        last_name: localStorage.getItem('last_name')!,
        first_name: localStorage.getItem('first_name')!,
        service_number: localStorage.getItem('service_number')!
      });
    }
    this.updateFilters();
  }

  gotoOngoing() {
    setTimeout(async () => this.router.navigateByUrl('/driving-logs'), 300);
  }

  /**
   * Convert raw filters from to API-ready filters.
   *
   * @param skipUpdate Whether to update the driving logs archives list after
   *    once filters have been updated (defaults: `false`).
   */
  updateFilters(skipUpdate = false) {
    /*
     * Update `sectors` filter
     * EVO Multiple sectors: No need to encapsulate values of both operands into arrays
     */
    this.filters.sectors = this.rawFilters.sectors ? [this.rawFilters.sectors] : [this.filterData.sectors.default];

    // Update `from` and `to` filters
    let fromMoment = dayjs(this.rawFilters.from);
    fromMoment = this.rawFilters.from && fromMoment.isValid() ? fromMoment : dayjs(this.filterData.from.default);
    let toMoment = dayjs(this.rawFilters.to);
    toMoment = this.rawFilters.to && toMoment.isValid() ? toMoment : dayjs(this.filterData.to.default);

    if (fromMoment.isAfter(toMoment, 'day')) {
      // Swap "from" and "to" filters if they somehow end up in the wrong order
      [fromMoment, toMoment] = [toMoment, fromMoment];
      this.rawFilters.from = fromMoment.toDate();
      this.rawFilters.to = toMoment.toDate();
    }

    this.filters.from = fromMoment.format('YYYY-MM-DD');
    this.filterData.from.max = toMoment.toDate();

    this.filters.to = toMoment.format('YYYY-MM-DD');
    this.filterData.to.min = fromMoment.toDate();
    // Save updated filters to local storage
    this.updateLocalFilters();
    this.getDrivingLogsList();
    if (!skipUpdate) {
      this.getArchives();
    }
  }

  /**
   * Retrieve driving log archives from the API.
   */
  private getArchives() {
    if (!this.rawFilters.sectors) {
      return;
    }
    this.drivingLogs
      .getArchives({
        sectors: this.rawFilters.sectors,
        from: dayjs(this.rawFilters.from).format('YYYY-MM-DD'),
        to: dayjs(this.rawFilters.to).format('YYYY-MM-DD')
      })
      .pipe(
        tap(({ items, total }) => {
          this.items = this.parseList(items);
          this.total = total;
        })
      )
      .subscribe();
  }

  /**
   * Extract filters for this page from local storage if available. The filters
   * are saved in both `rawFilters` and `filters` properties accordingly.
   */
  // private extractLocalFilters() {
  //   const savedFilters = localStorage.getItem(this.localFiltersKey);
  //   if (savedFilters) {
  //     this.filters = JSON.parse(savedFilters);
  //     this.rawFilters.sectors = (this.filters.sectors) ? this.filters.sectors[0] : '';
  //   }
  // }

  /**
   * Save current filters into local storage.
   */
  private updateLocalFilters() {
    localStorage.setItem(this.localFiltersKey, JSON.stringify(this.filters));
  }

  /**
   * Convert the raw API response to a display-ready data structure.
   */
  private parseList(items: IDrivingLogArchive[]): IDrivingLogArchiveDisplay[] {
    return items.map((item) => ({
      _id: item._id,
      archived_at: dayjs(item.archived_at).toDate(),
      first_name: item.user.first_name,
      last_name: item.user.last_name,
      service_number: item.user.service_number,
      created_at: item.started_at !== '' ? item.started_at : null,
      signed: item.signed ? true : false,
      reads: item.reads,
      analyze: item.analyze,
      actions: [
        {
          type: 'button',
          style: { color: 'warn' },
          events: { click: 'download' },
          options: { icon: 'file_download' },
          tooltip: 'GLOBAL.DOWNLOAD'
        }
      ]
    }));
  }

  public viewReaders(readers: { first_name: string; last_name: string; service_number: string }) {
    const dialogRef = this.dialog.open(ReadersDialogComponent, {
      width: '400px',
      data: readers
    });

    dialogRef.afterClosed().subscribe();
  }

  public getGPX(id: string) {
    this.drivingLogs.getGPX(id, true);
  }

  public devMode() {
    return localStorage.getItem('devMode') !== null;
  }

  public toggleSelectSectors() {
    if (this.selectedSectors.length < this.allSectors.length) {
      this.selectedSectors = this.allSectors.map((sector: any) => sector.value);
    } else {
      this.selectedSectors = [];
    }
    this.getDrivingLogsList();
  }

  public handleFilters(event: { type: string; data: any; params: any }) {
    if (event.data.search) {
      this.filters.q = String(event.data.search);
    } else if (event.type === 'search') {
      delete this.filters.q;
    }
  }

  generateFilters() {
    const m = dayjs().endOf('day');
    const dayjsUpperBounds: { [key: string]: () => dayjs.Dayjs } = {
      LAST_WEEK: () => m,
      LAST_MONTH: () => m,
      LAST_YEAR: () => m,
      OLD_AF: () => dayjs().subtract(1, 'year').endOf('day')
    };
    const dayjsLowerBounds: { [key: string]: () => dayjs.Dayjs } = {
      LAST_WEEK: () => dayjs().subtract(1, 'week').startOf('day'),
      LAST_MONTH: () => dayjs().subtract(1, 'month').startOf('day'),
      LAST_YEAR: () => dayjs().subtract(1, 'year').startOf('day'),
      OLD_AF: () => dayjs(0, 'X')
    };
    // -------------------- Sector_id --------------------

    delete this.filters.sectors;
    if (this.selectedSectors.length > 0) {
      this.filters.sectors = [...this.selectedSectors];
    }

    // Permissions

    delete this.filters.permissions;
    if (this.selectedPermissions.length > 0) {
      this.filters.permissions = [...this.selectedPermissions];
    }

    // -------------------- Updated date --------------------

    delete this.filters.updated_after; // from
    delete this.filters.updated_before; // to
    if (this.selectedUpdateDate) {
      this.filters.updated_before = dayjsUpperBounds[this.selectedUpdateDate]().toDate().toISOString();
      this.filters.updated_after = dayjsLowerBounds[this.selectedUpdateDate]().toDate().toISOString();
    }

    // -------------------- Made date --------------------

    delete this.filters.made_after; // from
    delete this.filters.made_before; // to
    if (this.selectedMadeDate) {
      this.filters.made_before = dayjsUpperBounds[this.selectedMadeDate]().toDate().toISOString();
      this.filters.made_after = dayjsLowerBounds[this.selectedMadeDate]().toDate().toISOString();
    }

    const storedFilters = _.omit(this.filters, ['limit', 'offset']);
    localStorage.setItem('sectorsSelected', JSON.stringify(storedFilters));
  }

  getDrivingLogsList(event?: { type: string; data: any; params: any }) {
    this.loaded = false;
    this.generateFilters();
    if (event && event.data) {
      this.handleFilters(event);
    }

    if (event && event.params) {
      this.filters = { ...this.filters, ...event.params };
    }
    if (!this.filters.sectors) {
      this.total = 0;
      this.drivingLogList = [];
      this.loaded = true;

      return;
    }

    this.loading.on();
    this.drivingLogs.getArchives(this.filters).subscribe((res: any) => {
      this.total = res.total;
      this.drivingLogList = this.parseList(res.items);
      this.loading.off();
      this.loaded = true;
    });
  }

  showAnalyze(item: any, lvl: string): void {
    console.info(lvl, item.analyze[`issues${lvl}`]);
  }
}
