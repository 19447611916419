<div class="container-tasks" *ngIf="!filtersLoading">
  <h1 class="mainTitle">
    <div class="reporting-buttons-actions">
      <button
        mat-raised-button
        color="warn"
        class="icon-only-button"
        matTooltip="{{ 'GLOBAL.ADD' | translate }}"
        matTooltipPosition="above"
        (click)="addTask()"
      >
        <mat-icon>add</mat-icon>
      </button>
      <button
        mat-raised-button
        class="icon-only-button"
        matTooltip="{{ 'GLOBAL.DOWNLOAD' | translate }}"
        matTooltipPosition="above"
        [disabled]="items.length === 0"
        [ngClass]="{ 'disabled-button': items.length === 0 }"
        (click)="downloadReporting()"
      >
        <mat-icon>file_download</mat-icon>
      </button>
    </div>
  </h1>
  <br /><br />

  <div class="filters-task-block">
    <form [formGroup]="filtersForm">
      <span class="slide-priority-toggle">
        {{ 'TASKS.PRIORITY_TASKS' | translate }}
        <mat-slide-toggle
          color="warn"
          class="red-blue-slide"
          [ngClass]="{ selected: storage.mode === 'secondary' }"
          [checked]="storage.mode === 'secondary'"
          (change)="toggleMode($event)"
        />
        {{ 'GLOBAL.SECONDARY_P' | translate }}
      </span>

      <mat-form-field>
        <mat-select
          placeholder="{{ 'GLOBAL.SECTOR' | translate }}"
          formControlName="sectors"
          multiple
          (openedChange)="updateFilters($event)"
        >
          <div class="clean toggleList h-auto" (click)="toggleList('sectors')">
            {{ 'GLOBAL.TOGGLE_SELECT' | translate }}
          </div>

          <mat-option *ngFor="let sector of sectorsList" [value]="sector.id">
            {{ sector.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-select
          placeholder="{{ 'GLOBAL.STATUS' | translate }}"
          formControlName="states"
          multiple
          (openedChange)="updateFilters($event)"
        >
          <div class="clean toggleList h-auto" (click)="toggleList('states')">
            {{ 'GLOBAL.TOGGLE_SELECT' | translate }}
          </div>
          <mat-option *ngFor="let state of reportStates" [value]="state.id">
            {{ state.label | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-select
          placeholder="{{ 'GLOBAL.TYPE' | translate }}"
          formControlName="types"
          multiple
          (openedChange)="updateFilters($event)"
        >
          <div class="clean toggleList h-auto" (click)="toggleList('types')">
            {{ 'GLOBAL.TOGGLE_SELECT' | translate }}
          </div>
          <mat-option *ngFor="let type of getFilteredTypes()" [value]="type.id">
            {{ type.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-select
          placeholder="{{ 'GLOBAL.SUBTYPE' | translate }}"
          formControlName="subtypes"
          multiple
          (openedChange)="updateFilters($event)"
        >
          <div class="clean toggleList h-auto" (click)="toggleList('subtypes')">
            {{ 'GLOBAL.TOGGLE_SELECT' | translate }}
          </div>
          <mat-option *ngFor="let subtype of availableSubTypes" [value]="subtype.id">
            {{ subtype.label }} {{ getOptionalTypeLabel(subtype.label, subtype.typeId) }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-select
          placeholder="{{ 'GLOBAL.HIGHWAY_FILTER' | translate }}"
          formControlName="sectorsHighways"
          multiple
          (openedChange)="updateFilters($event)"
        >
          <div class="clean toggleList h-auto" (click)="toggleList('sectorsHighways')">
            {{ 'GLOBAL.TOGGLE_SELECT' | translate }}
          </div>
          <mat-option *ngFor="let sectorsHighway of highwayList" [value]="sectorsHighway.UID">
            {{ sectorsHighway.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-select
          placeholder="{{ 'GLOBAL.DIRECTION' | translate }}"
          formControlName="direction"
          multiple
          (openedChange)="updateFilters($event)"
        >
          <div class="clean toggleList h-auto" (click)="toggleList('direction')">
            {{ 'GLOBAL.TOGGLE_SELECT' | translate }}
          </div>
          <mat-option *ngFor="let el of getDirection" [value]="el">
            {{ el }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="this.isPrDisable()">
        <input
          matInput
          placeholder="{{ 'GLOBAL.PR_START' | translate }}"
          (change)="updateFilters()"
          formControlName="startPr"
        />
        <mat-error>{{ 'GLOBAL.ERROR.PATTERN' | translate: { hint: '1+200' } }}</mat-error>
      </mat-form-field>

      <mat-form-field *ngIf="this.isPrDisable()">
        <input
          matInput
          placeholder="{{ 'GLOBAL.PR_END' | translate }}"
          (change)="updateFilters()"
          formControlName="endPr"
        />
        <mat-error>{{ 'GLOBAL.ERROR.PATTERN' | translate: { hint: '1+200' } }}</mat-error>
      </mat-form-field>

      <mat-form-field>
        <input
          matInput
          [matDatepicker]="fromDate"
          placeholder="{{ 'GLOBAL.CREATION_DATE_FROM' | translate }}"
          formControlName="from"
          (dateChange)="updateFilters()"
          [value]="startDate"
        />
        <mat-datepicker-toggle matSuffix [for]="fromDate"></mat-datepicker-toggle>
        <mat-datepicker #fromDate disabled="false"></mat-datepicker>
      </mat-form-field>

      <mat-form-field>
        <input
          matInput
          [matDatepicker]="toDate"
          placeholder="{{ 'GLOBAL.CREATION_DATE_TO' | translate }}"
          formControlName="to"
          (dateChange)="updateFilters()"
          [value]="endDate"
        />
        <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
        <mat-datepicker #toDate disabled="false"></mat-datepicker>
      </mat-form-field>
    </form>
  </div>

  <div *ngIf="this.loading" class="loader"></div>

  <app-list
    *ngIf="!this.loading"
    [listParameters]="listParameters"
    [items]="items"
    [total]="total"
    searchType="dynamic"
    (action)="handleAction($event)"
    (refresh)="search($event)"
  ></app-list>
</div>
