import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import {
  SelectComponent,
  SelectOption,
  SelectOptionOrGroup,
  SelectOptionValue
} from 'src/app/components/generic/form/select/select.component';
import { LoadingComponent } from 'src/app/components/generic/loading/loading.component';
import { ReportingType, ReportingTypePriority } from 'src/app/interface/report.interface';
import { ReportsService } from 'src/app/services/reports.service';

@Component({
  standalone: true,
  selector: 'app-reporting-type-select',
  imports: [CommonModule, LoadingComponent, TranslateModule, ReactiveFormsModule, SelectComponent],
  templateUrl: './reporting-type-select.field.component.html'
})
export class ReportingTypeSelectComponent {
  public reportingTypeOptions: SelectOptionOrGroup<string, ReportingType>[];

  @Input() public pformGroup: FormGroup<{
    reportingType: FormControl<string | SelectOptionValue>;
  }>;
  @Input() public groupOptions?: boolean = true;

  constructor(private reportsService: ReportsService) {
    this.reportsService.getReportingTypes().then((reportingTypes) => {
      this.reportingTypeOptions = this.makeOptions(reportingTypes, this.groupOptions);
    });
  }

  private makeOptions(reportingTypes: ReportingType[], group?: boolean): SelectOptionOrGroup<string, ReportingType>[] {
    if (!group) {
      return reportingTypes.map((e) => this.makeTypeOption(e));
    }

    const typesByPriority: { [key: string]: SelectOption<string, ReportingType>[] } = {
      high: [],
      medium: [],
      low: [],
      other: []
    };
    const priorityMap: { [key: string]: string } = {
      [ReportingTypePriority.RED]: 'high',
      [ReportingTypePriority.ORANGE]: 'medium',
      [ReportingTypePriority.BLUE]: 'low'
    };
    for (const type of reportingTypes) {
      const priorityKey = type.priority in priorityMap ? priorityMap[type.priority] : 'other';

      if (priorityKey in typesByPriority) {
        typesByPriority[priorityKey].push(this.makeTypeOption(type));
      }
    }

    return Object.keys(typesByPriority)
      .map((priority) => ({
        isGroup: true as const,
        label: `REPORTING.${priority.toUpperCase()}_PRIORITY`,
        options: typesByPriority[priority]
      }))
      .filter((priorityGroup) => priorityGroup.options.length > 0);
  }

  private makeTypeOption(reportingType: ReportingType): SelectOption<string, ReportingType> {
    return {
      value: {
        key: reportingType.id,
        label: reportingType.label || '',
        icon: reportingType.picto
      },
      original: reportingType
    };
  }
}
