import {
  AsyncValidatorFn,
  FormControl,
  FormControlOptions,
  FormControlState,
  FormGroup,
  ɵElement,
  UntypedFormGroup,
  ValidatorFn
} from '@angular/forms';

/**
 * Takes a value type and transforms it to its FormControl type. Also works with FormBuilder parameter arrays.
 *
 * @example T | null  => FormControl<T | null>
 */
export type FormControlFromValue<T> = ɵElement<T, null>;

/**
 * Takes a "form values" type and map each field value to its controlled value type.
 *
 * @example { key: string | null } => { key: FormControl<string | null }
 */
export type FormControlsFromValue<T> = { [K in keyof T]: FormControlFromValue<T[K]> };

/**
 * Takes a "form values" type and creates the FormGroup type from it
 *
 * @example { key: string | null } => FormGroup<{ key: FormControl<string | null }>
 */
export type FormGroupFromValues<T> = FormGroup<FormControlsFromValue<T>>;

type FormControlArrayFromValue<T> =
  | [T | FormControlState<T | null> | null]
  | [T | FormControlState<T | null> | null, FormControlOptions | ValidatorFn | ValidatorFn[] | null | undefined]
  | [
      T | FormControlState<T | null> | null,
      FormControlOptions | ValidatorFn | ValidatorFn[] | null | undefined,
      AsyncValidatorFn | AsyncValidatorFn[] | null | undefined
    ];

/**
 * Creates the parameter type for formBuilder.group from a "form values" type
 */
type FormBuilderOptions<T> = {
  [K in keyof T]: FormControlArrayFromValue<T[K]>;
};

export class FormHelper {
  public static isErrorInForm(form: UntypedFormGroup, controlName: string, errorName: string): boolean {
    // TODO: use lodash get for nested controls?
    return form.controls[controlName].hasError(errorName);
  }

  /**
   * Creates a strongly typed FormGroup using FormBuilder syntax
   */
  // TODO: handle nested form groups
  public static group<T>(options: FormBuilderOptions<T>): FormGroupFromValues<T> {
    const formGroupOptions = {} as FormControlsFromValue<T>;
    // In this function, we rely on FormControlsFromValue (which is strongly typed)
    // Typing those "any" seems overkill and would take a lot of time
    Object.entries(options).forEach(([key, arr]: [string, any]) => {
      formGroupOptions[key as keyof FormControlsFromValue<T>] = new FormControl(...(arr as [any])) as any;
    });
    return new FormGroup<FormControlsFromValue<T>>(formGroupOptions);
  }
}
