import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { CarouselComponent, ParsedPicture, Picture } from '../../carousel/carousel.component';

let lastInputId = 0;

@Component({
  standalone: true,
  selector: 'app-file-input',
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    CarouselComponent
  ],
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss']
})
export class FileInputComponent {
  public fileLength: number = 0;
  public pictures: Picture[] = [];
  public inputId: string = 'file-input-' + ++lastInputId;
  public authorizedFilesFormat: string = '';
  public isHighlighted: boolean = false;
  private highlightTimeoutId?: NodeJS.Timeout;

  @Input() public pformGroup: UntypedFormGroup;
  @Input() public pformControlName: string;
  @Input() public label?: string;
  @Input() public icon?: string;
  @Input() private mimeTypes?: string[];
  @Input() public accept?: string; // @see https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/accept#unique_file_type_specifiers

  @ViewChild('fileInput') fileInput?: ElementRef<HTMLInputElement>;

  constructor(
    private _sanitizer: DomSanitizer,
    private translate: TranslateService,
    private snackbar: MatSnackBar
  ) {
    if (this.mimeTypes?.length) {
      this.authorizedFilesFormat = this.mimeTypes.join(', ');
    }
  }

  public handleDragEnter(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    if (this.highlightTimeoutId) {
      clearTimeout(this.highlightTimeoutId);
      this.highlightTimeoutId = undefined;
    }
    this.highlight();
  }

  private highlight(): void {
    this.isHighlighted = true;
  }

  private unhighlight(): void {
    this.highlightTimeoutId = setTimeout(() => (this.isHighlighted = false), 100);
  }

  public handleDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.unhighlight();
  }

  public handleDrop(event: DragEvent): void {
    event.preventDefault();
    this.unhighlight();
    if (!this.fileInput) return;
    if (event.dataTransfer) {
      this.fileInput.nativeElement.files = event.dataTransfer.files;
      this.handleChange();
    }
  }

  public handleClick(event: MouseEvent): void {
    event.preventDefault();
    this.fileInput?.nativeElement.click();
  }

  public handleChange(_event?: InputEvent): void {
    const fileList = this.fileInput?.nativeElement.files;
    if (!fileList || this.pictures === undefined) {
      this.fileLength = 0;
      this.updateFormValue();
      return;
    }

    const fileErrorsList: string[] = [];
    const files = Array.from(fileList);

    // Filter out the "new" files that were already here
    const pictures = this.pictures.filter((e) => !files.some((file) => this.isSameFile(file, e.original_object)));

    const tabValidFiles = files.filter((file) => {
      if (this.mimeTypes && this.mimeTypes.length > 0 && this.mimeTypes.indexOf(file.type) === -1) {
        fileErrorsList.push(file.name);
        return false;
      }
      return true;
    });

    tabValidFiles.forEach((currentFile) => {
      let url = '';
      const fileType = currentFile.type.split('/')[0];
      if (fileType === 'image') {
        url = URL.createObjectURL(currentFile);
        // currentFile.url = url;
      } else {
        url = '/assets/icon/reportings/details/file.svg';
      }
      pictures.push({
        name: currentFile.name,
        extension: currentFile.name.substring(currentFile.name.lastIndexOf('.'), currentFile.name.length - 1),
        type: fileType,
        horodate: new Date(currentFile.lastModified),
        mime_type: currentFile.type,
        url: this._sanitizer.bypassSecurityTrustUrl(url) as string,
        local: true,
        original_object: currentFile // Allow to delete the file in files input name.
      });
    });

    this.pictures = pictures;
    this.fileLength = pictures.length;

    const isMissingFile = pictures.some((e) => !files.some((file) => this.isSameFile(file, e.original_object)));
    if (this.fileInput && isMissingFile) {
      const newValue = this.newFileList(pictures.map((e) => e.original_object));
      this.fileInput.nativeElement.files = newValue;
    } else {
      this.updateFormValue();
    }

    // if (tabValidFiles.length > 0) {
    //   this.applyEvent('change', tabValidFiles);
    // }

    if (fileErrorsList.length > 0) {
      this.openSnackbar(fileErrorsList);
    }
  }

  public async removeImage(picture: ParsedPicture): Promise<void> {
    const el = this.fileInput?.nativeElement;
    if (!el || !el.files) return;
    const arr = Array.from(el.files).filter((e) => !this.isSameFile(e, picture.original_object));
    el.files = this.newFileList(arr);
    this.fileLength = arr.length;
    this.pictures = this.pictures.filter((e) => !this.isSameFile(e.original_object, picture.original_object));
    this.updateFormValue();
  }

  private updateFormValue(): void {
    const el = this.fileInput?.nativeElement;
    if (!el) return;
    const files = el.files ? Array.from(el.files) : [];
    this.pformGroup.get(this.pformControlName)?.setValue(files);
  }

  private newFileList(files: File[]): FileList {
    const dt = new DataTransfer();
    files.forEach((file) => dt.items.add(file));
    return dt.files;
  }

  /**
   * Not sure there is a way to check the file path,
   * we could read content and check it as well, but here we only check for metadata
   */
  private isSameFile(a: File, b: File): boolean {
    return a.lastModified === b.lastModified && a.name === b.name && a.size === b.size && a.type === b.type;
  }

  private openSnackbar(errorList: any[]): void {
    const message = `${this.translate.instant('REPORTING.ERROR.ERRORS_REPORTING_FILES')} ${errorList.join(', ')}`;
    this.snackbar.open(message, this.translate.instant('GLOBAL.CLOSE_LABEL'));
  }
}
