<div class="GPXViewer" *ngIf="mapsApiLoaded">
  <div class="header">
    <span class="title"> {{ 'GLOBAL.BREADCRUMB.GPX_VIEWER' | translate }} </span>

    <div class="fill"></div>

    <button type="button" *ngIf="!gpxData.length" mat-flat-button (click)="fileInput.click()">
      {{ 'GPX_VIEWER.SELECT_FILE' | translate }}
    </button>
    <input hidden #fileInput type="file" [formControl]="gpx" (change)="handleGPXFile($event)" />

    <span *ngIf="gpxData.length">
      <button mat-flat-button (click)="previousGPXInterval()"><mat-icon>skip_previous</mat-icon></button>
      {{ gpxSelectedStart | date: 'short' }} - {{ gpxSelectedEnd | date: 'short' }}
      <button mat-flat-button (click)="nextGPXInterval()"><mat-icon>skip_next</mat-icon></button>
    </span>

    <button *ngIf="gpxData.length" mat-flat-button (click)="clearGPX()">
      <mat-icon>delete_forever</mat-icon>
    </button>
  </div>

  <div class="map">
    <google-map
      width="100%"
      height="100%"
      [options]="{
        center: initialPosition,
        zoom: initialZoom,
        mapTypeControl: true
      }"
    >
      <!-- "start" green circle -->
      <map-circle
        *ngIf="gpxStart !== null"
        [center]="{
          lat: gpxStart.lat,
          lng: gpxStart.lng
        }"
        [options]="{
          fillColor: '#2ac416',
          fillOpacity: 0.35,
          strokeColor: '#0b5402',
          strokeWeight: 3,
          radius: 20
        }"
      />

      <!-- "end" red circle -->
      <map-circle
        *ngIf="gpxEnd !== null"
        [center]="{
          lat: gpxEnd.lat,
          lng: gpxEnd.lng
        }"
        [options]="{
          fillColor: '#d90000',
          fillOpacity: 0.35,
          strokeColor: '#540215',
          strokeWeight: 3,
          radius: 20
        }"
      />

      <!-- the line through the points -->
      <map-polyline
        *ngIf="polylinePoints.length > 0"
        [path]="polylinePoints"
        [options]="{
          strokeColor: '#AE0E16'
        }"
        (polylineClick)="not_implemented_zoomToPolyline($event)"
      />

      <!-- a circle for each point -->
      <map-circle
        *ngFor="let point of polylinePoints"
        #circle="mapCircle"
        [center]="{
          lat: point.lat,
          lng: point.lng
        }"
        [options]="{
          fillColor: '#AE0E16',
          fillOpacity: 1,
          strokeColor: '#ffd500',
          strokeWeight: 3,
          radius: 1
        }"
        (circleMouseover)="showGPXPointInfoWindow(point, circle)"
        (circleMouseout)="hideGPXPointInfoWindow()"
      />

      <!-- gpx point info on mouseover -->
      <map-info-window (closeclick)="hideGPXPointInfoWindow()">
        <div class="gpxInfoWindow" *ngIf="gpxInfos">
          <strong>{{ 'GPX_VIEWER.LATITUDE' | translate }} :</strong> <em>{{ gpxInfos.lat }}</em>
          <br />
          <strong>{{ 'GPX_VIEWER.LONGITUDE' | translate }} :</strong> <em>{{ gpxInfos.lng }}</em>
          <br />
          <strong>{{ 'GLOBAL.DATE' | translate }} :</strong> <em>{{ gpxInfos.horodate }}</em>
          <br />
        </div>
      </map-info-window>
    </google-map>
  </div>
</div>
