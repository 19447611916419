<h1 class="mainTitle">{{ 'ROLES.LIST_PAGE_NAME' | translate }}</h1>

<h2 class="second-level-title">{{ 'ROLES.PERMISSIONS_APP_MOBILE' | translate }}</h2>
<app-loading [active]="!loaded"></app-loading>
<app-list
  *ngIf="loading.get('get_roles').disabled && hasPermissionImport === true && loaded"
  [listParameters]="listParameters"
  [items]="rolesByPermissionListAppMobile"
  pagination="never"
  (action)="updateRolesList($event)"
>
</app-list>

<h2 class="second-level-title">{{ 'ROLES.PERMISSIONS_BACKOFFICE' | translate }}</h2>
<app-loading [active]="!loaded"></app-loading>
<app-list
  *ngIf="loading.get('get_roles').disabled && hasPermissionImport === true && loaded"
  [listParameters]="listParameters"
  [items]="rolesByPermissionListBackoffice"
  pagination="never"
  (action)="updateRolesList($event)"
>
</app-list>

<span class="role-list-button" *ngIf="loaded">
  <button mat-raised-button class="validation" (click)="sendNewRolesList()">
    {{ 'GLOBAL.SUBMIT_BUTTON_LABEL' | translate }}
  </button>
  <button mat-button (click)="cancelNewRoles()">{{ 'GLOBAL.CANCEL' | translate }}</button>
</span>
