// src/app/auth/auth-guard.service.ts
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { SessionService } from 'app/components/generic/services/session/session.service';
import { Permissions } from 'app/interface/permissions.enum';

import { ActiveModules, Profile, ProfileService } from './profile.service';
import { RolesService } from './roles.service';

@Injectable()
export class AuthGuardService {
  constructor(
    private sessionService: SessionService,
    private rolesService: RolesService,
    private router: Router
  ) {}
  private profile: Profile | null = ProfileService.getCurrentProfile();

  public canActivate(route: ActivatedRouteSnapshot): boolean {
    if (!this.checkValidToken()) {
      this.router.navigateByUrl('/');
      return false;
    }
    if (route.data.permissions && !this.checkPermissions(route.data.permissions)) {
      this.router.navigateByUrl('/');
      return false;
    }

    if (route.data.checkAdmin && !this.checkAdmin()) {
      this.router.navigateByUrl('/');
      return false;
    }

    if (route.data.checkDevMode && !this.checkDevMode()) {
      this.router.navigateByUrl('/');
      return false;
    }

    if (route.data.activeModule) {
      const moduleToCheck: string = route.data.activeModule;
      if (!this.profile || !this.profile.activeModules[moduleToCheck as keyof ActiveModules]) {
        this.router.navigateByUrl('/');
        return false;
      }
    }

    return true;
  }

  public canActivateChild(route: ActivatedRouteSnapshot): boolean {
    return this.canActivate(route);
  }

  private checkValidToken(): boolean {
    if (!this.sessionService.isTokenValid()) {
      return false;
    }
    return true;
  }

  private checkPermissions(permissions?: Permissions | Permissions[]): boolean {
    if (typeof permissions === 'string') {
      return this.rolesService.checkPermission(permissions as Permissions);
    } else if (Array.isArray(permissions) && permissions.length > 0) {
      for (const permission of permissions) {
        if (!this.rolesService.checkPermission(permission)) {
          return false;
        }
      }
    }
    return true;
  }

  private checkAdmin(): boolean {
    const isAdmin = this.rolesService.checkPermission(Permissions.BACK_OFFICE_SUPER_ADMIN);
    if (!isAdmin) {
      return false;
    }
    return true;
  }

  private checkDevMode(): boolean {
    const isDevMode = localStorage.getItem('devMode') !== null;
    if (!isDevMode) {
      return false;
    }
    return true;
  }
}
