<div *ngIf="form" [(formGroup)]="form" class="form-group">
  <div [ngSwitch]="question.controlType" class="form-field-question">
    <!-- INPUT TEXT START -->
    <div *ngSwitchCase="'textbox'">
      <mat-form-field *ngIf="!question.readonly && !question.shouldHide">
        <input
          matInput
          [formControlName]="question.key"
          [attr.disabled]="question.disabled"
          placeholder="{{ question.label | translate }}"
          value="{{ question.value }}"
          pattern="{{ question.pattern }}"
          [id]="question.key"
          [type]="question.type"
          (keyup)="applyEvent('keyup', $event)"
        />
        <mat-icon *ngIf="question.icon !== ''" matSuffix>{{ question.icon }}</mat-icon>
      </mat-form-field>
      <mat-form-field *ngIf="question.readonly">
        <input
          matInput
          [(attr.disabled)]="question.disabled"
          placeholder="{{ question.label | translate }}"
          value="{{ question.value }}"
          [id]="question.key"
          [type]="question.type"
          readonly="true"
        />
        <mat-icon *ngIf="question.icon !== ''" matSuffix>{{ question.icon }}</mat-icon>
      </mat-form-field>
    </div>
    <!-- INPUT TEXT END -->

    <!-- TEXTAREA START -->
    <div *ngSwitchCase="'textarea'">
      <mat-form-field *ngIf="!question.readonly && !question.shouldHide">
        <textarea
          matInput
          [formControlName]="question.key"
          [(attr.disabled)]="question.disabled"
          placeholder="{{ question.label | translate }}"
          value="{{ question.value }}"
          pattern="{{ question.pattern }}"
          [id]="question.key"
          (keyup)="applyEvent('keyup', $event)"
        ></textarea>
        <mat-icon *ngIf="question.icon !== ''" matSuffix>{{ question.icon }}</mat-icon>
      </mat-form-field>
      <mat-form-field *ngIf="question.readonly && !question.shouldHide">
        <textarea
          matInput
          [(attr.disabled)]="question.disabled"
          placeholder="{{ question.label | translate }}"
          value="{{ question.value }}"
          [id]="question.key"
          [type]="question.type"
          readonly="true"
        ></textarea>
        <mat-icon *ngIf="question.icon !== ''" matSuffix>{{ question.icon }}</mat-icon>
      </mat-form-field>
    </div>
    <!-- TEXTAREA END -->

    <!-- INPUT FILE START -->
    <input
      *ngSwitchCase="'file'"
      [formControlName]="question.key"
      [(attr.disabled)]="question.disabled"
      name="ques"
      [accept]="question.accept"
      [id]="question.key"
      [type]="question.type"
      (focus)="applyEvent('focus')"
      (change)="getFileNameAndLength($event)"
      (click)="applyEvent('click', $event)"
      style="display: none"
      multiple
    />

    <div *ngSwitchCase="'file'">
      <app-old-carousel
        [pictures]="question.picturesView"
        [mode]="'gallery'"
        [actions]="question.actions"
        [question]="question"
      ></app-old-carousel>
      <div class="add-button-container">
        <label
          [for]="question.key"
          (focus)="applyEvent('focus', $event)"
          (change)="applyEvent('change', $event)"
          (click)="applyEvent('click', $event)"
        >
          <i class="material-icons">add_a_photo</i> <span translate>REPOSITORIES.SELECT_FILE</span>
        </label>
        <span *ngSwitchCase="'file'">
          <span>{{ question.fileName }}</span>
          <span *ngIf="question.fileLength === 1">{{ question.fileLength }} {{ 'GLOBAL.ADDED_FILE' | translate }}</span>
          <span *ngIf="question.fileLength > 1">{{ question.fileLength }} {{ 'GLOBAL.ADDED_FILES' | translate }}</span>
        </span>
        <p *ngIf="authorizedFilesFormat !== ''" class="file-format">
          {{ 'GLOBAL.AUTHORIZED_FORMAT' | translate }} : {{ authorizedFilesFormat }}
        </p>
      </div>
    </div>
    <!-- INPUT FILE END -->

    <!-- DROPDOWN START -->
    <div *ngSwitchCase="'dropdown'">
      <mat-form-field *ngIf="!question.readonly && !question.shouldHide">
        <mat-select
          [disableControl]="!!question.disabled"
          placeholder="{{ question.label | translate }}"
          (selectionChange)="applyEvent('change', $event)"
          (click)="applyEvent('click', $event)"
          [(value)]="question.value"
          [formControlName]="question.key"
        >
          <mat-option *ngIf="question.options.length === 1" [value]=""></mat-option>
          <ng-container *ngFor="let option of question.options">
            <mat-option *ngIf="!option.items" [value]="option.key" [disabled]="option.disabled">
              {{ option.value | translate }}
            </mat-option>
            <mat-optgroup *ngIf="option.items" label="{{ option.group | translate }}">
              <mat-option
                *ngFor="let suboption of option.items"
                [value]="suboption.key"
                [disabled]="suboption.disabled"
              >
                {{ suboption.value | translate }}
              </mat-option>
            </mat-optgroup>
          </ng-container>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="question.readonly">
        <input
          matInput
          [(attr.disabled)]="question.disabled"
          placeholder="{{ question.label | translate }}"
          value="{{ question.value }}"
          [id]="question.key"
          [type]="question.type"
          readonly="true"
        />
        <mat-icon *ngIf="question.icon !== ''" matSuffix>{{ question.icon }}</mat-icon>
      </mat-form-field>
    </div>
    <!-- DROPDOWN END -->

    <!-- DATEPICKER START -->
    <div *ngSwitchCase="'datepicker'" [hidden]="!question.visible">
      <mat-form-field *ngIf="question.edit && !question.shouldHide">
        <input
          *ngIf="question.filter"
          matInput
          [matDatepicker]="picker"
          placeholder="{{ question.label | translate }}"
          [(attr.disabled)]="question.disabled"
          [formControlName]="question.key"
          [matDatepickerFilter]="question.filter"
          [min]="question.min"
          [max]="question.max"
          (dateChange)="applyEvent('change', $event)"
          [required]="question.required"
          [readonly]="question.readonly"
        />
        <input
          *ngIf="!question.filter"
          matInput
          [matDatepicker]="picker"
          placeholder="{{ question.label | translate }}"
          [(attr.disabled)]="question.disabled"
          [formControlName]="question.key"
          [min]="question.min"
          [max]="question.max"
          (dateChange)="applyEvent('change', $event)"
          [required]="question.required"
          [readonly]="question.readonly"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker [disabled]="disabled"></mat-datepicker>
      </mat-form-field>
      <mat-form-field *ngIf="!question.edit">
        <input
          matInput
          [(attr.disabled)]="question.disabled"
          placeholder="{{ question.label | translate }}"
          value="{{ question.value }}"
          [id]="question.key"
          [type]="question.type"
          readonly="true"
        />
        <mat-icon *ngIf="question.icon !== ''" matSuffix>{{ question.icon }}</mat-icon>
      </mat-form-field>
    </div>
    <!-- DATEPICKER END -->

    <!-- SWITCH START -->
    <mat-slide-toggle
      *ngSwitchCase="'switch'"
      [checked]="!!question.value"
      [formControlName]="question.key"
      [labelPosition]="question.labelPosition || 'after'"
    >
      {{ question.label | translate }}
    </mat-slide-toggle>
    <!-- SWITCH END -->

    <!-- TAB START -->
    <div *ngSwitchCase="'tab'">
      <mat-tab-group [selectedIndex]="indexSelectedTab">
        <mat-tab *ngFor="let tab of question.tabs" [disabled]="tab.disabled">
          <ng-template mat-tab-label>
            <mat-icon>{{ tab.icon }}</mat-icon> {{ tab.label | translate }}
          </ng-template>
          <section *ngFor="let item of tab.items">
            <mat-checkbox
              value="{{ item.id }}"
              (change)="manageSelectedItems(tab, item); applyEventTab('click', tab, item, $event)"
              [checked]="item.checked"
              [disabled]="question.readonly"
              >{{ item.value }}</mat-checkbox
            >
          </section>
          <div *ngIf="tab.items.length === 0" class="custom-message">
            <p *ngIf="tab.noItemMessage" class="align-center main-color">{{ tab.noItemMessage | translate }}</p>
            <p *ngIf="!tab.noItemMessage" class="align-center main-color">
              {{ 'CUSTOM_ERROR.NO_ITEM_TAB' | translate }}
            </p>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
    <!-- TAB END -->
  </div>
  <div *ngIf="!isValid() && hasBeenTouched()" class="errorMessage">
    <ng-container *ngIf="getErrorCount() === 1; else multipleErrors">
      {{ getErrorMessage()[0].text | async }}
    </ng-container>
    <ng-template #multipleErrors>
      <ul>
        <li *ngFor="let error of getErrorMessage()">{{ error.text | async }}</li>
      </ul>
    </ng-template>
  </div>
</div>
