export const environment = {
  production: true,
  APP_TITLE: "Vigie Réseau",
  GOOGLE_MAP_API_KEY: "AIzaSyD0EN86qQuYRoBP1S6XmwIXEg0eetz_1RA",
  GIT_COMMIT: "f4f9c237",
  BASE_URL: "https://vigie-reseau-preprod.data-newroad.com",
  PROFILE_API_URL:
    "https://profiles.vigie-reseau-preprod.data-newroad.com",
  SSE_SERVICE_URL: "",
  keycloak: {
    redirectUrl: "https://vigie-reseau-preprod.data-newroad.com",
    postLogoutRedirectUri:
      "https://vigie-reseau-preprod.data-newroad.com",
    stsServer: "https://keycloak.data-newroad.com",
    clientId: "frontend",
    wellknownEndpoint:
      "https://keycloak.data-newroad.com",
  },
};
