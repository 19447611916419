<div>
  <form (ngSubmit)="onSubmit()" [formGroup]="form">
    <div *ngFor="let row of questions" class="form-row">
      <div *ngFor="let question of row" class="question-container-custom question-container-{{ question.size }}">
        <app-question [question]="question" [form]="form"></app-question>
      </div>
    </div>

    <div class="form-row button-container">
      <span class="main-buttons-span">
        <button mat-raised-button class="validation" *ngIf="validateButton" type="submit">
          <i class="material-icons">check</i
          >{{ (submitLabelButton ? submitLabelButton : 'GLOBAL.SUBMIT_BUTTON_LABEL') | translate }}
        </button>
        <span class="cancel-center">
          <button mat-button *ngIf="cancelButton.activated" type="reset" (click)="resetForm()">
            {{ 'GLOBAL.CANCEL' | translate }}
          </button>
        </span>
      </span>
    </div>
  </form>
</div>
