<div>
  <h1 class="mainTitle">{{ 'REPOSITORIES.REPOSITORIES_LIST' | translate }}</h1>
  <app-loading [active]="!loaded" />
  <app-list
    *ngIf="loaded"
    [listParameters]="listParameters"
    [items]="repositoriesList"
    (action)="getEvent($event)"
    [pageSize]="20"
  />
</div>
