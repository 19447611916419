import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DEFAULT_PAGE_SIZE, IGenericListParameters } from 'app/components/generic/list/list.interface';
import { FluflrService } from 'app/services/flu-flr.service';
import { LoadingService } from 'app/services/loading.service';
import { TitleService } from 'app/services/title.service';
import { Observable } from 'rxjs';
import { finalize, first, map, mergeMap, tap } from 'rxjs/operators';
import { FluFilters } from 'src/app/interface/flu-fr.interface';

@Component({
  selector: 'app-flu-flr',
  templateUrl: './flu-flr.list.component.html',
  styleUrls: ['./flu-flr.list.component.scss']
})
export class FluFlrListComponent implements OnInit {
  items: any[] = [];
  total = 0;
  public loaded = false;

  listParameters: IGenericListParameters = {
    paginator: true,
    listHead: [
      {
        key: 'highway_name',
        type: 'text',
        title: 'FLU-FLR.HIGHWAY_NAME',
        apiKey: 'locationStart.highway.name'
      },
      {
        key: 'direction',
        type: 'text',
        title: 'FLU-FLR.DIRECTION',
        options: {
          sortable: false
        }
      },
      {
        key: 'landmark_start',
        type: 'text',
        title: 'FLU-FLR.LANDMARK_START',
        apiKey: 'locationStart.kilometerPoint'
      },
      {
        key: 'landmark_end',
        type: 'text',
        title: 'FLU-FLR.LANDMARK_END',
        apiKey: 'locationEnd.kilometerPoint'
      },
      {
        key: 'lane_position',
        type: 'text',
        title: 'FLU-FLR.LANE_POSITION',
        apiKey: 'lanePosition'
      }
    ]
  };

  viewMode: Observable<'flu' | 'flr'>;

  constructor(
    private fluflrService: FluflrService,
    public loading: LoadingService,
    route: ActivatedRoute,
    title: TitleService
  ) {
    this.viewMode = route.data.pipe(
      map((data) => data.view),
      first()
    );

    this.viewMode.subscribe((view) => title.setTitle(`GLOBAL.BREADCRUMB.${view.toUpperCase()}`));
  }

  async ngOnInit() {
    this.viewMode.subscribe((view) =>
      this.getFluFlrList({ type: view, params: { limit: DEFAULT_PAGE_SIZE, offset: 0 } })
    );
  }

  parseList(items: any[]) {
    return items.map((item) => {
      const direction =
        item.direction === 1
          ? `${item.origin} &rarr; ${item.destination}`
          : `${item.destination} &rarr; ${item.origin}`;

      return {
        direction,
        highway_name: item.highway_name,
        landmark_start: `${item.landmark_start.kilometer_point}+${item.landmark_start.landmark}`,
        landmark_end: `${item.landmark_end.kilometer_point}+${item.landmark_end.landmark}`,
        lane_position: item.lane_name
      };
    });
  }

  getFluFlrList(event?: { type?: string; data?: any; params?: any }) {
    let filters: FluFilters = {};

    if (event && event.data && event.type === 'search') {
      if (event.data.search) {
        filters.q = String(event.data.search);
      } else if (event.data.search === '') {
        delete filters.q;
      }
    }
    if (event && event.params) {
      filters = { ...filters, ...event.params };
    }

    this.viewMode
      .pipe(
        tap(() => {
          this.loaded = false;
          this.loading.on();
        }),
        tap((view) => (filters.type = view)),
        mergeMap(() => this.fluflrService.getAll(filters)),
        tap(({ items, total }) => {
          this.items = filters.type === 'flr' ? this.parseList(items.FLR) : this.parseList(items.FLU);
          this.total = total;
        }),
        finalize(() => {
          this.loading.off();
          this.loaded = true;
        })
      )
      .subscribe();
  }
}
