function getRootCSSVariable(variableName: string) {
  return getComputedStyle(document.documentElement).getPropertyValue(variableName);
}

function setRootCSSVariable(variableName: string, value: string | number | null | undefined) {
  document.documentElement.style.setProperty(variableName, !value ? null : '' + value);
}

function getRootCSSVariableNames() {
  const variableNames = Array.from(document.styleSheets)
    .filter((sheet) => sheet.href === null || sheet.href.startsWith(window.location.origin))
    .reduce<string[]>(
      (acc, sheet) =>
        (acc = [
          ...acc,
          ...Array.from(sheet.cssRules).reduce<string[]>(
            (def, rule) =>
              (def =
                rule instanceof CSSStyleRule && rule.selectorText === ':root'
                  ? [...def, ...Array.from(rule.style).filter((name) => name.startsWith('--'))]
                  : def),
            []
          )
        ]),
      []
    );
  return variableNames;
}

export {
  //
  getRootCSSVariable,
  setRootCSSVariable,
  getRootCSSVariableNames
};
