import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { GenericDialogComponent } from 'app/components/generic/generic-dialog/generic-dialog.component';
import { Loader, LoadingService } from 'app/services/loading.service';
import { deburr } from 'lodash';
import { Subject, takeUntil } from 'rxjs';
import { RequestFilters } from 'src/app/interface/utils.interfaces';

import { DEFAULT_PAGE_SIZE, IGenericListParameters } from './list.interface';

export interface IListItem {
  _id?: string;
  selected: boolean;
  [key: string]: any;
}

export interface ListActionEvent {
  type?: string;
  data?: any;
}

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListComponent<U extends any> implements AfterContentInit, OnDestroy {
  dialogRef: MatDialogRef<GenericDialogComponent>;
  destroyed$ = new Subject();

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatTable, { static: true }) table: MatTable<IListItem>;
  @ViewChild('paginator') paginatorRef: MatPaginator;

  @Output() refresh: EventEmitter<any> = new EventEmitter<any>();
  @Output() action: EventEmitter<any> = new EventEmitter<any>();
  @Input() listParameters: IGenericListParameters;
  @Input() total: number;
  @Input() searchType: string;
  @Input() addButton: string;
  @Input() addButtonLabel: string;
  @Input() selectedRow: IListItem[] = [];
  @Input() pagination: 'always' | 'never' | 'auto' = 'auto';
  @Input() pageSize = DEFAULT_PAGE_SIZE;
  @Input() loaded = true;
  @Input() set items(newItems) {
    this._rawItems = newItems;

    this.loader.on();
    this.updateColumns();
    this.updateData(newItems);
    this.loader.off();
  }

  get items() {
    return this._rawItems;
  }

  get showPaginator() {
    return (
      this.pagination === 'always' || (this.pagination === 'auto' && (this.total > this.pageSize || this.pageIndex > 0))
    );
  }

  private _rawItems: U[] = [];

  loader: Loader;
  searchValue = '';
  length = 0;

  sort_direction?: 'asc' | 'desc';
  sort_field?: string;

  /**
   * @suggestion Save list options in local storage
   */
  pageSizeOptions = [10, 20, 50];
  pageIndex = 0;

  displayedColumns: string[] = [];

  dataSource: MatTableDataSource<U>;

  public collator: Intl.Collator;

  ngAfterContentInit() {
    /*
     * Set paginator options
     * Doing so after the content is loaded allows to properly load the list
     * when used in dynamically generated views (e.g. in a modal).
     */
    if (this.paginatorRef) {
      this.paginatorRef.pageSizeOptions = this.pageSizeOptions;
      this.paginatorRef._changePageSize(this.pageSize);
    }

    // if the user change the sort, trigger regresh event
    this.sort.sortChange.pipe(takeUntil(this.destroyed$)).subscribe((sort) => {
      this.pageIndex = 0;
      this.sort_direction = sort.direction || undefined;
      // This is because the sort.active is the key of the column that is possibly different
      // from the attribute we want to sort on in the backend.
      const activeSortCol = this.listParameters.listHead.find((col) => col.key === sort.active);
      const apiSortKey = activeSortCol?.apiKey || sort.active;
      this.sort_field = this.sort_direction ? apiSortKey : undefined;
      this.fetchPage();
    });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  handlePageEvent(e: PageEvent) {
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.fetchPage();
  }

  constructor(
    private translate: TranslateService,
    loading: LoadingService,
    public dialog: MatDialog
  ) {
    this.loader = loading.get('generic-list');
    this.collator = new Intl.Collator(this.translate.currentLang, { numeric: true, sensitivity: 'base' });
  }

  headerKeyTracker(row: any) {
    return row.key;
  }

  propagate<T>(event: T): void {
    this.action.emit(event);
  }

  applySearch() {
    // Force the display to reset to first page
    this.pageIndex = 0;
    const filterValue: string = deburr(this.searchValue).trim().toLowerCase();
    if (this.searchType === 'static') {
      this.dataSource.filter = encodeURIComponent(filterValue);
    } else {
      this.fetchPage();
    }
  }

  fetchPage() {
    const filterValue: string = deburr(this.searchValue).trim().toLowerCase();
    const params = this.getPaginationAndSortParams();
    this.refresh.emit({
      type: 'search',
      data: { search: encodeURIComponent(filterValue) },
      params: params
    });
  }

  private getPaginationAndSortParams() {
    if (this.pagination === 'never') return {};

    const params: RequestFilters = {
      limit: this.pageSize,
      offset: this.pageIndex * this.pageSize,
      sort: this.sort_field,
      order: this.sort_direction
    };

    //remove undefined values
    Object.keys(params).forEach(
      (key) => params[key as keyof RequestFilters] === undefined && delete params[key as keyof RequestFilters]
    );
    return params;
  }

  onRowClick(row: any): void {
    this.action.emit({ type: 'rowClick', data: row });
  }

  private updateColumns() {
    this.displayedColumns = this.listParameters.listHead
      .filter((col) => col.visible !== false)
      .map((col: { key: string }) => col.key);
  }

  private updateData(items: any[] = []) {
    // Add selected-row
    if (this.selectedRow && this.selectedRow.length > 0) {
      for (const item of items) {
        item.selected = this.selectedRow.some((row) => row._id === item._id);
      }
    }

    // Register provided array as a data source and override the sorting method
    this.length = this.total || items.length;
    this.dataSource = new MatTableDataSource<U>(items);
    this.dataSource.sort = this.sort;
  }

  handleAddClick() {
    if (this.addButton !== 'dynamic') {
      return;
    }
    const event = { type: 'addButtonClick' };
    this.action.emit(event);
  }
}

export const generateEditButton = {
  type: 'button',
  events: { click: 'edit' },
  style: { color: 'warn' },
  options: { icon: 'edit' },
  tooltip: 'GLOBAL.EDIT'
};

export const generateRemoveButton = {
  type: 'button',
  events: {
    click: 'delete'
  },
  style: { color: 'warn' },
  options: { icon: 'delete' },
  tooltip: 'GLOBAL.DELETE'
};
