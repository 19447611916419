import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import {
  SelectComponent,
  SelectOptionOrGroup,
  SelectOptionValue
} from 'src/app/components/generic/form/select/select.component';
import { LoadingComponent } from 'src/app/components/generic/loading/loading.component';
import { ReportingSubtype } from 'src/app/interface/report.interface';
import { ReportsService } from 'src/app/services/reports.service';

@Component({
  standalone: true,
  selector: 'app-reporting-subtype-select',
  imports: [CommonModule, LoadingComponent, TranslateModule, ReactiveFormsModule, SelectComponent],
  templateUrl: './reporting-subtype-select.field.component.html'
})
export class ReportingSubtypeSelectComponent implements OnInit {
  public isLoaded: boolean = false;
  public reportingSubypeOptions: SelectOptionOrGroup<string, ReportingSubtype>[];

  @Input() public pformGroup: FormGroup<{
    reportingType: FormControl<string | SelectOptionValue>;
    reportingSubtype: FormControl<string | SelectOptionValue>;
  }>;

  constructor(private reportsService: ReportsService) {
    this.reportsService.getReportingTypes().then(() => {
      this.isLoaded = true;
    });
  }

  ngOnInit(): void {
    this.pformGroup.get('reportingSubtype')?.disable();
    this.pformGroup.get('reportingType')?.valueChanges.subscribe((value) => {
      this.pformGroup.get('reportingSubtype')?.disable();
      this.pformGroup.get('reportingSubtype')?.setValue('');
      const reportingTypeId = (value as SelectOptionValue)?.key;
      if (!reportingTypeId) {
        return;
      }
      const reportingType = this.reportsService.getReportingTypeByIdSync(reportingTypeId as string);
      if (reportingType?.subtypes.length) {
        this.reportingSubypeOptions = reportingType.subtypes.map((e) => {
          return { value: { key: e.id, label: e.label || '' }, original: e };
        });
        this.pformGroup.get('reportingSubtype')?.enable();
      }
    });
  }
}
