<div class="edit-instruction-block" *ngIf="loaded">
  <h1 class="title-form">
    {{ titleForm | translate }}
    <span
      *ngIf="!edit"
      class="editButton"
      (click)="goToEdit()"
      matTooltip="{{ 'GLOBAL.EDIT' | translate }}"
      matTooltipPosition="above"
    >
      <i class="material-icons">mode_edit</i>
    </span>
  </h1>
  <app-dynamic-form
    [(questions)]="questions"
    [(exportFromParents)]="exportsChild"
    [cancelButton]="cancelButton"
    [validateButton]="displayValidateButton"
    [disabledSubmit]="disabledSubmit"
  >
  </app-dynamic-form>
</div>
