<h1 mat-dialog-title>{{ 'DRIVING_LOGS.EXPIRED_LIST.TITLE' | translate }}</h1>

<mat-dialog-content class="dialog-padding">
  <ng-container *ngIf="maxAgeDays; else noMaxAgeFound">
    <p>{{ 'DRIVING_LOGS.EXPIRED_LIST.INFORMATION' | translate: { since: maxAgeDays } }}</p>
  </ng-container>
  <ng-template #noMaxAgeFound>
    <p>{{ 'DRIVING_LOGS.EXPIRED_LIST.INFORMATION_NO_MAX_AGE' | translate }}</p>
  </ng-template>

  <app-list
    [listParameters]="listParameters"
    [items]="drivingLogsDisplay"
    (action)="captureEvent($event)"
    searchType="none"
  >
  </app-list>

  <p class="align-center">
    <strong>{{ 'GLOBAL.WARNING_COLON' | translate | uppercase }}</strong>
    {{ 'DRIVING_LOGS.EXPIRED_LIST.IRREVERSIBLE_ACTION_DISCLAIMER' | translate }}
  </p>
</mat-dialog-content>

<!-- Button -->
<mat-dialog-actions>
  <span class="margin-auto">
    <button (click)="closeDialog()" mat-button>
      <span>{{ 'GLOBAL.CLOSE_LABEL' | translate }}</span>
    </button>
  </span>
</mat-dialog-actions>
