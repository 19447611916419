import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class TitleService {
  constructor(
    private title: Title,
    private translate: TranslateService
  ) {}

  setTitle(newTitle: string, interpolateParams?: { [key: string]: string }) {
    this.translate.get(newTitle, interpolateParams).subscribe((el: string) => this.title.setTitle(el));
  }

  getTitle(): string {
    return this.title.getTitle();
  }
}
