/**
 * Available permissions for edition and/or verification.
 *
 * Note: the order in which the following keys are defined will affect the order
 * in which it will be displayed when viewing/editing roles.
 *
 * @see fr.json for translations (under PERMISSIONS.{KEY_NAME})
 */
export enum Permissions {
  // Backoffice : access
  BACK_OFFICE_CONNECTION = 'BACK_OFFICE_CONNECTION',

  // Backoffice : operator module activation
  BACK_OFFICE_OPERATOR = 'BACK_OFFICE_OPERATOR',

  // Permissions : visualisation & modification
  BACK_OFFICE_PERMISSIONS = 'BACK_OFFICE_PERMISSIONS',

  // Referentiel : importation
  BACK_OFFICE_IMPORTS = 'BACK_OFFICE_IMPORTS',

  // Teams driving logs : download and close
  BACK_OFFICE_TEAMS_DRIVING_LOGS = 'BACK_OFFICE_TEAMS_DRIVING_LOGS',

  // Addition, edition and deletion of users
  BACK_OFFICE_USERS = 'BACK_OFFICE_USERS',

  // App mobile : access
  APP_MOBILE_CONNECTION = 'APP_MOBILE_CONNECTION',

  // Driving-logs : visualisation & modification
  APP_MOBILE_DRIVING_LOGS = 'APP_MOBILE_DRIVING_LOGS',

  // Back-office : super admin features such as types creation, (replaces legacy test : service_number==='admin')
  BACK_OFFICE_SUPER_ADMIN = 'BACK_OFFICE_SUPER_ADMIN',

  // Back-office : create another super admin user, potentially in a different schema
  BACK_OFFICE_CREATE_SUPER_ADMIN = 'BACK_OFFICE_CREATE_SUPER_ADMIN'
}
