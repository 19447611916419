import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GenericDialogComponent } from 'app/components/generic/generic-dialog/generic-dialog.component';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent implements OnInit {
  @Input() sections: any[];
  @Input() multi = false;
  dialogRef: MatDialogRef<GenericDialogComponent>;

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {
    this.multi = !!this.multi;
  }

  changeExpandPanel(section: { step: string; expand: boolean }, value: boolean) {
    section.expand = value;
  }

  zoomIn(urlPicture: string) {
    const struct = {
      title: '',
      text: '',
      buttons: [
        {
          text: 'GLOBAL.OK',
          class: 'validation',
          action: {
            target: 'generic',
            params: { id: 'close' }
          }
        }
      ]
    };
    this.dialogRef = this.dialog.open(GenericDialogComponent, {
      width: 'auto',
      height: 'auto',
      maxWidth: '90%',
      maxHeight: 'calc(100vh - 100px)',
      data: {
        structure: struct,
        content: {
          type: 'img',
          src: urlPicture
        }
      },
      disableClose: false
    });
  }
}
