import { CommonModule, DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { ReportingMarker } from '../map.component';

@Component({
  standalone: true,
  selector: 'app-reporting-info-window',
  imports: [CommonModule, DatePipe, TranslateModule],
  templateUrl: 'reporting-info-window.component.html',
  styleUrls: ['./info-window.scss']
})
export class ReportingInfoWindowComponent {
  @Input() marker: ReportingMarker;

  public reportStatus: Record<string, string> = {
    NEW: 'GLOBAL.STATES.NEW',
    IN_PROGRESS: 'GLOBAL.STATES.PROGRESS',
    AGENT_ON_SITE: 'GLOBAL.STATES.ONSITE',
    DONE: 'GLOBAL.STATES.DONE',
    DONE_UNFINALIZED: 'GLOBAL.STATES.DONE_UNFINALIZED',
    RAS: 'GLOBAL.STATES.RAS'
  };

  public getTrigram(firstName: string, lastName: string) {
    return `${firstName[0]}${lastName.slice(0, 2)}`;
  }
}
