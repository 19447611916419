import { Coordinates, ILocationComponent } from './location.interface';
import { IMedias } from './media.interface';
import { ITeam } from './team.interface';
import { IUser } from './user.interface';

export enum ReportingState {
  NEW = 'NEW',
  IN_PROGRESS = 'IN_PROGRESS',
  AGENT_ON_SITE = 'AGENT_ON_SITE',
  RAS = 'RAS',
  DONE_UNFINALIZED = 'DONE_UNFINALIZED',
  DONE = 'DONE',
  CLOSED = 'CLOSED'
}

export interface IReporting<T = any> {
  _id?: string;
  type: ReportingTypeWithoutSubtypes;
  subtype: ReportingSubtype;
  location: ILocationComponent;
  description: string;
  coordinates: Coordinates;
  source: string;
  source_id: string;
  made_date?: Date;
  due_date: Date | null;
  creation_date: Date;
  medias: IMedias[];
  created_by: IUser;
  state: ReportingState;
  details: T;
  teams?: ITeam[];
  affected_to?: ReportingUser;
  on_call_user?: ReportingUser;
  created_at?: Date;
  updated_at?: Date;
  autodone?: boolean;
  unknownAccident?: {
    user: string;
    date: Date;
    synced?: boolean;
  };
  linked: Array<{
    id: string;
    type: 'PARENT' | 'CHILD';
  }>;
  events: IReportingEvent[];
}

export interface IReportingEvent {
  id: string;
  comment?: string;
  payload: any;
  date: Date;
  deletedAt?: Date | null;
  createdAt: Date;
  createdById?: string;
  updatedAt?: Date;
  type: ReportingEventType;
}

// For now, duplicated enum from backend
// TODO: Set a GET ALL route that returns all categories available
export enum CategoryEnum {
  CALL = 'CALL',
  DEPARTURE = 'DEPARTURE',
  ONCOMING = 'ONCOMING',
  OTHER = 'OTHER'
}

export interface FeatureFlags {
  askIntervenor?: boolean;
  askInterlocutor?: boolean;
  askCallType?: boolean;
  isRepairer?: boolean;
}

export interface ReportingEventType {
  id: string;
  name: string;
  picto?: string;
  category: CategoryEnum;
  featureFlags: FeatureFlags;
}

export type IReportingResponse<T = any> = IReporting<T>;

export interface ReportingUser {
  _id: string;
  service_number: string;
  first_name: string;
  last_name: string;
  role: string;
}

export enum ReportingTypeName {
  ACCIDENT = '11',
  PEDESTRIAN = '12',
  STOPPED_VEHICLE = '14',
  VEHICLE_ON_FIRE = '15',
  WEATHER = '21',
  BREAKDOWN = '22',
  TRAFFIC_JAM = '23',
  OBJECT = '24',
  ANIMAL = '25',
  GUARDRAIL = '31',
  ENVIRONMENT = '32',
  MARKINGS = '33',
  ROADWAY = '34',
  MISCELLANEOUS = '35'
}

export enum ReportingSubtypeName {
  CONCRETE = '311',
  IRON = '312',
  GUARDRAIL_MISCELLANEOUS = '313',
  TREE = '321',
  FENCE = '322',
  SANITATION = '323',
  ENVIRONMENT_MISCELLANEOUS = '324',
  POLICE = '331',
  TEMPORARY = '332',
  PLASTIC = '333',
  MARKINGS_MISCELLANEOUS = '334',
  DEAD_ANIMAL = '351',
  MISCELLANEOUS_DAMAGE = '352',
  MISSED_TOY = '353',
  PROTEST_EVENT = '354',
  TAGS = '355',
  ABANDONED_CAR = '356',
  MISCELLANEOUS = '357'
}

export const RenewableReportingTypeNames = [ReportingTypeName.ACCIDENT, ReportingTypeName.PEDESTRIAN];

export enum ReportingTypePriority {
  RED = 'RED',
  ORANGE = 'ORANGE',
  BLUE = 'BLUE'
}

export interface ReportingSubtype {
  id: string;
  name: ReportingSubtypeName;
  label?: string;
  picto?: string;
  typeId: string;
}

export interface ReportingTypeWithoutSubtypes {
  id: string;
  name: ReportingTypeName;
  label?: string;
  priority: ReportingTypePriority;
  picto?: string;
  pin?: string;
  critical: boolean;
  // Front only:
  disabled?: boolean;
  editable?: boolean;
}

export interface ReportingType extends ReportingTypeWithoutSubtypes {
  subtypes: ReportingSubtype[];
}

export interface IReportingStateInformations {
  id: ReportingState;
  label: string;
  default: boolean;
}

export const reportStates: IReportingStateInformations[] = [
  {
    id: ReportingState.NEW,
    label: 'REPORTING.STATES.NEW',
    default: true
  },
  {
    id: ReportingState.IN_PROGRESS,
    label: 'REPORTING.STATES.IN_PROGRESS',
    default: true
  },
  {
    id: ReportingState.AGENT_ON_SITE,
    label: 'REPORTING.STATES.AGENT_ON_SITE',
    default: true
  },
  {
    id: ReportingState.RAS,
    label: 'REPORTING.STATES.NOTHING_TO_REPORT',
    default: false
  },
  {
    id: ReportingState.DONE_UNFINALIZED,
    label: 'REPORTING.STATES.DONE_UNFINALIZED',
    default: true
  },
  {
    id: ReportingState.DONE,
    label: 'REPORTING.STATES.DONE',
    default: false
  },
  {
    id: ReportingState.CLOSED,
    label: 'REPORTING.STATES.CLOSED',
    default: false
  }
];

export interface ReportingFilters {
  q?: string;
  type?: string[]; // reportingTypeId uuid
  sub_type?: string[]; // reportingTypeId uuid
  state?: string[];
  sector_id?: string[];
  infrastructure?: string[]; // infrastructureTypeId uuid
  sort?: string;
  unacc?: string | null;
  knacc?: string | null;
  noacc?: string | null;
  start?: string | null;
  end?: string | null;
  highway_name?: string | Array<string>;
  direction?: string | Array<string>;
  start_pr?: string;
  end_pr?: string;
}
export interface ReportingWeatherConditionReturn {
  id: string;
  label: string;
}

export enum AffectedLaneCustomLabel {
  vd = 'vd',
  vg = 'vg',
  bau = 'bau',
  bretelle = 'bretelle',
  parking = 'parking',
  voirie = 'voirie',
  voie_de_peage = 'voie_de_peage',
  accotement = 'accotement'
}
