import { QuestionBase } from './question-base';

export class DatepickerQuestion extends QuestionBase<string> {
  controlType = 'datepicker';
  options?: any;
  min?: any;
  max?: any;

  constructor(options: { options?: any[]; [key: string]: any } = {}) {
    super(options);
    this.options = options.options || [];
    this.min = options.min ? options.min : null;
    this.max = options.max ? options.max : null;
    this.readonly = options.readonly || false;
    this.filter = options.filter || undefined;
  }
}
