import { Component, Inject } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import { CategoryEnum, IReportingEvent, ReportingEventType } from 'src/app/interface/report.interface';
import { ReportsService } from 'src/app/services/reports.service';

import { getTranslationWithFallback } from '../../../tools/translate';

interface NewEventDialogData {
  reportingId: string;
  reportingEventToEdit?: IReportingEvent;
}

interface ReportingEventFormValues {
  typeId?: string;
  date?: Date;
  time?: string;
  comment?: string;
  categoryId?: string;
  intervenor?: string;
  interlocutor?: string;
  intervenorName?: string;
  interlocutorName?: string;
  callType?: string;
  evacuationType?: string;
}

interface CategoryOption {
  value: CategoryEnum;
  label: string;
}

export type NewEventDialogResult = 'cancelled' | IReportingEvent;

@Component({
  selector: 'app-new-event-dialog',
  templateUrl: './new-event-dialog.component.html'
})
export class NewEventDialogComponent {
  public reportingEventOptions: ReportingEventType[];
  public reportingEventTypesFilteredOptions: ReportingEventType[];
  public reportingEventCategoriesOptions: CategoryOption[];
  public selectedReportingEventType?: ReportingEventType;

  public intervenors: string[] = [];

  private repairersList: string[] = ['Dépanneur 1', 'Dépanneur 2'];

  private intervenorsList: string[] = [
    'Corg',
    'DIR/CIGT',
    'Patrouilleur 1',
    'Patrouilleur 2',
    'Technicien',
    'Astreinte patrouilleur',
    'Astreinte technicien',
    'Astreinte exploitation',
    'Astreinte concession'
  ];

  public interlocutors: string[] = [
    'Pompiers',
    'SAMU',
    'Corg',
    'DIR/CIGT',
    'Dépanneur 1',
    'Dépanneur 2',
    'Patrouilleur 1',
    'Patrouilleur 2',
    'Technicien',
    'Astreinte patrouilleur',
    'Astreinte technicien',
    'Astreinte exploitation',
    'Astreinte concession'
  ];

  public evacuationTypes: string[] = ['Sur place', 'À évacuer'];

  public callTypes: string[] = ['Entrant', 'Sortant'];

  public form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<NewEventDialogComponent, NewEventDialogResult>,
    @Inject(MAT_DIALOG_DATA) public data: NewEventDialogData,
    private reportsService: ReportsService,
    private translateService: TranslateService
  ) {
    this.form = new FormGroup({
      typeId: new FormControl<string | null>(null, [Validators.required]),
      date: new FormControl<Date | null>(null, [Validators.required]),
      time: new FormControl<string | null>(null, [Validators.required]),
      comment: new FormControl<string>(''),
      categoryId: new FormControl<string | null>(null),
      intervenor: new FormControl<string | null>(null, [
        this.needsValueIfAsked(() => !!this.selectedReportingEventType?.featureFlags.askIntervenor)
      ]),
      interlocutor: new FormControl<string | null>(null, [
        this.needsValueIfAsked(() => !!this.selectedReportingEventType?.featureFlags.askInterlocutor)
      ]),
      intervenorName: new FormControl<string | null>(null),
      interlocutorName: new FormControl<string | null>(null),
      callType: new FormControl<string | null>(null, [this.needsCallType()]),
      evacuationType: new FormControl<string | null>(null, [
        this.needsValueIfAsked(() => !!this.selectedReportingEventType?.featureFlags.isRepairer)
      ])
    });

    this.reportsService.getReportingEventTypes().then((reportingEventTypes) => {
      this.reportingEventOptions = reportingEventTypes.filter(
        (e) => !['agent_oncoming', 'agent_departure', 'nothing_to_report', 'external'].includes(e.name)
      );

      this.setupCategories();
      this.handleSelectedReportingEventType();

      let initialValues: ReportingEventFormValues = {};
      let initialDate: Date;
      if (data.reportingEventToEdit) {
        initialValues = {
          categoryId: data.reportingEventToEdit.type.category,
          typeId: data.reportingEventToEdit.type.id,
          comment: data.reportingEventToEdit.comment || '',
          evacuationType: data.reportingEventToEdit.payload.evacuationType || '',
          callType: data.reportingEventToEdit.payload.callType || '',
          interlocutor: data.reportingEventToEdit.payload.interlocutor || '',
          intervenor: data.reportingEventToEdit.payload.intervenor || '',
          intervenorName: data.reportingEventToEdit.payload.intervenorName || '',
          interlocutorName: data.reportingEventToEdit.payload.interlocutorName || ''
        };
        initialDate = data.reportingEventToEdit.date;
      } else {
        initialDate = new Date();
      }
      initialDate = dayjs(initialDate).set('second', 0).toDate();
      initialValues.time = dayjs(initialDate).format('HH:mm');
      initialValues.date = initialDate;
      this.form.patchValue(initialValues);
    });
  }

  private setupCategories(): void {
    this.setupCategoriesOptions();

    this.form.get('categoryId')?.valueChanges.subscribe((value) => {
      this.resetSelectedType();
      this.reportingEventTypesFilteredOptions = this.reportingEventOptions.filter(
        (option: ReportingEventType) => option.category == value
      );
      if (this.reportingEventTypesFilteredOptions.length === 1) {
        this.form.get('typeId')?.setValue(this.reportingEventTypesFilteredOptions[0].id);
      }
    });
  }

  private setupCategoriesOptions(): void {
    const values = Object.keys(CategoryEnum);
    this.reportingEventCategoriesOptions = values.map((value: string) => {
      return {
        value: CategoryEnum[value as keyof typeof CategoryEnum],
        label: getTranslationWithFallback(`REPORTING.REPORTING_EVENT.CATEGORIES.${value}`, value, this.translateService)
      };
    });
    // We only want categories that include type options
    this.reportingEventCategoriesOptions = this.reportingEventCategoriesOptions.filter(
      (categoryOption: CategoryOption) =>
        this.reportingEventOptions.find((type) => type.category == categoryOption.value)
    );
  }

  private resetSelectedType(): void {
    this.form.get('typeId')?.setValue(null);
    this.resetOptionnalFields();
  }

  private handleSelectedReportingEventType(): void {
    this.form.get('typeId')?.valueChanges.subscribe((value) => {
      this.selectedReportingEventType = this.reportingEventTypesFilteredOptions.find((type) => type.id === value)!;
      this.resetOptionnalFields();
      if (this.selectedReportingEventType.featureFlags.isRepairer) {
        this.intervenors = this.repairersList;
      } else {
        this.intervenors = this.intervenorsList;
      }
    });
  }

  private resetOptionnalFields(): void {
    this.form.get('interlocutor')?.setValue(null);
    this.form.get('intervenor')?.setValue(null);
    this.form.get('evacuationType')?.setValue(null);
    this.form.get('interlocutorName')?.setValue(null);
    this.form.get('intervenorName')?.setValue(null);
    this.form.get('callType')?.setValue(null);
  }

  private needsValueIfAsked(isAsked: () => boolean): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const needsIntervenor = !!isAsked();
      const notValid = needsIntervenor && !control.value;
      return notValid ? { required: { value: control.value } } : null;
    };
  }

  private needsCallType(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const needsIntelocutor = this.selectedReportingEventType
        ? this.selectedReportingEventType.category === CategoryEnum.CALL
        : false;
      const notValid = needsIntelocutor && !control.value;
      return notValid ? { required: { value: control.value } } : null;
    };
  }

  private parseTimeInputValue(value: string): [number, number] {
    const [hourStr, minStr] = value.split(':');
    if (!hourStr || !minStr) {
      throw Error('parseTimeInputValue: Incorrect time value');
    }
    const hour = Number(hourStr);
    const min = Number(minStr);
    return [hour, min];
  }

  public async validateDialog() {
    const values = this.form.value;
    const [hour, min] = this.parseTimeInputValue(values.time!);
    const date = dayjs(values.date).set('hour', hour).set('minute', min).toDate();
    let newEvent;
    if (this.data.reportingEventToEdit) {
      newEvent = await this.reportsService.patchReportingEvent(
        this.data.reportingId,
        this.data.reportingEventToEdit.id,
        {
          typeId: values.typeId!,
          date: date || undefined,
          comment: values.comment || undefined,
          interlocutor: values.interlocutor || undefined,
          intervenor: values.intervenor || undefined,
          interlocutorName: values.interlocutorName || undefined,
          intervenorName: values.intervenorName || undefined,
          callType: values.callType || undefined,
          evacuationType: values.evacuationType || undefined
        }
      );
    } else {
      newEvent = await this.reportsService.postReportingEvent(this.data.reportingId, {
        typeId: values.typeId!,
        date: date || undefined,
        comment: values.comment || undefined,
        interlocutor: values.interlocutor || undefined,
        intervenor: values.intervenor || undefined,
        interlocutorName: values.interlocutorName || undefined,
        intervenorName: values.intervenorName || undefined,
        callType: values.callType || undefined,
        evacuationType: values.evacuationType || undefined
      });
    }
    this.dialogRef.close(newEvent);
  }

  public closeDialog(): void {
    this.dialogRef.close('cancelled');
  }
}
