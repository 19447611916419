<div class="carousel-view">
  <ng-container *ngIf="loadingInstance.disabled === true">
    <div class="carousel-empty" *ngIf="galleryView.length === 0"></div>
    <div class="carousel-slider" *ngIf="galleryView.length > 0 && mode === 'carousel'">
      <figure class="carousel-slider-item">
        <img
          class="carousel-slider-item-picture"
          (click)="zoomIn(selectedPicture)"
          [attr.src]="selectedPicture.url | secure | async"
          [alt]="selectedPicture.alt"
        />
      </figure>
    </div>
    <div *ngIf="galleryView.length > 0 && mode === 'gallery'" class="imagesCount">
      {{ galleryView.length }}
      {{ 'GLOBAL.IMAGE' | translate | lowercase }}<span *ngIf="galleryView.length > 1">s</span>
    </div>
    <div
      class="carousel-gallery"
      *ngIf="(galleryView.length > 1 && mode === 'carousel') || (galleryView.length > 0 && mode === 'gallery')"
      [ngClass]="{ 'shadow-border': mode === 'gallery' }"
    >
      <figure *ngFor="let picture of galleryView" class="carousel-gallery-item">
        <img
          [attr.src]="picture.local ? picture.url : (picture.url | secure | async)"
          class="carousel-gallery-item-picture"
          [ngClass]="{
            'is-selected': picture === selectedPicture,
            gallery: mode === 'gallery',
            cursor: picture.type === 'image'
          }"
          [id]="picture.id"
          [alt]="picture.alt"
          (click)="actionPicture(picture)"
          matTooltip="{{ picture.original_object ? picture.original_object.name : '' }}"
          matTooltipPosition="above"
        />
        <span
          class="delete-action"
          *ngIf="actions && actions.indexOf('delete') !== -1"
          (click)="openDeleteConfirmation(picture)"
          matTooltip="{{ 'GLOBAL.DELETE' | translate }}"
          matTooltipPosition="above"
        >
          <i class="material-icons">close</i>
        </span>
        <span
          class="info-picture"
          *ngIf="picture.local"
          matTooltip="{{ 'GLOBAL.VALIDATE_TO_UPLOAD' | translate }}"
          matTooltipPosition="below"
        >
          <i class="material-icons">file_upload</i>
        </span>
      </figure>
    </div>
  </ng-container>
</div>
