<div class="info-window infrastructure-info-window">
  <div class="info-window--main">
    <div class="info-window--icon">
      <img src="{{ marker.data.svgIcon }}" class="over-icon" />
    </div>
    <div class="info-window--detail">
      <p>
        {{ marker.reporting.location.highway_name }} - {{ marker.reporting.location.display }} ({{
          'HIGHWAYS.DIRECTION_LABEL' | translate
        }}
        {{ marker.reporting.location.direction }})
      </p>
      <p>
        <span>{{ marker.reporting.type.label }}</span>
        <span *ngIf="marker.reporting.subtype">
          <br />
          <span>{{ marker.reporting.subtype.label }}</span>
        </span>
      </p>
      <ng-container *ngIf="marker.reporting.created_by && !marker.data.isRegaCesar; else legacySources">
        <p>
          {{ getTrigram(marker.reporting.created_by.first_name, marker.reporting.created_by.last_name) | uppercase }}
        </p>
      </ng-container>
      <ng-template #legacySources>
        <p>
          {{ marker.reporting.source | uppercase }}
        </p>
      </ng-template>

      <p>{{ marker.data.date | date: 'short' }}</p>
      <p>{{ reportStatus[marker.reporting.state] | translate }}</p>
    </div>
  </div>
</div>
