import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';

import { IReportingEvent } from '../../../../interface/report.interface';
import { ReportsService } from '../../../../services/reports.service';
import { GenericDialogComponent } from '../../../generic/generic-dialog/generic-dialog.component';

export interface ReportingExternalEvents {
  id: string;
  date: Date;
  comment?: string;
}

interface FilterValues {
  comment: string | null;
}

@Component({
  selector: 'app-external-events',
  templateUrl: './external-events.component.html',
  styleUrls: ['./external-events.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    TranslateModule,
    MatDialogModule,
    CommonModule,
    MatInputModule
  ]
})
export class ExternalEventsComponent implements OnInit {
  form: FormGroup = new FormGroup({
    commentFilter: new FormControl('')
  });
  filterValues: FilterValues = {
    comment: ''
  };

  dialogRef?: MatDialogRef<GenericDialogComponent>;
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = ['date', 'comment', 'actions'];

  constructor(
    private reportsService: ReportsService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: { reportingId: string; onAdd: (reportingEvent: IReportingEvent) => void }
  ) {
    this.dataSource.filterPredicate = this.createFilter();
  }
  ngOnInit(): void {
    this.reportsService.getReportingExternalEventsObservable().subscribe((reportingExternalEvents) => {
      this.dataSource.data = reportingExternalEvents;
    });
    this.form.get('commentFilter')!.valueChanges.subscribe((comment) => {
      this.filterValues.comment = comment;
      this.dataSource.filter = JSON.stringify(this.filterValues);
    });
  }

  createFilter(): (data: any, filter: string) => boolean {
    let filterFunction = (data: any, filter: any): boolean => {
      let searchTerms = JSON.parse(filter);
      return data.comment.toLowerCase().indexOf(searchTerms.comment.toLowerCase()) !== -1;
    };
    return filterFunction;
  }

  public handleIgnore(reportingExternalId: string) {
    this.dialogRef = this.dialog.open(GenericDialogComponent, {
      width: '50%',
      data: { structure: this.ignoreStructure(reportingExternalId) },
      disableClose: true
    });
  }

  public ignoreStructure(reportingExternalId: string) {
    return {
      title: 'GLOBAL.CONFIRMATION',
      text: 'CONFIRM.IGNORE_EXTERNAL_EVENT',
      buttons: [
        {
          text: 'GLOBAL.IGNORE',
          class: 'validation',
          action: {
            target: 'custom',
            params: {
              function: async () => {
                await this.reportsService.deleteReportingExternalEvents(reportingExternalId);
                this.reportsService.getReportingExternalEventsObservable().subscribe((reportingExternalEvents) => {
                  this.dataSource.data = reportingExternalEvents;
                });
                this.dialog.closeAll();
              }
            }
          }
        },
        {
          text: 'GLOBAL.CANCEL',
          class: 'cancel',
          action: {
            target: 'generic',
            params: {
              id: 'close',
              function: undefined
            }
          }
        }
      ]
    };
  }
  public handleAdd(reportingExternalId: string) {
    this.dialogRef = this.dialog.open(GenericDialogComponent, {
      width: '50%',
      data: { structure: this.addStructure(reportingExternalId) },
      disableClose: true
    });
  }
  public addStructure(reportingExternalId: string) {
    return {
      title: 'GLOBAL.CONFIRMATION',
      text: 'CONFIRM.ADD_EXTERNAL_EVENT',
      buttons: [
        {
          text: 'GLOBAL.ADD',
          class: 'validation',
          action: {
            target: 'custom',
            params: {
              id: 'deletePictureButtonId',
              function: async () => {
                const reportingEvent: IReportingEvent = await this.reportsService.addReportingExternalEvents(
                  reportingExternalId,
                  this.data.reportingId
                );
                this.data.onAdd(reportingEvent);
                this.dialog.closeAll();
              }
            }
          }
        },
        {
          text: 'GLOBAL.CANCEL',
          class: 'cancel',
          action: {
            target: 'generic',
            params: {
              id: 'close',
              function: undefined
            }
          }
        }
      ]
    };
  }
}
