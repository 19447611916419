import { ICreatedBy } from './user.interface';

export enum AssignedTypeEnum {
  USERS = 'users',
  TEAMS = 'teams'
}

export enum ReadEnum {
  all = 'all',
  some = 'some',
  nobody = 'nobody'
}

export const statusFilter = [
  { id: 'all', name: 'INSTRUCTIONS.STATUS_LABEL.ALL' },
  { id: 'some', name: 'INSTRUCTIONS.STATUS_LABEL.SOME' },
  { id: 'nobody', name: 'INSTRUCTIONS.STATUS_LABEL.NOBODY' }
];

export interface IFiltersGetInstructions {
  teams?: string[];
  limit?: number;
  offset?: number;
  sort?: string;
  q?: string;
  dateFrom?: string;
  dateTo?: string;
  state?: string[];
  type?: string;
  fields?: string[];
}

export interface IInstruction {
  _id: string;
  created_by: ICreatedBy;
  created_at: Date;
  title: string;
  description: string;
  important: boolean;
  assigned: {
    type: AssignedTypeEnum;
    data: string[];
    read: string[];
    users?: {
      service_number: string;
      first_name: string;
      last_name: string;
      read: boolean;
    }[];
  };
  state?: ReadEnum;
  start_date?: Date;
  end_date?: Date;
}
