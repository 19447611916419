<ng-container [formGroup]="pformGroup">
  <mat-form-field>
    <textarea
      matInput
      placeholder="{{ label | translate }}"
      [formControlName]="pformControlName"
      [(attr.disabled)]="disabled"
    >
    </textarea>

    <!-- value="{{ question.value }}"
      pattern="{{ question.pattern }}"
      [id]="question.key"
      (keyup)="applyEvent('keyup', $event)"
    -->
    <mat-icon *ngIf="icon" matSuffix>{{ icon }}</mat-icon>
  </mat-form-field>
</ng-container>
