import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { GenericDialogComponent } from 'app/components/generic/generic-dialog/generic-dialog.component';
import { ListActionEvent } from 'app/components/generic/list/list.component';
import { DEFAULT_PAGE_SIZE, IGenericListParameters } from 'app/components/generic/list/list.interface';
import { Permissions } from 'app/interface/permissions.enum';
import { IUser, UserFilters } from 'app/interface/user.interface';
import { LoadingService } from 'app/services/loading.service';
import { RolesService } from 'app/services/roles.service';
import { TeamsService } from 'app/services/teams.service';
import { TitleService } from 'app/services/title.service';
import { UsersService } from 'app/services/users.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-users',
  templateUrl: './users.list.component.html',
  styleUrls: ['./users.list.component.scss']
})
export class UsersListComponent implements OnInit {
  usersList: Partial<IUser>[] = [];
  total = 0;
  teamList: any;
  loaded = false;
  dialogRef: MatDialogRef<GenericDialogComponent>;
  public addButton: boolean = true;
  private isSuperAdminVR: boolean = false;

  listParameters: IGenericListParameters = {
    paginator: true,
    listHead: [
      {
        key: 'service_number',
        type: 'text',
        title: 'USERS.LOGIN_LABEL',
        styles: { fontWeight: 'bold' },
        apiKey: 'serviceNumber'
      },
      {
        key: 'last_name',
        type: 'text',
        title: 'USERS.LAST_NAME',
        apiKey: 'lastName'
      },
      {
        key: 'first_name',
        type: 'text',
        title: 'USERS.FIRST_NAME',
        apiKey: 'firstName'
      },
      {
        key: 'role',
        type: 'text',
        title: 'USERS.ROLE',
        apiKey: 'role.id'
      },
      {
        key: 'teams_display',
        title: 'USERS.TEAMS',
        type: 'text',
        options: {
          tooltip: 'tooltip_teams',
          icon: {
            key: 'iconTeams',
            class: 'class_icon_teams',
            tooltip: 'tooltip_teams'
          },
          sortable: false
        }
      },
      {
        key: 'last_login',
        type: 'date',
        title: 'USERS.LAST_LOGIN',
        cols: 1,
        apiKey: 'lastLogin'
      },
      {
        key: 'actions',
        type: 'button'
      }
    ]
  };

  // generatePasswordButton = {
  //   type: 'button',
  //   label: 'USERS.BACKUP_PASSWORD',
  //   events: {
  //     click: 'backup'
  //   },
  //   style: { color: 'warn' },
  //   options: { icon: 'refresh' }
  // };

  generateEditButton = {
    type: 'button',
    events: { click: 'edit' },
    style: { color: 'warn' },
    options: { icon: 'edit' },
    tooltip: 'GLOBAL.EDIT'
  };

  generateRemoveButton = {
    type: 'button',
    events: {
      click: 'delete'
    },
    style: { color: 'warn' },
    options: { icon: 'delete' },
    tooltip: 'GLOBAL.DELETE'
  };

  constructor(
    private title: TitleService,
    private users: UsersService,
    private teams: TeamsService,
    public loading: LoadingService,
    public dialog: MatDialog,
    private translate: TranslateService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private rolesService: RolesService
  ) {
    this.title.setTitle('GLOBAL.BREADCRUMB.USERS.LIST');
    iconRegistry.addSvgIcon('upload', sanitizer.bypassSecurityTrustResourceUrl('/assets/icon/file_upload.svg'));
  }

  async ngOnInit() {
    this.getUsersList({ params: { limit: DEFAULT_PAGE_SIZE, offset: 0 } });
    this.getAllSectors();
    if (!this.rolesService.checkPermission(Permissions.BACK_OFFICE_USERS)) {
      this.listParameters.listHead = this.listParameters.listHead.filter((h) => h.key !== 'actions');
      this.addButton = false;
    }
    this.isSuperAdminVR =
      this.rolesService.checkPermission(Permissions.BACK_OFFICE_SUPER_ADMIN) &&
      this.rolesService.checkPermission(Permissions.BACK_OFFICE_CREATE_SUPER_ADMIN);
  }

  /**
   * Display the teams column data (first team) + number of total teams.
   *
   * @param teams
   * @return {string}
   */
  getDisplayTeams(teams: { id: string; name: string }[]) {
    let displayResult = '';
    const tabTeams = teams.map((team) => team.name);
    const teamsLength = tabTeams.length;

    if (teamsLength > 0) {
      displayResult = tabTeams[0];
      if (teamsLength > 1) {
        displayResult += `  (+${teamsLength - 1})`;
      }
    }

    return displayResult;
  }

  /**
   * Retrieve or refresh the users list and apply provided filters.
   *
   * @param event  An event object emitted by ListComponent when a refresh is
   *   required.
   */
  getUsersList(event?: { type?: string; data?: any; params: any }) {
    this.loaded = false;
    // Extract filters from event if available
    // Note: For each filter from the event, we must separate cases where the
    // value is undefined (keep current filter state) from those where the value
    // is empty (remove the filter)
    let filters: UserFilters = {};
    if (event && event.data && event.type === 'search') {
      if (event.data.search) {
        filters.q = String(event.data.search);
      } else if (event.data.search === '') {
        delete filters.q;
      }
    }
    if (event && event.params) {
      filters = { ...filters, ...event.params };
    }

    // Retrieve user datas with the computed filters
    this.loading.on();
    this.users
      .getAll(filters)
      .pipe(
        finalize(async () => {
          this.loading.off();
        })
      )
      .subscribe((res: { items: any[]; total: number }) => {
        for (const item of res.items || []) {
          const teamJoin = item.teams.map((team: any) => team.name).join(', ');
          item.actions = [
            this.generateEditButton,
            this.generateRemoveButton
            // this.generatePasswordButton
          ];
          // Teams
          item.tooltip_teams = teamJoin;
          item.teams_display = this.getDisplayTeams(item.teams);
          item.iconTeams = 'group';
          item.class_icon_teams = 'icon_affected_to';
        }
        this.usersList = res.items;
        this.total = res.total;
        this.loaded = true;
      });
  }

  /**
   * Generate a temporary password for a given user, and display a modal with
   * the generated password.
   *
   * @param event  An event object emitted by ListComponent when the temporary
   *   password generation button is activated.
   */
  handleAction(event: ListActionEvent): void {
    const { type } = event;
    if (!type) {
      return;
    }
    switch (type) {
      case 'edit':
        this.dialogRef = this.dialog.open(GenericDialogComponent, {
          width: '40%',
          data: this.editModalStructure(event.data.service_number, event.data),
          disableClose: true
        });
        break;
      case 'delete':
        this.dialogRef = this.dialog.open(GenericDialogComponent, {
          width: '50%',
          data: { structure: this.deleteModalStructure(event.data.service_number) },
          disableClose: true
        });
        break;
      case 'addButtonClick':
        this.dialogRef = this.dialog.open(GenericDialogComponent, {
          width: '40%',
          data: this.addModalStructure(),
          disableClose: true
        });
        break;
      // case 'backup':
      //   this.createTemporaryPassword(data);
      //   break;
    }
  }

  // createTemporaryPassword(data: any) {
  //   const userId = data.service_number;
  //   this.loading.on();
  //   this.users
  //     .createTemporaryPassword(userId)
  //     .pipe(finalize(() => this.loading.off()))
  //     .subscribe(
  //       (password: string) => this.openTemporaryPasswordModal(password),
  //       () => {
  //         /* Silently fail */
  //       }
  //     );
  // }

  private deleteModalStructure(serviceNumber: string) {
    return {
      title: 'GLOBAL.CONFIRMATION',
      text: 'CONFIRM.DELETE_USER',
      buttons: [
        {
          text: 'GLOBAL.DELETE',
          class: 'validation',
          isRaisedButton: true,
          action: {
            target: 'custom',
            params: {
              id: 'delete',
              function: () => {
                this.users.delete(serviceNumber).subscribe(() => this.getUsersList());
                this.dialog.closeAll();
              }
            }
          }
        },
        {
          text: 'GLOBAL.CANCEL',
          class: 'cancel',
          action: {
            target: 'generic',
            params: {
              id: 'close',
              function: undefined
            }
          }
        }
      ]
    };
  }

  getTeamsUser(teams: any) {
    return teams.map((e: any) => `${e.name} ${e.id}`);
  }

  getAllSectors() {
    return this.teams.getAll().subscribe((e: any) => {
      this.teamList = e.items.map((x: any) => `${x.name} ${x.id}`);
    });
  }

  private editModalStructure(serviceNumber: string, user: any) {
    return {
      structure: {
        title: 'GLOBAL.BREADCRUMB.USERS.EDIT',
        text: 'GLOBAL.BREADCRUMB.USERS.EDIT'
      },
      content: {
        endpoint: `/users/${serviceNumber}`,
        method: 'patch',
        type: 'form',
        fields: [
          {
            key: 'first_name',
            title: 'USERS.FIRST_NAME',
            value: user.first_name,
            type: 'text'
          },
          {
            key: 'last_name',
            title: 'USERS.LAST_NAME',
            value: user.last_name,
            type: 'text'
          },
          {
            key: 'role',
            title: 'USERS.ROLE',
            value: user.role,
            type: 'text'
          },
          {
            key: 'teams',
            title: 'USERS.TEAMS',
            allValue: this.teamList,
            value: this.getTeamsUser(user.teams),
            type: 'list'
          }
        ],
        button: {
          text: this.translate.instant('GLOBAL.SUBMIT_BUTTON_LABEL'),
          class: 'validate'
        },
        disableClose: true
      }
    };
  }

  private addModalStructure() {
    const structure = {
      structure: {
        title: 'GLOBAL.BREADCRUMB.USERS.NEW',
        text: 'GLOBAL.BREADCRUMB.USERS.NEW'
      },
      content: {
        endpoint: `/users/new`,
        method: 'post',
        type: 'form',
        fields: [
          {
            key: 'first_name',
            title: 'USERS.FIRST_NAME',
            type: 'text'
          },
          {
            key: 'last_name',
            title: 'USERS.LAST_NAME',
            type: 'text'
          },
          {
            key: 'email',
            title: 'USERS.EMAIL',
            type: 'email'
          },
          {
            key: 'role',
            title: 'USERS.ROLE',
            type: 'text'
          },
          {
            key: 'service_number',
            title: 'USERS.FORM.LOGIN_LABEL',
            type: 'text'
          },
          // TODO: Change call to send proper team ids and remove formatting logic in back-end,
          {
            key: 'teams',
            title: 'USERS.TEAMS',
            allValue: this.teamList,
            type: 'list'
          }
        ],
        button: {
          text: this.translate.instant('GLOBAL.SUBMIT_BUTTON_LABEL'),
          class: 'validate'
        }
      }
    };
    // Allow super admin Vigie Reseau to set a client code to create a cross schema user
    if (this.isSuperAdminVR) {
      // Removing 'role' and 'teams' keys as they won't be relevant to create a cross schema user
      structure.content.fields = structure.content.fields.filter(
        (field) => field.key !== 'teams' && field.key !== 'role'
      );
      // Adding the profile code field
      structure.content.fields.push({
        key: 'code',
        title: 'USERS.FORM.CODE',
        type: 'text'
      });
      // Secure endpoint to create cross schema user
      structure.content.endpoint = '/users/new-super-admin';
    }
    return structure;
  }
}
