import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { PdfViewerComponent } from 'src/app/components/generic/map/pdf-viewer/pdf-viewer.component';
import { ReportsService } from 'src/app/services/reports.service';

@Component({
  standalone: true,
  selector: 'app-reporting-pdf-dialog',
  templateUrl: './reporting-pdf.dialog.component.html',
  imports: [CommonModule, TranslateModule, MatDialogModule, PdfViewerComponent]
})
export class ReportingPdfDialogComponent {
  public blob: Blob;
  public filename!: string;

  constructor(reportsService: ReportsService, @Inject(MAT_DIALOG_DATA) data: any) {
    reportsService.downloadPdfBlob(data.reportingId).then((response) => {
      this.blob = response.body!;
      this.filename = response.headers.get('File-Name') ?? 'reporting.pdf';
    });
  }
}
