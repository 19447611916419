<ng-container *ngIf="isOff">
  <h1 class="align-center">
    environment?.USE_MOCKED_PROFILE=true
  </h1>
</ng-container>

<ng-container *ngIf="!isOff">
  <h1 class="mainTitle">{{ 'GLOBAL.BREADCRUMB.PROFILES.LIST' | translate }}</h1>

  <app-loading [active]="!loaded" />
  <app-list
    *ngIf="loaded"
    addButton="dynamic"
    [listParameters]="listParameters"
    [items]="items"
    [total]="total"
    (refresh)="handleRefresh($event)"
    (action)="handleAction($event)"
  />

</ng-container>
