import { Component, Input, ViewEncapsulation } from '@angular/core';
import { SessionService } from 'app/components/generic/services/session/session.service';
import { ProfileService } from 'src/app/services/profile.service';

@Component({
  selector: 'app-pictures-card',
  templateUrl: './pictures-card.component.html',
  styleUrls: ['./pictures-card.component.scss'],
  providers: [],
  encapsulation: ViewEncapsulation.None
})
export class PicturesCardComponent {
  picturesView: any[] = [];
  jwt?: string;

  @Input() reportid: any;
  @Input() private set pictures(newValue) {
    this._pictures = newValue;
    this.parsePictures();
  }
  private get pictures(): string[] {
    return this._pictures;
  }

  private _pictures: string[] = [];
  private baseURL?: string;

  constructor(private session: SessionService) {}

  private getFullURL(image: any) {
    return `${this.baseURL}${image.name}/file?jwt=${this.jwt}`;
  }

  private parsePictures() {
    if (!this.jwt) {
      this.jwt = this.session.getToken();
    }

    if (!this.baseURL) {
      const apiRoot = ProfileService.getAPIUrl();
      const apiPath = `reportings/${this.reportid}/media/`;
      this.baseURL = `${apiRoot}${apiPath}`;
    }

    const tempPictures: any[] = [];
    this.pictures.forEach((picture: any) => {
      tempPictures.push({
        ...picture,
        url: this.getFullURL(picture)
      });
    });
    this.picturesView = tempPictures;
  }
}
