export type GenericListColumnOptions =
  | IGenericListTextColumn
  | IGenericListDateColumn
  | IGenericListBooleanColumn
  | IGenericListButtonColumn
  | IGenericListCheckboxColumn
  | IGenericListIconColumn
  | IGenericListReportingIconColumn
  | IGenericListImageColumn
  | IGenericListImageSrcColumn;

export interface IGenericListParameters {
  paginator?: boolean;
  clickable?: boolean;
  listHead: GenericListColumnOptions[];
}

export interface IGenericListColumn {
  type: string;
  key: string;
  title?: string;
  cols?: number;
  apiKey?: string;
  colMinWidth?: string;
  colMaxWidth?: string;
  colWidth?: string;
  visible?: boolean;
}

export interface IGenericListTextColumn extends IGenericListColumn {
  type: 'text';
  options?: {
    icon?: {
      key?: string;
      type?: 'image' | 'icon' | 'reporting-icon';
      class?: string;
      tooltip?: string;
    };
    picto?: boolean;
    value?: string;
    class?: string;
    tooltip?: string;
    sortable?: boolean;
  };
  events?: { [key: string]: string };
  color?: string;
  styles?: { [key: string]: any };
}

export interface IGenericListReportingIconColumn extends IGenericListColumn {
  type: 'reporting-icon';
}

export interface IGenericListDateColumn extends IGenericListColumn {
  type: 'date';
  options?: { format?: string; sortable?: boolean };
}

export interface IGenericListBooleanColumn extends IGenericListColumn {
  type: 'boolean';
  trueLabel: string;
  falseLabel: string;
}

export interface IGenericListButtonColumn extends IGenericListColumn {
  type: 'button';
  options?: { sortable?: boolean };
}

export interface IGenericListCheckboxColumn extends IGenericListColumn {
  type: 'checkbox';
  disabled: false;
  events?: { [key: string]: string };
}

export interface IGenericListIconColumn extends IGenericListColumn {
  type: 'icon';
  options: {
    icon: string;
    tooltip?: string;
  };
  events?: { [key: string]: string };
}

export interface IGenericListImageColumn extends IGenericListColumn {
  type: 'image';
  icon: string;
  options: {
    tooltip?: string;
  };
  events?: { [key: string]: string };
}

export interface IGenericListImageSrcColumn extends IGenericListColumn {
  type: 'imageSrc';
  options?: {
    tooltip?: string;
  };
  events?: { [key: string]: string };
}

export const DEFAULT_PAGE_SIZE = 20;
