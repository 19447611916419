import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { isObject } from 'lodash';
@Injectable()
export class IconService {
  registry: { [key: string]: string } = {};
  typedRegistry: { [key: string]: string } = {};

  defaultIcons = {
    reporting: {
      unknown: '/assets/icon/reportings/pins/POI_inconnu.svg',
      accident: '/assets/icon/reportings/pins/POI_crash.svg'
    },
    poi: {
      unknown: '/assets/icon/POI/pins/POI_inconnu.svg'
    }
  };

  constructor(
    public iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {
    this.registerSet(this.defaultIcons);
  }

  /**
   * USE
   <mat-icon matSuffix svgIcon="ICON_NAME"></mat-icon>
   * @param name ICON_NAME to call in your view
   * @param url URL to your SVG Icon
   */
  register(name: string, url: string) {
    // Ignore already registered icons
    if (name in this.registry) {
      return;
    }

    this.registry[name] = url;
    this.iconRegistry.addSvgIcon(name, this.sanitizer.bypassSecurityTrustResourceUrl(url));
  }

  getURL(...names: string[]): string {
    const name = this.get(...names);

    return this.registry[name] || name;
  }

  get(...names: string[]): string {
    let fallback = '';
    for (const name of names) {
      fallback = name;
      if (name in this.registry) {
        return name;
      }
    }

    return fallback;
  }

  registerSet(set: { [key: string]: any }, prefix = '') {
    for (const key of Object.keys(set)) {
      if (isObject(set[key])) {
        this.registerSet(set[key], key);
      } else {
        this.register([prefix, key].filter((a) => a).join('_'), set[key]);
      }
    }
  }
}
