import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SessionService } from 'app/components/generic/services/session/session.service';
import { ControlTypeEnum, IControl, IControlPayload, IControlQuestion } from 'app/interface/controls.interface';
import { ControlsService } from 'app/services/controls.service';
import { IconService } from 'app/services/icon.service';
import { LoadingService } from 'app/services/loading.service';
import { TitleService } from 'app/services/title.service';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import { finalize } from 'rxjs/operators';
import { ProfileService } from 'src/app/services/profile.service';

import { getTranslationWithFallback } from '../../../tools/translate';

@Component({
  selector: 'app-control-details',
  templateUrl: './controls.details.component.html',
  styleUrls: ['./controls.details.component.scss']
})
export class ControlDetailComponent implements OnInit {
  typeControl: string;
  id: string;
  controlDetails: any;
  sliderChecked = true;
  slidingSlider = false;
  loaded = false;
  expand = false;
  // Attributes to get images
  baseURL = '';
  jwt?: string;
  controlAudit: boolean;

  constructor(
    private title: TitleService,
    private controls: ControlsService,
    public loading: LoadingService,
    private router: Router,
    public iconService: IconService,
    private http: HttpClient,
    private session: SessionService,
    private translate: TranslateService,
    route: ActivatedRoute
  ) {
    route.data.subscribe((data) => (this.controlAudit = data.auditControl));
    route.params.subscribe((params) => (this.id = params.id));
    this.title.setTitle('GLOBAL.CONTROLS_DETAILS');

    this.jwt = this.session.getToken();

    const apiRoot = ProfileService.getAPIUrl();
    const apiPath = `controls/${this.id}/images/`;
    this.baseURL = `${apiRoot}${apiPath}`;

    iconService.register('beacon', '/assets/icon/infrastructures/equipement-autres.svg');
    iconService.register('unknown', '/assets/icon/infrastructures/inconnu.svg');
  }

  async ngOnInit() {
    this.getControlDetails();
  }

  // ---------------------------- Header values ----------------------------

  getIconControl(control: IControl): string {
    if (control.type !== ControlTypeEnum.BEACON && control.infrastructure) {
      return control.infrastructure.type.picto || '';
    } else if (control.type !== ControlTypeEnum.BEACON && !control.infrastructure) {
      return this.iconService.getURL('unknown');
    }
    return this.iconService.getURL('beacon');
  }

  getDirectionControl(control: IControl) {
    const location = control.location
      ? control.location
      : control.type !== ControlTypeEnum.BEACON && control.infrastructure
      ? control.infrastructure.location
      : undefined;
    const direction = location.direction;
    const origin = location.origin;
    const destination = location.destination;

    return direction === 1 ? `sens ${origin} - ${destination}` : `sens ${destination} - ${origin}`;
  }

  getTitleControl(control: IControl) {
    if (control.type === ControlTypeEnum.BEACON) {
      return 'AUDITS.BEACON_CONTROL';
    } else if (control.infrastructure) {
      return control.infrastructure.name.toUpperCase();
    }
    return control.infrastructure_id;
  }

  getInfrastructureTypeControl(control: IControl) {
    if (control.type !== ControlTypeEnum.BEACON && control.infrastructure) {
      return getTranslationWithFallback(
        `INFRASTRUCTURES.TYPES.${control.infrastructure.type.name.toUpperCase()}`,
        control.infrastructure.type.name,
        this.translate
      );
    } else if (control.type !== ControlTypeEnum.BEACON && !control.infrastructure) {
      return this.translate.instant('AUDITS.UNKNOWN');
    }
    return this.translate.instant('AUDITS.BEACON');
  }

  getSectorControl(control: IControl) {
    const location = control.location
      ? control.location
      : control.type !== ControlTypeEnum.BEACON && control.infrastructure
      ? control.infrastructure.location
      : undefined;

    return location.sector_name;
  }

  getHighwayNameControl(control: IControl) {
    const location = control.location
      ? control.location
      : control.type !== ControlTypeEnum.BEACON && control.infrastructure
      ? control.infrastructure.location
      : undefined;

    return location.highway_name;
  }

  getPrControl(control: IControl) {
    const location = control.location
      ? control.location
      : control.type !== ControlTypeEnum.BEACON && control.infrastructure
      ? control.infrastructure.location
      : undefined;

    return location.display;
  }

  getConformity(control: IControl) {
    const conformity = control.conformity !== undefined ? control.conformity : '';

    return conformity;
  }

  expandAccordion(sections: any, expand: boolean) {
    for (const section of sections) {
      if (section.questions) {
        this.expandAccordion(section.questions, expand);
      }
      section.expand = expand;
    }
  }

  // ---------------------------- Build questions ----------------------------

  buildMediaQuestion(field: any) {
    const medias = field.medias && _.isArray(field.medias) ? field.medias : [];
    return medias.map((media: any) => `${this.baseURL}${media.name}?key=${field.key}&jwt=${this.jwt}`);
  }

  buildNewQuestion(field: any, indexSection: number, index: number) {
    const newField: IControlQuestion = {
      key: field.key,
      type: field.type,
      title: field.title,
      mandatory: field.mandatory,
      medias: this.buildMediaQuestion(field),
      questions: field.questions || [],
      content: field.content || '',
      response: field.value || '',
      step: `${indexSection}-${index}`
    };

    return newField;
  }

  buildDateQuestion(questions: IControlQuestion[], field: any, indexSection: number, index: number) {
    const newField = this.buildNewQuestion(field, indexSection, index);

    if (field.value) {
      newField.response = dayjs(field.value).format('DD/MM/YYYY');
    }

    questions.push(newField);
  }

  buildSingleChoiceQuestion(questions: IControlQuestion[], field: any, indexSection: number, index: number) {
    const newQuestion = this.buildNewQuestion(field, indexSection, index);
    questions.push(newQuestion);
  }

  buildMultiChoiceQuestion(questions: IControlQuestion[], field: any, indexSection: number, index: number) {
    const newQuestion = this.buildNewQuestion(field, indexSection, index);
    questions.push(newQuestion);
  }

  buildBinaryQuestion(questions: IControlQuestion[], field: any, indexSection: number, index: number) {
    const newField = this.buildNewQuestion(field, indexSection, index);

    if (field.value !== undefined) {
      newField.response = field.value ? field.positiveBtnText : field.negativeBtnText;
    }

    questions.push(newField);

    if (newField.response) {
      let subFields = [];

      if (field.value) {
        subFields = field.childFields || [];
      } else {
        subFields = field.negativeChildFields || [];
      }

      if (subFields.length > 0) {
        newField.questions = [];
        subFields.forEach((subField: any, subindex: number) => {
          this.buildQuestion(newField.questions, subField, index, subindex);
        });
      }
    }
  }

  buildInputQuestion(questions: IControlQuestion[], field: any, indexSection: number, index: number) {
    const newField = this.buildNewQuestion(field, indexSection, index);
    questions.push(newField);
  }

  buildQuestion(questions: IControlQuestion[], field: any, indexSection: number = 0, index: number = 0) {
    switch (field.type.toUpperCase()) {
      case 'DATE':
        this.buildDateQuestion(questions, field, indexSection, index);
        break;
      case 'SINGLE_CHOICE':
        this.buildSingleChoiceQuestion(questions, field, indexSection, index);
        break;
      case 'MULTI_CHOICE':
        this.buildMultiChoiceQuestion(questions, field, indexSection, index);
        break;
      case 'BINARY_QUESTION':
        this.buildBinaryQuestion(questions, field, indexSection, index);
        break;
      case 'INPUT':
        this.buildInputQuestion(questions, field, indexSection, index);
        break;
      case 'INPUT_NUMBER':
        this.buildInputQuestion(questions, field, indexSection, index);
        break;
      default:
        console.error('Type de champ non géré : ', field.type);
        break;
    }
  }

  private buildSectionsControl(control: any): void {
    control.sections = [];
    if (!control.payload?.sections) {
      return;
    }
    const payload: IControlPayload = control.payload;
    payload.sections.forEach((section, sectionIndex) => {
      const newSection: any = {
        title: section.title,
        questions: [],
        expand: true
      };
      if (section.fields && Array.isArray(section.fields)) {
        section.fields.forEach((field, index) => {
          this.buildQuestion(newSection.questions, field, sectionIndex, index);
        });
      }
      control.sections.push(newSection);
    });
  }

  // ---------------------------- GET ----------------------------

  getControlDetails() {
    this.loading.on();
    this.controls
      .getById(this.id)
      .pipe(
        finalize(async () => {
          this.loading.off();
        })
      )
      .subscribe((res: IControl) => {
        // If the control is an audit control --> redirect to the correct route (used to change breadcrumb)
        if (res.audit_id && !this.controlAudit) {
          this.router.navigate([`audits/${res.audit_id}/control/${this.id}`]);
        }

        this.typeControl = res.type;
        this.controlDetails = {
          ...res,
          conformity: this.getConformity(res),
          audit_id: res.audit_id,
          header: {
            icon: this.getIconControl(res),
            title: this.getTitleControl(res),
            created_at: dayjs(res.created_at).format('DD/MM/YYYY'),
            updated_at: res.updated_at !== res.created_at ? dayjs(res.updated_at).format('DD/MM/YYYY H:m') : '',
            control_type: `CONTROLS.CONTROLTYPE.${res.type.toUpperCase()}`,
            infrastructure_type: this.getInfrastructureTypeControl(res),
            sector: this.getSectorControl(res),
            highway_name: this.getHighwayNameControl(res),
            direction: this.getDirectionControl(res),
            pr: this.getPrControl(res),
            status: `CONTROLS.RESULTS.${res.status}`,
            affected_to: res.affected_to ? `${res.affected_to.last_name} ${res.affected_to.first_name}` : '',
            created_by: res.created_by ? `${res.created_by.last_name} ${res.created_by.first_name}` : ''
          }
        };
        this.buildSectionsControl(this.controlDetails);
        this.loaded = true;
      });
  }

  exportControl() {
    const title = 'controls-detail';
    const downloadDate = dayjs().format('DD-MM-YYYY-hhmm');
    const downloadedFilename = `${title}-${downloadDate}.pdf`;
    this.http
      .get(`api://controls/${this.id}/pdf`, { responseType: 'blob' })
      .subscribe((data) => saveAs(data, downloadedFilename));
  }

  handleSliderChange() {
    this.slidingSlider = true;
    setTimeout(async () => this.router.navigate([`audits`]), 300);
  }
}
