import { ValidationErrors } from '@angular/forms';
import { Observable } from 'rxjs';

export type ControlValidatorType = 'pattern' | 'required' | 'async' | 'email';

export interface IControlGenericValidator {
  key: ControlValidatorType;
  pattern?: RegExp | string;
  fn?: (value: any) => Observable<ValidationErrors | null>;
  errors?: { [key: string]: string };
}

export interface IControlPatternValidator extends IControlGenericValidator {
  key: 'pattern';
  pattern: RegExp | string;
}

export interface IControlRequiredValidator extends IControlGenericValidator {
  key: 'required';
}

export interface IControlEmailValidator extends IControlGenericValidator {
  key: 'email';
}

export interface IControlAsyncValidator extends IControlGenericValidator {
  key: 'async';
  standalone?: boolean;
  dependencies?: string[];
  fn: (value: any) => Observable<ValidationErrors | null>;
}

export type IControlValidator =
  | IControlRequiredValidator
  | IControlEmailValidator
  | IControlPatternValidator
  | IControlAsyncValidator;

export class QuestionBase<T> {
  error: string;
  value: T | undefined;
  key: string;
  label: string;
  pristine: boolean;
  order: number;
  size: string;
  controls: IControlValidator[];
  pattern: string;
  controlType: string;
  disabled: boolean | null;
  events: any;
  placeholder: string;
  hint: string;
  icon: string;
  fileName?: string;
  fileLength?: number;
  images?: any[];
  actions?: string[];
  shouldHide?: boolean;
  typeMime?: string[];
  picturesView?: any[];
  readonly?: boolean;
  visible: boolean;
  updateValue?: any;
  edit?: boolean;
  filter?: any;
  [key: string]: any;

  constructor(options: Partial<QuestionBase<T>> = {}) {
    const questionOptions = {
      error: '',
      displayValue: '',
      key: '',
      label: '',
      pristine: false,
      order: 1,
      size: '1-1',
      controls: [],
      pattern: '',
      controlType: '',
      disabled: null,
      events: null,
      placeholder: '',
      hint: '',
      icon: '',
      fileName: '',
      fileLength: 0,
      images: [],
      actions: [],
      typeMime: [],
      shouldHide: false,
      picturesView: [],
      readonly: false,
      edit: true,
      visible: true,
      filter: undefined,
      updateValue: (newValue: any) => {
        this.value = newValue;
        if (this.events && this.events.change) {
          this.events.change(newValue);
        }
        for (const control of this.controls) {
          if (control.fn && control.fn instanceof Function) {
            control.fn(newValue).subscribe();
          }
        }
      },
      ...options
    };

    if (questionOptions.value) {
      this.value = questionOptions.value;
    }

    this.error = questionOptions.error;
    this.placeholder = questionOptions.placeholder;
    this.hint = questionOptions.hint;
    this.icon = questionOptions.icon;
    this.key = questionOptions.key;
    this.label = questionOptions.label;
    this.pristine = !!questionOptions.pristine;
    this.size = questionOptions.size;
    this.controls = questionOptions.controls;
    this.pattern = questionOptions.pattern;
    this.order = questionOptions.order;
    this.controlType = questionOptions.controlType;
    this.disabled = questionOptions.disabled ? true : null;
    this.events = questionOptions.events;
    this.fileName = questionOptions.fileName;
    this.shouldHide = questionOptions.shouldHide;
    this.fileLength = questionOptions.fileLength;
    this.images = questionOptions.images;
    this.actions = questionOptions.actions;
    this.typeMime = questionOptions.typeMime;
    this.picturesView = questionOptions.picturesView;
    this.readonly = questionOptions.readonly;
    this.edit = questionOptions.edit;
    this.updateValue = questionOptions.updateValue;
    this.visible = !!questionOptions.visible || true;
  }
}
