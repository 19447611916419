import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DEFAULT_PAGE_SIZE, IGenericListParameters } from 'app/components/generic/list/list.interface';
import { CrossingPointsEnum, ICrossingPoint } from 'app/interface/crossing-points.interface';
import { CrossingPointsService } from 'app/services/crossing-points.service';
import { LoadingService } from 'app/services/loading.service';
import { TitleService } from 'app/services/title.service';
import { finalize } from 'rxjs/operators';
import { RequestFilters } from 'src/app/interface/utils.interfaces';

@Component({
  selector: 'app-crossing-points',
  templateUrl: './crossing-points.list.component.html',
  styleUrls: ['./crossing-points.list.component.scss']
})
export class CrossingPointsListComponent implements OnInit {
  public loaded = false;
  crossingPointsList: Partial<ICrossingPoint>[] = [];
  loadedList: any[] = [];
  total = 0;
  listParameters: IGenericListParameters = {
    paginator: true,
    listHead: [
      {
        key: 'name',
        type: 'text',
        title: 'CROSSING_POINTS.NAME'
      },
      {
        key: 'type',
        type: 'text',
        title: 'CROSSING_POINTS.TYPE',
        apiKey: 'crossingPointsType.name'
      },
      {
        key: 'direction',
        type: 'text',
        title: 'CROSSING_POINTS.DIRECTION',
        apiKey: 'location.highway.name'
      },
      {
        key: 'landmark',
        type: 'text',
        title: 'CROSSING_POINTS.PR_ID',
        apiKey: 'location.kilometerPoint'
      },
      {
        key: 'longitude',
        type: 'text',
        title: 'CROSSING_POINTS.LONGITUDE',
        options: {
          sortable: false
        }
      },
      {
        key: 'latitude',
        type: 'text',
        title: 'CROSSING_POINTS.LATITUDE',
        options: {
          sortable: false
        }
      },
      {
        key: 'radius',
        type: 'text',
        title: 'CROSSING_POINTS.RADIUS'
      }
    ]
  };

  constructor(
    private title: TitleService,
    private crossingPoints: CrossingPointsService,
    public loading: LoadingService,
    public router: Router
  ) {
    this.title.setTitle('GLOBAL.BREADCRUMB.CROSSING_POINTS.LIST');
  }

  async ngOnInit() {
    this.getCrossingPointsList({ params: { limit: DEFAULT_PAGE_SIZE, offset: 0 } });
  }

  loadList() {
    const finalList: any = [];
    let latitude = '';
    let longitude = '';
    let direction: any = '';
    this.crossingPointsList.forEach((el: Partial<ICrossingPoint>) => {
      if (el.coordinates) {
        longitude = String((Math.floor(el.coordinates.longitude * 10000) / 10000).toFixed(4));
        latitude = String((Math.floor(el.coordinates.latitude * 10000) / 10000).toFixed(4));
      }
      if (el.location === undefined) {
        el.location = {
          destination: '',
          origin: '',
          display: '',
          direction: 1,
          highway_name: '',
          kilometer_point: 0,
          landmark: 100,
          landmark_id: 'empty landmark',
          offset: 0,
          sector_id: '',
          sector_name: '',
          type: '',
          value: 0
        };
      }
      direction = el.location.highway_name;
      finalList.push({
        name: el.name,
        type: el.type ? CrossingPointsEnum[el.type] : undefined,
        radius: (Math.floor(el.radius! * 100) / 100).toFixed(2),
        landmark: el.location.display,
        longitude: longitude,
        latitude: latitude,
        direction: direction
      });
    });

    return finalList;
  }

  getCrossingPointsList(event?: { type?: string; data?: any; params: any }) {
    this.loaded = false;
    let filters: RequestFilters = {};
    if (event && event.data && event.type === 'search') {
      if (event.data.search) {
        filters.q = String(event.data.search);
      } else if (event.data.search === '') {
        delete filters.q;
      }
    }
    if (event && event.params) {
      filters = { ...filters, ...event.params };
    }

    this.loading.on();
    this.crossingPoints
      .getAll(filters)
      .pipe(
        finalize(() => {
          this.loadedList = this.loadList();
          this.loading.off();
        })
      )
      .subscribe((res: { items: ICrossingPoint[]; total: number }) => {
        this.crossingPointsList = res.items.map((crossingPoints: ICrossingPoint) => crossingPoints);
        this.total = res.total;
        this.loaded = true;
      });
  }

  public goToManager(): void {
    this.router.navigate(['/repositories/crossing-points/manager']);
  }
}
