<ol class="breadcrumb">
  <li class="breadcrumb-item">
    <a routerLink="" translate>GLOBAL.BREADCRUMB.HOME</a>
  </li>
  <ng-container *ngFor="let breadcrumb of breadcrumbs ; let $index = index">
    <li class="breadcrumb-item {{$index === breadcrumbs.length - 1 && !breadcrumb.params.UID ? 'active' : ''}}">
      <a [routerLink]="[breadcrumb.url]" translate>{{ breadcrumb.label }}</a>
    </li>
    <li *ngIf="breadcrumb.params.UID" class="breadcrumb-item {{$index === breadcrumbs.length - 1 ? 'active' : ''}}">
      <a [routerLink]="[breadcrumb.url, breadcrumb.params.UID]" translate>{{ breadcrumb.params.UID }}</a>
    </li>
  </ng-container>
</ol>
