type LoadScriptOptions = {
  async?: boolean;
  type?: string;
};

export class ScriptHelper {
  // Ajoute le <script> au DOM, retourne une promesse quand le script est chargé
  // Utilisé notamment pour charger le script de l'api google maps
  public static async loadScript(src: string, options?: LoadScriptOptions): Promise<void> {
    const { async = true, type = 'text/javascript' } = options || {};

    return new Promise<void>((s, f) => {
      const tag = document.createElement('script');
      tag.type = type;
      tag.async = async;
      tag.src = src;

      tag.addEventListener('load', () => s());

      tag.addEventListener('error', (err) =>
        f(
          Error(`Failed to load script with src ${src}`, {
            cause: err.error
          })
        )
      );

      const container = document.head || document.body;
      container.appendChild(tag);
    });
  }
}
