import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { TitleService } from 'app/services/title.service';
import { ProfileService } from 'src/app/services/profile.service';

@Component({
  selector: 'app-profile-code-form',
  templateUrl: './profile-code-form.component.html',
  styleUrls: [
    '../../../components/generic/dynamic-form/dynamic-form.component.scss',
    './profile-code-form.component.scss'
  ]
})
export class ProfileCodeFormComponent {
  srcLogo = '../../assets/common/logo.svg';

  public isLoading = false;
  public codeForm: FormGroup<{ profileCode: FormControl<string | null> }>;

  constructor(
    private title: TitleService,
    private formBuilder: FormBuilder,
    private profileService: ProfileService
  ) {
    this.codeForm = this.formBuilder.group({ profileCode: '' });
    this.title.setTitle('GLOBAL.TITLE_PROJECT');
  }

  onSubmit() {
    const profileCode = this.codeForm.value.profileCode;
    if (!profileCode) return;
    this.profileService.sendProfileCode(profileCode);
  }
}
