import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { MapClickEvent, MapComponent, MarkerDragEvent, MarkerInfo } from 'src/app/components/generic/map/map.component';
import { BoundingBox, Coordinates } from 'src/app/interface/location.interface';
import { HighwaysService } from 'src/app/services/highways.service';
import { LocationsService } from 'src/app/services/locations.service';
import { ReportsService } from 'src/app/services/reports.service';
import { GeneralHelper } from 'src/app/tools/general.helper';
import { MapsHelper } from 'src/app/tools/maps.helper';

import { LocationFormHelper } from './location-form.helper';

/** Use in combination with LocationFormHelper */
@Component({
  standalone: true,
  imports: [MapComponent],
  selector: 'app-location-form-map',
  template: `
    <app-map
      [markers]="[marker]"
      [(zoom)]="zoom"
      [center]="center"
      [boundingBox]="boundingBox"
      [fitMarkersOnLoad]="fitMarkersOnLoad"
      [streetViewControl]="false"
      (markerDrag)="handleMarkerDrag($event)"
      (mapClick)="handleMapClick($event)"
    />
  `
})
export class LocationFormMapComponent implements OnInit {
  public marker: MarkerInfo;
  public center: Coordinates | undefined;
  public fitMarkersOnLoad = false;

  @Input() private pformGroup?: UntypedFormGroup;
  @Input() private coordinates?: Coordinates; // Initial coordinates
  @Input() public zoom: number = 6;
  @Input() public boundingBox?: BoundingBox;
  @Input() public markerCoordinates: Observable<Coordinates>;

  @Output() markerMove = new EventEmitter<Coordinates>();

  constructor(
    private reportsService: ReportsService,
    private locationsService: LocationsService,
    private highwaysService: HighwaysService,
    private locationFormHelper: LocationFormHelper
  ) {}

  ngOnInit() {
    const coordinatesValue = this.pformGroup?.get('coordinates')?.value;
    const coordinates: Coordinates = coordinatesValue || this.coordinates || { longitude: 0, latitude: 0 };

    this.marker = {
      markerType: 'generic',
      coordinates,
      draggable: true,
      showInfos: false
    };
    this.center = coordinates;
    this.zoom = 16;

    if (coordinatesValue) {
      this.fitMarkersOnLoad = true;
    }
    this.markerCoordinates.subscribe((coords) => {
      this.marker.coordinates = coords;
      this.center = coords;
      this.zoom = 16;
    });
  }

  public handleMapClick(event: MapClickEvent): void {
    const coords = MapsHelper.gmCoordsToCoords(event.latLng);
    if (!coords) {
      return;
    }
    if (this.zoom >= 16) {
      this.handleMarkerMoved(coords);
    }
    if (this.zoom < 16) {
      this.zoom = Math.min(18, this.zoom + (this.zoom <= 10 ? 4 : this.zoom <= 16 ? 3 : 2));
      this.center = coords;
    }
  }

  public handleMarkerDrag(event: MarkerDragEvent): void {
    const coords = MapsHelper.gmCoordsToCoords(event.event.latLng);
    if (!coords) {
      return;
    }
    this.handleMarkerMoved(coords);
  }

  private handleMarkerMoved(coords: Coordinates): void {
    this.marker.coordinates = coords;
    this.markerMove.emit(coords);
    if (this.pformGroup) {
      this.handleMarkerMovedInFormGroup(coords);
    }
  }

  public async handleMarkerMovedInFormGroup(coords: Coordinates): Promise<void> {
    if (!this.pformGroup) {
      return;
    }

    const locations = await this.reportsService.getLocationsFromCoordinates(coords);
    if (!locations.length) {
      return;
    }
    const interPoint = this.locationsService.buildLandmarkFromPoints(locations, coords);
    const highwayId = interPoint.highwayId;
    const highway = await this.highwaysService.get(highwayId);
    const highwayValue = LocationFormHelper.makeHighwayOption(highway).value;
    const direction = this.locationFormHelper.makeDirectionOption(String(interPoint.direction), highway).value;
    const pr = interPoint.value.toFixed(3).replace(/\./g, '+');

    this.pformGroup.get('highway')?.setValue(highwayValue);
    await GeneralHelper.wait(0); // let location-fields.component clear the coordinates and direction before setting them back to actual values
    this.pformGroup.get('coordinates')?.setValue(coords);
    this.pformGroup.get('direction')?.setValue(direction);

    // Get the meters number from a float that represents a kilometer value. Handle floating point imprecision
    this.pformGroup.get('pr')?.setValue(pr);
    this.pformGroup.get('direction')?.enable();
    this.pformGroup.updateValueAndValidity({ emitEvent: true });
  }
}
