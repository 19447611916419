<ng-container [formGroup]="pformGroup">
  <mat-form-field>
    <mat-label *ngIf="label">{{ label | translate }}</mat-label>
    <input
      matInput
      placeholder="{{ placeholder | translate }}"
      [formControlName]="pformControlName"
      [matDatepicker]="picker"
      [matDatepickerFilter]="filter"
      [min]="min"
      [max]="max"
      [(attr.disabled)]="disabled"
    />
    <!--
      [required]="question.required"
      [readonly]="question.readonly" -->
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker [disabled]="disabled"></mat-datepicker>
  </mat-form-field>
</ng-container>
