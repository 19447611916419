import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Permissions } from 'app/interface/permissions.enum';
import { IRolesMatrix } from 'app/interface/roles-matrix.interface';
import { LoadingService } from 'app/services/loading.service';
import { RolesService } from 'app/services/roles.service';
import { TitleService } from 'app/services/title.service';
import _ from 'lodash';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, finalize } from 'rxjs/operators';

import { GenericDialogComponent } from '../../generic/generic-dialog/generic-dialog.component';

type PermissionsList = {
  permission_name: string;
  [key: string]: any;
}[];

@Component({
  selector: 'app-users',
  templateUrl: './roles.list.component.html',
  styleUrls: ['./roles.list.component.scss']
})
export class RolesListComponent implements OnInit, OnDestroy {
  public loaded = false;
  dialogRef: MatDialogRef<GenericDialogComponent>;

  /**
   * Generic list component parameters. Populated once roles are parsed.
   * @see parseRolesMatrix
   */
  listParameters: any = {
    rowHeight: '50px',
    paginator: false,
    listHead: []
  };

  /**
   * The parsed roles list to be sent to the generic list component. This
   * property is populated on roles matrix retrieval, and should NOT be tampered
   * with nor accessed directly.
   * @see parseRolesMatrix
   */
  rolesByPermissionList: PermissionsList = [];
  rolesByPermissionListBackoffice: PermissionsList = [];
  rolesByPermissionListAppMobile: PermissionsList = [];

  /**
   * Subject to which the roles update operations are bound to in order to
   * control request frequency (debounce). The update stream is configured to
   * wait a full second before sending the roles update. Since it is triggered
   * on each value change, this measure allows buffering of multiple changes
   * before soliciting the API.
   * @see ngOnInit for the update stream configuration.
   * @see updateRolesList for the update stream triggering mechanism.
   */
  private updateStream: Subject<any> = new Subject<any>();

  /**
   * Empty subscription to wrap any subscription within the component. This
   * subscription (and its children) is closed on component destroyal to free
   * resources.
   * @see ngOnDestroy
   */
  private subscriptions: Subscription = new Subscription();

  /**
   * The roles matrix as retrieved from the API.
   */
  private rolesMatrix: IRolesMatrix = {};

  /**
   * An dictionary of empty sets, extracted from the enum Permissions. Used as a
   * skeleton when parsing roles matrices.
   * @see ngOnInit
   * @see parseRolesMatrix
   */
  private defaultPermissionsSet: { [key: string]: Set<string> } = {};

  /**
   * The base path for permissions translation keys. It should not include the
   * final dot; keys resolve to `translationKey + '.' + `roleKey`.
   */
  private translationKey = 'PERMISSIONS';
  private updatedRoleList: any;
  public hasPermissionImport: any;
  private feature: string;
  private role: string;

  constructor(
    private title: TitleService,
    private roles: RolesService,
    private router: Router,
    public loading: LoadingService,
    private snackbar: MatSnackBar,
    private translate: TranslateService,
    private rolesService: RolesService,
    private dialog: MatDialog
  ) {
    this.title.setTitle('GLOBAL.BREADCRUMB.ROLES');
  }

  /**
   * Retrieve roles list and configure the roles update stream.
   */
  ngOnInit(): void {
    this.hasPermissionImport = this.rolesService.checkPermission(Permissions.BACK_OFFICE_PERMISSIONS);
    if (this.hasPermissionImport) {
      this.getRolesList();
    } else {
      this.router.navigateByUrl('/repositories');
      const t = 'ROLES.ERRORS.REDIRECTION_FORCED';
      this.translate
        .get(t)
        .subscribe((el: string) => this.snackbar.open(el, this.translate.instant('GLOBAL.CLOSE_LABEL')));
    }

    // Initialize empty premissions set for each role (used when parsing)
    // Note: Permissions is an enumeration, meaning its keys are bidirectional;
    // thus do we need to filter out keys that does not match a number
    Object.keys(Permissions)
      .filter((key: string | number) => typeof key === 'number')
      .forEach((permission: string) => {
        this.defaultPermissionsSet[permission] = new Set<string>();
      });

    // Subscribe to the update stream
    // Note: the update stream is used to update roles with a debounce value;
    // this allows to limit the number of requests sent to the API, without
    // impairing the end user with frustrating delays
    this.subscriptions.add(
      this.updateStream.pipe(debounceTime(1000)).subscribe((roles) => this.sendRolesUpdate(roles))
    );
  }

  /**
   * Close all subscriptions.
   */
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /**
   * Retrieve all roles and their associated permissions.
   */
  getRolesList(): void {
    this.loaded = false;
    this.loading.get('get_roles').on(100);
    this.subscriptions.add(
      this.roles
        .getAll()
        .pipe(finalize(() => this.loading.get('get_roles').off()))
        .subscribe((matrix) => {
          this.parseRolesMatrix(matrix);
          this.loaded = true;
        })
    );
  }

  /**
   * Intercept the generic list component events and request a roles update to
   * the update stream.
   *
   * @see sendRolesUpdate for the actual API call.
   * @param event Event datas retrieved from the generic list component.
   */
  updateRolesList(event: { type: string; data: any }): void {
    // Ignore invalid, empty and malformed events
    if (!event.type || !event.data || event.type.slice(0, 7) !== 'toggle_') {
      return;
    }

    // Ignore updates on the special "ADM" role
    const role: string = event.type.slice(7);
    const feature: string = event.data.permission_name.replace(new RegExp(`^${this.translationKey}\\.`), '');
    if (role === 'ADM') {
      return;
    }

    this.role = role;
    this.feature = feature;

    // Is atleast one of the mobile permissions already checked
    const mobileFeatures: string[] = Object.values(Permissions).filter((feature) => feature.match('APP_MOBILE'));
    const isThereMobileFeatures: boolean = this.rolesMatrix[role].some((feature) => mobileFeatures.includes(feature));

    // If the permission checked is BACK_OFFICE_OPERATOR and atleast one of the mobile permissions is already checked
    // we ask for confirmation
    if (
      this.feature === Permissions.BACK_OFFICE_OPERATOR &&
      !this.rolesMatrix[this.role].includes(this.feature) &&
      isThereMobileFeatures
    ) {
      const mobileFeaturesChecked: string[] = this.rolesMatrix[role].filter((permission) =>
        permission.match('APP_MOBILE')
      );
      this.openDialog('CONFIRM.DEACTIVATE_MOBILE', mobileFeaturesChecked);
      return;
    } else if (
      // If the permission checked is one of the mobile permissions and the BACK_OFFICE_OPERATOR permission is already checked
      // we ask for confirmation
      mobileFeatures.includes(this.feature) &&
      !this.rolesMatrix[this.role].includes(this.feature) &&
      this.rolesMatrix[this.role].includes(Permissions.BACK_OFFICE_OPERATOR)
    ) {
      this.openDialog('CONFIRM.DEACTIVATE_OPERATOR', [Permissions.BACK_OFFICE_OPERATOR]);
      return;
    }

    this.toggleFeature(feature);
  }

  private toggleFeature(feature: string) {
    // Toggle the feature on or off in the role permissions
    const newRoles: any = _.cloneDeep(this.rolesMatrix);
    newRoles[this.role] = new Set(newRoles[this.role]);

    const oldValue = newRoles[this.role].has(feature);
    const newValue = !oldValue;

    if (oldValue) {
      newRoles[this.role].delete(feature);
    } else {
      newRoles[this.role].add(feature);
    }

    newRoles[this.role] = Array.from(newRoles[this.role]);

    // Save new roles temporarily
    // Note: the update request will automatically update this once it is done;
    // however, a debounce time being in place on the request, this will ensure
    // that all updates are taken into accounts in the last emitted value.
    this.rolesMatrix = _.cloneDeep(newRoles);

    // Remove the "ADM" role field to comply with the API requirements
    delete newRoles.ADM;

    this.updatedRoleList = newRoles;

    this.toggleCheckBox(feature, newValue);
  }

  toggleCheckBox(feature: string, check: boolean) {
    this.rolesByPermissionListBackoffice = this.toggleArray(this.rolesByPermissionListBackoffice, feature, check);
    this.rolesByPermissionListAppMobile = this.toggleArray(this.rolesByPermissionListAppMobile, feature, check);
  }

  toggleArray(rolesPermissionsArray: PermissionsList, feature: string, check: boolean): any[] {
    const rolesPermissionsArrayClone = _.cloneDeep(rolesPermissionsArray);
    let foundFeature = rolesPermissionsArrayClone.find(
      (a) => a.permission_name.replace('PERMISSIONS.', '') === feature
    );
    if (foundFeature) {
      foundFeature[this.role] = check;
    }
    return rolesPermissionsArrayClone;
  }

  sendNewRolesList() {
    if (this.updatedRoleList) {
      this.updateStream.next(this.updatedRoleList);
    } else {
      this.snackbar.open(
        this.translate.instant('ROLES.NONE_CHANGE_UPDATE'),
        this.translate.instant('GLOBAL.CLOSE_LABEL')
      );
    }
  }

  cancelNewRoles() {
    this.getRolesList();
    this.router.navigate([`repositories`]);
  }

  /**
   * Send a new roles matrix to the API for replacement.
   *
   * This method is automatically invoked by the update stream when enough time
   * has elapsed before the
   *
   * @param replacementMatrix The updated roles matrix.
   */
  private sendRolesUpdate(replacementMatrix: IRolesMatrix): void {
    this.loading.get('replace_roles').on(200);

    // Send the updated roles to the API
    this.subscriptions.add(
      this.roles
        .replace(replacementMatrix)
        .pipe(
          finalize(() => {
            // Reload the roles list, even if the actual request failed to
            // ensure view consistency
            this.loading.get('replace_roles').off();
            this.getRolesList();
          })
        )
        .subscribe(() => {
          this.snackbar.open(
            this.translate.instant('ROLES.UPDATE_SUCCESS_SNACKBAR'),
            this.translate.instant('GLOBAL.CLOSE_LABEL')
          );
          this.router.navigate([`repositories`]);
        })
    );
  }

  /**
   * Parse a roles matrix and populate the displayable roles list for the view.
   *
   * @param originalMatrix The roles matrix as retrieved from the API.
   */
  private parseRolesMatrix(originalMatrix: IRolesMatrix): void {
    // Check pour supression des champs created_at / updated_at
    Object.keys(originalMatrix).forEach((key: string) => {
      if (!Array.isArray(originalMatrix[key])) {
        delete originalMatrix[key];
      }
    });
    this.rolesMatrix = originalMatrix;
    this.listParameters.listHead = [
      {
        key: 'permission_name',
        title: 'ROLES.FEATURE',
        type: 'text',
        options: {
          sortable: false
        }
      }
    ];

    const rolesByPermission: { [key: string]: Set<string> } = _.cloneDeep(this.defaultPermissionsSet);

    const loaders = [this.loading.get('replace'), this.loading.get('get_roles')];
    Object.keys(originalMatrix).forEach((role: string) => {
      // Create a column header for the current role
      this.listParameters.listHead.push({
        key: role,
        type: 'checkbox',
        title: `${role.toUpperCase()}`,
        colWidth: '70px',
        events: {
          change: `toggle_${role}`
        },
        options: {
          sortable: false,
          disabled() {
            return role === 'ADM' || role === 'SUPER_ADMIN' || loaders[0].enabled || loaders[1].enabled;
          }
        }
      });

      // Add the current role into its associated features sets
      originalMatrix[role].forEach((permission: string) => {
        if (!rolesByPermission.hasOwnProperty(permission)) {
          rolesByPermission[permission] = new Set<string>();
        }

        rolesByPermission[permission].add(role);
      });
    });

    // Project roles and their features sets as a display-ready array
    this.rolesByPermissionList = Object.keys(rolesByPermission)
      .sort((a: string, b: string) => a.localeCompare(b))
      .map((permission: string) => {
        console.log(permission);
        const permissionEntry: { permission_name: string; [key: string]: any } = {
          permission_name: `${this.translationKey}.${permission}`,
          disabled:
            permission == Permissions.BACK_OFFICE_CREATE_SUPER_ADMIN ||
            permission == Permissions.BACK_OFFICE_SUPER_ADMIN
        };

        rolesByPermission[permission].forEach((role: string) => {
          // Enable checkboxes (= TRUE) of roles with the current permission
          permissionEntry[role] = true;
        });

        return permissionEntry;
      });
    this.rolesByPermissionListBackoffice = this.rolesByPermissionList.filter((permission: any) =>
      permission.permission_name.match('BACK_OFFICE')
    );
    this.rolesByPermissionListAppMobile = this.rolesByPermissionList.filter((permission: any) =>
      permission.permission_name.match('APP_MOBILE')
    );
  }

  openDialog(text: string, featuresToUncheck: string[]): void {
    this.dialogRef = this.dialog.open(GenericDialogComponent, {
      width: '50%',
      data: { structure: this.confirmStructure(text, featuresToUncheck) },
      disableClose: true
    });
  }

  confirmStructure(text: string, featuresToUncheck: string[]) {
    return {
      title: 'GLOBAL.CONFIRMATION',
      text: text,
      buttons: [
        {
          text: 'GLOBAL.CONFIRM',
          class: 'validation',
          isRaisedButton: true,
          action: {
            target: 'custom',
            params: {
              function: () => {
                for (const feature of featuresToUncheck) {
                  this.toggleFeature(feature);
                }
                this.toggleFeature(this.feature);
                this.dialog.closeAll();
              }
            }
          }
        },
        {
          text: 'GLOBAL.CANCEL',
          class: 'cancel',
          action: {
            target: 'generic',
            params: {
              function: () => {
                this.toggleCheckBox(this.feature, false);
                this.dialog.closeAll();
              }
            }
          }
        }
      ]
    };
  }
}
