import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IReporting } from 'app/interface/report.interface';
import { ReportsService } from 'app/services/reports.service';
import { lastValueFrom } from 'rxjs';

import { ReportingFormComponent } from '../reporting-form.component';

@Component({
  standalone: true,
  selector: 'app-edit-reporting-form',
  templateUrl: './edit-reporting-form.component.html',
  imports: [CommonModule, ReportingFormComponent]
})
export class EditReportingFormComponent implements OnInit {
  private reportingId: string;
  public reporting: IReporting; // Current Reporting

  constructor(
    private reportingService: ReportsService,
    route: ActivatedRoute
  ) {
    route.params.subscribe((params) => (this.reportingId = params.id));
  }

  ngOnInit() {
    this.loadReporting();
  }

  private async loadReporting(): Promise<void> {
    const reporting = await lastValueFrom(this.reportingService.getOne(this.reportingId));
    this.reporting = reporting;
  }
}
