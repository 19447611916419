import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import _ from 'lodash';
import { lastValueFrom } from 'rxjs';

import { ExpiredDrivingLogsListComponent } from './components/driving-logs/expired-list/expired-driving-logs-list.component';
import { SessionService } from './components/generic/services/session/session.service';
import { Permissions } from './interface/permissions.enum';
import { ITeam } from './interface/team.interface';
import { DrivingLogsService, IDrivingLogsResponse } from './services/driving-logs.service';
import { ProfileService } from './services/profile.service';
import { RolesService } from './services/roles.service';
import { TitleService } from './services/title.service';
import { UsersService } from './services/users.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public loggedin: { [key: string]: any } | null = null;
  public profileCode: string | null = null;

  constructor(
    private title: TitleService,
    private session: SessionService,
    private rolesService: RolesService,
    private users: UsersService,
    private drivingLogs: DrivingLogsService,
    private dialog: MatDialog,
    private profileService: ProfileService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  private handleSessionChange(): void {
    this.updateLoginStatus();
    this.deferExpiredDrivingLogsDialogOnNextLoad();
  }

  ngOnInit() {
    this.profileService.profileCode.subscribe((value) => {
      this.profileCode = value;
      this.changeDetectorRef.detectChanges();
    });
    this.handleSessionChange();
    this.session.changes.subscribe(() => this.handleSessionChange());

    const lastLogin = localStorage.getItem('last_login');
    if (lastLogin) {
      localStorage.removeItem('last_login');
      this.openExpiredDrivingLogsDialog();
    }
  }

  updateLoginStatus() {
    if (this.session.isTokenValid() === true) {
      this.title.setTitle('GLOBAL.BREADCRUMB.HOME');
      this.loggedin = this.session.getDecoded();
    } else if (localStorage.getItem('current_error')) {
      this.title.setTitle('GLOBAL.LOGIN_PAGE_TITLE');
      this.loggedin = null;
    }
  }

  deferExpiredDrivingLogsDialogOnNextLoad() {
    const lastLogin = localStorage.getItem('last_login');
    if (!lastLogin && this.session.isTokenValid()) {
      localStorage.setItem('last_login', new Date().toISOString());
    }
  }

  public async openExpiredDrivingLogsDialog(): Promise<void> {
    let userTeams: ITeam[];
    try {
      userTeams = JSON.parse(localStorage.getItem('teams') || '[]');
    } catch (err) {
      localStorage.setItem('teams', '[]');
      userTeams = [];
    }

    const hasTeams = !!userTeams.length;
    const hasPermission = this.rolesService.checkPermission(Permissions.BACK_OFFICE_TEAMS_DRIVING_LOGS);
    if (!hasTeams || !hasPermission) {
      return;
    }

    const userTeamsId = userTeams.map((team) => team.id);

    const [drivingLogs] = await Promise.all([
      this.drivingLogs.getExpiredFromTeams(userTeamsId),
      lastValueFrom(this.users.getAll({ teams: userTeamsId }))
    ]);

    if (!drivingLogs.total) {
      return;
    }

    this.dialog.open<ExpiredDrivingLogsListComponent, IDrivingLogsResponse>(ExpiredDrivingLogsListComponent, {
      width: '50%',
      data: _.cloneDeep(drivingLogs),
      disableClose: true
    });
  }
}
