import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { GenericDialogComponent } from './generic-dialog.component';

export function openGenericDeleteDialog(
  dialog: MatDialog,
  dialogText: string,
  deleteFn: () => void
): MatDialogRef<GenericDialogComponent> {
  const dialogStructure = {
    title: 'GLOBAL.CONFIRMATION',
    text: dialogText,
    buttons: [
      {
        text: 'GLOBAL.DELETE',
        class: 'validation',
        isRaisedButton: true,
        action: {
          target: 'custom',
          params: {
            id: 'delete',
            function() {
              deleteFn();
              dialog.closeAll();
            }
          }
        }
      },
      {
        text: 'GLOBAL.CANCEL',
        class: 'cancel',
        action: {
          target: 'generic',
          params: {
            id: 'close',
            function: undefined
          }
        }
      }
    ]
  };
  return dialog.open(GenericDialogComponent, {
    width: '50%',
    data: { structure: dialogStructure },
    disableClose: true
  });
}
