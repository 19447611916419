import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GenericDialogComponent } from 'app/components/generic/generic-dialog/generic-dialog.component';
import { IGenericListParameters } from 'app/components/generic/list/list.interface';
import {
  IReporting,
  IReportingStateInformations,
  ReportingState,
  ReportingSubtype,
  ReportingType,
  ReportingTypePriority,
  reportStates
} from 'app/interface/report.interface';
import { HighwaysService } from 'app/services/highways.service';
import { IconService } from 'app/services/icon.service';
import { ReportsService } from 'app/services/reports.service';
import { SectorsService } from 'app/services/sectors.service';
import { TitleService } from 'app/services/title.service';
import _ from 'lodash';
import { combineLatestWith } from 'rxjs';
import { IHighway } from 'src/app/interface/highway.interface';
import { ISector } from 'src/app/interface/sector.interface';

export interface TasksFilters {
  mode: 'primary' | 'secondary';
  sectors: ISector[];
  types: {
    primary: string[];
    secondary: string[];
  };
  subtypes: string[];
  sectorsHighways: IHighway[];
  states: string[];
  direction: number[];
  startPr: string;
  endPr: string;
  dates: {
    from: Date;
    to: Date;
  };
}

interface FormattedFilters {
  sector_id?: string;
  type?: string;
  sub_type?: string;
  state?: string[];
  q?: string;
  highway_name?: string;
  start?: Date;
  start_pr?: string;
  end_pr?: string;
  end?: Date;
  direction?: number[];
}

type TaskItem<T = any> = Omit<
  IReporting<T>,
  'icon' | 'type' | 'subtype' | 'affected_to' | 'state' | 'actions' | 'original'
> & {
  icon: string;
  type: string;
  subtype: string;
  affected_to: string;
  state: string;
  actions: string;
  original: IReporting<T>;
};

const STORAGE_LABEL = 'tasks-filters';

@Component({
  selector: 'app-repositories',
  templateUrl: './tasks.list.component.html',
  styleUrls: ['./tasks.list.component.scss']
})
export class TasksListComponent implements OnInit {
  listParameters: IGenericListParameters = {
    paginator: true,
    clickable: true,
    listHead: [
      {
        key: 'icon',
        title: '',
        type: 'imageSrc'
      },
      {
        key: 'type',
        title: 'GLOBAL.TYPE',
        type: 'text'
      },
      {
        key: 'subtype',
        title: 'TASKS.SUB_TYPE',
        type: 'text'
      },
      {
        key: 'creation_date',
        title: 'TASKS.CREATION_DATE',
        type: 'date',
        options: { format: 'shortDate' }
      },
      {
        key: 'made_date',
        title: 'TASKS.MADE_DATE',
        type: 'date',
        options: { format: 'shortDate' }
      },
      {
        key: 'due_date',
        title: 'TASKS.DUE_DATE',
        type: 'date',
        options: { format: 'shortDate' },
        visible: false
      },
      {
        key: 'affected_to',
        title: 'TASKS.AFFECTATION',
        type: 'text'
      },
      {
        key: 'state',
        title: 'GLOBAL.STATUS',
        type: 'text'
      },
      {
        key: 'actions',
        type: 'button',
        colWidth: '80px'
      }
    ]
  };
  loader = true;
  constructor(
    private title: TitleService,
    private reportingService: ReportsService,
    private translate: TranslateService,
    private router: Router,
    private http: HttpClient,
    public dialog: MatDialog,
    private iconService: IconService,
    private sectorService: SectorsService,
    private highwayService: HighwaysService,
    private formBuilder: UntypedFormBuilder
  ) {
    this.title.setTitle('TASKS.LABEL');
    this.iconService.register('unknown', '/assets/icon/reportings/rounded/inconnu.svg');
  }

  public filtersForm: UntypedFormGroup;
  public sectorsList: ISector[];
  public highwayList: IHighway[];
  public reportingTypes: ReportingType[];
  public reportingSubTypes: ReportingSubtype[];
  public availableSubTypes: ReportingSubtype[];

  public loading = true; // Tasks list loading flag
  public filtersLoading = true; // flag for initial filters data loading
  public items: TaskItem[] = [];
  public total = 0;
  public dialogRef?: MatDialogRef<GenericDialogComponent>;
  public searchWord = ''; // Search data
  public startDate: Date;
  public endDate: Date;
  public reportStates: IReportingStateInformations[] = reportStates;

  get getDirection() {
    return [1, 2];
  }

  get storage(): TasksFilters {
    const data = localStorage.getItem(STORAGE_LABEL);

    return data
      ? JSON.parse(data)
      : {
          mode: 'primary',
          sectors: [],
          sectorsHighways: [],
          direction: [],
          startPr: '',
          endPr: '',
          status: [],
          types: {
            primary: [],
            secondary: []
          },
          subtypes: {
            primary: [],
            secondary: []
          }
        };
  }

  set storage(data: TasksFilters) {
    localStorage.setItem(STORAGE_LABEL, JSON.stringify(data));
  }

  ngOnInit() {
    this.filtersForm = this.formBuilder.group({
      mode: 'primary',
      sectors: '',
      sectorsHighways: '',
      direction: '',
      states: '',
      startPr: ['', Validators.pattern(/^[0-9]+[.,+][0-9]{3}$/)],
      endPr: ['', Validators.pattern(/^[0-9]+[.,+][0-9]{3}$/)],
      types: '',
      subtypes: '',
      from: this.storage.dates ? (this.storage.dates.from ? this.storage.dates.from : '') : '',
      to: this.storage.dates ? (this.storage.dates.to ? this.storage.dates.to : '') : '',
      search: ''
    });
    this.filtersLoading = true;

    // Load Highways
    const sectorsHighways = localStorage.getItem('sectorsHighways');
    const areHighwaysInLocalStorage = !!(sectorsHighways && JSON.parse(sectorsHighways).length);
    if (areHighwaysInLocalStorage) {
      this.highwayList = JSON.parse(sectorsHighways);
    } else {
      this.filtersForm.get('sectors')?.valueChanges.subscribe((value) => {
        if (value.length > 0) {
          this.highwayService.getHighwaysBySectors({ sector_id: value }).subscribe((res) => {
            this.highwayList = res.items;
            this.filtersForm.get('sectorsHighways')?.enable();
          });
        } else {
          this.highwayList = [];
          this.filtersForm.get('sectorsHighways')?.disable();
          this.filtersForm.get('sectorsHighways')?.setValue([]);
        }
      });
    }

    this.filtersForm.get('sectorsHighways')?.valueChanges.subscribe((value) => {
      if (value.length === 0) {
        const directionForm = this.filtersForm.get('direction');
        directionForm?.disable();
        directionForm?.setValue([]);
      } else {
        this.filtersForm.get('direction')?.enable();
      }
    });

    this.filtersForm.get('types')?.valueChanges.subscribe((value) => {
      const subtypeForm = this.filtersForm.get('subtypes');
      if (value.length === 0) {
        subtypeForm?.disable();
        subtypeForm?.setValue([]);
      } else {
        const selectedTypes = this.reportingTypes.filter((type) => value.includes(type.id));
        this.availableSubTypes = selectedTypes
          .flatMap((type) => type.subtypes)
          .sort((a, b) => a.label!.localeCompare(b.label!));
        if (this.availableSubTypes.length > 0) {
          subtypeForm?.enable();
        } else {
          subtypeForm?.disable();
          subtypeForm?.setValue([]);
        }
      }
    });

    this.sectorService
      .getAll()
      .pipe(
        combineLatestWith(
          this.reportingService.getReportingTypesObservable(),
          this.reportingService.getReportingSubTypesObservable()
        )
      )
      .subscribe((res) => {
        const [sectors, types, subtypes] = res;
        this.sectorsList = _.sortBy(sectors.items, (e) => e.name) as any;
        this.reportingTypes = types;
        this.reportingSubTypes = subtypes;
        this._setMode();
        this._setSectors();
        this._setTypes();
        this._setSubTypes();
        this._setStates();
        this._setSectorsHighways();
        this._setSens();
        this._setStartPr();
        this._setEndPr();
        this.filtersLoading = false;
        this._loadTasks();
        this.dueDateColumn();
      });
  }

  public dueDateColumn() {
    const mode = this.filtersForm.get('mode')!.value;
    const dueDateIndex = this.listParameters.listHead.findIndex((el) => el.key === 'due_date');
    if (mode === 'secondary') this.listParameters.listHead[dueDateIndex].visible = true;
    else if (mode === 'primary') this.listParameters.listHead[dueDateIndex].visible = false;
    this.listParameters.listHead[dueDateIndex].visible = false;
  }

  public toggleMode(event: any) {
    this.filtersForm.get('mode')!.setValue(event.checked ? 'secondary' : 'primary');
    this.filtersForm.get('types')!.setValue(this.storage.types[event.checked ? 'secondary' : 'primary']);
    this.dueDateColumn();
    this.updateFilters();
  }

  public search(event: any) {
    if (event.type === 'search') {
      this.searchWord = event.data.search;
      this.updateFilters();
    }
  }

  public handleAction(event: any) {
    if (event.type && event.data) {
      switch (event.type) {
        case 'finalize':
          this._openFinalize(event.data);
          break;
        case 'edit':
          this._goToEdit(event.data);
          break;
        case 'delete':
          this.dialogRef = this.dialog.open(GenericDialogComponent, {
            width: '50%',
            data: { structure: this._deleteTaskStructure(event.data.original._id) },
            disableClose: true
          });
          break;
        case 'rowClick':
          this._openDetails(event.data);
          break;
      }
    }
  }

  public downloadReporting() {
    this.reportingService.downloadCSV(this._formatFilters(), false);
  }

  private _deleteTaskStructure(_id: string) {
    return {
      title: 'GLOBAL.CONFIRMATION',
      text: 'CONFIRM.DELETE_TASK',
      buttons: [
        {
          text: 'GLOBAL.DELETE',
          class: 'validation',
          isRaisedButton: true,
          action: {
            target: 'custom',
            params: {
              id: 'delete',
              function: () => {
                this._deleteTask(_id);
              }
            }
          }
        },
        {
          text: 'GLOBAL.CANCEL',
          class: 'cancel',
          action: {
            target: 'generic',
            params: {
              id: 'close',
              function: undefined
            }
          }
        }
      ]
    };
  }

  public addTask() {
    this.router.navigateByUrl(`tasks/new`);
  }

  private _deleteTask(_id: string) {
    this.http.delete(`api://reportings/${_id}`).subscribe(() => this._loadTasks());
    this.dialog.closeAll();
  }

  private _openFinalize(reporting: any) {
    this.dialog.open(GenericDialogComponent, {
      width: '50%',
      data: {
        structure: {
          title: 'GLOBAL.CONFIRMATION',
          text: 'CONFIRM.FINALIZE_TASK',
          buttons: [
            {
              text: 'GLOBAL.FINALIZE',
              class: 'validation',
              isRaisedButton: true,
              action: {
                target: 'custom',
                params: {
                  id: 'finalize',
                  function: () => this._finalizeTask(reporting)
                }
              }
            },
            {
              text: 'GLOBAL.CANCEL',
              class: 'cancel',
              action: {
                target: 'generic',
                params: {
                  id: 'close',
                  function: undefined
                }
              }
            }
          ]
        }
      },
      disableClose: true
    });
  }

  private _goToEdit(taskItem: TaskItem) {
    this.router.navigate([`tasks/${taskItem.original._id}/edit`]);
  }

  private async _finalizeTask(taskItem: TaskItem): Promise<void> {
    await this.reportingService.finalize(taskItem.original._id!);
    this._loadTasks();
    this.dialog.closeAll();
  }

  private _openDetails(taskItem: TaskItem) {
    this.router.navigate([`tasks/${taskItem.original._id}/details`]);
  }

  private _setMode() {
    const mode = this.storage.mode ? this.storage.mode : 'primary';
    this.filtersForm.get('mode')!.setValue(mode);
  }

  private _setSectors() {
    const sectors = this.storage.sectors.length > 0 ? this.storage.sectors : [];
    this.filtersForm.get('sectors')!.setValue(sectors);
  }

  private _setTypes() {
    const types = this.storage.types[this.storage.mode] ? this.storage.types[this.storage.mode] : [];
    this.filtersForm.get('types')!.setValue(types);
  }

  private _setStates() {
    const states = this.storage.states ? this.storage.states : [];
    this.filtersForm.get('states')!.setValue(states);
  }

  private _setSubTypes() {
    const subtypes = this.storage.subtypes ? this.storage.subtypes : [];
    this.filtersForm.get('subtypes')!.setValue(subtypes);
  }

  private _setSectorsHighways() {
    const states = this.storage.sectorsHighways ? this.storage.sectorsHighways : [];
    this.filtersForm.get('sectorsHighways')!.setValue(states);
  }

  private _setSens() {
    const direction = this.storage.direction ? this.storage.direction : [];
    this.filtersForm.get('direction')!.setValue(direction);
  }

  private _setStartPr() {
    const startPr = this.storage.startPr ? this.storage.startPr : '';
    this.filtersForm.get('startPr')!.setValue(startPr);
  }

  private _setEndPr() {
    const endPr = this.storage.endPr ? this.storage.endPr : '';
    this.filtersForm.get('endPr')!.setValue(endPr);
  }

  private isPrDisable() {
    return this.filtersForm.get('direction')!.value.length;
  }

  private _formatFilters(): any {
    const filters: FormattedFilters = {};
    if (this.storage.sectors && this.storage.sectors.length) {
      filters.sector_id = this.storage.sectors.join(',');
    } else {
      return false;
    }

    if (this.storage.types && this.storage.types[this.storage.mode].length) {
      filters.type = this.storage.types[this.storage.mode].join(',');
    } else {
      return false;
    }

    if (this.storage.subtypes.length > 0) {
      filters.sub_type = this.storage.subtypes.join(',');
    }

    if (this.storage.states.length === 0) {
      return false;
    }

    filters.state = this.storage.states;

    if (this.searchWord !== '') {
      filters.q = this.searchWord;
    }

    if (this.storage.sectorsHighways && this.storage.sectorsHighways.length) {
      filters.highway_name = this.storage.sectorsHighways.join(',');
    }

    if (this.storage.dates.from) {
      filters.start = this.storage.dates.from;
    }

    if (this.storage.startPr) {
      if (/^[0-9]+[.,+][0-9]{3}$/.test(this.storage.startPr)) {
        filters.start_pr = encodeURIComponent(this.storage.startPr);
      } else {
        return false;
      }
    }

    if (this.storage.endPr) {
      if (/^[0-9]+[.,+][0-9]{3}$/.test(this.storage.endPr)) {
        filters.end_pr = encodeURIComponent(this.storage.endPr);
      } else {
        return false;
      }
    }

    if (this.storage.dates.to) {
      filters.end = this.storage.dates.to;
    }

    if (this.storage.direction && this.storage.direction.length) {
      filters.direction = this.storage.direction;
    }

    return filters;
  }

  /* Tasks loading methods ----------------------------------------------------------- */

  public updateFilters(event?: boolean): void {
    if (event !== undefined && event) {
      return;
    }

    this.isPrDisable();

    this.buildStorage();

    this._loadTasks();
  }

  private buildStorage() {
    const buildedStorage: TasksFilters = {
      sectors: this.filtersForm.get('sectors')!.value,
      states: this.filtersForm.get('states')!.value,
      sectorsHighways: this.filtersForm.get('sectorsHighways')!.value,
      direction: this.filtersForm.get('direction')!.value,
      startPr: this.filtersForm.get('startPr')!.value,
      endPr: this.filtersForm.get('endPr')!.value,
      types: { ...this.storage.types },
      subtypes: this.filtersForm.get('subtypes')!.value,
      mode: this.filtersForm.get('mode')!.value,
      dates: {
        from: this.filtersForm.get('from')!.value,
        to: this.filtersForm.get('to')!.value
      }
    };
    buildedStorage.types[buildedStorage.mode] = this.filtersForm.get('types')!.value;
    this.storage = buildedStorage;
  }

  private _loadTasks() {
    this.loading = true;
    const filters = this._formatFilters();
    if (!filters) {
      this.items = [];
      this.total = 0;
      this.loading = false;

      return;
    }
    this.reportingService.getAll(filters, false).subscribe((result) => {
      this.items = result.items.map(
        (reporting) =>
          ({
            icon: reporting.type.picto,
            type: reporting.type.label,
            subtype: reporting.subtype?.label,
            affected_to: this._getAffectedTo(reporting),
            state: this._getStateName(reporting.state),
            actions: this._getActions(reporting) as any,
            original: reporting,
            creation_date: reporting.created_at,
            made_date: reporting.made_date
          }) as TaskItem
      );
      this.total = result.total;
      this.loading = false;
    });
  }
  /* ------------------------------------------------------------------------------------------ */

  private _getAffectedTo(task: any): string {
    if (task.affected_to) {
      let firstName = '';
      let lastName = '';
      if (task.affected_to) {
        if (task.affected_to.first_name) {
          firstName = task.affected_to.first_name;
        }
        if (task.affected_to.last_name) {
          lastName = task.affected_to.last_name;
        }
      }

      return `${firstName} ${lastName}`;
    } else if (task.teams && task.teams.length > 0) {
      return task.teams[0].name;
    } else {
      return '';
    }
  }

  private _getStateName(state: ReportingState): string {
    return this.translate.instant(`REPORTING.STATES.${state}`);
  }

  private _getActions(task: IReporting) {
    const editButton = {
      type: 'button',
      style: { color: 'warn' },
      events: { click: 'edit' },
      options: { icon: 'edit' },
      tooltip: 'GLOBAL.EDIT'
    };

    const finalizeButton = {
      type: 'button',
      style: { color: 'warn' },
      events: { click: 'finalize' },
      options: { icon: 'done' },
      tooltip: 'GLOBAL.FINALIZE'
    };

    const deleteButton = {
      type: 'button',
      style: { color: 'warn' },
      events: { click: 'delete' },
      options: { icon: 'delete' },
      tooltip: 'GLOBAL.DELETE'
    };
    const actions = [];
    if (!task.type.critical && ![ReportingState.DONE, ReportingState.RAS, ReportingState.CLOSED].includes(task.state)) {
      actions.push(finalizeButton);
      actions.push(editButton);
      actions.push(deleteButton);
    }

    return actions;
  }
  /* Task object formater methods end -------------------------------------------------------- */

  public getFilteredTypes() {
    if (this.storage.mode === 'primary') {
      return this.reportingTypes.filter((e) =>
        [ReportingTypePriority.RED, ReportingTypePriority.ORANGE].includes(e.priority)
      );
    } else {
      return this.reportingTypes.filter((e) => e.priority === ReportingTypePriority.BLUE);
    }
  }

  public getOptionalTypeLabel(subTypeLabel: string, typeId: string) {
    const isDuplicateLabel: boolean =
      this.availableSubTypes.filter((subtype) => subtype.label === subTypeLabel).length > 1;
    if (!isDuplicateLabel) {
      return;
    }
    return `(${this.reportingTypes.find((type) => type.id === typeId)?.label})`;
  }

  public toggleList(list: 'sectors' | 'states' | 'types' | 'sectorsHighways' | 'direction' | 'subtypes') {
    switch (list) {
      case 'sectors':
        if (this.filtersForm.get('sectors')!.value.length) {
          this.filtersForm.get('sectors')!.setValue([]);
        } else {
          this.filtersForm.get('sectors')!.setValue(this.sectorsList.map((s: any) => s.id));
        }
        break;
      case 'states':
        if (this.filtersForm.get('states')!.value.length) {
          this.filtersForm.get('states')!.setValue([]);
        } else {
          this.filtersForm.get('states')!.setValue(reportStates.map((e) => e.id));
        }
        break;
      case 'subtypes':
        if (this.filtersForm.get('subtypes')!.value.length) {
          this.filtersForm.get('subtypes')!.setValue([]);
        } else {
          this.filtersForm.get('subtypes')!.setValue(this.reportingSubTypes.map((e) => e.id));
        }
        break;
      case 'types':
        if (this.filtersForm.get('types')!.value.length) {
          this.filtersForm.get('types')!.setValue([]);
        } else {
          this.filtersForm.get('types')!.setValue(this.getFilteredTypes().map((s: any) => s.id));
        }
        break;
      case 'sectorsHighways':
        if (this.filtersForm.get('sectorsHighways')!.value.length) {
          this.filtersForm.get('sectorsHighways')!.setValue([]);
        } else {
          this.filtersForm.get('sectorsHighways')!.setValue(this.highwayList.map((s: any) => s.UID));
        }
        break;
      case 'direction':
        if (this.filtersForm.get('direction')!.value.length) {
          this.filtersForm.get('direction')!.setValue([]);
        } else {
          this.filtersForm.get('direction')!.setValue(this.getDirection);
        }
        break;
    }
  }
}
