<div class="block w-full min-h-100vh profile-code-bg">
  <div class="flex w-full min-h-100vh justify-center items-center">
    <div class="profile-code-form-modal">
      <div class="login-logo">
        <img src="{{ srcLogo }}" />
      </div>
      <form [formGroup]="codeForm" (ngSubmit)="onSubmit()">
        <input
          name="profileCode"
          formControlName="profileCode"
          placeholder="{{ 'PROFILES.PROFILE_CODE_PLACEHOLDER' | translate }}"
          autofocus
          autocomplete="off"
        />
        <button mat-raised-button type="submit">{{ 'GLOBAL.SUBMIT_BUTTON_LABEL' | translate }}</button>
      </form>
    </div>
  </div>
</div>
