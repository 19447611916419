<div class="user-list-dialog">
  <div class="user-list" *ngFor="let user of usersList">
    <span><i class="material-icons">account_circle</i><b> {{user.first_name}} {{user.last_name}}</b></span>
    <span>
      <br><span>{{user.service_number}}</span>
      <br><span translate>GLOBAL.ROLES</span><span> : {{user.role}}</span>
    </span>
  </div>
</div>
<div class="center-close-list">
  <button mat-raised-button (click)="closeDialog()" translate>GLOBAL.CLOSE_LABEL</button>
</div>
