<div class="damages-card">
  <div class="title">{{'REPORTING.DAMAGES_CARD.TITLE' | translate}}</div>
  <div *ngIf="damagesView" class="damages-view">
    <mat-accordion displayMode="flat" [multi]="false">
      <ng-container *ngFor="let section of damagesView; let index = index">
        <!-- Form only -->
        <div *ngIf="!section.items" (click)="openDamagesFormDialog(section.form)" class="damages-section-header">
          <div class="damages-section-header-title" [ngClass]="{'grey': isEmpty(section) }">
            <mat-icon class="damages-section-header-title-icon" [svgIcon]="section.icon"></mat-icon>
            {{section.title | translate}}
          </div>
          <div *ngIf="section.formAvailable" class="damages-section-header-description">
            <mat-icon svgIcon="document" matTooltip="{{'REPORTING.DAMAGES_CARD.FORM_AVAILABLE' | translate}}" matTooltipPosition="left"></mat-icon>
          </div>
        </div>

        <!-- Form + data -->
        <mat-expansion-panel *ngIf="section.items" class="damages-section" [disabled]="isEmpty(section)">
          <mat-expansion-panel-header class="damages-section-header" collapsedHeight="56px" expandedHeight="56px">
            <mat-panel-title class="damages-section-header-title">
              <mat-icon class="damages-section-header-title-icon" [svgIcon]="section.icon"></mat-icon>
              {{section.title | translate}}
            </mat-panel-title>
            <mat-panel-description class="damages-section-header-description">
              <span *ngIf="section.total > 0" class="damages-section-total">
                {{section.total}}
              </span>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div class="damages-section-content" *ngIf="section.formAvailable">
            <div *ngIf="!section.filledForm" class="damages-section-content-placeholder">
              {{'REPORTING.DAMAGES_CARD.NO_FORM' | translate}}
            </div>
            <button *ngIf="section.filledForm" class="damages-section-content-view" mat-button (click)="openDamagesFormDialog(section.form)">
              <span>{{'REPORTING.DAMAGES_CARD.OPEN_FORM' | translate}}</span>
              <mat-icon>visibility</mat-icon>
            </button>
          </div>

          <mat-divider *ngIf="section.formAvailable"></mat-divider>

          <mat-list dense>
            <ng-container *ngFor="let item of section.items">
              <mat-list-item *ngIf="item.count > 0">
                {{item.label | translate}}
                <span class="damages-section-item-count">{{item.count}}</span>
              </mat-list-item>
            </ng-container>
          </mat-list>
        </mat-expansion-panel>
      </ng-container>
    </mat-accordion>
    <div class="damages-view-placeholder"></div>
  </div>
</div>
