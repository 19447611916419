<h1 class="mainTitle" translate>DRIVING_LOGS.ONGOING_LIST.TITLE</h1>

<div class="filters filters-test-switch">
  <span class="filters-switch switch">
    {{ 'DRIVING_LOGS.ONGOING_P' | translate }}
    <mat-slide-toggle class="red-blue-slide" [checked]="false" (change)="gotoArchives()" />
    {{ 'DRIVING_LOGS.ARCHIVED_P' | translate }}
  </span>

  <mat-form-field>
    <mat-select
      placeholder="{{ 'DRIVING_LOGS.FILTERS.SECTORS' | translate }}"
      name="sectors"
      [(ngModel)]="selectedSectors"
      multiple
    >
      <div class="mat-option h-auto" (click)="toggleSelectSectors()">
        {{ 'GLOBAL.TOGGLE_SELECT' | translate }}
      </div>
      <mat-option *ngFor="let sector of allSectors" [value]="sector.value" (click)="getDrivingLogsList()">
        {{ sector.label | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-select
      placeholder="{{ 'DRIVING_LOGS.FILTERS.TYPES' | translate }}"
      name="permissions"
      [(ngModel)]="selectedPermissions"
      multiple
    >
      <mat-option [value]="'APP_MOBILE_DRIVING_LOGS'" (click)="getDrivingLogsList()">
        {{ 'DRIVING_LOGS.MOBILE' | translate }}
      </mat-option>
      <mat-option [value]="'BACK_OFFICE_OPERATOR'" (click)="getDrivingLogsList()">
        {{ 'DRIVING_LOGS.OPERATOR' | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>

<table cellspacing="0" cellpadding="0">
  <thead>
    <th>{{ 'DRIVING_LOGS.SERVICE_NUMBER' | translate }}</th>
    <th>{{ 'DRIVING_LOGS.FIRST_NAME' | translate }}</th>
    <th>{{ 'DRIVING_LOGS.LAST_NAME' | translate }}</th>
    <th>{{ 'DRIVING_LOGS.STARTED_AT' | translate }}</th>
    <th class="align-right">Actions</th>
  </thead>
  <tbody>
    <tr *ngFor="let item of this.drivingLogList">
      <td>{{ item.service_number }}</td>
      <td>{{ item.first_name }}</td>
      <td>{{ item.last_name }}</td>
      <td>
        <mat-icon class="indicator" [ngClass]="{ alert: isTooLong(item.started_at) }">warning</mat-icon>
        <span [ngClass]="{ alert: isTooLong(item.started_at) }">{{ item.started_at | date: 'medium' }}</span>
      </td>

      <td class="align-right">
        <mat-icon (click)="importDrivingLog(item)" class="download" matTooltip="Importer">vertical_align_top</mat-icon>
        <mat-icon (click)="download(item._id)" class="download" matTooltip="{{ 'GLOBAL.DOWNLOAD' | translate }}">
          vertical_align_bottom
        </mat-icon>
        <mat-icon
          (click)="getGPX(item._id)"
          *ngIf="devMode()"
          class="download"
          matTooltip="{{ 'DRIVING_LOGS.DOWNLOAD_GPX' | translate }}"
        >
          navigation
        </mat-icon>
      </td>
    </tr>
  </tbody>
</table>

<!--
<app-list [listParameters]="listParameters"
          [items]="items"
          [total]="total"
          searchType="none"
          (action)="captureEvent($event)">
</app-list>-->
