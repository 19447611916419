import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IListResult } from 'app/interface/list-result.interface';
import { ITeam, TeamFilters } from 'app/interface/team.interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class TeamsService {
  constructor(private http: HttpClient) {}

  getAll(filters: TeamFilters = {}): Observable<IListResult<ITeam>> {
    return this.http.get('api://teams', { params: filters } as any) as any;
  }

  getById(id: string, filters?: { sort: string }): Observable<ITeam>;
  getById(ids: string[], filters?: { sort: string }): Observable<ITeam[]>;
  getById(id: string[] | string, filters?: { sort: string }): Observable<ITeam[]> | Observable<ITeam> {
    if (Array.isArray(id)) {
      const params: { sort?: string; limit: string; offset: string; total: string; id?: string } = {
        ...(filters || {}),
        limit: '0',
        offset: '0',
        total: '0'
      };

      if (id.length > 0) {
        params.id = id.join(',');
      }

      return this.http.get('api://teams', { params }).pipe(map((result: any) => result.items)) as any;
    } else {
      const params = { ...(filters || {}), id };

      return this.http.get('api://teams', { params }).pipe(map((result: any) => result.items[0])) as any;
    }
  }
}
