import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { GenericDialogComponent } from 'app/components/generic/generic-dialog/generic-dialog.component';
import { ImportQuestion } from 'app/components/generic/questions/question-import';
import { IPicture } from 'app/interface/picture.interface';
import { Loader, LoadingService } from 'app/services/loading.service';
import * as minimal from 'app/tools/objectMinimal';

@Component({
  selector: 'app-old-carousel',
  templateUrl: './old-carousel.component.html',
  styleUrls: ['./old-carousel.component.scss']
})
export class OldCarouselComponent implements OnInit {
  dialogRef: MatDialogRef<GenericDialogComponent> | undefined;
  galleryView: IPicture[] = [];
  selectedPicture?: IPicture;
  state = 'void';
  stepsInCarousel = 2;
  loadingInstance: Loader;
  reportId = '';

  private selectedPictureElement?: ElementRef;
  private arrayStart = 0;
  private arrayEnd = 2;
  private _pictures: any[] = [];

  @Input() actions = [];
  @Input() question: ImportQuestion | undefined;
  @Input() mode = 'carousel'; // Existing mode : carousel (default) / gallery
  @Input() set pictures(newValue) {
    this._pictures = newValue;
    this.parsePictures();
  }
  get pictures(): any[] {
    return this._pictures;
  }

  constructor(
    public loading: LoadingService,
    private dialog: MatDialog,
    private rd: Renderer2,
    private route: ActivatedRoute,
    private http: HttpClient
  ) {
    this.loadingInstance = loading.get(`carousel-${new Date().getTime()}`);
    this.loadingInstance.on();
  }

  ngOnInit() {
    this.loadingInstance.off();
  }

  // Allow to add "alt" and "id" property : URL, name, extension etc --> parent controller
  private parsePictures() {
    if (!this.pictures) {
      return;
    }

    const parsedPictures = this.pictures.map((picture, index) => ({
      ...picture,
      id: `image-${index}`,
      alt: `image-${index}`
    }));

    minimal.replace(this.galleryView, parsedPictures);

    this.galleryView = parsedPictures;
    if (!this.selectedPicture && this.mode !== 'gallery') {
      this.selectedPicture = this.galleryView[0];
    }
  }

  setStepsInCarousel() {
    const innerWidth = window.innerWidth;
    if (innerWidth >= 1920) {
      this.stepsInCarousel = 5;
    } else if (innerWidth < 1920 && innerWidth >= 1640) {
      this.stepsInCarousel = 4;
    } else if (innerWidth < 1640 && innerWidth >= 840) {
      this.stepsInCarousel = 3;
    } else {
      this.stepsInCarousel = 2;
    }
  }

  moveLeft() {
    if (this.arrayStart < 0) {
      this.arrayStart = 0;
      this.arrayEnd = this.stepsInCarousel;
    } else if (this.arrayStart > 0) {
      this.arrayStart -= this.stepsInCarousel;
      this.arrayEnd -= this.stepsInCarousel;
    }
    this.galleryView = this.pictures.slice(this.arrayStart, this.arrayEnd);
  }

  moveRight() {
    if (this.arrayEnd >= this.pictures.length) {
      this.arrayStart = 0;
      this.arrayEnd = this.stepsInCarousel;
    } else {
      this.arrayStart += this.stepsInCarousel;
      this.arrayEnd += this.stepsInCarousel;
    }
    this.galleryView = this.pictures.slice(this.arrayStart, this.arrayEnd);
  }

  onFinish() {
    this.state = 'void';
  }

  zoomIn(picture: IPicture) {
    if (picture.type === 'image') {
      let pictureUrl = picture.url;
      if (picture.local) {
        pictureUrl = picture.original_object.url;
      }

      const struct = {
        title: '',
        text: '',
        buttons: [
          {
            text: 'GLOBAL.OK',
            class: 'validation',
            action: {
              target: 'generic',
              params: { id: 'close' }
            }
          }
        ]
      };
      this.dialogRef = this.dialog.open(GenericDialogComponent, {
        width: 'auto',
        height: 'auto',
        maxWidth: '90%',
        maxHeight: 'calc(100vh - 100px)',
        data: {
          structure: struct,
          content: {
            type: 'img',
            src: pictureUrl
          }
        },
        disableClose: false
      });
    }
  }

  actionPicture(picture: IPicture) {
    if (this.mode !== 'gallery') {
      if (this.selectedPictureElement) {
        this.rd.removeClass(this.selectedPictureElement, 'is-selected');
      }
      this.selectedPictureElement = this.rd.selectRootElement(`#${picture.id}`);
      this.rd.addClass(this.selectedPictureElement, 'is-selected');
      this.selectedPicture = picture;
    } else {
      this.zoomIn(picture);
    }
  }

  deletePicture(picture: IPicture) {
    if (this.dialogRef !== undefined) {
      if (picture.local) {
        this.sliceArrayPicture(picture);
        this.dialogRef.close();
      } else {
        if (this.reportId === '') {
          this.route.params.subscribe((res: any) => {
            this.reportId = res.id;
          });
        }
        const baseURL = `reportings/${this.reportId}/media/${picture.name}`;
        this.http.delete(`api://${baseURL}`).subscribe(() => {
          if (this.dialogRef !== undefined) {
            this.sliceArrayPicture(picture);
            this.dialogRef.close();
          }
        });
      }

      // Allow to delete the files in the files input form (new-report and edit-report for example)
      if (this.question !== undefined && this.question.events.remove) {
        this.question.events.remove(this.question, picture);
      }
    }
  }

  sliceArrayPicture(picture: IPicture) {
    const index = this.galleryView.findIndex((searchPicture: any) => searchPicture.name === picture.name);
    if (index !== -1) {
      // Same index because _cloneDeep with 2 others attributes (alt and id)
      this.pictures.splice(index, 1);
      this.galleryView.splice(index, 1);
    }
  }

  deletePictureStructure(picture: any) {
    return {
      title: 'GLOBAL.CONFIRMATION',
      text: 'CONFIRM.DELETE_REPORT_PICTURE',
      buttons: [
        {
          text: 'GLOBAL.DELETE',
          class: 'validation',
          action: {
            target: 'custom',
            params: {
              id: 'deletePictureButtonId',
              function: () => {
                this.deletePicture(picture);
              }
            }
          }
        },
        {
          text: 'GLOBAL.CANCEL',
          class: 'cancel',
          action: {
            target: 'generic',
            params: {
              id: 'close',
              function: undefined
            }
          }
        }
      ]
    };
  }

  openDeleteConfirmationDialog(picture: any): void {
    this.dialogRef = this.dialog.open(GenericDialogComponent, {
      width: '50%',
      data: { structure: this.deletePictureStructure(picture) },
      disableClose: true
    });
  }

  openDeleteConfirmation(picture: any) {
    this.openDeleteConfirmationDialog(picture);
  }
}
