import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { SessionService } from 'app/components/generic/services/session/session.service';
import { IAccidentForm } from 'app/interface/accident-reporting.interface';
import { LocationsService } from 'app/services/locations.service';
import * as minimal from 'app/tools/objectMinimal';
import dayjs from 'dayjs';
import { ProfileService } from 'src/app/services/profile.service';

interface IDictionary<T> {
  [key: string]: T;
}
interface IPayloadEntry {
  key: string;
  value: any;
  url?: string;
  type?: string;
}
interface IVehicleFormEntry {
  reference: string;
  payload: IPayloadEntry[];
}

@Component({
  selector: 'app-damages-form-dialog',
  templateUrl: './damages-form-dialog.component.html',
  styleUrls: ['./damages-form-dialog.component.scss']
})
export class DamagesFormDialogComponent {
  translationNamespace: string;
  title: string;
  type: string;
  lastUpdated?: dayjs.Dayjs;
  payloadView: IVehicleFormEntry[] | IPayloadEntry[] = [];
  baseURL?: string;
  jwt?: string;
  reportId: string;

  constructor(
    private session: SessionService,
    private translate: TranslateService,
    public dialogRef: MatDialogRef<DamagesFormDialogComponent>,
    private locationsService: LocationsService,
    @Inject(MAT_DIALOG_DATA) public form: { type: string; data: IAccidentForm; reportId: string }
  ) {
    const formData = form.data;
    const formType = form.type;
    this.reportId = form.reportId;
    this.type = formType.toLocaleUpperCase();
    this.translationNamespace = `REPORTING.FORMS.${this.type}`;
    this.title = this.translationKey('DIALOG_TITLE');
    this.updateFormData(formData);
  }

  /**
   * Closes the form dialog.
   */
  closeDialog(): void {
    this.dialogRef.close();
  }

  /**
   * Returns the translation key relative to the displayed form translation
   * namespace. All segments are forced to uppercase.
   *
   * @param keyPath The path to the translation key (rest parameter).
   */
  translationKey(...keyPath: string[]): string {
    return [this.translationNamespace, ...keyPath.map((key) => key.toLocaleUpperCase())].join('.');
  }

  /**
   * Updates the form with new datas; this method is public to enable refreshing
   * the reporting informations while the dialog is open using the following:
   *
   * ```
   * const dialogRef = matDialogInstance.open(DamagesFormDialogComponent, config);
   * dialogRef.componentInstance.updateFormData(newData);
   * ```
   *
   * @param formData The updated form data as returned by the API (e.g.
   *   `apiResponse.details.formWeather`).
   */
  updateFormData(formData: IAccidentForm) {
    this.lastUpdated = dayjs(formData.date);
    minimal.replace(this.payloadView, this.parsePayload(formData.payload || []));
  }

  /**
   * Identifies the provided form and return a display-ready array of its data.
   *
   * @param payload The form payload as provided by the API (e.g.
   *   `apiResponse.details.formVehicles.payload`).
   */
  private parsePayload(payload: IDictionary<any>) {
    switch (this.type) {
      case 'VEHICLES':
        return this.parseVehiclesFormPayload(payload);
      default:
        return this.parseItems(payload);
    }
  }

  /**
   * Parses the vehicles form (`formVehicles`) and returns a display-ready array
   * of its data.
   *
   * @param payload The form payload as provided by the API (e.g.
   *   `apiResponse.details.formVehicles.payload`).
   */
  private parseVehiclesFormPayload(payload: IDictionary<any>): IVehicleFormEntry[] {
    const parsedPayload: IVehicleFormEntry[] = [];

    for (const reference of Object.keys(payload)) {
      const vehiclePayload: any = {};
      for (const key of Object.keys(payload[reference])) {
        const parsedKey = key.toLocaleUpperCase().replace(new RegExp(`_${reference}$`), '');

        vehiclePayload[parsedKey] = payload[reference][key];
      }
      parsedPayload.push({
        reference,
        payload: this.parseItems(vehiclePayload)
      });
    }

    return parsedPayload;
  }

  private parseObjectValue(value: Array<string> | { [key: string]: any }, key?: string): string {
    if (Array.isArray(value)) {
      if (value.length === 2 && value[1] === '') {
        return value[0];
      } else {
        const newValue = value.map((val) => {
          if (key) {
            const translationPath = `${
              this.translationNamespace
            }.${key.toLocaleUpperCase()}_${val.toLocaleUpperCase()}`;
            const translatedValue = this.translate.instant(translationPath);

            return translatedValue === translationPath ? val : translatedValue;
          } else {
            return val;
          }
        });

        return newValue.join(', ');
      }
    }
    if (value.hasOwnProperty('landmark_id')) {
      const rawLocation = this.locationsService.parseLandmarkId(value.landmark_id);
      rawLocation.landmark += value.offset ? value.offset / 1000 : 0;
      const km = Math.floor(rawLocation.landmark);
      const meters = Math.round((rawLocation.landmark - km) * 1000);
      const landmark = `${km}+${`${String(meters)}000`.slice(0, 3)}`;

      return `${rawLocation.highway_name} (${this.translate.instant(
        'HIGHWAYS.KILOMETER_POINT'
      )} ${landmark} - ${this.translate.instant('HIGHWAYS.DIRECTION_LABEL')} ${rawLocation.direction})`;
    }

    return String(value);
  }

  private parsePictures(parsedEntry: any) {
    let url = '';
    if (parsedEntry.type === 'full' || parsedEntry.type === 'id') {
      if (!this.jwt) {
        this.jwt = this.session.getToken();
      }
      if (!this.baseURL) {
        const apiRoot = ProfileService.getAPIUrl();
        const apiPath = `reportings/${this.reportId}/media/`;
        this.baseURL = `${apiRoot}${apiPath}`;
      }
      url = this.getFullURL(parsedEntry.url);
    }

    return url;
  }

  private getFullURL(imageName: any) {
    return `${this.baseURL}${imageName}?jwt=${this.jwt}`;
  }

  /**
   * This function is used to return proper structure to the item we're supposed to display in report,
   * based on if and what informations have been given to the payload
   *
   * @param payload vehicle assurance payload informations
   * @param itm the Object used to display the right informations and which we'll format here
   */
  private checkForPicture(payload: Array<string>, itm: { key: string; type: string; value?: string; url?: string }) {
    // when out of dev context, remove second part of the if condition
    if (payload.length === 2 && payload[1] === '' && payload[0] !== '') {
      // if the second entry is empty but not the first one, it means the mobile applicaiton
      // only sent us the name of the assurance without picture
      itm.type = 'string';
      itm.value = payload[0];
    } else if (payload.length === 2 && payload[0] === '' && payload[1] !== '') {
      // this case is the opposite of the previous one, we only got one picture to give
      // us informations about the assurance company so we stored the picture id in the payload
      itm.type = 'id';
      itm.url = payload[1];
    } else if (payload.length === 2 && payload[0] !== '' && payload[1] !== '') {
      // If we got both id and name, we want a special case since we'll have to display both the picture AND
      // the name of the company
      itm.type = 'full';
      itm.value = payload[0];
      itm.url = payload[1];
    } else {
      itm.type = 'empty';
    }

    return itm;
  }

  /**
   * Parses a generic form and returns a display-ready array of its data.
   *
   * @param payload The form payload as provided by the API (e.g.
   *   `apiResponse.details.formWeather.payload`).
   */
  private parseItems(payload: IDictionary<any>): IPayloadEntry[] {
    const parsedItems: IPayloadEntry[] = [];
    for (const key of Object.keys(payload)) {
      const value = payload[key];

      if (!value && typeof value !== 'boolean') {
        continue;
      }

      const itm: { key: string; type: string; value: string; url?: string } = {
        key: this.translationKey(key),
        type: 'empty',
        value: ''
      };

      let parsedValue: string;
      switch (typeof value) {
        case 'string':
          parsedValue = value;
          break;
        case 'number':
          parsedValue = String(+value);
          break;
        case 'boolean':
          parsedValue = this.translate.instant(value ? 'GLOBAL.YES' : 'GLOBAL.NO');
          break;
        case 'object':
          this.checkForPicture(value, itm);
          parsedValue = this.parseObjectValue(value, key);
          break;
        default:
          parsedValue = String(value);
      }

      /**
       * const itm = {
        key: this.translationKey(key),
        value: parsedValue
      };
       */
      if (itm.type === 'empty' || itm.type === 'string') {
        parsedItems.push({
          key: this.translationKey(key),
          value: parsedValue
        });
      } else {
        itm.url = this.parsePictures(itm);
        parsedItems.push(itm);
      }
    }

    return parsedItems;
  }
}
