import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { IFiltersGetInstructions, IInstruction } from 'app/interface/instruction.interface';
import { IListResult } from 'app/interface/list-result.interface';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { Observable } from 'rxjs';

@Injectable()
export class InstructionsService {
  constructor(
    private http: HttpClient,
    private snackbar: MatSnackBar,
    private translate: TranslateService
  ) {}

  /**
   * Retrieve all instructions
   *
   * @param {IFiltersGetInstructions} filters
   * @returns {Observable<IListResult<IInstruction>}
   */
  getAll(filters: IFiltersGetInstructions = {}): Observable<IListResult<IInstruction>> {
    return this.http.get('api://instructions', { params: filters } as any) as any;
  }

  /**
   * Retrieve all instructions
   *
   * @param {string} instructionId
   * @returns {Observable<IInstruction>}
   */
  getOne(instructionId: string): Observable<IInstruction> {
    return this.http.get(`api://instructions/${instructionId}`) as any;
  }

  /**
   * Remove an instruction
   *
   * @param {string} id
   * @returns {Observable}
   */
  delete(id: string): Observable<void> {
    return this.http.delete(`api://instructions/${id}`) as any;
  }

  /**
   * Add an instruction
   *
   * @param {any} model
   * @returns {Observable}
   */
  add(model: { [key: string]: any }) {
    return this.http.post('api://instructions', model) as Observable<IInstruction>;
  }

  /**
   * Patch an instruction
   *
   * @param {any} model
   * @param {string} instructionId
   * @returns {Observable}
   */
  patch(model: { [key: string]: any }, instructionId: string) {
    return this.http.patch(`api://instructions/${instructionId}`, model);
  }

  openSnackbar(message: string): void {
    this.snackbar.open(this.translate.instant(message), this.translate.instant('GLOBAL.CLOSE_LABEL'));
  }

  downloadCSV(filterParams: any): any {
    const downloadDate = dayjs().format('DD-MM-YYYY-HHmm');
    const downloadedFilename = `instructions-${downloadDate}.csv`;
    this.http
      .get(`api://instructions/export`, { responseType: 'blob', params: filterParams })
      .subscribe((data) => saveAs(data, downloadedFilename));
  }

  verificationFormData(values: any): any | boolean {
    const formSubmit: any = {
      title: '',
      type: '',
      assigned: {
        type: '',
        data: []
      }
    };

    // --------------- Title ---------------

    if (!values.title) {
      this.openSnackbar('INSTRUCTIONS.ERROR.TITLE_REQUIRED_FIELD_ERROR');

      return false;
    }
    formSubmit.title = values.title;

    // --------------- Description ---------------

    if (values.description) {
      formSubmit.description = values.description;
    }

    // --------------- Priority ---------------

    if (values.priority !== 'high' && values.priority !== 'normal') {
      this.openSnackbar('INSTRUCTIONS.ERROR.DESCRIPTION_REQUIRED_FIELD_ERROR');

      return false;
    }
    formSubmit.type = values.priority === 'high' ? 'PRIORITY' : 'INFORMATION';

    // --------------- Date ---------------

    if (!values.from_date || !values.to_date) {
      this.openSnackbar('INSTRUCTIONS.ERROR.DATE_REQUIRED_FIELD_ERROR');

      return false;
    }

    const startDate = dayjs(values.from_date).startOf('days');
    const endDate = dayjs(values.to_date).endOf('days');

    if (!startDate.isBefore(endDate)) {
      this.openSnackbar('INSTRUCTIONS.ERROR.END_DATE_BEFORE_START_DATE_ERROR');

      return false;
    }
    formSubmit.start_date = new Date(startDate.toDate());
    formSubmit.end_date = new Date(endDate.toDate());

    // --------------- Assigned ---------------

    const tabType = Object.keys(values.assigned);
    if (tabType.length !== 1) {
      this.openSnackbar('INSTRUCTIONS.ERROR.ASSIGNED_FIELD_ERROR');

      return false;
    }

    if (tabType[0] !== 'users' && tabType[0] !== 'teams') {
      this.openSnackbar('INSTRUCTIONS.ERROR.ASSIGNED_TYPE_FIELD_ERROR');

      return false;
    }

    formSubmit.assigned.type = tabType[0];
    formSubmit.assigned.data = values.assigned[tabType[0]];

    return formSubmit;
  }
}
