import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { IconService } from 'app/services/icon.service';
import { Coordinates } from 'src/app/interface/location.interface';

@Component({
  selector: 'app-map-card',
  templateUrl: './map-card.component.html',
  styleUrls: ['./map-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MapCardComponent implements OnInit {
  @Input() report: any = {};
  public loaded = false;
  public list: any[] = [];
  public iconListReporting: any;
  public center: Coordinates;

  constructor(public iconService: IconService) {}

  ngOnInit() {
    this.list = [this.report];
    this.center = {
      latitude: this.report.coordinates.latitude,
      longitude: this.report.coordinates.longitude
    };
    this.loaded = true;
  }
}
