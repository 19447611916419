<div class="reporting-parent" *ngIf="isLoaded">
  <div class="list-parent">
    <div class="list-headers">
      <div class="reporting-buttons-actions">
        <button
          mat-raised-button
          class="icon-only-button big-icon-button"
          color="warn"
          matTooltip="{{ 'GLOBAL.ADD' | translate }}"
          matTooltipPosition="above"
          [disabled]="!isRightReporting"
          (click)="addReporting()"
        >
          <mat-icon>add</mat-icon>
        </button>
        <button
          mat-raised-button
          class="icon-only-button big-icon-button"
          matTooltip="{{ 'GLOBAL.DOWNLOAD' | translate }}"
          matTooltipPosition="above"
          [disabled]="!isRightReporting || reportingList.length === 0"
          [ngClass]="{ 'disabled-button': reportingList.length === 0 || !isRightReporting }"
          (click)="downloadReporting()"
        >
          <mat-icon>file_download</mat-icon>
        </button>
        <button
          mat-raised-button
          class="icon-only-button big-icon-button"
          matTooltip="{{ 'GLOBAL.REFRESH' | translate }}"
          matTooltipPosition="above"
          (click)="filterData().subscribe()"
        >
          <mat-icon>refresh</mat-icon>
        </button>
      </div>
      <div class="list-filters">
        <div class="list-filters-search">
          <input
            [(ngModel)]="searchValue"
            (keyup)="keyUpSearchInList($event)"
            class="list-filters-search-field"
            type="text"
            placeholder="{{ 'GLOBAL.SEARCH' | translate }}"
          />
          <button class="list-filters-search-button" [disabled]="pendingRequest" (click)="filterData().subscribe()">
            <i class="material-icons">search</i>
          </button>
        </div>
      </div>
    </div>
    <div class="reporting-list" #itemsList>
      <app-loading [active]="pendingRequest"></app-loading>
      <p *ngIf="reportingList.length === 0 && !pendingRequest">{{ 'REPORTING.FORMS.EMPTY_PAYLOAD' | translate }}</p>
      <mat-list
        infiniteScroll
        [infiniteScrollDistance]="triggerScroll"
        [infiniteScrollThrottle]="50"
        [infiniteScrollContainer]="'.reporting-list'"
        [scrollWindow]="false"
        [fromRoot]="true"
        (scrolled)="loadNextItems()"
        *ngIf="pendingRequest === false"
      >
        <mat-list-item
          *ngFor="let marker of reportingList | slice: 0 : listEnd"
          [ngClass]="{ selected: selectedReportings.indexOf(marker) !== -1 }"
        >
          <div class="flex items-center gap-4">
            <img
              src="{{ marker.reporting.type.picto }}"
              (click)="clickReportInList(marker)"
              matListAvatar
              matTooltip="{{ marker.reporting.type.label }} {{ marker.reporting.subtype?.label }}"
              class="hover icon-md-size report-icon report-icon-{{ marker.reporting.type.name }} report-icon-{{
                marker.reporting.type.name
              }}-{{ marker.reporting.state | lowercase }}"
            />
            <div class="clickable-container" (click)="goToDetails($event, marker.reporting._id)">
              <!-- TODO: What is that "report_title" ? -->
              <h4 *ngIf="marker.data.report_title" matLine class="report_title">{{ marker.data.report_title }}</h4>
              <p matLine class="report_by">
                <span *ngIf="marker.reporting.created_by && !marker.data.isRegaCesar">
                  <span
                    matTooltip="{{ marker.reporting.created_by.first_name }} {{
                      marker.reporting.created_by.last_name | uppercase
                    }} ({{ marker.reporting.created_by.service_number }})"
                  >
                    {{
                      getTrigram(marker.reporting.created_by.first_name, marker.reporting.created_by.last_name)
                        | uppercase
                    }}
                  </span>
                  -
                </span>
                <span *ngIf="marker.data.isRegaCesar">
                  <span matTooltip="{{ marker.reporting.source }}">
                    {{ marker.reporting.source | uppercase }}
                  </span>
                  -
                </span>
                {{ marker.data.date | date: 'dd/MM/yyyy' }} {{ 'GLOBAL.AT' | translate | lowercase }}
                {{ marker.data.date.format('HH:mm') }} - {{ 'REPORTING.STATES.' + marker.reporting.state | translate }}
              </p>
              <p matLine>
                <mat-chip-list class="flex gap-2">
                  <mat-chip color="primary" class="custom-chip highway_chips">{{
                    marker.reporting.location.highway_name
                  }}</mat-chip>
                  <mat-chip color="accent" class="custom-chip pr_chips">
                    {{ marker.reporting.location.display }} ({{ 'HIGHWAYS.DIRECTION_LABEL' | translate }}
                    {{ marker.reporting.location.direction }})
                  </mat-chip>
                </mat-chip-list>
              </p>
            </div>
            <span class="reportingsActions">
              <button mat-icon-button [matMenuTriggerFor]="menuActions">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menuActions="matMenu" class="menuActionsReport">
                <button
                  mat-menu-item
                  (click)="handleEditClick(marker.reporting._id)"
                  *ngIf="isRightReporting && isEditable(marker.reporting)"
                >
                  <mat-icon>edit</mat-icon>
                  <span>{{ 'GLOBAL.EDIT' | translate }}</span>
                </button>
                <button mat-menu-item (click)="handlePdfClick(marker.reporting._id)">
                  <mat-icon>picture_as_pdf</mat-icon>
                  <span>{{ 'REPORTING.PDF.OPEN_PDF_VIEWER' | translate }}</span>
                </button>
                <button
                  mat-menu-item
                  *ngIf="isRightReporting && isFinalizable(marker.reporting)"
                  (click)="handleFinalizeClick(marker.reporting._id)"
                >
                  <mat-icon>done</mat-icon>
                  <span>{{ 'GLOBAL.FINALIZE' | translate }}</span>
                </button>
                <button mat-menu-item (click)="handleDeleteClick(marker.reporting._id)" *ngIf="isRightReporting">
                  <mat-icon>delete</mat-icon>
                  <span>{{ 'GLOBAL.DELETE' | translate }}</span>
                </button>
              </mat-menu>
            </span>
          </div>
        </mat-list-item>
        <!-- don't forget to add class when selected -->
        <mat-list-item *ngFor="let marker of infrastructuresList | slice: 0 : listEnd" class="no-pointer">
          <div class="flex items-center gap-4">
            <img
              matListAvatar
              class="poi-icon icon-md-size"
              src="{{ marker.data.type.picto }}"
              (click)="clickInfrastructureInList(marker)"
            />
            <div>
              <h4 matLine class="report_title">{{ marker.infrastructure.name }}</h4>
              <p matLine class="report_by">{{ marker.data.type.name }}</p>
              <!-- Add action on click to go detail -->
              <p matLine class="chip">
                <mat-chip-list>
                  <mat-chip color="primary" class="custom-chip highway_chips">
                    {{ marker.infrastructure.location.highway_name }}
                  </mat-chip>
                  <mat-chip color="accent" class="custom-chip pr_chips">
                    {{ marker.infrastructure.location.display }} ({{ 'HIGHWAYS.DIRECTION_LABEL' | translate }}
                    {{ marker.infrastructure.location.direction }})
                  </mat-chip>
                </mat-chip-list>
              </p>
            </div>
          </div>
        </mat-list-item>
      </mat-list>
    </div>
  </div>
  <div class="reporting-map">
    <div class="map-title">
      <span class="page-title">{{ 'REPORTING.REPORTS' | translate }}</span>
      <span *ngIf="selectedReportings.length === 1" class="nb-selected-reporting">
        ({{ selectedReportings.length }} {{ 'GLOBAL.SELECTED' | translate | lowercase }})
      </span>
      <span *ngIf="selectedReportings.length > 1" class="nb-selected-reporting">
        ({{ selectedReportings.length }} {{ 'GLOBAL.SELECTED_P' | translate | lowercase }})
      </span>
    </div>
    <div class="map-filter">
      <mat-form-field subscriptSizing="dynamic">
        <mat-label>{{ 'GLOBAL.SECTOR' | translate }}</mat-label>
        <mat-select [(ngModel)]="filters.sector_id" multiple>
          <mat-option selected class="clean h-auto" (click)="toggleSelectSectors()">
            {{ 'GLOBAL.TOGGLE_SELECT' | translate }}
          </mat-option>
          <mat-option *ngFor="let sector of sectors" (click)="updateSectors()" [value]="sector.id">
            {{ sector.name | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <!-- insert here buttons Secteur - filter -->
      <button mat-button (click)="openClassicFiltersDialog()" [disabled]="formDisable">
        <mat-icon>filter_list</mat-icon>
        {{ 'REPORTING.REPORTS' | translate }}
      </button>
      <button mat-button (click)="openInfrastructureDialog()" [disabled]="formDisable">
        <mat-icon>filter_list</mat-icon>
        {{ 'GLOBAL.BREADCRUMB.INFRASTRUCTURES.LIST' | translate }}
      </button>
    </div>
    <app-map
      [style.flex]="1"
      [markers]="markersList"
      [center]="mapOrigin"
      [resizeEvent]="resizeEvent"
      [zoom]="mapZoom"
      (centerChange)="(handleMapCenterChange)"
      (zoomChange)="mapZoom = $event"
    />
  </div>
</div>
