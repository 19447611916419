import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  GetInfrastructureQueryParams,
  IInfrastructure,
  IInfrastructureResponse,
  InfrastructureToCreate,
  InfrastructureToEdit,
  InfrastructureType
} from 'app/interface/infrastructure.interface';
import { IBareListResult, IListResult } from 'app/interface/list-result.interface';
import { BehaviorSubject, from, lastValueFrom, Observable, of as observableOf, Subject } from 'rxjs';
import { filter, map, mergeMap, shareReplay } from 'rxjs/operators';

import { Coordinates } from '../interface/location.interface';
import { getTranslationWithFallback } from '../tools/translate';

@Injectable()
export class InfrastructuresService {
  constructor(
    private http: HttpClient,
    private translate: TranslateService
  ) {}

  private infrastructureTypesSubject = new BehaviorSubject<InfrastructureType[] | null>(null);
  private reportingTypesObservable = this.infrastructureTypesSubject.asObservable().pipe(filter(Boolean));
  private fetchedInfrastructureTypes = false;

  /**
   * Retrieve all infrastructures as a filtered list.
   *
   * @param filters  A set of zero, one, or more generic filters.
   * @returns An observable
   */
  getAll(filters: GetInfrastructureQueryParams = {}): Observable<IBareListResult<IInfrastructure>> {
    if (
      (filters.type && !filters.type.length) ||
      (filters.sector_id && !filters.sector_id.length) ||
      (filters.highway_id && !filters.highway_id.length)
    ) {
      return observableOf({ items: [], total: 0, limit: filters.limit || 0, offset: filters.offset || 0 });
    }
    filters = { ...filters };
    Object.entries(filters).forEach(([key, value]) => {
      if (value === undefined) {
        delete (filters as any)[key];
      }
    });
    return this.http.get<IBareListResult<IInfrastructureResponse>>('api://infrastructures', { params: filters }).pipe(
      map((res) => {
        const list: IBareListResult<IInfrastructure> = {
          ...res,
          items: res.items.map((e) => this.parseInfrastructureResponse(e))
        };
        return list;
      })
    );
  }

  /**
   * Retrieves all infrastructures by incremental steps, each step querying the
   * next ring from a given origin.
   *
   * The first ring always starts from the origin itself (inner radius = 0).
   * Duplicate values are merged to avoid needless API calls. If all the results
   * have not been retrieved when reaching the last ring, an additional request
   * is sent to fetch the remaining entries. Filters are taken into account when
   * computing the number of expected entries.
   *
   * @param origin The origin point for the radial search.
   * @param steps A list of successive radii to split the results into multiple
   *   requests.
   * @param filters A set of zero, one, or more generic filters.
   * @returns A subject that will emit zero, one, or n values, where n is the
   *   number of values provided in the "steps" parameter. The emitted values
   *   include the inner request result and append the field "done", which will
   *   be set to "true" once all the results have been retrieved.
   */
  // Raphaël: cette fonction était utilisée pour fetch les infrastructures sur la map d'accueil
  // Aujourd'hui elle est cassée:
  // 1. elle envoie toutes les requêtes d'un coup
  // 2. le filtre serverside semble être cassé aussi
  // 3. elle ne sert à rien tant qu'on attend la fin des requêtes pour afficher quelque chose
  // Idée peut-être: utiliser les données au fur et à mesure qu'elles arrivent + passer un flag loaded=true quand tout est chargé
  public getAllBySteps(
    origin: Coordinates,
    steps: number[],
    filters: {
      sort?: string;
      offset?: string;
      limit?: string;
      q?: string;
    }
  ) {
    const subject = new Subject<IListResult<IInfrastructure> & { done: boolean }>();
    let requestsSequence: Observable<void | null> = observableOf(null);
    const radiiList = Array.from(new Set([0, ...steps]))
      .filter((a: number) => a >= 0)
      .sort((a: number, b: number) => a - b);

    // Retrieve total of infrastructures that must be retrieved
    this.http.get('api://infrastructures/count', { params: filters } as any).subscribe((total: any) => {
      let count = 0;

      // Prepare the request sequence to execute
      const initialRadius = radiiList[0];
      let innerRadius: number | undefined;
      for (let iteration = 0; iteration < radiiList.length; iteration++) {
        /*
         * Start from the first value of the radii list, i.e. 0 since negative
         * values are not accepted, and 0 is always added if absent.
         */
        const outerRadius = steps[iteration];
        if (!innerRadius) {
          innerRadius = initialRadius;
        }

        // Map the current incremental request to the global sequence
        const incrementalRequest = from(this.getBetween(origin, innerRadius, outerRadius, filters)).pipe(
          // eslint-disable-next-line @typescript-eslint/no-loop-func
          map((response: any) => {
            count += response.total;
            subject.next({ ...response, done: count === total });
          })
        );

        requestsSequence = requestsSequence.pipe(mergeMap(() => incrementalRequest));
        innerRadius = outerRadius;
      }

      // Execute the request sequence
      requestsSequence.subscribe();
    });

    return subject.pipe(shareReplay());
  }

  /**
   * Retrieves all the infrastructures within a given ring from an origin point.
   */
  private async getBetween(
    origin: Coordinates,
    rmin?: number,
    rmax?: number,
    filters: {
      sort?: string;
      offset?: string;
      limit?: string;
      q?: string;
    } = {}
  ): Promise<IBareListResult<IInfrastructure>> {
    const stepFilters: { origin: string; rmin?: string; rmax?: string } = {
      origin: [String(origin.longitude), String(origin.latitude)].join(',')
    };

    if (rmin !== undefined) {
      stepFilters.rmin = String(rmin);
    }
    if (rmax !== undefined) {
      stepFilters.rmax = String(rmax);
    }

    const res = await lastValueFrom(
      this.http.get<IBareListResult<IInfrastructureResponse>>('api://infrastructures', {
        params: { ...filters, ...stepFilters }
      })
    );
    const list: IBareListResult<IInfrastructure> = {
      ...res,
      items: res.items.map((e) => this.parseInfrastructureResponse(e))
    };
    return list;
  }

  public parseInfrastructureResponse(res: IInfrastructureResponse): IInfrastructure {
    return {
      ...res,
      type: this.parseInfrastructureTypeResponse(res.type)
    };
  }

  public async createInfrastructure(data: InfrastructureToCreate): Promise<IInfrastructure> {
    const res = await lastValueFrom(this.http.post<IInfrastructureResponse>(`api://infrastructures`, data));
    return this.parseInfrastructureResponse(res);
  }

  public async patchInfrastructure(id: string, data: InfrastructureToEdit): Promise<void> {
    await lastValueFrom(this.http.patch<IInfrastructureResponse>(`api://infrastructures/${id}`, data));
  }

  public async deleteOneInfrastructure(id: string): Promise<void> {
    await lastValueFrom(this.http.delete(`api://infrastructures/${id}`));
  }

  //
  // Infrastructure type
  //

  public parseInfrastructureTypeResponse(res: InfrastructureType): InfrastructureType {
    return {
      ...res,
      name: getTranslationWithFallback(`INFRASTRUCTURES.TYPES.${res.name.toUpperCase()}`, res.name, this.translate)
    };
  }

  public async getInfrastructureTypes(force: boolean = false): Promise<InfrastructureType[]> {
    const value = this.infrastructureTypesSubject.getValue();
    if (!force && value) {
      return value;
    }
    const [infrastructureTypes] = await this.getInfrastructureTypesAndCount();
    this.infrastructureTypesSubject.next(infrastructureTypes);
    this.fetchedInfrastructureTypes = true;
    return infrastructureTypes;
  }

  public getInfrastructureTypesSync(): InfrastructureType[] {
    const infrastructureTypes = this.infrastructureTypesSubject.getValue();
    if (!infrastructureTypes) {
      throw Error('InfrastructuresService.getInfrastructureTypesSync: infrastructureTypes are not loaded');
    }
    return infrastructureTypes;
  }

  public async getInfrastructureTypesAndCount(): Promise<[InfrastructureType[], number]> {
    const { items, total } = await lastValueFrom(
      this.http.get<{ items: InfrastructureType[]; total: number }>(`api://infrastructure-types`)
    );
    const infrastructureTypes = items.map((e) => this.parseInfrastructureTypeResponse(e));
    return [infrastructureTypes, total];
  }

  /**
   * Only admin is authorized to deleteOne
   */
  public async deleteOneInfrastructureType(id: string): Promise<void> {
    await lastValueFrom(this.http.delete(`api://infrastructure-types/${id}`));
  }
}
