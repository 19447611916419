import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { GoogleMap, MapInfoWindow } from '@angular/google-maps';
import dayjs from 'dayjs';
import { GPXPoint } from 'src/app/interface/location.interface';
import { FileHelper } from 'src/app/tools/file.helper';
import { MapsHelper } from 'src/app/tools/maps.helper';
import * as xml2js from 'xml2js';

@Component({
  selector: 'app-gpx-viewer',
  templateUrl: './gpx-viewer.component.html',
  styleUrls: ['./gpx-viewer.component.scss']
})
export class GPXViewerComponent implements OnInit {
  public mapsApiLoaded: boolean = false;
  private mapsHelper: MapsHelper;
  @ViewChild(GoogleMap) private map: GoogleMap;
  @ViewChild(MapInfoWindow) private mapInfoWindow: MapInfoWindow;

  public initialPosition: google.maps.LatLngLiteral = { lat: 46.7833, lng: 4.85 };
  public initialZoom = 7;

  public gpx = new UntypedFormControl();
  public polylinePoints: GPXPoint[] = [];
  public gpxData: GPXPoint[] = [];
  public gpxStart: { lat: number; lng: number } | null = null;
  public gpxEnd: { lat: number; lng: number } | null = null;
  public gpxSelectedStart: Date = dayjs().toDate();
  public gpxSelectedEnd: Date = dayjs().add(1, 'hour').startOf('hour').toDate();
  public gpxInfos: GPXPoint | null = null;

  constructor(private httpClient: HttpClient) {
    this.mapsHelper = new MapsHelper(httpClient);
  }

  ngOnInit() {
    this.mapsHelper.loadMapsApi().then(() => (this.mapsApiLoaded = true));
  }

  public async handleGPXFile(event: InputEvent): Promise<void> {
    const content = await FileHelper.handleFileInputChange(event);
    if (!content) {
      return;
    }
    this.parseGPXFile(content.toString());
  }

  private parseGPXFile(data: string): void {
    xml2js.parseString(data, (_err, result) => {
      this.gpxData = result.gpx.trk[0].trkseg[0].trkpt.map(
        (point: { $: { lat: string; lon: string }; time: Array<string> }) => ({
          lat: +point.$.lat,
          lng: +point.$.lon,
          horodate: new Date(point.time[0])
        })
      );
    });

    this.setGPXData();
  }

  private setGPXData(): void {
    this.gpxSelectedStart = dayjs(this.gpxData[0].horodate).startOf('hour').toDate();
    this.gpxSelectedEnd = dayjs(this.gpxData[0].horodate).add(1, 'hour').startOf('hour').toDate();
    this.processPolylineData();
  }

  public previousGPXInterval(): void {
    this.gpxSelectedStart = dayjs(this.gpxSelectedStart).subtract(1, 'hour').startOf('hour').toDate();
    this.gpxSelectedEnd = dayjs(this.gpxSelectedStart).endOf('hour').toDate();
    this.processPolylineData();
  }

  public nextGPXInterval(): void {
    this.gpxSelectedStart = dayjs(this.gpxSelectedEnd).add(1, 'hour').startOf('hour').toDate();
    this.gpxSelectedEnd = dayjs(this.gpxSelectedStart).endOf('hour').toDate();
    this.processPolylineData();
  }

  public processPolylineData(): void {
    this.polylinePoints = this.gpxData.filter((data) => {
      const h = dayjs(data.horodate);
      return h.isSameOrAfter(this.gpxSelectedStart) && h.isSameOrBefore(this.gpxSelectedEnd);
    });

    if (!this.polylinePoints.length) {
      this.gpxStart = null;
      this.gpxEnd = null;
      return;
    }

    this.gpxStart = { lat: this.polylinePoints[0].lat, lng: this.polylinePoints[0].lng };
    this.gpxEnd = {
      lat: this.polylinePoints[this.polylinePoints.length - 1].lat,
      lng: this.polylinePoints[this.polylinePoints.length - 1].lng
    };
    // this.map.zoom = 20;
    setTimeout(() => this.moveToCurrentPolyline());
  }

  private moveToCurrentPolyline(): void {
    const bounds = new google.maps.LatLngBounds();
    this.polylinePoints.forEach((e) => {
      const latlng = new google.maps.LatLng(e.lat, e.lng);
      bounds.extend(latlng);
    });
    this.map.fitBounds(bounds);
  }

  public clearGPX(): void {
    this.polylinePoints = [];
    this.gpxData = [];
    this.gpxStart = null;
    this.gpxEnd = null;
    this.gpx.setValue('');
  }

  public showGPXPointInfoWindow(marker: GPXPoint, circle: google.maps.Circle): void {
    this.gpxInfos = marker;
    const gpxInfoWindow = this.mapInfoWindow;
    gpxInfoWindow.position = circle.getCenter()!;
    gpxInfoWindow.open();
  }

  public hideGPXPointInfoWindow(): void {
    const gpxInfoWindow = this.mapInfoWindow;
    gpxInfoWindow?.close();
    this.gpxInfos = null;
  }
}
