<h1 mat-dialog-title>
  <mat-icon>note_add</mat-icon>
  {{ 'INFRASTRUCTURES.NEW.TITLE' | translate }}
</h1>
<mat-dialog-content class="dialog-padding">
  <div *ngIf="!loading" class="new-infrastructure-dialog">
    <div class="form">
      <div id="coordinates">
        <strong>{{ 'INFRASTRUCTURES.COORDINATES' | translate }} : </strong>
        {{ coordinates.latitude.toFixed(6) }}
        :
        {{ coordinates.longitude.toFixed(6) }}
      </div>

      <br />

      <app-dynamic-form
        #dynamicForm
        [(questions)]="questions"
        [cancelButton]="{ activated: true }"
        (formSubmit)="handleSubmit($event)"
        (cancel)="handleCancel($event)"
      />
    </div>

    <div class="map">
      <app-location-form-map [coordinates]="coordinates" (markerMove)="handleMarkerMove($event)" />
    </div>
  </div>

  <app-loading *ngIf="loading" [active]="true" />
</mat-dialog-content>
