import { EventEmitter, Injectable } from '@angular/core';
import { ILoadingStatus } from 'app/interface/loading-status.interface';
import { of as observableOf, Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';

export class Loader {
  private active?: boolean;
  private previous?: boolean;
  private next?: boolean;
  private ldelay = 0;
  private timerStart = 0;

  private delayedSubscription: Subscription = new Subscription();
  readonly statusChanges: EventEmitter<ILoadingStatus> = new EventEmitter<ILoadingStatus>();

  /**
   * Alias for this.status().enabled
   * @returns TRUE if loading is active
   */
  get enabled() {
    return this.active;
  }

  /**
   * Alias for this.status().enabled
   * @returns FALSE if loading is active
   */
  get disabled() {
    return !this.active;
  }

  /**
   * Set the loader status to enabled. If `ldelay` is provided, the status is
   * set after this amount of time, unless another status update occurs first.
   *
   * @param   ldelay  Amount of time in milliseconds to wait before enabling.
   * @returns The loader status.
   */
  on(ldelay?: number): ILoadingStatus {
    this.set(true, ldelay);

    return this.status();
  }

  /**
   * Set the loader status to disabled. If `ldelay` is provided, the status is
   * set after this amount of time, unless another status update occurs first.
   *
   * @param   ldelay  Amount of time in milliseconds to wait before disabling.
   * @returns The loader status.
   */
  off(ldelay?: number): ILoadingStatus {
    this.set(false, ldelay);

    return this.status();
  }

  /**
   * Return the current status of the loader.
   *
   * @returns The loader status.
   */
  status(): ILoadingStatus {
    const status: ILoadingStatus = {
      enabled: this.active,
      disabled: this.active !== undefined ? !this.active : undefined,
      previous: this.previous,
      current: this.active ? 'enabled' : 'disabled'
    };

    // Compute the remaining time and upcoming status if a delayed update
    // is pending
    const remaining: number = this.timerStart + this.ldelay - new Date().getTime();
    if (remaining > 0) {
      status.next = this.next;
      status.timer = remaining;
    }

    return status;
  }

  /**
   * Set the loader status to either TRUE (enabled) or FALSE (disabled).
   *
   * If `ldelay` is provided, the status is set after this amount of time,
   * unless another status update occurs first, delayed or not. This
   * guarantees that only the last *call* of this method (or one of its
   * aliases) is to be taken into account to determine the current and/or
   * next status of the loader.
   *
   * @param   status  TRUE to enable, FALSE to disable.
   * @param   ldelay   Amount of time in milliseconds to wait before updating.
   * @returns The loader status.
   */
  private set(status: boolean, ldelay: number = 0): void {
    // Cancel any delayed status update that might be pending
    if (this.delayedSubscription && !this.delayedSubscription.closed) {
      this.timerStart = 0;
      this.ldelay = 0;
      this.delayedSubscription.unsubscribe();
    }

    if (!isFinite(ldelay) || ldelay <= 0) {
      // No delay provided: update immediately
      this.updateAndNotify(status);
    } else {
      // Valid delay provided: start timer and create subscription
      this.next = status;
      this.ldelay = ldelay;
      this.timerStart = new Date().getTime();

      this.delayedSubscription = observableOf(null)
        .pipe(delay(ldelay))
        .subscribe(() => {
          // Update and remove subscription
          this.updateAndNotify(status);
          this.delayedSubscription.unsubscribe();
        });
    }
  }

  /**
   * Update loader status and notify any subscriber of the change.
   *
   * @param   status  TRUE to enable, FALSE to disable.
   * @returns The loader status.
   */
  private updateAndNotify(status: boolean): void {
    this.previous = this.active;
    this.active = status;
    this.statusChanges.emit(this.status());
  }
}

@Injectable()
export class LoadingService extends Loader {
  readonly instances: { [name: string]: Loader } = {};

  /**
   * Check whether a child loader exists.
   *
   * @param   name  The child loader name.
   * @returns TRUE if it exists, FALSE otherwise.
   */
  has(name: string): boolean {
    return this.instances.hasOwnProperty(name);
  }

  /**
   * Return an existing child loader instance and create it if needed.
   *
   * @param   name  The child loader name.
   * @returns A child loader instance.
   */
  get(name: string): Loader {
    if (!this.has(name)) {
      this.instances[name] = new Loader();
    }

    return this.instances[name];
  }

  /**
   * Disable all registered loaders (main loader and its childs)
   *
   * @param   ldelay   Amount of time in milliseconds to wait before disabling
   *   all loaders.
   * @returns A tuple including in this order:
   *   - the amount of loaders that were enabled and has been disabled;
   *   - the total number of loaders.
   */
  disableAll(ldelay?: number): [number, number] {
    let disabled = 0;
    const childs: Loader[] = Object.keys(this.instances).map((name) => this.instances[name]);
    const loaders: Loader[] = [this, ...childs];

    loaders.forEach((loader: Loader) => {
      disabled += loader.off(ldelay).enabled ? 1 : 0;
    });

    return [disabled, loaders.length];
  }
}
