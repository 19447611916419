import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { filter, take } from 'rxjs';
import {
  SelectComponent,
  SelectOption,
  SelectOptionOrGroup,
  SelectOptionValue
} from 'src/app/components/generic/form/select/select.component';
import { LoadingComponent } from 'src/app/components/generic/loading/loading.component';
import { IHighway } from 'src/app/interface/highway.interface';
import { ReportingType } from 'src/app/interface/report.interface';
import { HighwaysService } from 'src/app/services/highways.service';
import { FormHelper } from 'src/app/tools/form.helper';

import { DEFAULT_PAGE_SIZE } from '../../generic/list/list.interface';
import { LocationFormHelper } from './location-form.helper';

@Component({
  standalone: true,
  selector: 'app-location-fields',
  imports: [
    CommonModule,
    LoadingComponent,
    TranslateModule,
    MatInputModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    SelectComponent
  ],
  providers: [HighwaysService],
  template: `
    <div class="form-row" [formGroup]="pformGroup">
      <app-select
        class="form-field-1-2"
        label="{{ 'GLOBAL.HIGHWAY_U' | translate }}"
        [pformGroup]="pformGroup"
        [pformControlName]="'highway'"
        [loadOptions]="loadHighwayOptions.bind(this)"
      />
      <app-select
        class="form-field-1-2"
        label="{{ 'HIGHWAYS.DIRECTION_LABEL' | translate }}"
        [pformGroup]="pformGroup"
        [pformControlName]="'direction'"
        [options]="directionOptions"
      />
      <mat-form-field class="form-field-1-1">
        <mat-label>{{ 'HIGHWAYS.PR_LABEL' | translate }}</mat-label>
        <input matInput [formControlName]="'pr'" (input)="handlePrChange($event.target.value)" />
        <mat-hint>Ex: 200+500</mat-hint>
        <mat-error *ngIf="isErrorInForm('pr', 'required')">
          {{ 'GLOBAL.ERROR.REQUIRED' | translate }}
        </mat-error>
        <mat-error *ngIf="isErrorInForm('pr', 'landmark_not_found')">
          {{ 'GLOBAL.ERROR.LANDMARK_NOT_FOUND' | translate }}
        </mat-error>
        <mat-error *ngIf="isErrorInForm('pr', 'highway_missing')">
          {{ 'GLOBAL.ERROR.HIGHWAY_MISSING' | translate }}
        </mat-error>
        <mat-error *ngIf="isErrorInForm('pr', 'direction_missing')">
          {{ 'GLOBAL.ERROR.DIRECTION_MISSING' | translate }}
        </mat-error>
        <mat-error *ngIf="isErrorInForm('pr', 'pattern')">
          {{ 'GLOBAL.ERROR.PATTERN' | translate: { hint: '1+200' } }}
        </mat-error>
      </mat-form-field>
    </div>
  `
})
export class LocationFieldsComponent implements OnInit {
  @Input() public pformGroup: UntypedFormGroup;
  @Input() public pformControlName: string;
  @Output() validPrChange = new EventEmitter<string>();

  public reportingTypeOptions: SelectOptionOrGroup<ReportingType>[];
  public directionOptions: SelectOption[];

  public isErrorInForm = (controlName: string, errorName: string): boolean =>
    FormHelper.isErrorInForm(this.pformGroup, controlName, errorName);

  constructor(
    private highwaysService: HighwaysService,
    private locationFormHelper: LocationFormHelper
  ) {}

  ngOnInit(): void {
    this.pformGroup.get('direction')?.disable();
    this.handleHighwayValueChange(this.pformGroup.get('highway')?.value, true);
    this.pformGroup.get('highway')?.valueChanges.subscribe(async (value) => this.handleHighwayValueChange(value));
  }

  public handlePrChange(event: string) {
    this.pformGroup
      .get('pr')
      ?.statusChanges.pipe(
        filter((e) => e !== 'PENDING'),
        take(1)
      )
      .subscribe((e) => {
        if (e !== 'VALID') return;
        this.validPrChange.emit(event);
      });
  }

  private async handleHighwayValueChange(value: SelectOptionValue<string> | null, isInit = false): Promise<void> {
    const highwayId = value?.key;
    if (!highwayId) {
      return;
    }
    const highway = await this.highwaysService.get(highwayId);
    if (!isInit) {
      this.pformGroup.get('direction')?.setValue(null);
      this.pformGroup.get('pr')?.setValue(null);
    }
    this.updateDirectionOptions(highway);
  }

  public async loadHighwayOptions(search: string): Promise<SelectOptionOrGroup[]> {
    const { items: highways } = await this.highwaysService.getAll({
      q: search,
      limit: DEFAULT_PAGE_SIZE,
      locations: false
    });
    return highways.map((e) => LocationFormHelper.makeHighwayOption(e));
  }

  private updateDirectionOptions(highway: IHighway): void {
    this.directionOptions = [
      this.locationFormHelper.makeDirectionOption('1', highway),
      this.locationFormHelper.makeDirectionOption('2', highway)
    ];
    this.pformGroup.get('direction')?.enable();
  }
}
