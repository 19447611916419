import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { from, of as observableOf } from 'rxjs';
import { SelectOption } from 'src/app/components/generic/form/select/select.component';
import { DropdownQuestion } from 'src/app/components/generic/questions/question-dropdown';
import { TextboxQuestion } from 'src/app/components/generic/questions/question-textbox';
import { IHighway } from 'src/app/interface/highway.interface';
import { Coordinates } from 'src/app/interface/location.interface';
import { LocationsService } from 'src/app/services/locations.service';
import { ReportsService } from 'src/app/services/reports.service';

type LocationQuestions = [[DropdownQuestion, DropdownQuestion], [TextboxQuestion]];

/**
 * This helper handles 2 dynamic form rows, for 3 fields: "highway", "direction" and "pk"
 *
 * Usage:
 * use `generateQuestions` to generate your dynamic form questions,
 * call `setFormGroup` when your formGroup changes
 *
 * @example // Render a map linked with the form:
 * <app-location-form-map (markerMove)="locationFormHelper.handleMarkerMoved($event)" />
 */
@Injectable()
export class LocationFormHelper {
  private highways: IHighway[];
  private questions: LocationQuestions;
  private formGroup: UntypedFormGroup;

  private selectedHighwayId: string | null = null;
  private selectedDirection: string | null = null;

  constructor(
    private locationsService: LocationsService,
    private reportsService: ReportsService,
    private translate: TranslateService
  ) {}

  public setFormGroup(value: UntypedFormGroup): void {
    this.formGroup = value;
  }

  public generateQuestions(highways: IHighway[]): LocationQuestions {
    this.highways = highways;
    this.questions = [
      [
        new DropdownQuestion({
          key: 'highway',
          label: 'GLOBAL.HIGHWAY_U',
          value: '',
          controls: [{ key: 'required' }],
          pristine: true,
          order: 3,
          size: '1-2',
          disabled: false,
          options: [
            {
              group: 'REPORTING.FORM.SECTOR_HIGHWAYS',
              items: JSON.parse(localStorage.sectorsHighways).map((h: any) => ({ key: h.UID, value: h.name }))
            },
            {
              group: 'REPORTING.FORM.ALL_HIGHWAYS',
              items: highways.map((d) => ({
                key: d.UID,
                value: `${d.name}`
              }))
            }
          ],
          events: {
            change: (value: string, _event: any) => {
              this.selectedHighwayId = value;
              this.updateDirectionOptions(value);
            }
          }
        }),
        new DropdownQuestion({
          key: 'direction',
          label: 'HIGHWAYS.DIRECTION_LABEL',
          value: '',
          controls: [{ key: 'required' }],
          pristine: true,
          order: 4,
          size: '1-2',
          disabled: true,
          options: [],
          events: {
            change: (value: string, _event: any) => {
              this.selectedDirection = value;
            }
          }
        })
      ],
      [
        new TextboxQuestion({
          actions: ['change'],
          key: 'pk',
          label: 'HIGHWAYS.PR_LABEL',
          value: '',
          controls: [
            { key: 'required' },
            {
              key: 'pattern',
              pattern: /^[0-9]+[.,+][0-9]{3}$/
            },
            {
              key: 'async',
              standalone: false,
              dependencies: ['highway', 'direction'],
              fn: (value: any) => {
                if (!this.selectedHighwayId || !this.selectedDirection) {
                  const errors: { [key: string]: any } = {};
                  if (!this.selectedHighwayId) {
                    errors.highway_missing = true;
                  }
                  if (!this.selectedDirection) {
                    errors.direction_missing = true;
                  }
                  return observableOf(errors);
                }

                const highway = this.highways.find((e) => e.UID === this.selectedHighwayId);
                return from(
                  this.reportsService
                    .landmarkExists(highway || this.selectedHighwayId, this.selectedDirection, value)
                    .then((landmarkExists) => (!landmarkExists ? { landmark_not_found: { value } } : null))
                );
              },
              errors: {
                landmark_not_found: 'GLOBAL.ERROR.LANDMARK_NOT_FOUND',
                highway_missing: 'GLOBAL.ERROR.HIGHWAY_MISSING',
                direction_missing: 'GLOBAL.ERROR.DIRECTION_MISSING'
              }
            }
          ],
          pristine: true,
          order: 5,
          size: '1-1',
          disabled: false,
          hint: '1+200'
        })
      ]
    ];
    return this.questions;
  }

  private updateDirectionOptions(highwayId?: string | null): void {
    const highway = highwayId && this.highways.find((e) => e.UID === highwayId);
    if (!highway) {
      return;
    }

    const fieldId = this.questions[0].findIndex((e) => e.key === 'direction');
    this.questions[0][fieldId].options = [
      {
        key: '1',
        value: `${this.translate.instant('GLOBAL.DIRECTION')} 1 : ${highway.origin} > ${highway.destination}`
      },
      {
        key: '2',
        value: `${this.translate.instant('GLOBAL.DIRECTION')} 2 : ${highway.destination} > ${highway.origin}`
      }
    ];
    this.questions[0][fieldId].disabled = false;
  }

  private setFormValue(question: DropdownQuestion | TextboxQuestion, value: string | null): void {
    question.updateValue(value);
    this.formGroup.controls[question.key].setValue(value);
  }

  public handleMarkerMoved(coords: Coordinates): void {
    this.reportsService.getLocationsFromCoordinates(coords).then((locations) => {
      locations = locations || [];
      if (locations.length === 0) {
        return;
      }
      const interPoint = this.locationsService.buildLandmarkFromPoints(locations, coords);
      this.selectedHighwayId = interPoint.highwayId;
      this.selectedDirection = interPoint.direction.toString();
      this.updateDirectionOptions(this.selectedHighwayId);
      this.setFormValue(this.questions[0][0], this.selectedHighwayId);
      this.setFormValue(this.questions[0][1], this.selectedDirection);
      // Get the meters number from a float that represents a kilometer value. Handle floating point imprecision
      this.setFormValue(this.questions[1][0], interPoint.value.toFixed(3).replace(/\./g, '+'));
      this.questions[0][1].disabled = false;
      this.formGroup.updateValueAndValidity({ emitEvent: true });
    });
  }

  public static makeHighwayOption(highway: IHighway): SelectOption {
    const value = {
      key: highway.UID,
      label: highway.name
    };
    return { value, original: highway };
  }

  public makeDirectionOption(direction: string, highway: IHighway): SelectOption {
    return direction === '1'
      ? {
          value: {
            key: '1',
            label: `${this.translate.instant('GLOBAL.DIRECTION')} 1 : ${highway.origin} > ${highway.destination}`
          }
        }
      : {
          value: {
            key: '2',
            label: `${this.translate.instant('GLOBAL.DIRECTION')} 2 : ${highway.destination} > ${highway.origin}`
          }
        };
  }

  public prValidator(): AsyncValidatorFn {
    return async (control: AbstractControl): Promise<ValidationErrors | null> => {
      // Pattern matching is put here instead of being in sync Validators to stop useless backend calls
      if (!control.value.match(/^[0-9]+[.,+][0-9]{3}$/)) {
        return { pattern: true };
      }
      const selectedHighwayId = control.parent?.get('highway')?.value?.key as string | undefined;
      const selectedDirection = control.parent?.get('direction')?.value?.key as string | undefined;
      if (!selectedHighwayId || !selectedDirection) {
        const errors: Record<string, true> = {};
        if (!selectedHighwayId) {
          errors.highway_missing = true;
        }
        if (!selectedDirection) {
          errors.direction_missing = true;
        }
        return errors;
      }

      const landmarkExists = await this.reportsService.landmarkExists(
        selectedHighwayId,
        selectedDirection,
        control.value
      );
      return !landmarkExists ? { landmark_not_found: true } : null;
    };
  }
}
