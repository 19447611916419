import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { GoogleMap, GoogleMapsModule, MapInfoWindow, MapMarker, MapMarkerClusterer } from '@angular/google-maps';
import { Router } from '@angular/router';
import dayjs from 'dayjs';
import _ from 'lodash';
import { IInfrastructure } from 'src/app/interface/infrastructure.interface';
import { BoundingBox, Coordinates } from 'src/app/interface/location.interface';
import { IReporting } from 'src/app/interface/report.interface';
import { LocationsService } from 'src/app/services/locations.service';
import { GeneralHelper } from 'src/app/tools/general.helper';
import { MapsHelper } from 'src/app/tools/maps.helper';

import { InfrastructureInfoWindowComponent } from './info-windows/infrastructure-info-window.component';
import { ReportingInfoWindowComponent } from './info-windows/reporting-info-window.component';
import mapTypeStyles from './map-type-styles';

export type CenterChangeEvent = { coords: Coordinates; gmCoords: google.maps.LatLngLiteral };
export type MapClickEvent = google.maps.MapMouseEvent;
export type MarkerDragEvent = { event: google.maps.MapMouseEvent; marker: MarkerInfo };
export type MarkerClickEvent = { event: google.maps.MapMouseEvent; marker: MarkerInfo };

interface MarkerInfoBase {
  showInfos: boolean;
  markerType: 'generic' | 'reporting' | 'infrastructure';
  coordinates: Coordinates;
  iconUrl?: string;
  draggable?: boolean;
}

export interface GenericMarker extends MarkerInfoBase {
  markerType: 'generic';
}

export interface InfrastructureMarker extends MarkerInfoBase {
  markerType: 'infrastructure';
  infrastructure: IInfrastructure;
  data: {
    type: {
      name: string;
      picto?: string;
    };
  };
}

export interface ReportingMarker extends MarkerInfoBase {
  markerType: 'reporting';
  reporting: IReporting;
  data: {
    date: dayjs.Dayjs;
    svgIcon: string;
    isRegaCesar: boolean;
  };
}

export type MarkerInfo = GenericMarker | InfrastructureMarker | ReportingMarker;

@Component({
  standalone: true,
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  imports: [CommonModule, GoogleMapsModule, ReportingInfoWindowComponent, InfrastructureInfoWindowComponent]
})
export class MapComponent implements OnInit {
  private static defaultCoordinates: Coordinates = { latitude: 47.0380276, longitude: 3.1843412 };
  private static defaultZoom = 6;

  public mapsApiLoaded: boolean = false;
  private apiPromise: Promise<void>;
  private mapLoadedPromise: Promise<void>;
  private mapsHelper: MapsHelper;
  private locationsService: LocationsService;

  public gmCenter: google.maps.LatLngLiteral;
  public style: google.maps.MapTypeStyle[] = mapTypeStyles;
  public currentMarker?: MarkerInfo;

  private didFirstLoadWithMarkers = false;

  @ViewChild(GoogleMap) map: GoogleMap;
  @ViewChild(MapMarkerClusterer) cluster?: MapMarkerClusterer;
  @ViewChild(MapInfoWindow) infoWin?: MapInfoWindow;

  @Input() draggable: boolean = true;
  @Input() fullscreenControl: boolean = true;
  @Input() zoomControl: boolean = true;
  @Input() streetViewControl: boolean = true;
  @Input() scrollwheel: boolean = true;
  @Input() mapTypeControl: boolean = true;
  @Input() minZoom: number = 1;
  @Input() maxZoom: number = 20;
  @Input() fitMarkersOnLoad: boolean = true;

  private _markers: MarkerInfo[] = [];
  @Input()
  set markers(newMarkers) {
    this._markers = newMarkers || [];
    if (!newMarkers.length) {
      this.didFirstLoadWithMarkers = false;
    } else if (!this.didFirstLoadWithMarkers) {
      this.didFirstLoadWithMarkers = true;
      if (this.fitMarkersOnLoad) {
        this.scheduleFitMarkers();
      }
    }
  }
  get markers() {
    return this._markers;
  }

  private _boundingBox?: BoundingBox;
  @Input()
  set boundingBox(value: BoundingBox | undefined) {
    if (!value || _.isEqual(this._boundingBox, value)) {
      return;
    }
    this._boundingBox = value;
    this.applyBoundingBox(value);
  }
  get boundingBox() {
    return this._boundingBox;
  }

  private _center: Coordinates;
  @Input()
  set center(newCenter: Coordinates) {
    if (
      !newCenter ||
      !isFinite(newCenter.latitude) ||
      !isFinite(newCenter.longitude) ||
      _.isEqual(this._center, newCenter)
    ) {
      return;
    }
    this._center = newCenter;
    this.gmCenter = MapsHelper.coordsToGMCoords(newCenter);
    this.centerChange.emit({ coords: this._center, gmCoords: this.gmCenter });
  }
  get center() {
    return this._center;
  }

  private _zoom?: number;
  @Input()
  set zoom(newZoom) {
    if (newZoom === undefined) {
      newZoom = MapComponent.defaultZoom;
    }
    if (_.isEqual(newZoom, this._zoom)) {
      return;
    }
    const z = _.clamp(newZoom, Math.max(this.minZoom, 1), Math.min(this.maxZoom, 22));
    this._zoom = z;
    this.zoomChange.emit(this._zoom);
  }
  get zoom() {
    return this._zoom;
  }
  @Output() zoomChange: EventEmitter<number> = new EventEmitter<number>();

  @Output() centerChange = new EventEmitter<CenterChangeEvent>();
  @Output() mapClick = new EventEmitter<MapClickEvent>();
  @Output() markerDrag = new EventEmitter<MarkerDragEvent>();
  @Output() markerClick = new EventEmitter<MarkerClickEvent>();

  constructor(
    private router: Router,
    httpClient: HttpClient
  ) {
    this.mapsHelper = new MapsHelper(httpClient);
    this.locationsService = new LocationsService(httpClient);
    this.apiPromise = this.mapsHelper.loadMapsApi();
  }

  ngOnInit(): void {
    this.mapLoadedPromise = this.apiPromise.then(async () => {
      this.mapsApiLoaded = true;
      const initialCenter: Coordinates = this.center || MapComponent.defaultCoordinates;
      this.center = initialCenter;
      await GeneralHelper.wait(0);
    });
  }

  public handleMapClick(event: google.maps.MapMouseEvent): void {
    this.mapClick.emit(event);
  }

  public handleMapIdle(): void {
    if (!this.map) {
      return;
    }

    const newZoom = this.map.getZoom();
    const newCenter = MapsHelper.gmCoordsToCoords(this.map.getCenter());

    if (newZoom !== undefined && newZoom !== this.zoom) {
      this.zoom = newZoom;
    }

    if (newCenter && !_.isEqual(newCenter, this.center)) {
      this.center = newCenter;
    }
  }

  public handleMarkerDragend(event: google.maps.MapMouseEvent, marker: MarkerInfo): void {
    const coords = MapsHelper.gmCoordsToCoords(event.latLng);
    if (!coords) {
      return;
    }
    marker.coordinates = coords;
    this.markerDrag.emit({ event, marker });
  }

  public handleMarkerClick(event: google.maps.MapMouseEvent, marker: MarkerInfo): void {
    this.markerClick.emit({ event, marker });

    // TODO: move this behavior to the parent components that need it
    if (marker.markerType === 'reporting') {
      this.goToDetails(marker.reporting._id!);
    }
  }

  private goToDetails(reportingId: string): void {
    this.router.navigateByUrl(`reporting/${reportingId}/details`);
  }

  public openInfoWindow(_event: google.maps.MapMouseEvent, marker: MarkerInfo, mapMarker: MapMarker): void {
    this.currentMarker = marker;
    setTimeout(() => {
      this.infoWin?.open(mapMarker);
    });
  }

  public closeInfoWindow(/*event: google.maps.MapMouseEvent*/): void {
    this.currentMarker = undefined;
    if (this.infoWin) {
      this.infoWin.close();
    }
  }

  private async scheduleFitMarkers(): Promise<void> {
    await this.mapLoadedPromise;
    await this.fitMarkersIntoView(this._markers, true);
  }

  private async fitMarkersIntoView(selectedMarkers: MarkerInfo[], allowZoom: boolean = true): Promise<void> {
    const map = this.map;
    if (!window.google?.maps || !_.isArray(selectedMarkers) || selectedMarkers.length === 0 || !map) {
      return;
    }
    const oldMap = _.cloneDeep(map);
    const oldCenter = map.getCenter()!;

    const oldBounds = oldMap.getBounds();
    const oldZoom = oldMap.getZoom()!;
    let outOfBoundPoints = false;
    const bounds = new google.maps.LatLngBounds();
    for (const marker of selectedMarkers) {
      const latlng = new google.maps.LatLng(marker.coordinates.latitude, marker.coordinates.longitude);
      bounds.extend(latlng);
      if (!oldBounds || !oldBounds.contains(latlng)) {
        outOfBoundPoints = true;
      }
    }

    map.fitBounds(bounds, 10);

    if (!allowZoom && !outOfBoundPoints) {
      map.googleMap?.setZoom(oldZoom);
      map.googleMap?.setCenter(oldCenter);
    }
  }

  private async applyBoundingBox(boundingBox: BoundingBox): Promise<void> {
    await this.mapLoadedPromise;
    const SWBound = new google.maps.LatLng(boundingBox.minY, boundingBox.minX);
    const NEBound = new google.maps.LatLng(boundingBox.maxY, boundingBox.maxX);
    const latLngBounds = new google.maps.LatLngBounds(SWBound, NEBound);
    this.map.fitBounds(latLngBounds);
  }
}
