import { QuestionBase } from './question-base';

export class DropdownQuestion extends QuestionBase<string> {
  controlType = 'dropdown';
  options: { key: string; value: string }[] = [];

  constructor(options: { options?: any[]; [key: string]: any } = {}) {
    super(options);
    this.options = options.options || [];
  }
}
