import { CommonModule, Location } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CancelButtonComponent } from 'app/components/generic/form/cancel-button/cancel-button.component';
import { FileInputComponent } from 'app/components/generic/form/file-input/file-input.component';
import { SelectComponent, SelectOption, SelectOptionValue } from 'app/components/generic/form/select/select.component';
import { SubmitButtonComponent } from 'app/components/generic/form/submit-button/submit-button.component';
import { TextareaComponent } from 'app/components/generic/form/textarea/textarea.component';
import { LocationFieldsComponent } from 'app/components/locations/location-form/location-fields.component';
import { LocationFormHelper } from 'app/components/locations/location-form/location-form.helper';
import { LocationFormMapComponent } from 'app/components/locations/location-form/location-form-map.component';
import { AffectedLaneCustomLabel, IReporting, ReportingWeatherConditionReturn } from 'app/interface/report.interface';
import { ITeam } from 'app/interface/team.interface';
import { ReportsService } from 'app/services/reports.service';
import { TeamsService } from 'app/services/teams.service';
import { TitleService } from 'app/services/title.service';
import { titleCase } from 'app/tools/stringTransform';
import _ from 'lodash';
import { lastValueFrom, Subject } from 'rxjs';
import { DateInputComponent } from 'src/app/components/generic/form/date-input/date-input.component';
import { IAccidentLanes, LaneType } from 'src/app/interface/accident-reporting.interface';
import { BoundingBox, Coordinates } from 'src/app/interface/location.interface';
import { HighwaysService } from 'src/app/services/highways.service';
import { LocationsService } from 'src/app/services/locations.service';
import { FormGroupFromValues, FormHelper } from 'src/app/tools/form.helper';
import { ObjectHelper } from 'src/app/tools/object.helper';

import { ReportingSubtypeSelectComponent } from '../types/reporting-subtype-select.field.component';
import { ReportingTypeSelectComponent } from '../types/reporting-type-select.field.component';
import { TypesModalComponent } from '../types/types-modal/types-modal.component';

interface ReportingFormValues {
  description: string | null;
  reportingType: string | SelectOptionValue<string> | null;
  reportingSubtype: string | SelectOptionValue<string> | null;
  source: string | SelectOptionValue<string> | null;
  onCallUser: string | SelectOptionValue<string> | null;
  affectedToUser: string | SelectOptionValue<string> | null;
  weatherConditions: string | SelectOptionValue<string> | null;
  affectedLanes: string | SelectOptionValue<string> | null;
  dueDate: Date | null;
  images: File[] | null;
  // Location
  coordinates: Coordinates;
  highway: string | SelectOptionValue<string> | null;
  direction: string | SelectOptionValue<string> | null;
  pr: string | null;
}

@Component({
  standalone: true,
  selector: 'app-reporting-form',
  templateUrl: './reporting-form.component.html',
  styleUrls: ['./reporting-form.component.scss'],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ReportingTypeSelectComponent,
    ReportingSubtypeSelectComponent,
    LocationFieldsComponent,
    SelectComponent,
    TranslateModule,
    LocationFormMapComponent,
    SubmitButtonComponent,
    CancelButtonComponent,
    TextareaComponent,
    FileInputComponent,
    DateInputComponent
  ]
})
export class ReportingFormComponent implements OnInit, AfterViewChecked {
  public titleForm = ''; // Form title (Report or Task)
  public sourceOptions: SelectOption<string>[];
  public affectableUserOptions: SelectOption<string>[];
  public weatherConditionOptions: SelectOption<string>[];
  public affectedLaneOptions: SelectOption<string>[];
  public boundingBox?: BoundingBox;

  @Input() public isEdit: boolean = false;
  @Input() public existingReporting: IReporting | undefined;

  public markerCoordinates: Subject<Coordinates> = new Subject<Coordinates>();

  public formGroup: FormGroupFromValues<ReportingFormValues>;

  constructor(
    private title: TitleService,
    private reportsService: ReportsService,
    private teamsService: TeamsService,
    private translate: TranslateService,
    private locationFormHelper: LocationFormHelper,
    route: ActivatedRoute,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private locationsService: LocationsService,
    private highwaysService: HighwaysService,
    private location: Location
  ) {
    this.title.setTitle('GLOBAL.NEW_REPORT');
    route.data.subscribe((data) => {
      this.title.setTitle(data.breadcrumb);
      this.titleForm = data.breadcrumb;
    });
    dialog = true as any;
    changeDetectorRef = true as any;
  }

  ngOnInit(): void {
    this.formGroup = FormHelper.group<ReportingFormValues>({
      description: [null],
      reportingType: [null, [Validators.required]],
      reportingSubtype: [null, [Validators.required]],
      source: [null],
      onCallUser: [null],
      affectedToUser: [null],
      weatherConditions: [null],
      affectedLanes: [null],
      // Location
      highway: [null, [Validators.required]],
      direction: [null, [Validators.required]],
      pr: [null, [Validators.required], [this.locationFormHelper.prValidator()]],
      coordinates: [null],
      dueDate: [null],
      images: [null]
    });

    if (this.isEdit) {
      const reporting = this.existingReporting!;
      this.formGroup.reset({
        description: reporting?.description || null,
        reportingType: { key: reporting.type.id, label: reporting.type.label! },
        reportingSubtype: !reporting.subtype ? null : { key: reporting.subtype.id, label: reporting.subtype.label! },
        source: !reporting.source ? null : { key: reporting.source, label: reporting.source },
        affectedToUser: !reporting.affected_to ? null : this.makeUserOption(reporting.affected_to).value,
        onCallUser: !reporting.on_call_user ? null : this.makeUserOption(reporting.on_call_user).value,
        //
        highway: { key: reporting.location.highway_id, label: reporting.location.highway_name },
        direction: { key: String(reporting.location.direction), label: 'direction x' },
        pr: reporting.location.display,
        coordinates: reporting.coordinates,
        dueDate: reporting.due_date || null,
        images: null
      });
    }

    this.loadAffectedLaneOptions();
    this.loadSourceOptions();
    this.loadUserOptions();
    this.loadWeatherConditionsOptions();

    if (!this.isEdit) {
      this.locationsService.getMyBoundingBox().then((boundingBox) => {
        if (boundingBox && !this.boundingBox) {
          this.boundingBox = boundingBox;
        }
      });
    }

    this.formGroup.get('highway')?.valueChanges.subscribe(async (value) => {
      const highwayId = (value as SelectOptionValue<string>)?.key;
      if (highwayId) {
        const highway = await this.highwaysService.get(highwayId);
        if (highway.boundingBox) {
          this.boundingBox = highway.boundingBox;
        }
      }
    });

    this.formGroup.get('direction')?.statusChanges.subscribe(async (status) => {
      if (status !== 'VALID') {
        return;
      }
      this.formGroup.controls.pr.updateValueAndValidity();
      await this.getCoords();
    });
  }

  public handleValidPrChange() {
    this.getCoords();
  }

  public async getCoords() {
    const highwayForm = this.formGroup.get('highway');
    const prForm = this.formGroup.get('pr');
    const directionForm = this.formGroup.get('direction');
    if (!highwayForm || !prForm || !directionForm) {
      return;
    }

    const highway = (highwayForm.value as SelectOptionValue<string>)?.key;
    const direction = (directionForm.value as SelectOptionValue<string>)?.key;
    const pr = prForm.value;
    if (!pr) {
      return;
    }

    if (highwayForm?.valid && prForm.valid && directionForm?.valid) {
      const kilometerPoint = pr!.split('+')[0];
      const landmark = pr!.split('+')[1];
      const coordinates = await this.locationsService.getCoordByLocation(highway, kilometerPoint, landmark, direction);
      this.formGroup.get('coordinates')?.setValue(coordinates);
      this.markerCoordinates.next(coordinates);
    }
  }

  // Fix "ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked" error
  // @see https://stackoverflow.com/questions/53901392/expression-has-changed-after-it-was-checked-previous-value-ng-valid-true-c
  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  private async loadAffectedLaneOptions(): Promise<void> {
    this.affectedLaneOptions = Object.values(AffectedLaneCustomLabel)
      .map((key) => {
        const label = this.translate.instant('REPORTING.FORM.AFFECTED_LANE_CUSTOM_LABEL.' + key.toUpperCase());
        return { value: { key: label, label } };
      })
      .sort((a, b) => a.value.label.localeCompare(b.value.label));
  }

  private async loadSourceOptions(): Promise<void> {
    this.formGroup.get('source')?.disable();
    this.reportsService.getSources().then((sources) => {
      this.sourceOptions = sources.map((e) => ({ value: { key: e, label: e } }));
      this.formGroup.get('source')?.enable();
    });
  }

  private async loadWeatherConditionsOptions(): Promise<void> {
    const weatherConditions = await this.reportsService.getWeatherConditions();
    this.weatherConditionOptions = weatherConditions.map((e) => this.makeWeatherConditionOption(e));
  }

  private async loadUserOptions(): Promise<void> {
    const userTeams: ITeam[] = JSON.parse(localStorage.getItem('teams') || '[]');
    const userTeamsIds = userTeams.map((team: ITeam) => team.id);
    this.formGroup.get('onCallUser')?.disable();
    this.formGroup.get('affectedToUser')?.disable();

    const teams = await lastValueFrom(this.teamsService.getById(userTeamsIds));
    const users = _.flatten(teams.map((team) => team.users || []));
    this.affectableUserOptions = _.uniqBy(users, 'service_number')
      .sort((a, b) => a.last_name.localeCompare(b.last_name) || a.first_name.localeCompare(b.first_name))
      .map((e) => this.makeUserOption(e));
    this.formGroup.get('onCallUser')?.enable();
    this.formGroup.get('affectedToUser')?.enable();
  }

  private makeUserOption(user: {
    first_name: string;
    last_name: string;
    service_number: string;
  }): SelectOption<string> {
    return {
      value: {
        key: user.service_number,
        label: `${user.last_name.toUpperCase()} ${titleCase(user.first_name)}`
      }
    };
  }

  public makeWeatherConditionOption(weatherCondition: ReportingWeatherConditionReturn): SelectOption<string> {
    return {
      value: {
        key: weatherCondition.id,
        label: weatherCondition.label
      }
    };
  }

  async onSubmit(): Promise<void> {
    const form = this.formGroup;
    const values = form.value;

    const highwayId = (values.highway as SelectOptionValue<string>)!.key;
    const direction = (values.direction as SelectOptionValue<string>)!.key;
    const typeId = (values.reportingType as SelectOptionValue<string>)!.key;
    const subtypeId = (values.reportingSubtype as SelectOptionValue<string>)?.key || undefined;
    const affectedTo =
      values.affectedToUser === '' ? null : (values.affectedToUser as SelectOptionValue<string>)?.key || undefined;
    const onCallUser =
      values.onCallUser === '' ? null : (values.onCallUser as SelectOptionValue<string>)?.key || undefined;
    const sourceId = (values.source as SelectOptionValue<string>)?.key || undefined;
    const weatherConditions = (values.weatherConditions as SelectOptionValue<string>)?.key || undefined;
    const weatherConditionsLabel =
      (weatherConditions && this.weatherConditionOptions.find((e) => e.value.key === weatherConditions)?.value.label) ||
      weatherConditions;

    const formWeather = !weatherConditions
      ? undefined
      : {
          date: new Date(),
          type: 'FORM_TYPE_WEATHER',
          version: 1,
          payload: {
            weather_weather_conditions: weatherConditionsLabel
          }
        };

    const images = values.images;
    const affectedLaneLabel = _.isString(values.affectedLanes)
      ? values.affectedLanes
      : values.affectedLanes?.key || undefined;
    const formLanes: IAccidentLanes | undefined = !affectedLaneLabel
      ? undefined
      : {
          currentDirection: {
            number: 1,
            impacted: [{ type: LaneType.OTHER, selected: true, metadata: affectedLaneLabel }]
          },
          oppositeDirection: {
            number: 0,
            impacted: []
          }
        };

    const pkObj = await this.reportsService.checkPk(highwayId, direction, values.pr!);
    if (!pkObj || pkObj.pkInf === undefined) {
      return;
    }

    const body: any = {
      type: typeId,
      sub_type: subtypeId,
      description: values.description || undefined,
      affected_to: affectedTo,
      on_call_user: onCallUser,
      location: {
        landmark_id: pkObj.pkInf.landmark_id,
        offset: pkObj.distance
      },
      coordinates: values.coordinates || undefined,
      due_date: values.dueDate || undefined,
      source: sourceId || undefined,
      lanes: formLanes,
      formWeather
    };
    ObjectHelper.deleteUndefinedKeys(body);
    if (this.isEdit) {
      delete body.source;
      delete body.lanes;
      delete body.formWeather;
    }

    let reportingId: string;

    if (!this.isEdit) {
      reportingId = await this.reportsService.add(body);
    } else {
      reportingId = this.existingReporting!._id!;
    }

    if (images?.length) {
      await this.reportsService.sendManyPictures(images, reportingId);
    }

    if (this.isEdit) {
      await this.reportsService.patch(body, reportingId);
    }

    this.location.back();
  }

  public openTypesModal(): void {
    this.dialog.open(TypesModalComponent, { width: '50%', maxHeight: '80vh', autoFocus: false });
  }
}
