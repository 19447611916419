import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-cell',
  templateUrl: './cell.component.html',
  styleUrls: ['./cell.component.scss']
})
export class CellComponent implements OnDestroy {
  @Input() params: any = {};
  @Input() template: any[] = [];

  cell: any = {};
  options: any = {
    events: {}
  };

  private subscriptions: Subscription = new Subscription();

  @Output() action: EventEmitter<any> = new EventEmitter<any>();

  private _element: any;
  @Input() set element(element) {
    this._element = element;
    this.parseCellDatas(element[this.params.key]);
  }
  get element() {
    return this._element;
  }

  constructor(private translations: TranslateService) {}

  emitEvent(name: string, target?: { events: { [key: string]: Function } }, event?: Event): void {
    const eventsBase = !target || !target.hasOwnProperty('events') ? this.options.events : target.events;
    if (eventsBase.hasOwnProperty(name) && typeof eventsBase[name] === 'function') {
      eventsBase[name]();
    }
    if (event) {
      event.stopPropagation();
    }
  }

  readOption(option: any): any {
    return typeof option === 'function' ? option() : option;
  }

  actionEmitter(data: any, name?: string): () => void {
    return (): void => {
      this.action.emit({
        type: name,
        data: data
      });
    };
  }

  private parseCellDatas(cellValue: any) {
    const options = this.params.options || {};
    this.cell = {
      type: this.params.type,
      color: this.params.color,
      value: cellValue,
      displayValue: cellValue
    };

    if (typeof cellValue === 'string' && cellValue.trim() !== '') {
      this.translations.get(cellValue).subscribe((translation) => (this.cell.displayValue = translation));
    }

    this.options = {
      ...options,
      events: this.parseActions(this.params.events)
    };

    switch (this.params.type) {
      case 'button':
        this.parseButtonsCellDatas(cellValue);
        break;
      case 'boolean':
        this.parseBooleanCellDatas();
        break;
    }
  }

  private parseButtonsCellDatas(cellValue: any) {
    this.cell.buttons = [];
    for (const button of cellValue) {
      if (button) {
        this.cell.buttons.push({
          options: {},
          ...button,
          events: this.parseActions(button.events)
        });
      } else {
        this.cell.buttons.push(null);
      }
    }
  }

  private parseBooleanCellDatas() {
    const trueLabel = typeof this.params.trueLabel === 'string' ? this.params.trueLabel : 'GLOBAL.YES';
    const falseLabel = typeof this.params.falseLabel === 'string' ? this.params.falseLabel : 'GLOBAL.NO';

    if (trueLabel) {
      this.subscriptions.add(
        this.translations.get(trueLabel).subscribe((translation: string) => {
          this.cell.trueLabel = translation;
        })
      );
    }

    if (falseLabel) {
      this.subscriptions.add(
        this.translations.get(falseLabel).subscribe((translation: string) => {
          this.cell.falseLabel = translation;
        })
      );
    }
  }

  private parseActions(events: { [key: string]: string }) {
    if (!events) {
      return {};
    }

    const actions: { [key: string]: Function } = {};
    Object.keys(events).forEach((event: string) => {
      const eventName: string = events[event];
      actions[event] = this.actionEmitter(this.element, eventName);
    });

    return actions;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
