<div>
  <form [formGroup]="form" class="form">
    <mat-form-field class="filter" floatLabel="never">
      <mat-label>Recherche par message</mat-label>
      <input matInput [formControlName]="'commentFilter'" />
    </mat-form-field>
  </form>
</div>
<mat-table class="mat-elevation-z8" [dataSource]="dataSource">
  <ng-container matColumnDef="date">
    <mat-header-cell *matHeaderCellDef>Date</mat-header-cell>
    <mat-cell *matCellDef="let row">{{ row.date | date: 'short' }}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="comment">
    <mat-header-cell *matHeaderCellDef>Message </mat-header-cell>
    <mat-cell *matCellDef="let row">{{ row.comment }}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="actions">
    <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <button mat-raised-button color="warn" (click)="handleAdd(row.id)">{{ 'GLOBAL.ADD' | translate }}</button>
      <button mat-raised-button (click)="handleIgnore(row.id)">{{ 'GLOBAL.IGNORE' | translate }}</button>
    </mat-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="displayedColumns"> </mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>
