import _ from 'lodash';

interface IObjectOrArray {
  [key: string]: any;
  [key: number]: any;
}

interface IDiffObject {
  [key: string]: IDiff;
}

interface IDiff {
  operation: Operation;
  original?: any;
  updated?: any;
}

type Operation = 'creation' | 'update' | 'deletion';

type Mode = 'replace' | 'patch';

const isValidObject = (obj: any): obj is IObjectOrArray => typeof obj === 'object' && obj !== null;

/**
 * Computes the minimal set of operations to perform to transform the first
 * object to the second.
 *
 * @param mode Either "replace" or "patch"; the "patch" mode only includes
 *   keys creation or modification, whereas the "replace" mode also includes
 *   deletion operations.
 * @param orig The original object.
 * @param updt The final object ("replace" mode) or the patch to apply ("patch"
 *   mode).
 * @param keyRoot The initial path to the object properties; this value has no
 *   influence on the keys actually compared, and is merely prepended to all
 *   key names of the returned diff. Primarily used internally for recursive
 *   diff computation, but may have some use-cases.
 */
export const diff = (mode: Mode, orig: any, updt: any, keyRoot?: string): IDiffObject => {
  let objectDiff: IDiffObject = {};

  if (orig === updt) {
    return {};
  }

  if (!isValidObject(orig) || !isValidObject(updt)) {
    return {
      [keyRoot || '__root__']: {
        operation: 'update',
        original: orig,
        updated: updt
      }
    };
  }

  const allKeys = new Set([...Object.keys(orig), ...Object.keys(updt)]);

  for (const key of Array.from(allKeys)) {
    const keyPath = keyRoot ? [keyRoot, key].join('.') : key;

    // Check whether the key exists in the original and/or update object
    if (!orig.hasOwnProperty(key)) {
      // Does not exist in original object: register creation
      objectDiff[keyPath] = {
        operation: 'creation',
        updated: updt[key as keyof typeof updt]
      };
    } else if (!updt.hasOwnProperty(key) || (Array.isArray(updt) && !(key in updt))) {
      // Does not exist in updated object: register deletion in "replace" mode
      if (mode === 'replace') {
        objectDiff[keyPath] = {
          operation: 'deletion',
          original: orig[key]
        };
      }
    } else if (isValidObject(updt) && isValidObject(orig[key])) {
      // Both keys are objects: compute the diff of the sub-objects
      objectDiff = {
        ...objectDiff,
        ...diff(mode, orig[key], updt[key as keyof typeof updt], keyPath)
      };
    } else if (orig[key] !== updt[key as keyof typeof updt]) {
      // Original and updated keys differ: register update
      objectDiff[keyPath] = {
        operation: 'update',
        original: orig[key],
        updated: updt[key as keyof typeof updt]
      };
    }
  }

  return objectDiff;
};

/**
 * Applies a diff computed by `diff()` on an object (mutates the original).
 *
 * @param orig The original object to mutate.
 * @param objectDiff The diff as returned by `diff()`.
 */
export const apply = (orig: IObjectOrArray, objectDiff: IDiffObject) => {
  for (const keyPath of Object.keys(objectDiff)) {
    const keyDiff = objectDiff[keyPath as keyof IDiff];

    switch (keyDiff.operation) {
      case 'creation':
      case 'update':
        _.set(orig, keyPath, keyDiff.updated);
        break;
      case 'deletion':
        const [parentKeyPath, index] = keyPath.split(/\.(?=[^.]+$)/);
        const parent = _.get(orig, parentKeyPath);
        if (Array.isArray(_.get(orig, parentKeyPath))) {
          parent.splice(+index);
        } else {
          _.unset(orig, keyPath);
        }
        break;
    }
  }
};

/**
 * Computes a diff and applies it directly on an object (mutates the original).
 * This method can be used to mutate an object from one state to another without
 * replacing it entirely by the new object. This is useful when the reference of
 * the original object must be preserved.
 *
 * Shortcut for `apply(obj, diff(mode, obj, updt))`.
 *
 * @param mode Either "replace" or "patch"; the "patch" mode only create and/or
 *   modify existing values, whereas the "replace" mode also removes keys that
 *   does not exist in the updated object.
 * @param orig The original object.
 * @param updt The final object ("replace" mode) or the patch to apply ("patch"
 *   mode).
 */
export const update = (mode: Mode, orig: IObjectOrArray, updt: IObjectOrArray) => {
  const objectDiff = diff(mode, orig, updt);
  apply(orig, objectDiff);

  return objectDiff;
};

/**
 * Alias for `update('patch', obj, updt)`.
 */
export const patch = (orig: IObjectOrArray, updt: IObjectOrArray) => update('patch', orig, updt);

/**
 * Alias for `update('replace', obj, updt)`.
 */
export const replace = (orig: IObjectOrArray, updt: IObjectOrArray) => update('replace', orig, updt);
