import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GenericDialogComponent } from 'app/components/generic/generic-dialog/generic-dialog.component';
import { IListResult } from 'app/interface/list-result.interface';
import { Coordinates, ILocation } from 'app/interface/location.interface';
import {
  IReporting,
  IReportingEvent,
  IReportingResponse,
  ReportingEventType,
  ReportingFilters,
  ReportingState,
  ReportingSubtype,
  ReportingType,
  ReportingTypePriority,
  ReportingWeatherConditionReturn,
  reportStates
} from 'app/interface/report.interface';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import {
  ExternalEventsComponent,
  ReportingExternalEvents
} from '../components/reporting/detail/external-events/external-events.component';
import { ReportingPdfDialogComponent } from '../components/reporting/dialog-pdf/reporting-pdf.dialog.component';
import { IHighway } from '../interface/highway.interface';
import { HighwaysService } from './highways.service';
import { InfrastructuresService } from './infrastructures.service';
import { LocalStorageService } from './local-storage.service';

interface ReportingEventBody {
  typeId: string;
  date?: Date;
  comment?: string;
  interlocutor?: string;
  intervenor?: string;
  interlocutorName?: string;
  intervenorName?: string;
  callType?: string;
  evacuationType?: string;
}

@Injectable()
export class ReportsService {
  public dialogRef: MatDialogRef<GenericDialogComponent> | undefined;

  private reportingTypesSubject = new BehaviorSubject<ReportingType[] | null>(null);
  private reportingTypesObservable = this.reportingTypesSubject.asObservable().pipe(filter(Boolean));
  private reportingSubTypesSubject = new BehaviorSubject<ReportingSubtype[] | null>(null);
  private reportingSubTypesObservable = this.reportingSubTypesSubject.asObservable().pipe(filter(Boolean));
  private fetchedReportingTypes = false;
  private fetchedReportingSubTypes = false;

  private reportingExternalEventsSubject = new BehaviorSubject<ReportingExternalEvents[] | null>(null);
  private reportingExternalEventsObservable = this.reportingExternalEventsSubject.asObservable().pipe(filter(Boolean));

  private sourceStore?: string[];

  constructor(
    private http: HttpClient,
    private snackbar: MatSnackBar,
    private translate: TranslateService,
    private highwaysService: HighwaysService,
    private infrastructuresService: InfrastructuresService,
    public dialog: MatDialog,
    private router: Router
  ) {}

  getBoundaries(offset: string) {
    const intOffset = parseInt(offset, 10);
    const borneInf = Math.floor(intOffset / 100) * 100;
    const borneSup = ((Math.floor(intOffset / 100) + 1) * 100) % 1000;

    return {
      inf: borneInf,
      sup: borneSup
    };
  }

  formatTypes(typesMap: ReportingType[], group?: boolean) {
    if (!group) {
      return typesMap.map((type) => ({
        key: type.id,
        value: type.label
      }));
    }

    const typesByPriority: { [key: string]: any[] } = {
      high: [],
      medium: [],
      low: [],
      other: []
    };
    const priorityMap: { [key: string]: string } = {
      [ReportingTypePriority.RED]: 'high',
      [ReportingTypePriority.ORANGE]: 'medium',
      [ReportingTypePriority.BLUE]: 'low'
    };
    for (const type of typesMap) {
      const priorityKey = type.priority in priorityMap ? priorityMap[type.priority] : 'other';

      if (priorityKey in typesByPriority) {
        typesByPriority[priorityKey].push({
          key: type.id,
          value: type.label
        });
      }
    }

    return Object.keys(typesByPriority)
      .map((priority) => ({
        group: `REPORTING.${priority.toUpperCase()}_PRIORITY`,
        items: typesByPriority[priority]
      }))
      .filter((priorityGroup) => priorityGroup.items.length > 0);
  }

  // TODO!: create a landmarkExists api route instead of downloading every location and check clientside
  async landmarkExists(highwayOrId: IHighway | string, direction: string, landmark: string): Promise<boolean> {
    const [kilometerPoint, offset] = landmark.split(/\D/);
    const boundaries = this.getBoundaries(offset);

    if (isNaN(boundaries.inf) || isNaN(boundaries.sup)) {
      return false;
    }

    const highwayId = _.isString(highwayOrId) ? highwayOrId : highwayOrId.UID;
    const highway = await this.highwaysService.get(highwayId);
    if (!highway) {
      return false;
    }

    if (offset === String(boundaries.inf)) {
      return highway.locations.some((loc) => String(loc.direction) === direction && loc.display === landmark);
    }

    const lowerBoundary = `${kilometerPoint}+${`000${String(boundaries.inf)}`.slice(-3)}`;
    const infKP = highway.locations.find((loc) => String(loc.direction) === direction && loc.display === lowerBoundary);
    return !!infKP;
  }

  async checkPk(
    highwayID: string,
    direction: string,
    pk: string
  ): Promise<{ distance: number; pkInf?: ILocation; pkSup?: ILocation } | undefined> {
    let matchSeparator = '+';
    const arr = pk.match(/\D/);
    if (arr !== null && arr.length > 0) {
      matchSeparator = arr[0];
    }
    const offset = pk.split(matchSeparator, 2)[1];
    pk = pk.split(matchSeparator, 1)[0];
    if (highwayID && direction && pk && offset) {
      const result: {
        pkInf: ILocation | undefined;
        pkSup: ILocation | undefined;
        distance: number;
      } = {
        pkInf: undefined,
        pkSup: undefined,
        distance: 0
      };

      const highway = await this.highwaysService.get(highwayID);
      if (!highway) {
        return;
      }
      const bornes = this.getBoundaries(offset);

      // Invalid data (offset)
      if (isNaN(bornes.inf) || isNaN(bornes.sup)) {
        this.snackbar.open(
          this.translate.instant('GLOBAL.REPORT.ERRORS.INVALID_KILOMETER_POINT'),
          this.translate.instant('GLOBAL.CLOSE_LABEL')
        );

        return result;
      }

      // PK 100m
      if (bornes.inf.toString() === offset.toString()) {
        const pkFound = highway.locations.find(
          (o: ILocation) => o.direction.toString() === direction && o.display === `${pk}+${offset}`
        );

        if (pkFound === undefined) {
          this.snackbar.open(
            this.translate.instant('GLOBAL.REPORT.ERRORS.KILOMETER_POINT_NOT_EXISTS', {
              pk,
              offset,
              highwayName: highway.name,
              direction
            })`Erreur : Le PK ${pk}+${offset} sur ${highway.name} en sens ${direction} n'existe pas`,
            this.translate.instant('GLOBAL.CLOSE_LABEL')
          );
        } else {
          this.snackbar.dismiss();
          result.pkInf = pkFound;
        }
      } else {
        // PK 1m
        const borneInf = String(bornes.inf).padStart(3, '0');
        const borneSup = String(bornes.sup).padStart(3, '0');

        const pkFoundInf = highway.locations.find(
          (o: ILocation) => o.direction.toString() === direction && o.display === `${pk}+${borneInf}`
        );

        const pkFoundSup = highway.locations.find(
          (o: ILocation) => o.direction.toString() === direction && o.display === `${pk}+${borneSup}`
        );

        if (pkFoundInf === undefined) {
          this.snackbar.open(
            this.translate.instant('GLOBAL.REPORT.ERRORS.PREVIOUS_KILOMETER_POINT_NOT_EXISTS', {
              pk,
              offset: borneInf,
              highwayName: highway.name,
              direction
            }),
            this.translate.instant('GLOBAL.CLOSE_LABEL')
          );
        } else {
          result.pkInf = pkFoundInf;
          result.pkSup = pkFoundSup;
          result.distance = parseInt(offset, 10) % 100;
        }
      }

      return result;
    }

    return;
  }

  public getReportingTypesObservable(): Observable<ReportingType[]> {
    if (!this.fetchedReportingTypes) {
      this.getReportingTypes();
    }
    return this.reportingTypesObservable;
  }

  public async getReportingTypes(force: boolean = false): Promise<ReportingType[]> {
    const value = this.reportingTypesSubject.getValue();
    if (!force && value) {
      return value;
    }
    this.fetchedReportingTypes = true;
    const [reportingTypes] = await this.getReportingTypesAndCount();
    this.reportingTypesSubject.next(reportingTypes);
    return reportingTypes;
  }

  public async getReportingTypesAndCount(): Promise<[ReportingType[], number]> {
    const { items, total } = await lastValueFrom(
      this.http.get<{ items: ReportingType[]; total: number }>(`api://reporting-types`)
    );
    return [items, total];
  }

  public getReportingTypesSync(): ReportingType[] {
    const reportingTypes = this.reportingTypesSubject.getValue();
    if (!reportingTypes) {
      throw Error('ReportingService.getReportingTypesSync: reportingTypes are not loaded');
    }
    return reportingTypes;
  }

  public getReportingSubTypesObservable(): Observable<ReportingSubtype[]> {
    if (!this.fetchedReportingSubTypes) {
      this.getReportingSubtypes();
    }
    return this.reportingSubTypesObservable;
  }

  public async getReportingSubtypes(force: boolean = false): Promise<ReportingSubtype[]> {
    const value = this.reportingSubTypesSubject.getValue();
    if (!force && value) {
      return value;
    }
    this.fetchedReportingSubTypes = true;
    const [reportingSubtypes] = await this.getReportingSubtypesAndCount();
    this.reportingSubTypesSubject.next(reportingSubtypes);
    return reportingSubtypes;
  }

  public async getReportingSubtypesAndCount(): Promise<[ReportingSubtype[], number]> {
    const { items, total } = await lastValueFrom(
      this.http.get<{ items: ReportingSubtype[]; total: number }>(`api://reporting-subtypes`)
    );
    return [items, total];
  }

  public async getReportingEventTypes(): Promise<ReportingEventType[]> {
    const [reportingEventTypes] = await this.getReportingEventTypesAndCount();
    return reportingEventTypes;
  }

  public async getReportingEventTypesAndCount(): Promise<[ReportingEventType[], number]> {
    const { items, total } = await lastValueFrom(
      this.http.get<{ items: ReportingEventType[]; total: number }>(`api://reporting-event-types`)
    );
    return [items, total];
  }

  public getReportingExternalEventsObservable(): Observable<ReportingExternalEvents[]> {
    this.getReportingExternalEvents();
    return this.reportingExternalEventsObservable;
  }

  public async getReportingExternalEvents(): Promise<ReportingExternalEvents[]> {
    const reportingExternalEvents = await this.fetchReportingExternalEvents();
    this.reportingExternalEventsSubject.next(reportingExternalEvents);
    return reportingExternalEvents;
  }

  public async fetchReportingExternalEvents(): Promise<ReportingExternalEvents[]> {
    const reportingExternalEvents = await lastValueFrom(
      this.http.get<ReportingExternalEvents[]>(`api://reportings-external-events`)
    );
    return reportingExternalEvents;
  }

  public async postReportingEvent(reportingId: string, body: ReportingEventBody): Promise<IReportingEvent> {
    return lastValueFrom(
      this.http.post<IReportingEvent>(`api://reportings/${reportingId}/event`, {
        type: body.typeId,
        date: body.date || new Date(),
        comment: body.comment,
        interlocutor: body.interlocutor,
        intervenor: body.intervenor,
        interlocutorName: body.interlocutorName,
        intervenorName: body.intervenorName,
        callType: body.callType,
        evacuationType: body.evacuationType
      })
    );
  }

  public async patchReportingEvent(
    reportingId: string,
    reportingEventId: string,
    body: ReportingEventBody
  ): Promise<IReportingEvent> {
    return lastValueFrom(
      this.http.patch<IReportingEvent>(`api://reportings/${reportingId}/event/${reportingEventId}`, {
        type: body.typeId,
        date: body.date || new Date(),
        comment: body.comment,
        interlocutor: body.interlocutor,
        intervenor: body.intervenor,
        interlocutorName: body.interlocutorName,
        intervenorName: body.intervenorName,
        callType: body.callType,
        evacuationType: body.evacuationType
      })
    );
  }

  /**
   * Only admin is authorized to deleteOne
   */
  public async deleteOneReportingType(id: string): Promise<void> {
    await lastValueFrom(this.http.delete(`api://reporting-types/${id}`));
  }

  /**
   * Only admin is authorized to deleteOne
   */
  public async deleteOneReportingSubtype(id: string) {
    await lastValueFrom(this.http.delete(`api://reporting-subtypes/${id}`));
  }

  /**
   * Only admin is authorized to deleteOne
   */
  public async deleteOneReportingEventType(id: string) {
    await lastValueFrom(this.http.delete(`api://reporting-event-types/${id}`));
  }

  public getReportingTypeByIdSync(reportingTypeId: string): ReportingType {
    const reportingType = this.getReportingTypesSync().find((e) => e.id === reportingTypeId);
    if (!reportingType) {
      throw Error(`ReportingService.getReportingTypeByIdSync: no reporting type found with id ${reportingTypeId}`);
    }
    return reportingType;
  }

  public getReportingTypePriorityByIdSync(reportingTypeId: string): ReportingTypePriority {
    return this.getReportingTypeByIdSync(reportingTypeId).priority;
  }

  renewArchive(id: string): Observable<any> {
    return this.http.post(`api://reportings/${id}/regenerate-archive`, {});
  }

  getStates() {
    return reportStates;
  }

  getDefaultStates() {
    return reportStates.filter((state) => state.default);
  }

  downloadCSV(filterParams: any, origin = true): any {
    const title = filterParams.tasks && filterParams.tasks === true ? 'tasks' : 'reportings';
    const downloadDate = dayjs().format('DD-MM-YYYY-hhmm');
    const downloadedFilename = `${title}-${downloadDate}.csv`;
    this.http
      .get(`api://reportings/export`, { responseType: 'blob', params: { ...filterParams, origin } })
      .subscribe((data) => saveAs(data, downloadedFilename));
  }

  /**
   * Retrieve all reports
   *
   * @param filters  A set of zero, one, or more generic filters amongst
   *        `search`, `sort`, `offset` and `limit`.
   * @returns An observable
   */
  getAll(
    filters: {
      sort?: string;
      offset?: string;
      limit?: string;
      q?: string;
      type?: string;
      sub_type?: string;
      state?: string[];
      from?: Date;
      to?: Date;
      highway_name?: string | Array<string>;
      direction?: string | Array<string>;
      start_pr?: string;
      end_pr?: string;
    } = {},
    origin: boolean = true
  ): Observable<IListResult<IReporting>> {
    return (this.http.get('api://reportings', { params: { ...filters, origin } } as any) as any).pipe(
      map((res: IListResult<IReportingResponse>) => {
        const parsedRes = { ...res } as IListResult<IReporting>;
        parsedRes.items = res.items.map((e) => this.parseReportingResponse(e));
        return parsedRes;
      })
    );
  }

  getOne(reportingId: string): Observable<IReporting> {
    return this.http.get<IReportingResponse>(`api://reportings/${reportingId}`).pipe(
      map((res) => {
        const reporting = this.parseReportingResponse(res);
        return reporting;
      })
    );
  }

  private parseReportingResponse(res: IReportingResponse): IReporting {
    const reporting = {
      ...res,
      type: res.type,
      coordinates: _.isArray(res.coordinates)
        ? { latitude: res.coordinates[1], longitude: res.coordinates[0] }
        : res.coordinates
    } as IReporting;
    return reporting;
  }

  public async add(model: { [key: string]: any }): Promise<string> {
    return lastValueFrom(this.http.post<string>('api://reportings', model));
  }

  public async deleteReporting(reportId: string): Promise<void> {
    return lastValueFrom(this.http.delete<void>(`api://reportings/${reportId}`));
  }

  public async patch(model: { [key: string]: any }, reportId: string): Promise<void> {
    await lastValueFrom(this.http.patch(`api://reportings/${reportId}`, model));
  }

  /**
   * @description Finalize a reporting by UID
   * @param reportingId : Reporting unique ID
   */
  public async finalize(reportingId: string): Promise<void> {
    await lastValueFrom(this.http.patch(`api://reportings/${reportingId}`, { state: 'DONE' }));
  }

  public async unknownAccident(reportingId: string, serviceNumber: string): Promise<void> {
    await lastValueFrom(
      this.http.patch(`api://reportings/${reportingId}`, {
        unknownAccident: {
          user: serviceNumber,
          date: new Date().toISOString()
        }
      })
    );
  }

  public openPdfDialog(reportingId: string): void {
    this.dialog.open(ReportingPdfDialogComponent, {
      width: '70%',
      data: { reportingId }
    });
  }

  public async downloadPdfBlob(reportingId: string): Promise<HttpResponse<Blob>> {
    return await lastValueFrom(
      this.http.get(`api://reportings/${reportingId}/pdf`, { observe: 'response', responseType: 'blob' })
    );
  }

  public async downloadPdf(reportingId: string): Promise<void> {
    const blob = (await this.downloadPdfBlob(reportingId)).body!;
    saveAs(blob);
  }

  public async sendPicture(file: File, ruid: string) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('media_type', 'image');
    return lastValueFrom(this.http.post(`api://reportings/${ruid}/media`, formData));
  }

  public async sendManyPictures(files: File[], id: string) {
    await Promise.all(files.map((file) => this.sendPicture(file, id)));
  }

  public getLocationsFromCoordinates(coordinates: Coordinates): Promise<ILocation[]> {
    return lastValueFrom(
      this.http.get<ILocation[]>('api://locations/near', {
        params: {
          latitude: String(coordinates.latitude),
          longitude: String(coordinates.longitude)
        }
      })
    );
  }

  getFile(uploadedFiles: any[], files: any[]) {
    uploadedFiles.forEach((uploadedFile: any) => {
      files.push(uploadedFile);
    });
  }

  removeFile(question: any, file: any, files: any) {
    const originalObject = file.original_object;
    const indexFile = files.indexOf(originalObject);
    if (indexFile !== -1) {
      files.splice(indexFile, 1); // Delete file in file input
    }

    const indexFileQuestion = question.images.findIndex((image: any) => image.name === file.name);
    if (indexFileQuestion !== -1) {
      question.images.splice(indexFileQuestion, 1);
    }
    question.fileLength = 0;
    question.fileName = '';
  }

  /**
   * This function is synchronous, meaning we assume that 'reportingTypes' and 'infrastructureTypes'
   * are already fetched and available. Otherwise the function will throw.
   */
  public getStoredFiltersSync(options?: { noInfrastructures: boolean }): ReportingFilters | null {
    const { noInfrastructures = false } = options || {};

    const filters = LocalStorageService.getJSONItem<ReportingFilters>('filters');
    if (!filters) {
      return null;
    }

    //
    // Validate filter data
    //

    const reportingTypes = this.getReportingTypesSync();
    const reportingTypeIds = reportingTypes.map((e) => e.id);
    const reportingSubtypeIds = _.flatten(reportingTypes.map((e) => e.subtypes).filter((e) => e)).map((e) => e.id);

    if (filters.type) {
      filters.type = filters.type.filter((id) => reportingTypeIds.includes(id));
    }
    if (filters.sub_type) {
      filters.sub_type = filters.sub_type.filter((id) => reportingSubtypeIds.includes(id));
    }

    if (noInfrastructures) {
      delete filters.infrastructure;
    } else {
      const infrastructureTypes = this.infrastructuresService.getInfrastructureTypesSync();
      const infrastructureTypeIds = infrastructureTypes.map((e) => e.id);
      if (filters.infrastructure) {
        filters.infrastructure = filters.infrastructure.filter((id) => infrastructureTypeIds.includes(id));
      }
    }

    return filters;
  }

  public storeFilters(filters: ReportingFilters): void {
    localStorage.setItem('filters', JSON.stringify(filters));
  }

  public async getSources(): Promise<string[]> {
    if (this.sourceStore) return this.sourceStore;
    const sources = await lastValueFrom(this.http.get<string[]>(`api://sources`));
    this.sourceStore = sources;
    return sources;
  }

  public async getWeatherConditions(): Promise<ReportingWeatherConditionReturn[]> {
    return lastValueFrom(this.http.get<ReportingWeatherConditionReturn[]>(`api://reportings/weather-conditions`));
  }

  /**
   * @description Close a reporting by UID
   */
  public async close(reportingId: string): Promise<IReporting> {
    const res = await lastValueFrom(this.http.patch<IReportingResponse>(`api://reportings/${reportingId}/close`, {}));
    return this.parseReportingResponse(res);
  }

  public isEditable(reporting: IReporting): boolean {
    return !(
      reporting.state === ReportingState.DONE ||
      reporting.state === ReportingState.DONE_UNFINALIZED ||
      reporting.state === ReportingState.CLOSED
    );
  }

  public isClosed(reporting: IReporting): boolean {
    return reporting.state === ReportingState.CLOSED;
  }

  public isFinalizable(reporting: IReporting): boolean {
    return (
      !reporting.type.critical &&
      ![ReportingState.DONE, ReportingState.RAS, ReportingState.CLOSED].includes(reporting.state)
    );
  }

  public navigateToEditReporting(reportingId: string): void {
    this.router.navigateByUrl(`reporting/${reportingId}/edit`);
  }

  public openFinalizeReportingDialog(reportingId: string, onFinalized?: () => unknown): void {
    this.dialog.open(GenericDialogComponent, {
      width: '50%',
      data: {
        structure: {
          title: 'GLOBAL.CONFIRMATION',
          text: 'CONFIRM.FINALIZE_REPORT',
          buttons: [
            {
              text: 'GLOBAL.CANCEL',
              class: 'cancel',
              action: {
                target: 'generic',
                params: {
                  id: 'close',
                  function: undefined
                }
              }
            },
            {
              text: 'GLOBAL.FINALIZE',
              class: 'validation',
              isRaisedButton: true,
              action: {
                target: 'custom',
                params: {
                  id: 'finalize',
                  function: async () => {
                    await this.finalize(reportingId);
                    this.dialog.closeAll();
                    onFinalized?.();
                  }
                }
              }
            }
          ]
        }
      },
      disableClose: true
    });
  }

  public openDeleteReportingDialog(reportingId: string, onDeleted?: () => unknown): void {
    this.dialog.open(GenericDialogComponent, {
      width: '50%',
      data: {
        structure: {
          title: 'GLOBAL.CONFIRMATION',
          text: 'CONFIRM.DELETE_REPORT',
          buttons: [
            {
              text: 'GLOBAL.DELETE',
              class: 'validation',
              isRaisedButton: true,
              action: {
                target: 'custom',
                params: {
                  id: 'delete',
                  function: async () => {
                    await this.deleteReporting(reportingId);
                    this.dialog.closeAll();
                    onDeleted?.();
                  }
                }
              }
            },
            {
              text: 'GLOBAL.CANCEL',
              class: 'cancel',
              action: {
                target: 'generic',
                params: {
                  id: 'close',
                  function: undefined
                }
              }
            }
          ]
        }
      },
      disableClose: true
    });
  }

  public openCloseReportingDialog(reportingId: string, onClose?: (reporting: IReporting) => void): void {
    const structure = {
      title: 'GLOBAL.CONFIRMATION',
      text: 'CONFIRM.CLOSE',
      buttons: [
        {
          text: 'GLOBAL.CLOSE',
          class: 'validation',
          action: {
            target: 'custom',
            params: {
              id: 'logout',
              function: async () => {
                const reporting = await this.close(reportingId);
                this.dialog.closeAll();
                onClose?.(reporting);
              }
            }
          }
        },
        {
          text: 'GLOBAL.CANCEL',
          class: 'cancel',
          action: {
            target: 'generic',
            params: {
              id: 'close',
              function: undefined
            }
          }
        }
      ]
    };
    this.dialog.open(GenericDialogComponent, {
      width: '50%',
      data: { structure },
      disableClose: true
    });
  }

  public openExternalEventsDialog(reportingId: string, onAdd?: (reportingEvent: IReportingEvent) => void): void {
    this.dialog.open(ExternalEventsComponent, {
      width: '50%',
      maxHeight: '80vh',
      autoFocus: false,
      data: { reportingId, onAdd }
    });
  }

  public async deleteReportingExternalEvents(reportingExternalEvents: string) {
    await lastValueFrom(this.http.delete(`api://reportings-external-events/${reportingExternalEvents}`));
  }

  public async addReportingExternalEvents(
    reportingExternalEvents: string,
    reportingId: string
  ): Promise<IReportingEvent> {
    return await lastValueFrom(
      this.http.post<IReportingEvent>(
        `api://reportings-external-events/${reportingExternalEvents}/reportings/${reportingId}`,
        {}
      )
    );
  }
}
