import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { GenericDialogComponent } from 'src/app/components/generic/generic-dialog/generic-dialog.component';
import { SessionService } from 'src/app/components/generic/services/session/session.service';
import { Permissions } from 'src/app/interface/permissions.enum';
import { IReporting, IReportingEvent, RenewableReportingTypeNames } from 'src/app/interface/report.interface';
import { ReportsService } from 'src/app/services/reports.service';
import { RolesService } from 'src/app/services/roles.service';

@Component({
  selector: 'app-location-card',
  templateUrl: './location-card.component.html',
  styleUrls: ['./location-card.component.scss']
})
export class LocationCardComponent implements OnInit, AfterViewInit {
  @Input() report: IReporting;
  @Output() reportChange = new EventEmitter<IReporting>();
  loaded = false;
  highway: any = null;
  type: any;
  public isRightReporting = false;
  public isOperator: boolean = false;
  dialogRef: MatDialogRef<GenericDialogComponent>;

  @Output() createdEvent = new EventEmitter<IReportingEvent>();

  constructor(
    private reportService: ReportsService,
    private translate: TranslateService,
    private rolesService: RolesService,
    private session: SessionService
  ) {}

  ngOnInit() {
    this.highway = this.report.location;

    this.loaded = true;
    this.isRightReporting = this.rolesService.checkPermission(Permissions.BACK_OFFICE_CONNECTION);
    this.isOperator = this.rolesService.isOperator();
  }

  ngAfterViewInit(): void {
    this.session.changes.subscribe(() => {
      this.isRightReporting = this.rolesService.checkPermission(Permissions.BACK_OFFICE_CONNECTION);
      this.isOperator = this.rolesService.isOperator();
    });
  }

  getReportState(state: string): string {
    return this.translate.instant(`REPORTING.STATES.${state}`);
  }

  /**
   * This feature is not needed yet, commented to lighten the component.
   */
  // renewArchive() {
  //   this.reportService.renewArchive(this.report._id!).subscribe(() => {
  //     this.snackbar.open(
  //       this.translate.instant('GLOBAL.REGENERATE_ARCHIVE'),
  //       this.translate.instant('GLOBAL.CLOSE_LABEL')
  //     );
  //     this.report.details.exported = false;
  //   });
  // }

  public isRenewable() {
    if (RenewableReportingTypeNames.includes(this.report.type.name) && localStorage.getItem('devMode') !== null) {
      return this.report.details?.exported === true;
    }
    return false;
  }

  public isEditable(reporting: IReporting): boolean {
    return this.reportService.isEditable(reporting);
  }

  public isClosed(reporting: IReporting): boolean {
    return this.reportService.isClosed(reporting);
  }

  public handleEditClick(reportingId: string): void {
    this.reportService.navigateToEditReporting(reportingId);
  }

  public handleCloseClick(reportingId: string): void {
    this.reportService.openCloseReportingDialog(reportingId, (reporting) => {
      this.report = reporting;
      this.reportChange.emit(this.report);
    });
  }

  public handlePdfClick(reportingId: string): void {
    this.reportService.openPdfDialog(reportingId);
  }

  public handleExternalEvents(reportingId: string): void {
    this.reportService.openExternalEventsDialog(reportingId, (reportingEvent) => {
      this.createdEvent.emit(reportingEvent);
    });
  }
}
