import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  imports: [CommonModule, TranslateModule]
})
export class LoadingComponent {
  @Input() active: boolean = true;
  @Input() showText: boolean = true;
  @Input() className: string;
}
