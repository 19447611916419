import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { PdfViewerComponent } from 'src/app/components/generic/map/pdf-viewer/pdf-viewer.component';
import { DrivingLogsService } from 'src/app/services/driving-logs.service';

@Component({
  standalone: true,
  selector: 'app-reporting-pdf-dialog',
  templateUrl: './driving-logout.dialog.component.html',
  imports: [CommonModule, TranslateModule, MatDialogModule, MatButtonModule, PdfViewerComponent]
})
export class DrivingLogoutDialogComponent {
  public blob: Blob;
  public filename!: string;

  constructor(private drivingLogsService: DrivingLogsService) {
    drivingLogsService.downloadMyPdfBlob().then((response) => {
      this.blob = response.body!;
      this.filename = response.headers.get('File-Name') ?? 'reporting.pdf';
    });
  }

  public handleConfirmClick(): void {
    this.drivingLogsService.closeCurrentDrivingLogAndLogout();
  }

  public cancel(): void {
    this.drivingLogsService.closeDialog();
  }
}
