<h1 mat-dialog-title>{{ 'REPORTING.LANES_CARD.OTHER_LANES' | translate }}</h1>

<mat-dialog-content class="dialog-padding">
  <p class="meta-no-content" *ngIf="!payloadView">
    {{ 'REPORTING.FORMS.EMPTY_PAYLOAD' | translate }}
  </p>

  <div class="meta-has-content" *ngIf="canDisplay === true">
    <div *ngFor="let item of displayInfo">
      <div class="auxLaneType">{{ 'REPORTING.LANES_CARD.TITLE_TYPE' | translate }} {{ item.type | translate }}</div>
      <div *ngIf="item.metadata.length > 0" class="auxLaneMetadata">
        {{ 'REPORTING.LANES_CARD.METADATA_INTRODUCTION' | translate }} {{ item.metadata }}
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="dialog-buttons">
  <button mat-button (click)="closeDialog()">{{ 'GLOBAL.CLOSE_LABEL' | translate }}</button>
</mat-dialog-actions>
