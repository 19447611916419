<div class="container-instructions">
  <h1 class="mainTitle">
    <button
      mat-raised-button
      class="icon-only-button"
      matTooltip="{{ 'GLOBAL.ADD' | translate }}"
      matTooltipPosition="above"
      (click)="addInstruction()"
    >
      <mat-icon>add</mat-icon>
    </button>
    <button
      mat-raised-button
      class="icon-only-button"
      matTooltip="{{ 'GLOBAL.DOWNLOAD' | translate }}"
      matTooltipPosition="above"
      [disabled]="instructionsList.length === 0"
      [ngClass]="{ 'disabled-button': instructionsList.length === 0 }"
      (click)="downloadInstructions()"
    >
      <mat-icon>file_download</mat-icon>
    </button>
    {{ 'GLOBAL.BREADCRUMB.INSTRUCTIONS.LIST' | translate }}
  </h1>
  <div class="filters-instructions-block" *ngIf="loaded">
    <span class="priority-filters">
      <mat-checkbox class="high" (change)="getInstructions()" [(ngModel)]="priority.high">
        {{ 'INSTRUCTIONS.PRIORITY_VALUES.HIGH_P' | translate }}
      </mat-checkbox>
      <mat-checkbox class="normal" (change)="getInstructions()" [(ngModel)]="priority.normal">
        {{ 'INSTRUCTIONS.PRIORITY_VALUES.NORMAL_P' | translate }}
      </mat-checkbox>
    </span>
    <mat-form-field class="status-filters" *ngIf="!(!this.priority.high && this.priority.normal)">
      <mat-select placeholder="{{ 'GLOBAL.STATUS' | translate }}" [(ngModel)]="selectedStatus" multiple>
        <mat-option class="clean h-auto" [value]="statusToggle" (click)="toggleSelectStatus(selectedStatus)">
          {{ 'GLOBAL.TOGGLE_SELECT' | translate }}
        </mat-option>
        <mat-option *ngFor="let status of statuses" [value]="status.id" (click)="getInstructions()">
          {{ status.name | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <i
      class="material-icons chevron"
      (click)="changeSelectedDateFrom(-1)"
      matTooltip="{{ tooltipDate | translate }}"
      matTooltipPosition="above"
      >chevron_left</i
    >
    <mat-form-field class="dateFrom">
      <input
        matInput
        [matDatepicker]="dateFrom"
        placeholder="{{ 'GLOBAL.DISPLAY_DATE_FROM' | translate }}"
        [(ngModel)]="selectedDateFrom"
        (dateChange)="changeDateFrom($event)"
      />
      <mat-datepicker-toggle matSuffix [for]="dateFrom"></mat-datepicker-toggle>
      <mat-datepicker #dateFrom disabled="false"></mat-datepicker>
    </mat-form-field>
    <i
      class="material-icons chevron"
      (click)="changeSelectedDateFrom(1)"
      matTooltip="{{ tooltipDate | translate }}"
      matTooltipPosition="above"
      >chevron_right</i
    >
    <i
      class="material-icons chevron"
      (click)="changeSelectedDateTo(-1)"
      matTooltip="{{ tooltipDate | translate }}"
      matTooltipPosition="above"
      >chevron_left</i
    >
    <mat-form-field class="dateTo">
      <input
        matInput
        [matDatepicker]="dateTo"
        placeholder="{{ 'GLOBAL.DISPLAY_DATE_TO' | translate }}"
        [(ngModel)]="selectedDateTo"
        (dateChange)="changeDateTo($event)"
      />
      <mat-datepicker-toggle matSuffix [for]="dateTo"></mat-datepicker-toggle>
      <mat-datepicker #dateTo disabled="false"></mat-datepicker>
    </mat-form-field>
    <i
      class="material-icons chevron"
      (click)="changeSelectedDateTo(1)"
      matTooltip="{{ tooltipDate | translate }}"
      matTooltipPosition="above"
      >chevron_right</i
    >

    <span
      class="todayLink"
      (click)="backToday()"
      matTooltip="{{ 'INSTRUCTIONS.BACK_TODAY' | translate }}"
      matTooltipPosition="above"
      >{{ 'GLOBAL.TODAY' | translate }}</span
    >
  </div>
  <app-loading [active]="!loaded"></app-loading>
  <app-list
    [listParameters]="listParameters"
    *ngIf="loaded"
    [items]="instructionsList"
    [total]="total"
    searchType="dynamic"
    (refresh)="getInstructions($event)"
    (action)="getEvent($event)"
  ></app-list>
</div>
