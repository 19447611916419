<div *ngIf="loaded === true" class="infos-card">
  <div class="title">
    {{ 'GLOBAL.INFOS' | translate }}

    <div class="actions">
      <a
        *ngIf="isEditable"
        mat-mini-fab
        class="action mat-elevation-z0"
        matTooltip="{{ 'GLOBAL.EDIT' | translate }}"
        matTooltipPosition="above"
        routerLink="../edit"
      >
        <mat-icon>edit</mat-icon>
      </a>
      <a
        *ngIf="isFinalizable(report)"
        (click)="handleFinalizeClick(report)"
        matTooltip="{{ 'GLOBAL.FINALIZE' | translate }}"
        matTooltipPosition="above"
        mat-mini-fab
        class="action mat-elevation-z0"
      >
        <mat-icon>done</mat-icon>
      </a>
      <!-- <a
        *ngIf="couldBeUnknownAccident(report)"
        (click)="openUnknownAccident(report)"
        matTooltip="{{ 'GLOBAL.UNKNOWN_ACCIDENT' | translate }}"
        matTooltipPosition="above"
        mat-mini-fab
        class="action mat-elevation-z0"
      >
        <mat-icon>notification_important</mat-icon>
      </a> -->
    </div>
  </div>

  <div class="info-box">
    <mat-list class="info-box-details">
      <mat-list-item class="list-infos" *ngFor="let item of data">
        <strong>{{ item.key | translate }}</strong
        >&nbsp;:
        {{ item.isNoTranslation ? item.value || ' ' : (item.value || ' ' | translate) }}
        <i *ngIf="item.isRegaCesar" class="material-icons cursor" matTooltip="{{ toolTipInfos }}" translate>info</i>
      </mat-list-item>

      <mat-list-item *ngIf="parent.length > 0" class="list-infos clickable" (click)="openParent(parent)">
        <strong>{{ 'GLOBAL.PARENT' | translate }}</strong
        >&nbsp;:&nbsp;<span>{{ 'GLOBAL.YES' | translate }}</span> &nbsp;<i class="material-icons small">open_in_new</i>
      </mat-list-item>
    </mat-list>
    <div class="info-box-comment">
      <h4 class="info-box-comment-title" translate>GLOBAL.COMMENT</h4>
      <ng-container *ngIf="description; else noDescription">
        <p class="info-box-comment-area">{{ description | trim }}</p>
      </ng-container>
      <ng-template #noDescription>
        <p class="info-box-comment-area isempty">{{ 'GLOBAL.NO_COMMENT' | translate }}</p>
      </ng-template>
    </div>
  </div>
</div>
