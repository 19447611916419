<ng-container *ngIf="currentHighway">
  <h1 class="highway-name">{{ currentHighway.name | translate }}</h1>
  <p class="direction">{{ currentHighway.origin }} &rarr; {{ currentHighway.destination }}</p>
  <p class="align-center">{{ currentHighway.pr }} {{ 'HIGHWAYS.KILOMETER_POINT' | translate }}</p>

  <div>
    <form (ngSubmit)="onSubmit()" *ngIf="!loading.enabled">
      <mat-form-field *ngIf="!loading.enabled">
        <mat-select
          [(value)]="currentHighway.UID"
          placeholder="{{ 'HIGHWAYS.PLACEHOLDER' | translate }}"
          (selectionChange)="onHighwayChange($event)"
        >
          <mat-option *ngFor="let highway of highwaysList" [value]="highway.UID">{{ highway.name }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          type="number"
          placeholder="{{ filterParameters.prFrom.label | translate }}"
          [min]="filterParameters.prMin"
          [max]="filterParameters.prMax"
          [(ngModel)]="filterParameters.prFrom.value"
          [formControl]="filterParameters.minPrValidator"
          step="0.01"
          name="prMin"
        />
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          type="number"
          placeholder="{{ filterParameters.prTo.label | translate }}"
          [min]="filterParameters.prMin"
          [max]="filterParameters.prMax"
          [(ngModel)]="filterParameters.prTo.value"
          [formControl]="filterParameters.maxPrValidator"
          name="prMax"
        />
      </mat-form-field>
      <mat-form-field>
        <mat-select
          placeholder="{{ filterParameters.direction.label | translate }}"
          [(ngModel)]="filterParameters.direction.value"
          name="direc"
          multiple
        >
          <mat-option value="1">
            <span [(innerHTML)]="filterParameters.direction.option1"></span>
          </mat-option>
          <mat-option value="2">
            <span [(innerHTML)]="filterParameters.direction.option2"></span>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button type="submit" mat-raised-button name="submitBtn">
        <span translate>GLOBAL.SUBMIT_BUTTON_LABEL</span>
      </button>
    </form>
  </div>
  <app-list
    [listParameters]="listParameters"
    [items]="kilometerPointsList"
    [total]="total"
    searchType="dynamic"
    (refresh)="getKilometerPoints($event)"
  >
  </app-list>
</ng-container>
