import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GenericDialogComponent } from 'app/components/generic/generic-dialog/generic-dialog.component';
import { SessionService } from 'app/components/generic/services/session/session.service';
import { Permissions } from 'app/interface/permissions.enum';
import { IReporting, ReportingFilters, ReportingState, ReportingType } from 'app/interface/report.interface';
import { ISector } from 'app/interface/sector.interface';
import { IconService } from 'app/services/icon.service';
import { InfrastructuresService } from 'app/services/infrastructures.service';
import { ReportsService } from 'app/services/reports.service';
import { RolesService } from 'app/services/roles.service';
import { SectorsService } from 'app/services/sectors.service';
import { TitleService } from 'app/services/title.service';
import dayjs from 'dayjs';
import _ from 'lodash';
import { forkJoin as observableForkJoin, lastValueFrom, Observable, of as observableOf } from 'rxjs';
import { combineLatestWith } from 'rxjs/operators';
import { Coordinates } from 'src/app/interface/location.interface';

import { CenterChangeEvent, InfrastructureMarker, ReportingMarker } from '../generic/map/map.component';
import { ClassicFiltersDialogComponent } from './dialog-filter/dialogFilter.component';
import { InfrastructureDialogComponent } from './dialog-infrastructure/dialogInfrastructure.component';

@Component({
  selector: 'app-reporting',
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.scss']
})
export class ReportingComponent implements OnInit, AfterViewInit {
  public isLoaded: boolean = false;
  public sectors: ISector[] = [];
  public reportingTypes: ReportingType[] = [];
  public pendingRequest = false;
  public formDisable = false;
  public defaultStates: any[] = [];
  // Rights
  public isRightReporting = false;
  // Toggle
  public sectorToggle = 0;
  public ignoreMap = false;
  /**
   * Reportings
   */
  private _reportingList: ReportingMarker[] = [];
  set reportingList(newReportings: ReportingMarker[]) {
    this._reportingList = newReportings;
    if (!this.ignoreMap) {
      this.updateMarkers();
    }
  }
  get reportingList() {
    return this._reportingList;
  }
  selectedReportings: any[] = [];

  /**
   * Infrastructures
   */
  private _infrastructuresList: InfrastructureMarker[] = [];
  set infrastructuresList(newInfrastructures: InfrastructureMarker[]) {
    this._infrastructuresList = newInfrastructures;
    this.updateMarkers();
  }
  get infrastructuresList() {
    return this._infrastructuresList;
  }

  // Filters
  searchValue = '';
  filtersEnabled = true;
  /**
   * Filters applied on infrastructures and reportings retrieval requests.
   *
   * Setter: Define a new set of filters but overrides the sorting order as it
   * is not configurable by the user.
   *
   * Getter: Return the current set of filters.
   *
   * @todo If the sorting filter becomes editable, go back to a property.
   */
  set filters(newFilters) {
    this._filters = {
      ...newFilters,
      unacc: localStorage.getItem('unknownAccidentAssociation'),
      knacc: localStorage.getItem('knownAccidentAssociation'),
      noacc: localStorage.getItem('noAccidentAssociation'),
      start: localStorage.getItem('mapStartDate')
        ? new Date(localStorage.getItem('mapStartDate')!).toISOString()
        : null,
      end: localStorage.getItem('mapEndDate') ? new Date(localStorage.getItem('mapEndDate')!).toISOString() : null
    };
  }
  get filters() {
    return this._filters;
  }
  private _filters: ReportingFilters = {};

  filterLength: {
    infrastructures: number;
    reportings: number;
  };

  families: { id: string; name: string }[] = [
    {
      id: '1',
      name: 'REPORTING.REPORTS'
    },
    {
      id: '2',
      name: 'GLOBAL.BREADCRUMB.INFRASTRUCTURES.LIST'
    }
  ];

  // Dialog
  dialogRef?: MatDialogRef<GenericDialogComponent>;
  dialogRefReporting?: MatDialogRef<ClassicFiltersDialogComponent>;
  dialogRefInfrastructures?: MatDialogRef<InfrastructureDialogComponent>;

  @ViewChild('itemsList')
  itemsList?: ElementRef;
  triggerScroll = 2;
  listEnd = 18;
  defaultListEnd = 18;

  private _mapOrigin: Coordinates = { latitude: 47.0380276, longitude: 3.1843412 };
  set mapOrigin(newCenter) {
    this._mapOrigin = newCenter;
  }
  get mapOrigin() {
    return this._mapOrigin;
  }

  private _mapZoom = 7;
  set mapZoom(newZoom) {
    this._mapZoom = newZoom;
  }
  get mapZoom() {
    return this._mapZoom;
  }

  resizeEvent = new EventEmitter<{ markers: any[]; allowZoom: boolean }>();

  filterRadii: number[] = [];

  markersList: any[] = [];

  timeout: NodeJS.Timer;

  constructor(
    private iconService: IconService,
    private reportingService: ReportsService,
    private infrastructureService: InfrastructuresService,
    private rolesService: RolesService,
    private router: Router,
    private translate: TranslateService,
    private sectorService: SectorsService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private session: SessionService,
    title: TitleService
  ) {
    title.setTitle('GLOBAL.BREADCRUMB.REPORT.LIST');

    const initialSurface = 1e9;
    const rn = (innerRadius: number, linearScale = 1) =>
      linearScale * Math.sqrt(initialSurface / Math.PI + Math.pow(innerRadius, 2));
    this.defaultStates = this.reportingService.getDefaultStates();
    this.filterRadii = [];
    let previousRadius = 0;
    for (let i = 0; i < 6; i++) {
      const currentRadius = rn(previousRadius, 1.4);
      this.filterRadii.push(currentRadius / 1000);
      previousRadius = currentRadius;
    }

    this.filterLength = {
      infrastructures: 0,
      reportings: 0
    };
  }

  ngAfterViewInit(): void {
    this.session.changes.subscribe(() => {
      this.isRightReporting = this.rolesService.checkPermission(Permissions.BACK_OFFICE_CONNECTION);
    });
  }

  ngOnInit(): void {
    this.sectors = [];
    this.reportingTypes = [];
    this.filterLength = {
      infrastructures: 0,
      reportings: 0
    };
    this.searchValue = this.filters.q || '';
    this.isRightReporting = this.rolesService.checkPermission(Permissions.BACK_OFFICE_CONNECTION);

    this.reportingService
      .getReportingTypesObservable()
      .pipe(combineLatestWith(this.sectorService.getAll(), this.infrastructureService.getInfrastructureTypes()))
      .subscribe(async (results) => {
        const [reportingTypes, sectors] = results;
        this.sectors = sectors.items.sort((a, b) => a.name.localeCompare(b.name));
        this.reportingTypes = reportingTypes;

        // Init filter
        this.filters = await this.defaultFilters();
        if (Object.keys(this.filters).length === 0) {
          this.setDefaultFilters();
        }

        // Get reportings + infrastructures
        this.filterData().subscribe();
        this.isLoaded = true;
      });
  }

  setDefaultFilters() {
    this.filters.type = [];
    this.filters.sub_type = [];
    this.filters.state = [];
    this.filters.unacc = 'true';
    this.filters.knacc = 'true';
    this.filters.noacc = 'true';
    this.filters.start = null;
    this.filters.end = null;

    for (const type of this.reportingTypes) {
      if (type.subtypes.length === 0) {
        this.filters.type.push(type.id);
      }
    }

    for (const state of this.defaultStates) {
      this.filters.state.push(state.id);
    }

    for (const type of this.reportingTypes) {
      for (const subType of type.subtypes) {
        this.filters.sub_type.push(subType.id);
      }
    }
    const userSectors = JSON.parse(localStorage.getItem('sectors') || '[]');
    const sectorFilter = userSectors.length === 0 ? this.sectors : userSectors;
    this.filters.sector_id = sectorFilter.map((sector: { id: string }) => sector.id);
  }

  loadNextItems() {
    if (!this.itemsList) {
      return;
    }

    const itemHeight = 60;
    const visibleHeight = this.itemsList.nativeElement.clientHeight;
    const scrollableHeight = this.itemsList.nativeElement.scrollHeight;
    const visibleItems = Math.ceil(visibleHeight / itemHeight);
    const itemsCount = this.infrastructuresList.length + this.reportingList.length;

    this.triggerScroll = Math.min(visibleHeight / scrollableHeight) * 2;
    this.listEnd = Math.min(this.listEnd + visibleItems, itemsCount);
  }

  getTrigram(firstName: string, lastName: string) {
    return `${firstName[0]}${lastName.slice(0, 2)}`;
  }

  goToDetails(event: any, id: string): void {
    if (event.ctrlKey) {
      window.open(`reporting/${id}/details`);

      return;
    }
    this.router.navigateByUrl(`reporting/${id}/details`);
  }

  isEditable(reporting: IReporting): boolean {
    return ![ReportingState.DONE, ReportingState.RAS, ReportingState.CLOSED].includes(reporting.state);
  }

  getFiltersInfrastructures(): any {
    const f: any = _.cloneDeep(this.filters);
    const unusedProperty = ['state', 'type', 'sub_type'];
    Object.keys(f).forEach((key: keyof typeof f) => {
      const value = f[key];
      const isEmptyArray = () => Array.isArray(value) && value.length === 0;
      if (isEmptyArray() || value === '' || (typeof key === 'string' && unusedProperty.includes(key))) {
        delete f[key];
      }
    });

    if (f.infrastructure && Array.isArray(f.infrastructure)) {
      f.type = f.infrastructure;
      delete f.infrastructure;
    }

    return f;
  }

  getFiltersReporting(): any {
    const f: any = _.cloneDeep(this.filters);
    const unusedProperty = ['infrastructure'];

    Object.keys(f).forEach((key: string) => {
      const value = f[key];
      const isEmptyArray = () => Array.isArray(value) && value.length === 0;
      if (isEmptyArray() || value === '' || unusedProperty.includes(key)) {
        delete f[key];
      }
    });

    return f;
  }

  existSectorFilter() {
    const filterSector = this.filters.sector_id;

    return filterSector && Array.isArray(filterSector) && filterSector.length > 0;
  }

  existReportingFilter() {
    const isNotEmptyArray = (val?: any[]) => Array.isArray(val) && val.length > 0;

    return [this.filters.type, this.filters.sub_type, this.filters.state].some(isNotEmptyArray);
  }

  existInfrastructuresFilter() {
    const filterInfra = this.filters.infrastructure;

    return filterInfra && Array.isArray(filterInfra) && filterInfra.length > 0;
  }

  public async getInfrastructures(): Promise<void> {
    const getFilter = this.getFiltersInfrastructures();

    if (!this.existSectorFilter() || !this.existInfrastructuresFilter()) {
      this.filterLength.infrastructures = Object.keys(getFilter).length > 0 ? this.getFilterLength(getFilter) : 0;
      this.infrastructuresList = [];
      return;
    }

    const res = await lastValueFrom(this.infrastructureService.getAll(getFilter));
    const infrastructureMarkers = res.items.map((infrastructure) => {
      const marker: InfrastructureMarker = {
        markerType: 'infrastructure',
        showInfos: true,
        coordinates: infrastructure.coordinates, // ref so it will mutate
        infrastructure,
        iconUrl: infrastructure.type.pin,
        data: {
          type: infrastructure.type || {
            name: 'unknown',
            picto: this.iconService.getURL(`poi_unknown`)
          }
        }
      };
      return marker;
    });
    this.listEnd = this.defaultListEnd;
    this.infrastructuresList = infrastructureMarkers;
    this.filterLength.infrastructures = this.getFilterLength(getFilter);
  }

  getFilterLength(filters: any): number {
    //  TODO : fix this if number of filter has to be displayedlocal
    let length = 0;
    Object.keys(filters).forEach((key) => {
      if (key !== 'sector_id') {
        if (Array.isArray(filters[key])) {
          length += filters[key].length;
        } else if (filters[key] && typeof filters[key] === 'string') {
          length += filters[key].split(',').length;
        }
      }
    });

    return length;
  }

  async getReporting(ignoreMap = false): Promise<void> {
    this.ignoreMap = ignoreMap;
    this.selectedReportings = [];
    const getFilter = this.getFiltersReporting();
    if (!this.existSectorFilter() || !this.existReportingFilter() || !this.filtersEnabled) {
      if (!this.filtersEnabled) {
        this.snackbar.open(
          this.translate.instant('REPORTING.ERROR.NON_MATCH_FILTERS'),
          this.translate.instant('GLOBAL.CLOSE_LABEL')
        );
      }
      this.filterLength.reportings = Object.keys(getFilter).length > 0 ? this.getFilterLength(getFilter) : 0;
      this.reportingList = [];
      this.pendingRequest = false;
      return;
    }

    const res = await lastValueFrom(this.reportingService.getAll(getFilter, true));
    const reportingMarkers = res.items.map((reporting) => {
      const creationDate = dayjs(reporting.creation_date || reporting.created_at);
      const iconName = reporting.type.pin || this.iconService.get('reporting_unknown');
      const iconUrl = this.iconService.getURL(iconName);

      const reportingMarker: ReportingMarker = {
        markerType: 'reporting',
        showInfos: true,
        coordinates: reporting.coordinates, // ref so it will mutate
        iconUrl,
        reporting,
        data: {
          date: creationDate,
          svgIcon: iconName,
          isRegaCesar: reporting.source === 'REGA' || reporting.source === 'CESAR'
        }
      };
      return reportingMarker;
    });

    this.reportingList = reportingMarkers;
    this.pendingRequest = false;
    this.filterLength.reportings = this.getFilterLength(getFilter);
  }

  addReporting() {
    if (this.isRightReporting) {
      this.router.navigateByUrl(`reporting/new`);
    }
  }

  downloadReporting() {
    if (this.isRightReporting) {
      this.reportingService.downloadCSV(this.getFiltersReporting());
    }
  }

  clickReportInList(report: { [key: string]: any }) {
    this.viewReportingInMap(report);
    this.refreshMap();
  }

  refreshMap(allowZoom = true) {
    this.updateMarkers();
    this.resizeEvent.emit({ markers: this.markersList, allowZoom });
  }

  clickInfrastructureInList(infrastructure: any) {
    console.warn('Not implemented yet', infrastructure);
  }

  viewReportingInMap(report: { [key: string]: any }) {
    const selectedReportingPos = this.selectedReportings.findIndex(
      (selected) => selected.reporting._id === report.reporting._id
    );
    if (selectedReportingPos === -1) {
      this.selectedReportings.push(report);
    } else {
      this.selectedReportings.splice(selectedReportingPos, 1);
    }
  }

  updateSectors() {
    this.formDisable = true;
    this.pendingRequest = true;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.pendingRequest = false;
      this.filterData().subscribe();
    }, 3000);
  }

  filterData(): Observable<any> {
    this.filters.q = this.searchValue;
    this.refreshFilters();
    if (this.pendingRequest) {
      return observableOf(true);
    }
    this.formDisable = false;

    return observableForkJoin([this.getReporting(), this.getInfrastructures()]);
  }

  keyUpSearchInList(e: { which: number }) {
    this.filters.q = this.searchValue;
    if (e.which === 13 || this.searchValue === '') {
      this.filterData().subscribe();
    }
  }

  public isFinalizable(reporting: IReporting): boolean {
    return this.reportingService.isFinalizable(reporting);
  }

  public handleEditClick(reportingId: string): void {
    this.reportingService.navigateToEditReporting(reportingId);
  }

  public handleDeleteClick(reportingId: string): void {
    this.reportingService.openDeleteReportingDialog(reportingId, () => this.filterData());
  }

  public handleFinalizeClick(reportingId: string): void {
    this.reportingService.openFinalizeReportingDialog(reportingId, () => this.filterData());
  }

  public handlePdfClick(reportingId: string): void {
    this.reportingService.openPdfDialog(reportingId);
  }

  toggleSelectSectors() {
    if (!this.filters.sector_id) {
      this.filters.sector_id = [];
    }

    // Remove the "Select all/Deselect all" option from the list
    const sectorFilters = this.filters.sector_id.filter((sector) => sector !== undefined);
    this.filters.sector_id =
      sectorFilters.length === 0 ? this.sectors.map((sector: any) => sector.id) : (this.filters.sector_id = []);

    this.filterData().subscribe();
  }

  removePropertyInLocalStorage(key: string): void {
    localStorage.removeItem(key);
  }

  refreshFilters() {
    if (_.isEmpty(this.filters)) {
      return;
    }
    this.reportingService.storeFilters(this.filters);
  }

  openClassicFiltersDialog() {
    this.dialogRefReporting = this.dialog.open(ClassicFiltersDialogComponent, {
      panelClass: 'custom-dialog-container',
      disableClose: true,
      width: '90%',
      maxWidth: '1300px',
      data: {
        parent: this,
        title: 'REPORTING.DIALOG.FILTER_TITLE',
        content: this.filters
      }
    });

    this.dialogRefReporting.afterClosed().subscribe((res: string) => {
      if (res !== 'cancel') {
        if (res === 'bad-filters') {
          this.filtersEnabled = false;
        } else {
          this.filtersEnabled = true;
          this.filters = {
            ...this.defaultFilters(),
            unacc: localStorage.getItem('unknownAccidentAssociation'),
            knacc: localStorage.getItem('knownAccidentAssociation'),
            noacc: localStorage.getItem('noAccidentAssociation'),
            start: localStorage.getItem('mapStartDate')
              ? new Date(localStorage.getItem('mapStartDate')!).toISOString()
              : null,
            end: localStorage.getItem('mapEndDate') ? new Date(localStorage.getItem('mapEndDate')!).toISOString() : null
          };
        }
        this.filterData()
          .toPromise()
          .then(() => this.refreshMap(false));
      }
    });
  }

  defaultFilters(): ReportingFilters {
    const filters = this.reportingService.getStoredFiltersSync();
    if (filters) {
      return filters;
    }
    this.setDefaultFilters();
    return this.filters;
  }

  openInfrastructureDialog() {
    this.dialogRefInfrastructures = this.dialog.open(InfrastructureDialogComponent, {
      disableClose: true,
      width: '40%',
      maxWidth: '450px',
      data: {
        parent: this,
        title: 'REPORTING.DIALOG.INFRASTRUCTURE_TITLE',
        content: this.filters
      }
    });

    this.dialogRefInfrastructures.afterClosed().subscribe((res: string) => {
      if (res !== 'cancel') {
        this.filters = this.defaultFilters();
        this.filterData().subscribe();
      }
    });
  }

  private updateMarkers() {
    const reportings = this.selectedReportings.length > 0 ? this.selectedReportings : this.reportingList;
    const infrastructures = this.infrastructuresList;

    this.markersList = [...reportings, ...infrastructures];
  }

  public handleMapCenterChange(event: CenterChangeEvent) {
    this.mapOrigin = event.coords;
  }
}
