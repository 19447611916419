import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { QuestionBase } from 'app/components/generic/questions/question-base';
import { QuestionControlService } from 'app/components/generic/questions/question-control.service';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
  providers: [QuestionControlService]
})
export class DynamicFormComponent implements OnInit {
  @Input() disabledSubmit = false;
  @Input() exportFromParents: any = {}; // TODO: use event emitter
  @Input() binding?: any = {};
  @Input() questions: QuestionBase<any>[] = [];
  @Input() submitLabelButton?: string;
  @Input() cancelButton = { activated: false, backTo: '' };
  @Input() validateButton = true;
  @Output() form: UntypedFormGroup;
  @Output() formSubmit = new EventEmitter<UntypedFormGroup>();
  @Output() cancel = new EventEmitter<void>();

  constructor(
    private router: Router,
    private qcs: QuestionControlService
  ) {}

  ngOnInit() {
    this.form = this.qcs.toFormGroup(this.questions);
  }

  resetForm() {
    this.questions.map((el: QuestionBase<any>) => {
      if (el.controlType && el.controlType.toLowerCase() === 'file') {
        el.fileName = '';
      }
    });
    this.form.reset();
    if (this.cancelButton.backTo) {
      this.router.navigate([this.cancelButton.backTo]);
    }
    this.cancel.emit();
  }

  onSubmit() {
    if (this.disabledSubmit) {
      return;
    }
    this.disabledSubmit = true;
    this.exportFromParents?.formSubmitted?.(this.form);
    this.formSubmit.emit(this.form);
    setTimeout(() => {
      this.disabledSubmit = false;
    }, 1000);
  }
}
