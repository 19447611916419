import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GenericDialogComponent } from 'app/components/generic/generic-dialog/generic-dialog.component';
import { IGenericListParameters } from 'app/components/generic/list/list.interface';
import { IFiltersGetInstructions, IInstruction, statusFilter } from 'app/interface/instruction.interface';
import { ITeam } from 'app/interface/team.interface';
import { IUser } from 'app/interface/user.interface';
import { InstructionsService } from 'app/services/instructions.service';
import { TeamsService } from 'app/services/teams.service';
import { TitleService } from 'app/services/title.service';
import { UsersService } from 'app/services/users.service';
import { titleCase } from 'app/tools/stringTransform';
import dayjs from 'dayjs';
import { cloneDeep, get, omit } from 'lodash';
import { forkJoin as observableForkJoin } from 'rxjs';

@Component({
  selector: 'app-repositories',
  templateUrl: './instructions.list.component.html',
  styleUrls: ['./instructions.list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InstructionsListComponent implements OnInit {
  public loaded = false;
  usersList: IUser[] = [];
  teamsList: ITeam[] = [];
  instructionsList: IInstruction[] = [];
  filters: IFiltersGetInstructions = {
    limit: 1000,
    offset: 0,
    teams: JSON.parse(localStorage.getItem('teams') || '[]').map((t: { id: string }) => t.id)
  };
  dialogRef: MatDialogRef<GenericDialogComponent>;
  total = 0;
  serviceNumberConnectedUser = '';
  // Filters
  selectedDateFrom: Date = new Date();
  selectedDateTo: Date = new Date();
  invalidDate = false;
  tooltipDate = '';
  priority = {
    high: false,
    normal: false
  };
  statuses = statusFilter;
  selectedStatus: string[] = [];
  statusToggle = false;

  listParameters: IGenericListParameters = {
    paginator: true,
    clickable: true,
    listHead: [
      {
        key: 'title',
        title: 'INSTRUCTIONS.LABEL',
        type: 'text',
        options: {
          class: 'first-column-list-component'
        }
      },
      {
        key: 'important',
        title: 'INSTRUCTIONS.PRIORITY',
        type: 'text',
        options: {
          icon: {
            class: 'class_icon_priority',
            tooltip: 'tooltip_priority'
          }
        }
      },
      {
        key: 'created_at',
        title: 'INSTRUCTIONS.ADD_AT',
        type: 'date',
        options: {
          format: 'shortDate'
        }
      },
      {
        key: 'display_created_by',
        title: 'INSTRUCTIONS.EMITTED_BY',
        type: 'text'
      },
      {
        key: 'affected_to',
        title: 'INSTRUCTIONS.ATTENTION_OF',
        type: 'text',
        options: {
          tooltip: 'tooltip_affected_to',
          icon: {
            key: 'iconAffectedTo',
            class: 'class_icon_affected_to',
            tooltip: 'tooltip_affected_to'
          }
        }
      },
      {
        key: 'date',
        title: 'INSTRUCTIONS.DISPLAYED_DATE',
        type: 'text'
      },
      {
        key: 'iconStatus',
        title: 'INSTRUCTIONS.STATUS',
        type: 'icon',
        options: {
          icon: 'iconStatus',
          tooltip: 'tooltipIconStatus'
        },
        events: { click: 'clickState' }
      },
      {
        key: 'actions',
        type: 'button',
        colWidth: '80px'
      }
    ]
  };

  constructor(
    private title: TitleService,
    private usersService: UsersService,
    private teamsService: TeamsService,
    private translate: TranslateService,
    private router: Router,
    public dialog: MatDialog,
    private instructionsService: InstructionsService
  ) {
    dayjs().locale('fr');
    this.title.setTitle('INSTRUCTIONS.LABEL_P');
  }

  ngOnInit() {
    this.serviceNumberConnectedUser = localStorage.getItem('service_number') || '';
    observableForkJoin([
      this.usersService.getAll({ fields: 'service_number,last_name,first_name,role,teams' }),
      this.teamsService.getAll({ fields: 'id,name,users' })
    ]).subscribe((results) => {
      const [users, teams] = results;

      this.usersList = users.items;
      this.teamsList = teams.items;
      this.getFiltersTasks();
      this.getInstructions();
    });
  }

  // ---------------------------- Actions ----------------------------

  addInstruction() {
    this.router.navigate([`instructions/new`]);
  }

  downloadInstructions() {
    this.instructionsService.downloadCSV(this.filters);
  }

  // ---------------------------- Filters ----------------------------

  changeDateFrom(event: any) {
    const rawValue = event.targetElement.value;
    const value = event.value;
    this.tooltipDate = '';

    if (rawValue === '' && value === null) {
      this.selectedDateFrom = new Date();
      this.getInstructions();
    } else if (value && dayjs(value).isValid()) {
      this.getInstructions();
    } else {
      this.tooltipDate = 'GLOBAL.ERROR.INVALID_DATE';
    }
  }

  changeDateTo(event: any) {
    const rawValue = event.targetElement.value;
    const value = event.value;
    this.tooltipDate = '';

    if (rawValue === '' && value === null) {
      this.selectedDateTo = new Date();
      this.getInstructions();
    } else if (value && dayjs(value).isValid()) {
      this.getInstructions();
    } else {
      this.tooltipDate = 'GLOBAL.ERROR.INVALID_DATE';
    }
  }

  backToday() {
    this.tooltipDate = '';
    this.selectedDateFrom = new Date();
    this.selectedDateTo = new Date();
    this.getInstructions();
  }

  public changeSelectedDateFrom(numberOfDays: number) {
    if (this.selectedDateFrom !== null) {
      this.selectedDateFrom = dayjs(this.selectedDateFrom).add(numberOfDays, 'days').toDate();
      this.getInstructions();
    }
  }

  public changeSelectedDateTo(numberOfDays: number) {
    if (this.selectedDateTo !== null) {
      this.selectedDateTo = dayjs(this.selectedDateTo).add(numberOfDays, 'days').toDate();
      this.getInstructions();
    }
  }

  private addFilterData() {
    // Date
    delete this.filters.dateFrom;
    if (this.selectedDateFrom !== null) {
      this.filters.dateFrom = dayjs(this.selectedDateFrom).startOf('day').toISOString();
    }
    delete this.filters.dateTo;
    if (this.selectedDateTo !== null) {
      this.filters.dateTo = dayjs(this.selectedDateTo).endOf('day').toISOString();
    }
    // Important
    delete this.filters.type;
    if (this.priority.high && !this.priority.normal) {
      this.filters.type = 'PRIORITY';
    } else if (!this.priority.high && this.priority.normal) {
      this.filters.type = 'INFORMATION';
    }
    // State
    delete this.filters.state;
    if (this.selectedStatus.length > 0) {
      this.filters.state = [...this.selectedStatus];
    }
  }

  toggleSelectStatus(stateSelect: any) {
    if (!stateSelect[0]) {
      this.selectedStatus = statusFilter.map((status) => status.id);
      this.statusToggle = true;
    } else {
      this.selectedStatus = [];
      this.statusToggle = false;
    }

    this.getInstructions();
  }

  getFiltersTasks() {
    const filters = JSON.parse(localStorage.getItem('filtersInstructions') || '{}');

    if (filters.dateFrom && dayjs(filters.dateFrom).isValid()) {
      this.selectedDateFrom = new Date(filters.dateFrom);
    } else {
      this.selectedDateFrom = new Date();
    }
    if (filters.dateTo && dayjs(filters.dateTo).isValid()) {
      this.selectedDateTo = new Date(filters.dateTo);
    } else {
      this.selectedDateTo = new Date();
    }

    if (filters.priority && filters.priority.high !== undefined && filters.priority.normal !== undefined) {
      this.priority.high = !!filters.priority.high;
      this.priority.normal = !!filters.priority.normal;
    } else {
      this.priority.high = true;
      this.priority.normal = true;
    }

    if (filters.state && Array.isArray(filters.state)) {
      const filterState = filters.state.filter((state: string) =>
        statusFilter.some((stateSearch) => stateSearch.id === state)
      );
      this.selectedStatus = Array.from(new Set<string>(filterState));
      if (this.selectedStatus.length === statusFilter.length) {
        this.statusToggle = true;
      }
    }
  }

  updateFiltersTasks() {
    const filterInstructionsStorage = cloneDeep(omit(this.filters, ['important', 'limit', 'offset'])) as {
      [key: string]: any;
    };
    filterInstructionsStorage.priority = this.priority;

    localStorage.setItem('filtersInstructions', JSON.stringify(filterInstructionsStorage));
  }

  // ---------------------------- Dialog ----------------------------

  getDialogData(assigned: { type: string; data: string[]; read: string[] }) {
    const affectedTeamsList: any[] = [];
    if (assigned.type === 'teams') {
      for (const teamId of assigned.data) {
        const teamSearch = this.teamsList.find((team) => teamId === team.id);
        if (teamSearch && teamSearch.users && Array.isArray(teamSearch.users)) {
          for (const user of teamSearch.users as any) {
            user.read = assigned.read.some((userSearch: any) => user.service_number === userSearch) as any;
          }
          affectedTeamsList.push({
            id: teamSearch.id,
            name: teamSearch.name,
            users: teamSearch.users.sort(this.dynamicSort('last_name'))
          });
        }
      }
    } else if (assigned.type === 'users') {
      for (const userId of assigned.data) {
        const userSearch = this.usersList.find((user) => userId === user.service_number);
        if (userSearch && userSearch.teams && Array.isArray(userSearch.teams)) {
          const userAdd = {
            first_name: userSearch.first_name,
            last_name: userSearch.last_name,
            service_number: userSearch.service_number,
            role: userSearch.role,
            read: assigned.read.some((user: any) => user === userSearch.service_number)
          };
          if (userSearch.teams.length === 0) {
            userSearch.teams.push({
              id: 'no_team',
              name: this.translate.instant('INSTRUCTIONS.NO_TEAM')
            });
          }
          for (const team of userSearch.teams) {
            const index = affectedTeamsList.findIndex((affectedTeam) => affectedTeam.id === team.id);
            if (index === -1) {
              affectedTeamsList.push({
                id: team.id,
                name: team.name,
                users: [userAdd]
              });
            } else {
              if (!affectedTeamsList[index].users.some((user: any) => userAdd.service_number === user.service_number)) {
                affectedTeamsList[index].users.push(userAdd);
              }
            }
          }
        }
      }
      for (const team of affectedTeamsList) {
        team.users = team.users.sort(this.dynamicSort('last_name'));
      }
    }

    return affectedTeamsList;
  }

  buildStateDialogHtml(assigned: { type: string; data: string[]; read: string[] }) {
    const lastNameLabel = this.translate.instant('USERS.LAST_NAME');
    const firstNameLabel = this.translate.instant('USERS.FIRST_NAME');
    const statusLabel = this.translate.instant('GLOBAL.STATUS');
    const noUsersLabel = this.translate.instant('INSTRUCTIONS.NO_USERS');

    this.getDialogData(assigned);
    const affectedTeamsList: any[] = this.getDialogData(assigned);
    let htmlContent = '';
    if (affectedTeamsList.length === 0) {
      htmlContent = `<p>${noUsersLabel}</p>`;
    } else {
      for (const team of affectedTeamsList) {
        htmlContent += `<table class="table-instructions-dialog-user" cellspacing="0" cellpadding="3">`;
        htmlContent += `<tr class="teamNameColumn"><th colspan="5">${team.name}</th></tr>`;
        htmlContent += `<tr class="line">`;
        htmlContent += `<th>${lastNameLabel}</th>`;
        htmlContent += `<th>${firstNameLabel}</th>`;
        htmlContent += `<th>${statusLabel}</th>`;
        htmlContent += `</tr>`;

        for (const user of team.users) {
          htmlContent += `<tr>`;
          htmlContent += `<td>${user.last_name}</td>`;
          htmlContent += `<td>${user.first_name}</td>`;

          if (user.read) {
            htmlContent += `<td class="stateColumnYes"><i class="material-icons">check</i></td>`;
          } else {
            htmlContent += `<td class="stateColumnNo"><i class="material-icons">visibility_off</i></td>`;
          }

          htmlContent += `</tr>`;
        }
        htmlContent += `</table>`;
      }
    }

    return htmlContent;
  }

  dynamicSort(property: string) {
    let sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }

    return (a: any, b: any) => {
      const result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;

      return result * sortOrder;
    };
  }

  detailsStateStructure() {
    return {
      title: 'INSTRUCTIONS.ATTENTION_OF_USERS',
      text: '',
      buttons: [
        {
          text: 'GLOBAL.OK',
          class: 'validation',
          action: {
            target: 'generic',
            params: {
              id: 'close',
              function: undefined
            }
          }
        }
      ]
    };
  }

  deleteInstructionStructure(_id: string) {
    return {
      title: 'GLOBAL.CONFIRMATION',
      text: 'CONFIRM.DELETE_INSTRUCTION',
      buttons: [
        {
          text: 'GLOBAL.DELETE',
          class: 'validation',
          isRaisedButton: true,
          action: {
            target: 'custom',
            params: {
              id: 'delete',
              function: () => {
                this.deleteInstruction(_id);
              }
            }
          }
        },
        {
          text: 'GLOBAL.CANCEL',
          class: 'cancel',
          action: {
            target: 'generic',
            params: {
              id: 'close',
              function: undefined
            }
          }
        }
      ]
    };
  }

  private getIconAffectedTo(instruction: IInstruction) {
    if (instruction.assigned.data.length > 0) {
      return instruction.assigned.type === 'users' ? 'person' : 'group';
    }

    return 'person_outline';
  }

  // ---------------------------- Service call ----------------------------

  getInstructions(event?: { type: string; data: any; params: any }): void {
    this.loaded = false;
    if (event && event.data && event.type === 'search') {
      if (event.data.search) {
        this.filters.q = String(event.data.search);
      } else if (event.data.search === '') {
        delete this.filters.q;
      }
    }
    if (event && event.params) {
      this.filters = { ...this.filters, ...event.params };
    }

    this.addFilterData();
    this.updateFiltersTasks();

    const filters = cloneDeep(this.filters);
    if (!this.priority.high && this.priority.normal) {
      delete filters.state;
    }

    this.instructionsService.getAll(filters).subscribe((res: { items: IInstruction[]; total: number }) => {
      this.instructionsList = res.items;
      for (const instruction of this.instructionsList as any) {
        const affectedTo: string[] = this.getAffectedTo(instruction);
        // Priority
        instruction.class_icon_priority =
          instruction.type === 'PRIORITY' || instruction.type === 'END_SESSION'
            ? 'icon_instruction_red'
            : 'icon_instruction_blue';
        instruction.tooltip_priority = `INSTRUCTIONS.PRIORITY_VALUES.${
          instruction.type === 'PRIORITY' || instruction.type === 'END_SESSION' ? 'HIGH' : 'NORMAL'
        }`;
        // Affected to
        instruction.tooltip_affected_to = affectedTo.join(', ');
        instruction.affected_to = this.getDisplayAffectedTo(affectedTo);
        instruction.iconAffectedTo = this.getIconAffectedTo(instruction);
        instruction.class_icon_affected_to = 'icon_affected_to';
        // Created by
        instruction.display_created_by = this.getEmitter(instruction);
        // Date
        instruction.date = this.getDisplayedDate(instruction);
        // Status
        if (instruction.type === 'PRIORITY') {
          const iconInfos = this.getStateInfos(instruction);
          instruction.iconStatus = iconInfos.icon;
          instruction.tooltipIconStatus = iconInfos.tooltip;
        }
        instruction.important = '';
        // Actions
        instruction.actions = this.getActions(instruction);
      }
      this.total = res.total;
      this.loaded = true;
    });
  }

  public deleteInstruction(_id: string) {
    this.instructionsService.delete(_id).subscribe(() => this.getInstructions());
    this.dialog.closeAll();
  }

  // ---------------------------- Events ----------------------------

  public getEvent(event: { type: string; data: any }) {
    if (event.type && event.data) {
      switch (event.type) {
        case 'edit':
          this.router.navigate([`instructions/${event.data._id}/edit`]);
          break;
        case 'delete':
          this.dialogRef = this.dialog.open(GenericDialogComponent, {
            width: '50%',
            data: { structure: this.deleteInstructionStructure(event.data._id) },
            disableClose: true
          });
          break;
        case 'rowClick':
          this.router.navigate([`instructions/${event.data._id}/details`]);
          break;
        case 'clickState':
          this.dialogRef = this.dialog.open(GenericDialogComponent, {
            width: '30%',
            data: {
              structure: this.detailsStateStructure(),
              content: {
                type: 'html',
                html: this.buildStateDialogHtml(event.data.assigned)
              }
            },
            disableClose: false
          });
          break;
      }
    }
  }

  // ---------------------------- Data display ----------------------------

  private getEmitter(instruction: IInstruction) {
    const firstName = get(instruction, 'created_by.first_name', '');
    const lastName = get(instruction, 'created_by.last_name', '');

    return `${lastName.toUpperCase()} ${titleCase(firstName)}`;
  }

  private getDisplayedDate(instruction: IInstruction) {
    let dateDisplay = '';
    const startDate = dayjs(instruction.start_date);
    const endDate = dayjs(instruction.end_date);
    const today = dayjs();
    // Trad
    const fromTrad = this.translate.instant('GLOBAL.FROM');
    const theTrad = this.translate.instant('GLOBAL.THE');
    const todayTrad = this.translate.instant('GLOBAL.TODAY');
    const toTrad = this.translate.instant('GLOBAL.TO');

    if (startDate.startOf('day').isSame(endDate.startOf('day'))) {
      if (today.startOf('day').isSame(startDate.startOf('day'))) {
        dateDisplay = `${todayTrad}`;
      } else {
        dateDisplay = `${theTrad} ${startDate.format('L')}`;
      }
    } else {
      dateDisplay = `${fromTrad} ${startDate.format('L')} ${toTrad} ${endDate.format('L')}`;
    }

    return dateDisplay;
  }

  private getAffectedUsersName(instruction: IInstruction) {
    return instruction.assigned.users?.map((user) => `${user.last_name.toUpperCase()} ${user.first_name}`) || [];
  }

  private getAffectedTeamsName(instruction: IInstruction) {
    const affectedTeamsName: string[] = [];

    for (const teamId of instruction.assigned.data) {
      const teamSearch = this.teamsList.find((team) => teamId === team.id);
      if (teamSearch) {
        affectedTeamsName.push(teamSearch.name);
      }
    }

    return affectedTeamsName;
  }

  private getAffectedTo(instruction: IInstruction) {
    return instruction.assigned.type === 'users'
      ? this.getAffectedUsersName(instruction)
      : this.getAffectedTeamsName(instruction);
  }

  private getDisplayAffectedTo(rawAffectedTo: string[]) {
    let displayResult = '';
    const affectedToLength = rawAffectedTo.length;
    if (affectedToLength > 0) {
      displayResult = rawAffectedTo[0];
      if (affectedToLength > 1) {
        displayResult += `  (+${affectedToLength - 1})`;
      }
    } else {
      displayResult = 'REDACTED.AFFECTED_TO';
    }

    return displayResult;
  }

  private getStateInfos(instruction: IInstruction) {
    const iconInfos = {
      icon: '',
      tooltip: ''
    };
    if (instruction.assigned.data.length > 0) {
      const state = instruction.state;
      const stateObject = statusFilter.find((statusSearch) => statusSearch.id === state);
      if (state === 'nobody') {
        iconInfos.icon = 'visibility_off';
      } else if (state === 'some') {
        iconInfos.icon = 'visibility';
      } else if (state === 'all') {
        iconInfos.icon = 'check';
      }

      if (iconInfos.icon !== '') {
        iconInfos.tooltip = stateObject ? stateObject.name : '';
      }
    }

    return iconInfos;
  }

  getActions(instruction: IInstruction) {
    let actions: any[] = [];
    if (this.serviceNumberConnectedUser === instruction.created_by.service_number) {
      actions = [
        {
          type: 'button',
          style: { color: 'warn' },
          events: { click: 'edit' },
          options: { icon: 'edit' },
          tooltip: 'GLOBAL.EDIT'
        },
        {
          type: 'button',
          style: { color: 'warn' },
          events: { click: 'delete' },
          options: { icon: 'delete' },
          tooltip: 'GLOBAL.DELETE'
        }
      ];
    }

    return actions;
  }
}
