<div class="content" *ngIf='activeForm'>
  <!-- title -->
  <h1 class="mainTitle">
    <div class="title">
      <p>{{'GLOBAL.BREADCRUMB.CONTROLS.FORM' | translate}}</p>
    </div>
  </h1>

  <div class="form-container">
    <div class="section">
      <mat-accordion [multi]="true">
        <mat-expansion-panel [expanded]="expand || section.expand" *ngFor="let section of activeForm.payload.sections" class="overflow-hidden">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{section.title}}
              <span *ngIf="section.mandatory && section.mandatory === true" class="mandatory">*</span>
            </mat-panel-title>
            <mat-panel-description  class="panel-description" *ngIf="section?.fields">
              {{formatedFormFields.length}} {{'CONTROLS.FIELDS' | translate}}
            </mat-panel-description>
          </mat-expansion-panel-header>
          <mat-accordion [multi]="true">
            <mat-expansion-panel [expanded]="expand || field.expand" *ngFor="let field of formatedFormFields" class="overflow-hidden">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{field.title}}
                </mat-panel-title>
                <mat-panel-description class="panel-description" *ngIf="section?.fields">
                  {{ field.translatable | translate}}
                </mat-panel-description>
              </mat-expansion-panel-header>
              <mat-panel-description class="response-block">
                <mat-icon>trending_flat</mat-icon> {{ getParentTranslatable(field) }}
              </mat-panel-description>
            </mat-expansion-panel>
          </mat-accordion>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>
