<ngx-extended-pdf-viewer
  *ngIf="blob"
  [src]="blob"
  [height]="height"
  [zoom]="zoom"
  [showOpenFileButton]="false"
  [showStampEditor]="false"
  [showTextEditor]="false"
  [showOpenFileButton]="false"
  [showHandToolButton]="false"
  [showDrawEditor]="false"
  [showSecondaryToolbarButton]="false"
  [showRotateButton]="false"
  [filenameForDownload]="filename"
/>
<app-loading [active]="!blob"></app-loading>
