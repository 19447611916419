import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GenericDialogComponent } from 'app/components/generic/generic-dialog/generic-dialog.component';
import { IGenericListParameters } from 'app/components/generic/list/list.interface';
import {
  controlInfrastructureTypeOptions,
  ControlState,
  controlStateOptions,
  ControlTypeEnum,
  controlUpdatedDateOptions,
  IControl
} from 'app/interface/controls.interface';
import { ControlsService } from 'app/services/controls.service';
import { IconService } from 'app/services/icon.service';
import { LoadingService } from 'app/services/loading.service';
import { SectorsService } from 'app/services/sectors.service';
import { TitleService } from 'app/services/title.service';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { omit } from 'lodash';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-control-list',
  templateUrl: './controls.list.component.html',
  styleUrls: ['./controls.list.component.scss']
})
export class ControlsListComponent implements OnInit {
  // Filters data
  filterData: {
    sectorsList: { label: string; value: string }[];
    typesList: { label: string; value: string }[];
    statusList: { label: string; value: string; conformity?: boolean }[];
    updateDate: { label: string; value: string }[];
    infrastructuresList: { label: string; value: string }[];
  } = {
    sectorsList: [],
    typesList: [
      {
        label: 'CONTROLS.FILTERS.TYPES.BEACON',
        value: 'beacon'
      },
      {
        label: 'CONTROLS.FILTERS.TYPES.SPECIFIC',
        value: 'specific'
      }
    ],
    statusList: controlStateOptions,
    updateDate: controlUpdatedDateOptions,
    infrastructuresList: controlInfrastructureTypeOptions
  };
  // Filters
  selectedSectors: string[] = [];
  selectedUpdateDate?: 'LAST_WEEK' | 'LAST_MONTH' | 'LAST_YEAR' | 'OLD_AF';
  selectedStatus?: { label: string; value: string; conformity?: boolean };
  private filters: {
    q?: string;
    from?: string;
    to?: string;
    type?: string[];
    infrastructure_type?: string[];
    sector_id?: string[];
    status?: string;
    conformity?: boolean;
  } = {};
  // Other
  dialogRef: MatDialogRef<GenericDialogComponent>;
  disabledInfraFilter = false;
  id: string;
  controlsList: IControl[] = [];
  sliderChecked = true;
  slidingSlider = false;
  public total = 0;
  public structureToList = [];
  // List params
  listParameters: IGenericListParameters = {
    paginator: true,
    clickable: true,
    listHead: [
      {
        key: 'type',
        apiKey: 'infrastructure_type',
        title: 'CONTROLS.HEADERS.TYPE',
        type: 'text',
        options: {
          icon: {
            key: 'icon',
            type: 'image'
          },
          picto: true
        }
      },
      {
        key: 'highway_name',
        apiKey: 'infrastructure.location.highway_name',
        title: 'CONTROLS.HEADERS.HIGHWAY',
        type: 'text'
      },
      {
        key: 'pr',
        apiKey: 'PR',
        title: 'CONTROLS.HEADERS.PR',
        type: 'text'
      },
      {
        key: 'created_at',
        apiKey: 'created_at',
        title: 'CONTROLS.HEADERS.CREATED_AT',
        type: 'date',
        options: { format: 'shortDate' }
      },
      {
        key: 'updated_at',
        apiKey: 'updated_at',
        title: 'CONTROLS.HEADERS.UPDATED_AT',
        type: 'date',
        options: { format: 'shortDate' }
      },
      {
        key: 'affected_to',
        apiKey: 'affected_to',
        title: 'CONTROLS.HEADERS.MADE_BY',
        type: 'text'
      },
      {
        key: 'status',
        apiKey: 'translated_status',
        title: 'CONTROLS.HEADERS.STATUS',
        type: 'text'
      },
      {
        key: 'actions',
        type: 'button',
        colWidth: '80px'
      }
    ]
  };

  constructor(
    private title: TitleService,
    private controls: ControlsService,
    public loading: LoadingService,
    private router: Router,
    private sectors: SectorsService,
    private iconService: IconService,
    private translate: TranslateService,
    private http: HttpClient,
    public dialog: MatDialog
  ) {
    this.title.setTitle('GLOBAL.CONTROLS');
    this.iconService.register('beacon', '/assets/icon/infrastructures/equipement-autres.svg');
    this.iconService.register('unknown', '/assets/icon/infrastructures/inconnu.svg');

    this.sectors.getAll({ sort: 'name' }).subscribe((res) => {
      if (res && res.items) {
        this.filterData.sectorsList = res.items
          .map((item: any) => ({ value: item.id, label: item.name }))
          .sort((a, b) => a.label.localeCompare(b.label));
      }
    });

    this.getFiltersFromLocalStorage();
  }

  async ngOnInit() {
    this.setDefaultSector();
    this.getControlList();
  }

  public setDefaultSector(): void {
    const userSectors = JSON.parse(localStorage.getItem('sectors') || '[]').map((s: any) => s.id);
    if (!this.selectedSectors || this.selectedSectors.length === 0) {
      this.selectedSectors = userSectors;
      this.generateFilters();
    }
  }

  handleFilters(event: { type: string; data: any; params: any }) {
    if (event.data.search) {
      this.filters.q = String(event.data.search);
    } else if (event.type === 'search') {
      delete this.filters.q;
    }
  }

  getRelativeTime(time: dayjs.ConfigType) {
    const diff = dayjs().endOf('day').diff(dayjs(time), 'day');
    if (diff <= 7) {
      return 'LAST_WEEK';
    } else if (diff <= 30) {
      return 'LAST_MONTH';
    } else if (diff <= 366) {
      return 'LAST_YEAR';
    } else if (diff > 366) {
      return 'OLD_AF';
    } else {
      return undefined;
    }
  }

  getFiltersFromLocalStorage() {
    const filters = JSON.parse(localStorage.getItem('filtersControls') || '{}');

    this.selectedSectors = [...(filters.sector_id || [])];
    this.selectedUpdateDate = this.getRelativeTime(filters.from);
    this.selectedStatus = this.filterData.statusList.find(
      (status) => status.value === filters.status && status.conformity === filters.conformity
    );
  }

  generateFilters() {
    // -------------------- Type --------------------

    delete this.filters.type;
    this.filters.type = [ControlTypeEnum.SPECIFIC, ControlTypeEnum.BEACON];

    // -------------------- Sector_id --------------------

    delete this.filters.sector_id;
    if (this.selectedSectors.length > 0) {
      this.filters.sector_id = [...this.selectedSectors];
    }

    // -------------------- Updated date --------------------

    delete this.filters.from;
    delete this.filters.to;
    if (this.selectedUpdateDate) {
      const m = dayjs().endOf('day');
      const dayjsUpperBounds: { [key: string]: () => dayjs.Dayjs } = {
        LAST_WEEK: () => m,
        LAST_MONTH: () => m,
        LAST_YEAR: () => m,
        OLD_AF: () => dayjs().subtract(1, 'year').endOf('day')
      };
      const dayjsLowerBounds: { [key: string]: () => dayjs.Dayjs } = {
        LAST_WEEK: () => dayjs().subtract(1, 'week').startOf('day'),
        LAST_MONTH: () => dayjs().subtract(1, 'month').startOf('day'),
        LAST_YEAR: () => dayjs().subtract(1, 'year').startOf('day'),
        OLD_AF: () => dayjs(0, 'X')
      };

      this.filters.to = dayjsUpperBounds[this.selectedUpdateDate]().toDate().toISOString();
      this.filters.from = dayjsLowerBounds[this.selectedUpdateDate]().toDate().toISOString();
    }

    // -------------------- Status --------------------

    delete this.filters.status;
    delete this.filters.conformity;
    if (this.selectedStatus) {
      this.filters.status = this.selectedStatus.value;
      if (this.selectedStatus.conformity !== undefined) {
        this.filters.conformity = this.selectedStatus.conformity;
      }
    }

    const storedFilters = omit(this.filters, ['limit', 'offset']);
    localStorage.setItem('filtersControls', JSON.stringify(storedFilters));
  }

  toggleSelectSectors() {
    if (this.selectedSectors.length < this.filterData.sectorsList.length) {
      this.selectedSectors = this.filterData.sectorsList.map((sector: any) => sector.value);
    } else {
      this.selectedSectors = [];
    }

    this.getControlList();
  }

  getAffectedTo(item: any) {
    // Created by
    let result = '';
    if (item.created_by) {
      result = `${item.created_by.last_name} ${item.created_by.first_name}`;
    }

    return result;
  }

  getControlStatus(item: any) {
    const status = this.translate.instant(`CONTROLS.RESULTS.${item.status}`);
    if (item.status.toUpperCase() === ControlState.DONE) {
      const result = item.conformity
        ? this.translate.instant(`CONTROLS.RESULT.COMPLIANT`)
        : this.translate.instant(`CONTROLS.RESULT.NON_COMPLIANT`);

      return `${status} ${result.toLowerCase()}`;
    }

    return status;
  }

  createBeaconStructure(item: any) {
    item.highway_name = item.location.highway_name;
    item.icon = this.iconService.get(item.type, 'unknown');
    item.type = `INFRASTRUCTURES.TYPES.${item.type.toUpperCase()}`;
    item.pr = item.location.display;
  }

  goToFormControls() {
    this.router.navigate([`controls/forms`]);
  }

  createSpecificStructure(item: any) {
    item.highway_name = item.infrastructure ? item.infrastructure.location.highway_name : item.location.highway_name;
    if (item.infrastructure) {
      item.picto = item.infrastructure.type.picto;
    } else {
      item.icon = this.iconService.get(item.type, 'unknown');
    }

    item.type = item.infrastructure
      ? `INFRASTRUCTURES.TYPES.${item.infrastructure.type.name.toUpperCase()}`
      : 'AUDITS.UNKNOWN';
    item.pr = item.infrastructure ? item.infrastructure.location.display : item.location.display;
  }

  createStructure(items: any) {
    const res: any = [];

    items.map((item: any) => {
      if (item.type === 'beacon') {
        this.createBeaconStructure(item);
      } else {
        this.createSpecificStructure(item);
      }
      item.status = this.getControlStatus(item);
      item.affected_to = this.getAffectedTo(item);
    });

    return res;
  }

  getControlList(event?: { type: string; data: any; params: any }) {
    // Disable loading if no sector selected
    if (this.selectedSectors.length === 0) {
      this.controlsList = [];

      return;
    }
    this.generateFilters();

    if (event && event.data) {
      this.handleFilters(event);
    }
    if (event && event.params) {
      this.filters = { ...this.filters, ...event.params };
    }
    this.loading.on();

    this.controls
      .getAll(this.filters)
      .pipe(
        finalize(async () => {
          this.loading.off();
        })
      )
      .subscribe((res) => {
        this.controlsList = this.parseItems(res.items);
        this.total = res.total;
        this.createStructure(this.controlsList);
      });
  }

  parseItems(items: any[]) {
    return items.map((item) => ({
      ...item,
      actions: [
        {
          type: 'button',
          style: { color: 'warn' },
          events: { click: 'delete' },
          options: { icon: 'delete' },
          tooltip: 'GLOBAL.DELETE'
        }
      ]
    }));
  }

  exportControls() {
    const title = 'controls-list';
    const downloadDate = dayjs().format('DD-MM-YYYY-hhmm');
    const downloadedFilename = `${title}-${downloadDate}.pdf`;
    this.http
      .get(`api://controls/pdf`, { responseType: 'blob', params: this.filters as any })
      .subscribe((data) => saveAs(data, downloadedFilename));
  }

  deleteControl(_id: string) {
    return {
      title: 'GLOBAL.CONFIRMATION',
      text: 'CONFIRM.DELETE_CONTROL',
      buttons: [
        {
          text: 'GLOBAL.CANCEL',
          class: 'cancel',
          action: {
            target: 'generic',
            params: {
              id: 'close',
              function: undefined
            }
          }
        },
        {
          text: 'GLOBAL.DELETE',
          class: 'validation',
          isRaisedButton: true,
          action: {
            target: 'custom',
            params: {
              id: 'delete',
              function: () => {
                this.controls.delete(_id).subscribe(() => this.getControlList());
                this.dialog.closeAll();
              }
            }
          }
        }
      ]
    };
  }

  getEvent(event: { type: string; data: any }) {
    if (event.type && event.data) {
      switch (event.type) {
        case 'rowClick':
        case 'clickIcon':
          this.router.navigate([`controls/${event.data._id}`]);
          break;
        case 'delete':
          this.dialogRef = this.dialog.open(GenericDialogComponent, {
            width: '50%',
            data: { structure: this.deleteControl(event.data._id) },
            disableClose: true
          });
          break;
      }
    }
  }

  handleSliderChange() {
    this.slidingSlider = true;
    setTimeout(async () => this.router.navigate([`audits`]), 125);
  }
}
