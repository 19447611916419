<h1 class="mainTitle">{{ 'INFRASTRUCTURES.LIST_PAGE_NAME' | translate }}</h1>
<button mat-stroked-button (click)="goToManager()">
  {{ 'GLOBAL.BREADCRUMB.INFRASTRUCTURES.MANAGER' | translate }}
</button>
<app-list
  [listParameters]="listParameters"
  [loaded]="loaded"
  [items]="infrastructuresList"
  [total]="total"
  searchType="dynamic"
  (refresh)="getInfrastructuresList($event)"
></app-list>
