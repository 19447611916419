import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ProfileService } from 'src/app/services/profile.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  title = environment.APP_TITLE;
  srcLogo = ''; // = '../../assets/common/logo.svg';
  profileName = '';
  environment = '';

  constructor(
    private profileService: ProfileService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {

    this.profileService.profile.subscribe((e) => {
      if (!e) {
        return;
      }
      this.profileName = e.name;
      if (e.mainLogo) this.srcLogo = e.mainLogo;
      this.changeDetectorRef.detectChanges();
    });
  }
}
