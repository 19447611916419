import { animate, state, style, transition, trigger } from '@angular/animations';
import { NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';

import { ReportingSubtype, ReportingType, ReportingTypePriority } from '../../../../interface/report.interface';
import { ReportsService } from '../../../../services/reports.service';

interface ReportingTypeElement {
  label: string;
  picto: string;
  priority: ReportingTypePriority;
  subtypes: ReportingSubtype[];
}

@Component({
  selector: 'app-types-modal',
  templateUrl: './types-modal.component.html',
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ],
  styleUrls: ['./types-modal.component.scss'],
  standalone: true,
  imports: [MatTableModule, NgFor, MatButtonModule, NgIf, MatIconModule, TranslateModule, MatDialogModule]
})
export class TypesModalComponent implements OnInit {
  dataSource: ReportingTypeElement[] = [];
  reportingTypes: ReportingType[] = [];
  columnsToDisplay = ['label', 'picto', 'priority'];
  columnsToDisplayLabels = {
    label: 'REPORTING.REPORTING_TYPE_LABEL.LABEL',
    picto: 'REPORTING.REPORTING_TYPE_LABEL.PICTO',
    priority: 'REPORTING.REPORTING_TYPE_LABEL.PRIORITY'
  };
  subColumnsToDisplay = ['REPORTING.REPORTING_SUBTYPE_LABEL.LABEL'];
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  expandedElement: ReportingTypeElement | null;

  constructor(private reportsService: ReportsService) {}
  ngOnInit(): void {
    this.reportsService.getReportingTypesObservable().subscribe((types) => {
      this.reportingTypes = types;
      this.dataSource = this.reportingTypes.map((reportingType) => {
        return {
          label: reportingType.label!,
          picto: reportingType.picto!,
          priority: reportingType.priority!,
          subtypes: reportingType.subtypes!
        };
      });
    });
  }
}
