import { InfrastructureManagerFilters } from '../components/infrastructures/manager/infrastructure-manager.component';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type LocalStorageValues = {
  //
  // Dev mode
  devMode: string;
  //
  // Profile (client)
  profileCode: string;
  profileMainLogo: string;
  //
  // Session.service
  token: string;
  redirectUri: string; // after login click, navigate back to previous uri
  untouched_key: string; // deprecated?
  last_login: string; // deprecated?
  service_number: string;
  first_name: string;
  last_name: string;
  permissions: string;
  teams: string;
  sectors: string;
  sectorsHighways: string;
  role: string;
  //
  // Reportings
  filters: string;
  unknownAccidentAssociation: string;
  knownAccidentAssociation: string;
  noAccidentAssociation: string;
  mapStartDate: string;
  mapEndDate: string;
  sectorsSelected: string;
  //
  // DrivingLogs
  filtersDrivingLogArchives: string;
  //
  // Controls
  filtersControls: string;
  //
  // Instructions
  filtersInstructions: string;
  //
  // Infrastructure
  infrastructureManagerFilters: InfrastructureManagerFilters;
  //
  // Misc
  localFiltersKey: string;
  current_import: string;
  current_error: string;
  key_that_should_be_kept: string;
  'tasks-filters': string;
  'poi-manager-filters': string;
  google_maps: string;
};

export class LocalStorageService {
  public static clearAllExceptProfile() {
    for (const key in localStorage) {
      if (!['profileCode', 'profileMainLogo', 'devMode'].includes(key)) localStorage.removeItem(key);
    }
  }

  public static getJSONItem<T = any>(key: string): T | null {
    const itemStr = localStorage.getItem(key);
    if (itemStr === null) {
      return null;
    }
    let parsed;
    try {
      parsed = JSON.parse(itemStr);
    } catch (err) {
      console.error(`LocalStorageService.getJSONItem: error parsing json item with key "${key}"`);
      return null;
    }
    return parsed;
  }
}
