import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { NgxExtendedPdfViewerModule, pdfDefaultOptions } from 'ngx-extended-pdf-viewer';
import { CalcContentHeightOptions, MiscHelper } from 'src/app/tools/misc.helper';

import { LoadingComponent } from '../../loading/loading.component';

@Component({
  standalone: true,
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  imports: [CommonModule, NgxExtendedPdfViewerModule, LoadingComponent]
})
export class PdfViewerComponent {
  public height: string;

  @Input() public blob: Blob;
  @Input() public zoom: string = '100%';
  @Input() public filename: string = 'document.pdf';

  /**
   * Configure the Y axis padding based on app element heights.
   * Set "true" for each element you want the height to be removed from the pdf-viewer height.
   */
  @Input() set heightPadding(value: CalcContentHeightOptions | undefined) {
    this.height = `calc(100vh - ${MiscHelper.calcContentHeight(value)}px)`;
  }

  constructor() {
    pdfDefaultOptions.assetsFolder = 'assets/ngx-extended-pdf';
  }
}
