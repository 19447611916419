import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GenericDialogComponent } from 'app/components/generic/generic-dialog/generic-dialog.component';
import { IDrivingLog } from 'app/interface/driving-logs.interface';
import { IEvent } from 'app/interface/event.interface';
import { DrivingLogsService } from 'app/services/driving-logs.service';
import { LoadingService } from 'app/services/loading.service';
import { SectorsService } from 'app/services/sectors.service';
import { TitleService } from 'app/services/title.service';
import { UsersService } from 'app/services/users.service';
import dayjs from 'dayjs';
import _ from 'lodash';
import { tap } from 'rxjs/operators';

export interface IDrivingLogDisplay {
  _id: string;
  first_name: string;
  last_name: string;
  service_number: string;
  started_at: Date;
  last_activity_at: Date;
  actions: { [key: string]: any }[];
}

@Component({
  selector: 'app-driving-logs-archives',
  templateUrl: './ongoing-driving-logs-list.component.html',
  styleUrls: ['./ongoing-driving-logs-list.component.scss']
})
export class OngoingDrivingLogsListComponent implements OnInit {
  public loaded = false;
  items: IDrivingLogDisplay[] = [];
  total = 0;
  selectedUpdateDate?: 'LAST_WEEK' | 'LAST_MONTH' | 'LAST_YEAR' | 'OLD_AF';
  selectedMadeDate?: 'LAST_WEEK' | 'LAST_MONTH' | 'LAST_YEAR' | 'OLD_AF';

  filterData = {
    sectors: {
      items: [] as { value: string; label: string }[],
      default: '' // EVO Multiple sectors: Set default value as an array
    },
    from: {
      max: new Date(),
      default: dayjs().subtract(1, 'week').toDate()
    },
    to: {
      min: dayjs().subtract(1, 'week').toDate(),
      default: new Date()
    }
  };
  selectedSectors: string[] = [];
  selectedPermissions: string[] = [];
  drivingLogList: any[] = [];

  rawFilters = {
    sectors: '',
    from: dayjs().subtract(1, 'week').toDate(),
    to: new Date()
  };

  private filters: {
    q?: string;
    sectors?: string[];
    from?: string;
    to?: string;
    updated_before?: string;
    updated_after?: string;
    made_after?: string;
    made_before?: string;
    permissions?: string[];
  } = {};

  private localFiltersKey = 'filtersDrivingLogArchives';
  dialogRef?: MatDialogRef<GenericDialogComponent>;

  public allSectors: any;

  constructor(
    private sectorService: SectorsService,
    private drivingLogs: DrivingLogsService,
    private router: Router,
    public loading: LoadingService,
    public usersService: UsersService,
    public dialog: MatDialog,
    public translate: TranslateService,
    title: TitleService
  ) {
    title.setTitle('DRIVING_LOGS.ONGOING_LIST.TITLE');
  }

  async setDefaultSector(): Promise<void> {
    let userSectors = [];
    if (JSON.parse(localStorage.getItem('sectorsSelected') || '[]').sectors) {
      userSectors = JSON.parse(localStorage.getItem('sectorsSelected') || '[]').sectors.map((s: any) => s);
    }
    if (!this.filters.sectors || this.filters.sectors.length === 0) {
      this.filters.sectors = userSectors;
      this.selectedSectors = userSectors;
      await this.getDrivingLogsList();
    }
  }

  /**
   * Initialize generic list filters and retrieve driving log archives.
   */
  async ngOnInit() {
    if (localStorage.getItem('role') === 'ADM') {
      this.sectorService.getAll().subscribe((sectors) => {
        this.allSectors = _.sortBy(
          sectors.items.map((s) => ({
            value: s.id,
            label: s.name
          })),
          (e) => e.label
        );
      });
    } else {
      // Get user's sectors
      const teams = localStorage.getItem('teams');
      this.allSectors = [];
      if (teams) {
        await JSON.parse(teams).map(async (el: any) => {
          await this.sectorService.getByTeamsId(el.id).subscribe((sectors: any) => {
            const found = this.allSectors.some((sector: { label: any }) => sector.label === sectors[0].name);
            if (!found) {
              this.allSectors.push({
                value: sectors[0].id,
                label: sectors[0].name
              });
            }
          });
        });
      }
    }
    this.setDefaultSector();
    this.getDrivingLogsList();
  }

  /**
   * Handle generic list events.
   */
  captureEvent(event: IEvent) {
    switch (event.type) {
      case 'download':
        this.drivingLogs.downloadPdf(event.data._id);
        break;
      case 'import':
        this.importDrivingLog(event.data);
        break;
    }
  }

  download(id: string) {
    this.drivingLogs.downloadPdf(id);
  }

  upload(data: any) {
    this.importDrivingLog(data);
  }

  importDrivingLog(row: IDrivingLogDisplay) {
    this.openImportDialog(row._id, '/driving-logs');
  }

  isTooLong(date: Date) {
    return dayjs(date).isBefore(dayjs().subtract(10, 'hours'));
  }

  openImportDialog(id: string, target: string): void {
    const title = target.replace('/', '').toUpperCase();
    this.dialogRef = this.dialog.open(GenericDialogComponent, {
      width: '450px',
      height: 'auto',
      data: {
        hideCancelButtonOnSubmit: true,
        structure: {
          title: this.translate.instant(`IMPORT.IMPORT_${title}`),
          disclaimer: this.translate.instant(`IMPORT.IMPORT_${title}-DISCLAIMER`)
        },
        content: {
          endpoint: `${target}/${id}/signed-pdf`,
          baseURL: `${target}`,
          idURL: `${id}`,
          type: 'file',
          button: {
            text: this.translate.instant('GLOBAL.IMPORT'),
            class: 'validate'
          }
        }
      },
      disableClose: true
    });
    const subscription = this.dialogRef.beforeClosed().subscribe(() => {
      this.getDrivingLogsList();
      subscription.unsubscribe();
    });
  }

  gotoArchives() {
    setTimeout(async () => this.router.navigateByUrl('/driving-logs/archives'), 300);
  }

  /**
   * Convert raw filters from to API-ready filters.
   *
   * @param skipUpdate Whether to update the driving logs archives list after
   *    once filters have been updated (defaults: `false`).
   */
  updateFilters(skipUpdate = false) {
    /*
     * Update `sectors` filter
     * EVO Multiple sectors: No need to encapsulate values of both operands into arrays
     */
    this.filters.sectors = this.rawFilters.sectors ? [this.rawFilters.sectors] : [this.filterData.sectors.default];

    // Update `from` and `to` filters
    let fromMoment = dayjs(this.rawFilters.from);
    fromMoment = this.rawFilters.from && fromMoment.isValid() ? fromMoment : dayjs(this.filterData.from.default);

    let toMoment = dayjs(this.rawFilters.to);
    toMoment = this.rawFilters.to && toMoment.isValid() ? toMoment : dayjs(this.filterData.to.default);

    if (fromMoment.isAfter(toMoment, 'day')) {
      // Swap "from" and "to" filters if they somehow end up in the wrong order
      [fromMoment, toMoment] = [toMoment, fromMoment];
      this.rawFilters.from = fromMoment.toDate();
      this.rawFilters.to = toMoment.toDate();
    }

    this.filters.from = fromMoment.format('YYYY-MM-DD');
    this.filterData.from.max = toMoment.toDate();

    this.filters.to = toMoment.format('YYYY-MM-DD');
    this.filterData.to.min = fromMoment.toDate();

    // Save updated filters to local storage
    this.updateLocalFilters();

    if (!skipUpdate) {
      this.getDrivingLogs();
    }
  }

  /**
   * Retrieve driving log archives from the API.
   */
  private getDrivingLogs() {
    this.drivingLogs
      .getDrivingLogs({ ...this.filters, sectors: this.rawFilters.sectors })
      .pipe(
        tap(({ items, total }) => {
          this.drivingLogList = this.parseList(items);
          this.total = total;
        })
      )
      .subscribe();
  }

  /**
   * Save current filters into local storage.
   */
  private updateLocalFilters() {
    localStorage.setItem(this.localFiltersKey, JSON.stringify(this.filters));
  }

  /**
   * Convert the raw API response to a display-ready data structure.
   */
  private parseList(items: IDrivingLog[]): IDrivingLogDisplay[] {
    return items.map((item) => ({
      _id: item._id,
      first_name: item.user.first_name,
      last_name: item.user.last_name,
      service_number: item.user.service_number,
      started_at: dayjs(item.created_at).toDate(),
      last_activity_at: dayjs(item.last_activity_at).toDate(),
      actions: [
        {
          type: 'button',
          style: { color: 'warn' },
          events: { click: 'import' },
          options: { icon: 'file_upload' },
          tooltip: 'GLOBAL.IMPORT'
        },
        {
          type: 'button',
          style: { color: 'warn' },
          events: { click: 'download' },
          options: { icon: 'file_download' },
          tooltip: 'GLOBAL.DOWNLOAD'
        }
      ]
    }));
  }

  public getGPX(id: string) {
    this.drivingLogs.getGPX(id);
  }

  public devMode() {
    return localStorage.getItem('devMode') !== null;
  }

  public toggleSelectSectors() {
    if (this.selectedSectors.length < this.allSectors.length) {
      this.selectedSectors = this.allSectors.map((sector: any) => sector.value);
    } else {
      this.selectedSectors = [];
    }
    this.getDrivingLogsList();
  }

  public handleFilters(event: { type: string; data: any; params: any }) {
    if (event.data.search) {
      this.filters.q = String(event.data.search);
    } else if (event.type === 'search') {
      delete this.filters.q;
    }
  }

  generateFilters() {
    const m = dayjs().endOf('day');
    const dayjsUpperBounds: { [key: string]: () => dayjs.Dayjs } = {
      LAST_WEEK: () => m,
      LAST_MONTH: () => m,
      LAST_YEAR: () => m,
      OLD_AF: () => dayjs().subtract(1, 'year').endOf('day')
    };
    const dayjsLowerBounds: { [key: string]: () => dayjs.Dayjs } = {
      LAST_WEEK: () => dayjs().subtract(1, 'week').startOf('day'),
      LAST_MONTH: () => dayjs().subtract(1, 'month').startOf('day'),
      LAST_YEAR: () => dayjs().subtract(1, 'year').startOf('day'),
      OLD_AF: () => dayjs(0, 'X')
    };
    // -------------------- Sector_id --------------------

    delete this.filters.sectors;
    if (this.selectedSectors.length > 0) {
      this.filters.sectors = [...this.selectedSectors];
    }

    // -------------------- Permissions --------------------

    delete this.filters.permissions;
    if (this.selectedPermissions.length > 0) {
      this.filters.permissions = [...this.selectedPermissions];
    }

    // -------------------- Updated date --------------------

    delete this.filters.updated_after; // from
    delete this.filters.updated_before; // to
    if (this.selectedUpdateDate) {
      this.filters.updated_before = dayjsUpperBounds[this.selectedUpdateDate]().toDate().toISOString();
      this.filters.updated_after = dayjsLowerBounds[this.selectedUpdateDate]().toDate().toISOString();
    }

    // -------------------- Made date --------------------

    delete this.filters.made_after; // from
    delete this.filters.made_before; // to
    if (this.selectedMadeDate) {
      this.filters.made_before = dayjsUpperBounds[this.selectedMadeDate]().toDate().toISOString();
      this.filters.made_after = dayjsLowerBounds[this.selectedMadeDate]().toDate().toISOString();
    }

    const storedFilters = _.omit(this.filters, ['limit', 'offset']);
    localStorage.setItem('sectorsSelected', JSON.stringify(storedFilters));
  }

  async getDrivingLogsList(event?: { type: string; data: any; params: any }) {
    this.loaded = false;
    this.generateFilters();
    if (event && event.data) {
      this.handleFilters(event);
    }

    if (event && event.params) {
      this.filters = { ...this.filters, ...event.params };
    }
    if (!this.filters.sectors) {
      this.total = 0;
      this.drivingLogList = [];
      this.loaded = true;

      return;
    }

    this.loading.on();
    try {
      const resp = await this.drivingLogs.getDrivingLogs(this.filters).toPromise();
      if (!resp) throw Error('Cannot get driving logs');
      this.total = resp.total;
      this.drivingLogList = this.parseList(resp.items);
      this.loading.off();
      this.loaded = true;
    } catch (err) {
      throw err;
    }
  }
}
