import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IListResult } from 'app/interface/list-result.interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FluFilters, FluFlrResult } from '../interface/flu-fr.interface';

@Injectable()
export class FluflrService {
  constructor(private http: HttpClient) {}

  getAll(filters: FluFilters = {}): Observable<FluFlrResult> {
    return this.http.get('api://flu-flr', { params: filters } as any) as any;
  }

  getById(id: string): Observable<any>;
  getById(ids: string[], filters?: { sort: string }): Observable<any[]>;
  getById(id: string[] | string, filters?: { sort: string }): Observable<any[]> | Observable<any> {
    if (Array.isArray(id)) {
      const params = {
        ...(filters || {}),
        id: id.join(',')
      };

      return this.http
        .get('api://flu-flr', { params })
        .pipe(map((result: IListResult<any>) => result.items) as any) as any;
    } else {
      const params = { ...(filters || {}), id };

      return this.http
        .get('api://flu-flr', { params })
        .pipe(map((result: IListResult<any>) => result.items[0]) as any) as any;
    }
  }
}
