import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import dayjs from 'dayjs';
import { IAccidentLanes, LaneType } from 'src/app/interface/accident-reporting.interface';

interface IPayloadEntry {
  key: string;
  value: any;
}
interface IVehicleFormEntry {
  reference: string;
  payload: IPayloadEntry[];
}

@Component({
  selector: 'app-lanes-other-dialog',
  templateUrl: './lanes-other-dialog.component.html',
  styleUrls: ['./lanes-other-dialog.component.scss']
})
export class LanesOtherDialogComponent {
  lastUpdated?: dayjs.Dayjs;
  payloadView: IVehicleFormEntry[] | IPayloadEntry[] = [];
  displayInfo: any[];
  canDisplay = false;

  constructor(
    public dialogRef: MatDialogRef<LanesOtherDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public form: IAccidentLanes['currentDirection']
  ) {
    this.displayInfo = this.form.impacted
      .filter((e) => e.selected === true && e.type !== LaneType.LANE)
      .map((e) => ({ type: `REPORTING.LANES_CARD.TYPES.${e.type.toUpperCase()}`, metadata: e.metadata || '' }));
    this.canDisplay = true;
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }
}
