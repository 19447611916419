import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CPType, ICrossingPoint } from 'app/interface/crossing-points.interface';
import { IBareListResult } from 'app/interface/list-result.interface';
import { lastValueFrom, Observable } from 'rxjs';

import { RequestFilters } from '../interface/utils.interfaces';

const cpTypes: Omit<CPType, 'id' | 'label'>[] = [
  {
    name: 'limite_concession',
    // name: 'CROSSING_POINTS.TYPES.LIMITE_CONCESSION',
    picto: '/assets/poi-manager/markers/limite.png',
    color: 'black'
  },
  {
    name: 'acces_secours',
    // name: 'CROSSING_POINTS.TYPES.ACCES_SECOURS',
    picto: '/assets/poi-manager/markers/secours.png',
    color: 'SaddleBrown'
  },
  {
    name: 'acces_service',
    // name: 'CROSSING_POINTS.TYPES.ACCES_SERVICE',
    picto: '/assets/poi-manager/markers/service.png',
    color: 'Gold'
  },
  {
    name: 'aire',
    // name: 'CROSSING_POINTS.TYPES.AIRE',
    picto: '/assets/poi-manager/markers/aire.png',
    color: 'Crimson'
  },
  {
    name: 'barriere_peage',
    // name: 'CROSSING_POINTS.TYPES.BARRIERE_PEAGE',
    picto: '/assets/poi-manager/markers/bpeage.png',
    color: 'DodgerBlue'
  },
  {
    name: 'bifurcation_echangeur',
    // name: 'CROSSING_POINTS.TYPES.BIFURCATION_ECHANGEUR',
    picto: '/assets/poi-manager/markers/biffurcation.png',
    color: 'Purple'
  },
  {
    name: 'diffuseur',
    // name: 'CROSSING_POINTS.TYPES.DIFFUSEUR',
    picto: '/assets/poi-manager/markers/diffuseur.png',
    color: 'DarkCyan'
  },
  {
    name: 'direction_regionale',
    // name: 'CROSSING_POINTS.TYPES.DIRECTION_REGIONALE',
    picto: '/assets/poi-manager/markers/direction.png',
    color: 'DarkBlue'
  },
  {
    name: 'district_centre_entretien',
    // name: 'CROSSING_POINTS.TYPES.DISTRICT_CENTRE_ENTRETIEN',
    picto: '/assets/poi-manager/markers/ctr.png',
    color: 'Sienna'
  },
  {
    name: 'gare_peage',
    // name: 'CROSSING_POINTS.TYPES.GARE_PEAGE',
    picto: '/assets/poi-manager/markers/gpeage.png',
    color: 'DodgerBlue'
  },
  {
    name: 'locaux',
    // name: 'CROSSING_POINTS.TYPES.LOCAUX',
    picto: '/assets/poi-manager/markers/locaux.png',
    color: 'Crimson'
  },
  {
    name: 'point_appui',
    // name: 'CROSSING_POINTS.TYPES.POINT_APPUI',
    picto: '/assets/poi-manager/markers/point-appui.png',
    color: 'Crimson'
  }
];

// @Injectable({
//   providedIn: 'root'
// })
@Injectable()
export class CrossingPointsService {
  private cpTypes?: CPType[];

  constructor(
    private http: HttpClient,
    private translate: TranslateService
  ) {}

  getAll(filters: RequestFilters = {}): Observable<IBareListResult<ICrossingPoint>> {
    return this.http.get('api://crossing-points', { params: filters } as any) as any;
  }

  public getTypes(): CPType[] {
    if (this.cpTypes) {
      return this.cpTypes;
    }
    this.cpTypes = cpTypes.map((cpType) => {
      return {
        ...cpType,
        id: cpType.name,
        label: this.translate.instant('CROSSING_POINTS.TYPES.' + cpType.name.toUpperCase())
      };
    });
    return this.cpTypes;
  }

  public async remove(uid: string): Promise<void> {
    await lastValueFrom(this.http.delete(`api://crossing-points/${uid}`));
  }

  public async patchPOI(uid: string, body: { coordinates: number[]; radius: number; id: string }): Promise<void> {
    await lastValueFrom(this.http.patch(`api://crossing-points/${uid}`, body));
  }

  public create(data: {
    name: string;
    highway: string;
    direction: number;
    pk: string;
    type: string;
    coordinates: {
      lat: number;
      lng: number;
    };
  }) {
    return this.http.post(`api://crossing-points`, data);
  }
}
