<h1 mat-dialog-title>{{ data.title | translate }}</h1>
<div class="dialog-padding">
  <div *ngFor="let row of filtersList" class="row-dialog">
    <div class="nth_row">
      <div class="row-content">
        <div class="filter-item" *ngFor="let infrastructureType of row">
          <div>
            <img
              [ngClass]="{
                active: isActiveFilter(infrastructureType.id)
              }"
              [src]="infrastructureType.picto"
              (click)="manageFilterSimple(infrastructureType.id)"
            />
          </div>
          <p>{{ infrastructureType.name }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="center">
    <button mat-button (click)="toggleList()">{{ 'GLOBAL.TOGGLE_SELECT' | translate }}</button>
  </div>
  <div class="dialog-buttons mt-2">
    <!-- Button -->
    <button mat-raised-button color="warn" (click)="validateDialog()">
      <span>{{ 'GLOBAL.SUBMIT_BUTTON_LABEL' | translate }}</span>
    </button>
    <!-- Button -->
    <button mat-button (click)="closeDialog()">
      <span>{{ 'GLOBAL.CANCEL' | translate }}</span>
    </button>
  </div>
</div>
