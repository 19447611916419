import { HttpClient } from '@angular/common/http';
import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { GoogleMap, MapCircle, MapInfoWindow } from '@angular/google-maps';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { lastValueFrom } from 'rxjs';
import { CPType, ICrossingPoint, Marker } from 'src/app/interface/crossing-points.interface';
import { IHighway } from 'src/app/interface/highway.interface';
import { IBareListResult } from 'src/app/interface/list-result.interface';
import { Coordinates } from 'src/app/interface/location.interface';
import { ISector } from 'src/app/interface/sector.interface';
import { CrossingPointsService } from 'src/app/services/crossing-points.service';
import { HighwaysService } from 'src/app/services/highways.service';
import { SectorsService } from 'src/app/services/sectors.service';
import { MapsHelper } from 'src/app/tools/maps.helper';

import { NewCrossingPointDialogComponent } from '../new/new-crossing-point.dialog.component';

@Component({
  selector: 'app-crossing-point-manager',
  templateUrl: './crossing-point-manager.component.html',
  styleUrls: ['./crossing-point-manager.component.scss']
})
export class CrossingPointManagerComponent implements OnInit {
  public mapsApiLoaded: boolean = false;
  private mapsHelper: MapsHelper;
  @ViewChild(GoogleMap) private map: GoogleMap;
  @ViewChildren(MapCircle) private map_circles: QueryList<MapCircle>;
  // 0: cp
  @ViewChildren(MapInfoWindow) private map_infoWindows: QueryList<MapInfoWindow>;

  public developerMode = localStorage.getItem('devMode') !== null;
  public loaded = false;
  public edit: number | undefined = undefined;
  public lat = 46.7833;
  public lng = 4.85;
  public zoom = 7;
  public infoWindow: { open: boolean; type: string; content: any } = {
    open: false,
    type: '',
    content: {}
  };
  public types: CPType[];

  public crossingPoints: Marker<ICrossingPoint>[] = [];
  public newCP: Marker<ICrossingPoint>[] = [];
  public crossingPointsFiltered: Marker<ICrossingPoint>[] = [];
  public activeTypes: string[] = [];
  public highways: IHighway[] = [];
  public sectors: ISector[] = [];
  public selectedHighway: any = {};
  public deletionInProgress = false;
  public currentZoom = 0;
  public sectorsList = new UntypedFormControl();
  public gpx = new UntypedFormControl();
  public selectedStart = new UntypedFormControl();
  public selectedEnd = new UntypedFormControl();
  public sectorsHighways: Array<{ key: string; name: string }> = [];
  public storageKey = 'poi-manager-filters';

  /* Chart */
  public timelineData: Array<any> = [];
  public displayTL = false;
  public legend = true;
  public showLabels = true;
  public animations = true;
  public xAxis = true;
  public yAxis = true;
  public showYAxisLabel = false;
  public showXAxisLabel = false;
  public timeline = false;
  public view: any[] = [700, 100];

  constructor(
    private cpService: CrossingPointsService,
    private sectorService: SectorsService,
    private highwayService: HighwaysService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private translate: TranslateService,
    httpClient: HttpClient
  ) {
    this.types = this.cpService.getTypes();
    this.mapsHelper = new MapsHelper(httpClient);
  }

  ngOnInit() {
    this.mapsHelper.loadMapsApi().then(() => (this.mapsApiLoaded = true));
    this.view = [window.innerWidth - 240, 150];
    this.sectorsHighways = JSON.parse(localStorage.sectorsHighways).map((h: any) => ({ key: h.UID, value: h.name }));
    this._initFilters();
    this.loadData();
  }

  private _initFilters() {
    const storage = localStorage.getItem(this.storageKey);
    if (storage) {
      const decoded = JSON.parse(storage);
      this.sectorsList.setValue(decoded.sectorsList ? decoded.sectorsList : []);
      this.activeTypes = decoded.activeTypes ? decoded.activeTypes : [];
    }

    const sectors = localStorage.getItem('sectors');

    if (
      (this.sectorsList.value === null || this.sectorsList.value.length === 0) &&
      sectors !== null &&
      JSON.parse(sectors).length > 0
    ) {
      this.sectorsList.setValue(JSON.parse(sectors).map((sector: any) => sector.id));
    }
  }

  private saveFilters(data: { sectorsList?: Array<string>; activeTypes?: Array<string> }) {
    const storage = localStorage.getItem(this.storageKey);
    if (storage) {
      localStorage.setItem(
        this.storageKey,
        JSON.stringify({
          ...JSON.parse(storage),
          ...data
        })
      );
    } else {
      localStorage.setItem(this.storageKey, JSON.stringify(data));
    }
  }

  public formatSectors(data: IBareListResult<ISector>): void {
    this.sectors = _.sortBy([...data.items], (e) => e.name);
  }

  public formatHighways(data: IBareListResult<IHighway>): void {
    this.highways = data.items;
  }

  private formatCP(c: ICrossingPoint): Marker<ICrossingPoint> {
    const cpType = this.types.find((e) => e.name === c.type);
    return {
      coordinates: {
        latitude: c.coordinates.latitude,
        longitude: c.coordinates.longitude
      },
      title: c.name,
      radius: c.radius,
      type: 'crossing-point',
      style: cpType ? { url: cpType.picto, color: cpType.color } : { url: '', color: 'red' },
      data: c
    } as Marker<ICrossingPoint>;
  }

  public formatCPList(data: IBareListResult<ICrossingPoint>): void {
    this.crossingPoints = data.items.map((e) => this.formatCP(e));
  }

  public async loadData(): Promise<void> {
    this.loaded = false;
    const [cpList, highwayList, sectorList] = await Promise.all([
      lastValueFrom(this.cpService.getAll()),
      this.highwayService.getAll(),
      lastValueFrom(this.sectorService.getAll())
    ]);

    this.formatSectors(sectorList);
    this.formatCPList(cpList);
    this.formatHighways(highwayList);
    this.applyFilters();
    this.loaded = true;
  }

  public toggleFilter(type: CPType): void {
    if (this.activeTypes.indexOf(type.name) !== -1) {
      this.activeTypes = this.activeTypes.filter((t) => t !== type.name);
    } else {
      this.activeTypes.push(type.name);
    }

    this.applyFilters();

    if (this.activeTypes.length > 0 && (!this.sectorsList.value || this.sectorsList.value.length === 0)) {
      this.snackbar.open(
        this.translate.instant('CROSSING_POINTS.SNACKBAR.MUST_SELECT_HIGHWAY'),
        this.translate.instant('GLOBAL.CLOSE_LABEL'),
        {
          duration: 2000
        }
      );
    }
  }

  public isActiveFilter(typeName: string): boolean {
    return this.activeTypes.indexOf(typeName) !== -1;
  }

  private getDirection(location: any): string {
    if (location.direction === 1) {
      return `(${location.direction}) : ${location.origin} > ${location.destination}`;
    } else if (location.direction === 2) {
      return `(${location.direction}) : ${location.destination} > ${location.origin}`;
    } else {
      return `(${location.direction}) : ${this.translate.instant('CROSSING_POINTS.DIRECTION_3')}`;
    }
  }

  public applyFilters(): void {
    this.saveFilters({ sectorsList: this.sectorsList.value, activeTypes: this.activeTypes });
    this.crossingPointsFiltered = this.crossingPoints.filter((c) => {
      if (this.sectorsList.value !== null && this.sectorsList.value.length > 0) {
        return (
          this.sectorsList.value.indexOf(c.data.location.sector_id) !== -1 &&
          this.activeTypes.indexOf(c.data.type) !== -1
        );
      }

      return false;
    });
  }

  public handleCPMarkerMoved(index: number, event: google.maps.MapMouseEvent): void {
    const coords = MapsHelper.gmCoordsToCoords(event.latLng);
    if (!coords) {
      return;
    }
    this.updateCPCoords(index, coords);
  }

  public handleCPCircleMoved(index: number): void {
    const circle = this.map_circles.get(index);
    const coords = MapsHelper.gmCoordsToCoords(circle?.getCenter());
    if (!coords) {
      return;
    }
    this.updateCPCoords(index, coords);
  }

  public handleCPRadiusChanged(index: number): void {
    const circle = this.map_circles.get(index);
    const radius = circle?.getRadius();
    if (!radius) {
      return;
    }
    this.updateCPRadius(index, radius);
  }

  private async updateCPCoords(index: number, coords: Coordinates): Promise<void> {
    this.crossingPointsFiltered[index].coordinates = coords;
    await this.cpService.patchPOI(this.crossingPointsFiltered[index].data._id!, {
      coordinates: [coords.longitude, coords.latitude],
      radius: this.crossingPointsFiltered[index].radius || 20,
      id: this.crossingPointsFiltered[index].data._id!
    });
    this.updateCPSource(this.crossingPointsFiltered[index]);
  }

  private async updateCPRadius(index: number, radius: number): Promise<void> {
    if (radius < 5) {
      radius = 5;
    }
    const prevRadius = this.crossingPointsFiltered[index].radius;
    if (prevRadius === radius) {
      return;
    }
    this.crossingPointsFiltered[index].radius = radius;
    await this.cpService.patchPOI(this.crossingPointsFiltered[index].data._id!, {
      coordinates: [
        this.crossingPointsFiltered[index].coordinates.longitude,
        this.crossingPointsFiltered[index].coordinates.latitude
      ],
      radius: this.crossingPointsFiltered[index].radius!,
      id: this.crossingPointsFiltered[index].data._id!
    });
    this.updateCPSource(this.crossingPointsFiltered[index]);
  }

  private updateCPSource(updated: Marker<ICrossingPoint>): void {
    const index = _.findIndex(
      this.crossingPoints,
      (item: Marker<ICrossingPoint>) => item.data._id === updated.data._id
    );
    this.crossingPoints[index] = updated;
  }

  public isEditable(index: number): boolean {
    return this.edit === index;
  }

  public editable(data: Marker<ICrossingPoint>, index?: number): void {
    this.lat = data.coordinates.latitude;
    this.lng = data.coordinates.longitude;
    this.edit = index;
    this.zoom = 18;
  }

  public openCPInfoWindow(data: Marker<ICrossingPoint>, index?: number): void {
    const cpInfoWindow = this.map_infoWindows.get(0)!;
    cpInfoWindow.position = MapsHelper.coordsToGMCoords(data.coordinates);
    cpInfoWindow.open();
    this.editable(data, index);

    this.infoWindow = {
      open: true,
      type: data.type,
      content: data
    };

    this.cancelDeletion();
  }

  public closeCPInfoWindow(): void {
    const cpInfoWindow = this.map_infoWindows.get(0);
    cpInfoWindow?.close();
    this.infoWindow = { open: false, type: '', content: {} };
    this.cancelDeletion();
  }

  public deletionConfirm() {
    this.deletionInProgress = true;
  }

  public cancelDeletion() {
    this.deletionInProgress = false;
  }

  public async removeCP(uid: string): Promise<void> {
    await this.cpService.remove(uid);
    const index = _.findIndex(this.crossingPoints, (item) => item.data._id === uid);
    this.crossingPoints.splice(index, 1);
    this.applyFilters();
    this.closeCPInfoWindow();
  }

  public handleZoomChange(): void {
    this.currentZoom = this.map.getZoom()!;
  }

  public handleMapDblclick(event: google.maps.MapMouseEvent): void {
    const coords = MapsHelper.gmCoordsToCoords(event.latLng);
    if (!coords) {
      return;
    }
    this.newCrossingPoint(coords);
  }

  private newCrossingPoint(coordinates: Coordinates): void {
    if (this.currentZoom <= 16) {
      this.snackbar.open(
        this.translate.instant('CROSSING_POINTS.SNACKBAR.MUST_ZOOM_MORE'),
        this.translate.instant('GLOBAL.CLOSE_LABEL'),
        {
          duration: 2000
        }
      );
      return;
    }

    const dialogRef = this.dialog.open(NewCrossingPointDialogComponent, {
      width: '600px',
      height: '600px',
      data: { coordinates, types: this.types, sectors: this.sectors, highways: this.highways }
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res && res.result === 'valid') {
        this.crossingPoints.push(this.formatCP(res.data));
        this.addSector(res.data.location.sector_id);
        this.addType(res.data.type);
        this.applyFilters();
        this.zoomOnMarker(res.data);
      }
    });
  }

  private addSector(highway: string) {
    if (this.sectorsList.value) {
      if (this.sectorsList.value.indexOf(highway) === -1) {
        this.sectorsList.setValue([...this.sectorsList.value, highway]);
      }
    } else {
      this.sectorsList.setValue([highway]);
    }
  }

  private addType(type: string) {
    if (this.activeTypes.indexOf(type) === -1) {
      this.activeTypes.push(type);
      this.saveFilters({ activeTypes: this.activeTypes });
    }
  }

  private zoomOnMarker(marker: Marker<ICrossingPoint>) {
    this.lat = marker.coordinates.latitude;
    this.lng = marker.coordinates.longitude;
    this.zoom = 18;
  }
}
