<h1 translate class="mainTitle">USERS.LIST_PAGE_NAME</h1>
<app-list
  [listParameters]="listParameters"
  [items]="usersList"
  [total]="total"
  [loaded]="loaded"
  searchType="dynamic"
  [addButton]="addButton ? 'dynamic' : ''"
  (refresh)="getUsersList($event)"
  (action)="handleAction($event)"
/>
