<app-loading *ngIf="!isLoaded" className="small" [showText]="false" />
<ng-container *ngIf="isLoaded" [formGroup]="pformGroup">
  <app-select
    label="{{ 'GLOBAL.SUBTYPE' | translate }}"
    search
    [pformGroup]="pformGroup"
    [pformControlName]="'reportingSubtype'"
    [options]="reportingSubypeOptions"
  />
</ng-container>
