import { Pipe, PipeTransform } from '@angular/core';
import _ from 'lodash';

@Pipe({
  name: 'SubTypeFilter'
})
export class SubTypeFilter implements PipeTransform {
  transform(items: any[], arg: any): any {
    const item = items.filter((i) => arg.indexOf(i.id) !== -1);
    if (!item) {
      return [];
    }
    let subTypes = item.map((i: any) => i.subtypes);
    if (!subTypes || subTypes.length === 0) {
      return [];
    }
    subTypes = _.flattenDeep(subTypes);

    return subTypes;
  }
}
