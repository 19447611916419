import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BoundingBox, Coordinates, ILocation } from 'app/interface/location.interface';
import _ from 'lodash';
import { lastValueFrom } from 'rxjs';

@Injectable()
export class LocationsService {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(private http: HttpClient) {
    /* empty */
  }

  private earthRadius = 6378137;

  /**
   * Calculates the distance (in meters) between two given points using the Haversine formula
   * cf. https://en.wikipedia.org/wiki/Haversine_formula
   * @param {Coordinates} firstPoint
   * @param {Coordinates} secondPoint
   * @returns
   * @memberof LocationsService
   */
  public calcDist(firstPoint: Coordinates, secondPoint: Coordinates) {
    const dLat = (secondPoint.latitude * Math.PI) / 180 - (firstPoint.latitude * Math.PI) / 180;
    const dLon = (secondPoint.longitude * Math.PI) / 180 - (firstPoint.longitude * Math.PI) / 180;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((firstPoint.latitude * Math.PI) / 180) *
        Math.cos((secondPoint.latitude * Math.PI) / 180) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const angle = Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)) * 2;

    return this.earthRadius * angle;
  }

  // private buildGoogleURL(points: CoordinatesArray[]): string {
  //   let url = 'https://www.google.com/maps/dir/';
  //   for (const p of points) {
  //     url += `${p.reverse().join(',')}/`;
  //   }

  //   return url;
  // }

  private getInterPoint(lineStart: Coordinates, lineEnd: Coordinates, ratio: number): Coordinates {
    ratio = _.clamp(ratio, 0, 100);

    return {
      latitude: lineStart.latitude + (lineEnd.latitude - lineStart.latitude) * (ratio / 100),
      longitude: lineStart.longitude + (lineEnd.longitude - lineStart.longitude) * (ratio / 100)
    };
  }

  private getClosests(lineStart: Coordinates, lineEnd: Coordinates, point: Coordinates): [Coordinates, Coordinates] {
    const interPoints: Array<Coordinates> = [lineStart];
    for (let i = 0; i <= 8; ++i) {
      interPoints.push(this.getInterPoint(lineStart, lineEnd, (i + 1) * 10));
    }
    interPoints.push(lineEnd);
    let distances: Array<{ point: Coordinates; distance: number }> = [];
    for (const p of interPoints) {
      distances.push({ point: p, distance: this.calcDist(point, p) });
    }
    distances = _.sortBy(distances, 'distance');

    return [distances[0].point, distances[1].point];
  }

  public vectorProjection(lineStart: Coordinates, lineEnd: Coordinates, point: Coordinates): Coordinates {
    const closestsTo10m = this.getClosests(lineStart, lineEnd, point);
    const closestsTo1m = this.getClosests(closestsTo10m[0], closestsTo10m[1], point);
    this.getClosests(closestsTo1m[0], closestsTo1m[1], point);

    return closestsTo1m[0];
  }

  public radiansToMeter(angle: number): number {
    return angle * 6378137;
  }

  public buildLandmarkFromPoints(
    landmarks: ILocation[],
    sourcePoint: Coordinates
  ): {
    highwayId: string;
    direction: number;
    value: number;
  } {
    landmarks = landmarks.sort((a, b) => b.value - a.value);
    const firstLandmarkCoords = landmarks[0].coordinates;
    const secondLandmarkCoords = landmarks[1].coordinates;

    // Take the input point, and project it in the line joining the two landmarks
    const point = this.vectorProjection(firstLandmarkCoords, secondLandmarkCoords, sourcePoint);
    // Stores the distance (in meters) between the two landmarks
    const distance = this.calcDist(firstLandmarkCoords, secondLandmarkCoords);

    // Distance (in meters) between the "earliest" PR and the projected point
    const smallDistance = this.calcDist(point, firstLandmarkCoords);
    // Then, calculate the ratio between the two calculated distances
    const ratio = smallDistance / distance;

    return {
      highwayId: landmarks[0].highway_id,
      direction: landmarks[0].direction,
      value: _.round(landmarks[0].value - ratio / 10, 3)
    };
  }

  public parseLandmarkId(landmarkId: string): { highway_name: string; landmark: number; direction: string } {
    const splitted = landmarkId.split('-');

    return {
      highway_name: splitted[0],
      landmark: parseFloat(`${splitted[1]}.${splitted[2]}`),
      direction: splitted[3]
    };
  }

  public async getMyBoundingBox(): Promise<BoundingBox | undefined> {
    return lastValueFrom(this.http.get<BoundingBox | undefined>('api://my-bounding-box'));
  }

  public async getCoordByLocation(
    highway_name: string,
    kilometer_point: string,
    landmark: string,
    direction: string
  ): Promise<Coordinates> {
    return lastValueFrom(
      this.http.get<Coordinates>(
        `api://locations/coords?highway_name=${highway_name}&kilometer_point=${kilometer_point}&landmark=${landmark}&direction=${direction}&offset=0`
      )
    );
  }
}
