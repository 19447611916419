import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { IError } from 'app/components/generic/error-handler/error.interface';
import { ErrorHandler } from 'app/components/generic/error-handler/error-handler.service';
import { SessionService } from 'app/components/generic/services/session/session.service';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { HttpInterceptorHelper } from './http.interceptor.helper';

@Injectable()
export class HTTPReqInterceptor implements HttpInterceptor {
  constructor(
    private snackbar: MatSnackBar,
    private translate: TranslateService,
    private session: SessionService,
    private error: ErrorHandler
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const clonedRequest = req.clone({
      url: this.parseUrl(req),
      headers: this.parseHeaders(req),
      params: this.parseParams(req)
    });

    return next.handle(clonedRequest).pipe(
      // TO DO SEE WITH PANDA for the event: event

      map((event: HttpEvent<unknown>) => (event instanceof HttpResponse ? event : event)),
      catchError((e: unknown) => {
        if (e instanceof HttpErrorResponse) {
          let error: IError;
          if (e.error instanceof Blob) {
            const fileReader = new FileReader();
            fileReader.onloadend = () => {
              // TODO NOT SURE ABOUT THAT
              if (typeof fileReader.result === 'string') {
                error = this.error.getError(JSON.parse(fileReader.result));
              } else {
                error = this.error.getError(JSON.parse('error happened in interceptor'));
              }
              this.handleParsedError(error);
            };
            fileReader.readAsText(e.error);
          } else {
            error = this.error.getError(e.error);
            this.handleParsedError(error);
          }
        }

        return observableThrowError(e);
      })
    );
  }

  private openSnackbar(e: IError): void {
    this.snackbar.open(`${e.title} : ${e.message} (${e.code})`, this.translate.instant('GLOBAL.CLOSE_LABEL'));
  }

  private parseUrl(req: HttpRequest<any>): string {
    return HttpInterceptorHelper.injectHostInUrl(req.url);
  }

  private parseHeaders(req: HttpRequest<any>): any {
    return req.headers.set('Authorization', `Bearer ${this.session.getToken()}`).delete('Referrer-Policy');
  }

  private parseParams(req: HttpRequest<any>): any {
    const lang = navigator.language.split('-')[0];

    return req.params.set('source', 'front').set('lang', lang);
  }

  private handleParsedError(error: IError): void {
    const parsedErr = {
      title: error.title,
      code: error.code,
      message: error.message
    };
    // Check the received error code to handle special codes
    switch (error.code) {
      // Expired session: redirect to login
      case 40304:
      case 40305:
      case 40323:
        this.session.removeToken();
        localStorage.setItem('current_error', JSON.stringify(parsedErr || {}));
        break;

      // Credentials
      case 40101:
        this.session.unsetSession();
        // If a credentials error was thrown during an import, reformat the error:
        if (localStorage.getItem('current_import') === 'users') {
          // Prevent the error from re-appearing by removing the current_import value in localStorage
          localStorage.removeItem('current_import');
          // Override the error message
          parsedErr.message = this.translate.instant('ERRORS.USERS_IMPORT');
          // And save the error in the localStorage
          localStorage.setItem('current_error', JSON.stringify(parsedErr || {}));

          // Finally, logout the user
          localStorage.removeItem('token');
          window.location.reload();
        }
        break;
    }
    this.openSnackbar(error);
  }
}
