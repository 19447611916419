<div *ngIf="['static', 'dynamic'].includes(addButton)" class="list-filters">
  <button class="button-add" (click)="handleAddClick()">
    <span class="material-icons">add</span>
    {{ addButtonLabel || 'GLOBAL.ADD' | translate }}
  </button>
</div>
<form *ngIf="['static', 'dynamic'].includes(searchType)" (ngSubmit)="applySearch()" class="list-filters">
  <div class="list-filters-search">
    <input
      [(ngModel)]="searchValue"
      [ngModelOptions]="{ standalone: true }"
      class="list-filters-search-field"
      type="text"
      placeholder="{{ 'GLOBAL.SEARCH' | translate }}"
    />
    <button class="list-filters-search-button">
      <i class="material-icons">search</i>
    </button>
  </div>
</form>

<app-loading [active]="!loaded" [ngClass]="{ active: !loaded }"></app-loading>

<mat-table [ngClass]="{ hidden: !loaded }" [dataSource]="dataSource" matSort>
  <ng-container *ngFor="let col of listParameters.listHead; trackBy: headerKeyTracker" [matColumnDef]="col.key">
    <ng-container *ngIf="col.options?.sortable !== false; else unsortableHeader">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [style.flex]="col.cols !== undefined ? col.cols : 1"
        [style.width]="col.colMinWidth === col.colMaxWidth ? col.colWidth : 'auto'"
        [style.minWidth]="col.colMinWidth || col.colWidth"
        [style.maxWidth]="col.colMaxWidth || col.colWidth"
      >
        {{ col.title | translate }}
      </mat-header-cell>
    </ng-container>
    <ng-template #unsortableHeader>
      <mat-header-cell
        *matHeaderCellDef
        [style.flex]="col.cols !== undefined ? col.cols : 1"
        [style.width]="col.colMinWidth === col.colMaxWidth ? col.colWidth : 'auto'"
        [style.minWidth]="col.colMinWidth || col.colWidth"
        [style.maxWidth]="col.colMaxWidth || col.colWidth"
      >
        {{ col.title | translate }}
      </mat-header-cell>
    </ng-template>

    <mat-cell
      *matCellDef="let element"
      [style.flex]="col.cols !== undefined ? col.cols : 1"
      [style.width]="col.colMinWidth === col.colMaxWidth ? col.colWidth : 'auto'"
      [style.minWidth]="col.colMinWidth || col.colWidth"
      [style.maxWidth]="col.colMaxWidth || col.colWidth"
    >
      <app-cell (action)="propagate($event)" [params]="col" [element]="element"></app-cell>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns" />
  <mat-row
    *matRowDef="let row; columns: displayedColumns"
    [ngClass]="{
      'clickable-row': (row.notClickableRow ? false : true) ? listParameters.clickable : false,
      'selected-row': row.selected
    }"
    (click)="listParameters.clickable && onRowClick(row)"
  />
</mat-table>

<div *ngIf="loader.disabled && dataSource.data!.length === 0" class="list-empty">
  {{ 'GLOBAL.NO_RESULT' | translate }}
</div>
<mat-paginator
  #paginator
  *ngIf="showPaginator"
  class="demo-paginator"
  (page)="handlePageEvent($event)"
  [length]="total"
  [pageSize]="pageSize"
  [pageSizeOptions]="pageSizeOptions"
  [pageIndex]="pageIndex"
  aria-label="Select page"
>
</mat-paginator>
