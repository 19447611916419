import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, PRIMARY_OUTLET, Router } from '@angular/router';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import { filter, startWith } from 'rxjs/operators';

interface IBreadcrumb {
  label: string;
  params: Params;
  url: string;
}

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
  public breadcrumbs: IBreadcrumb[] = [];
  breadcrumbSubscription: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    // Subscribe to the NavigationEnd event
    const startingUrl = `/${this.activatedRoute.snapshot.url.join('/')}`;
    this.breadcrumbSubscription = this.router.events
      .pipe(
        startWith(new NavigationEnd(0, startingUrl, startingUrl)),
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        const root: ActivatedRoute = this.activatedRoute.root;
        this.breadcrumbs = this.getBreadcrumbs(root);
      });
  }

  ngOnDestroy() {
    this.breadcrumbSubscription.unsubscribe();
  }

  /**
   * Returns array of IBreadcrumb objects that represent the breadcrumb
   *
   * @class DetailComponent
   * @method getBreadcrumbs
   * @param {ActivateRoute} route
   * @param {string} url
   * @param {IBreadcrumb[]} breadcrumbs
   */
  getBreadcrumbs(route: ActivatedRoute, url: string[] = [''], breadcrumbs: IBreadcrumb[] = []): IBreadcrumb[] {
    const ROUTE_DATA_BREADCRUMB = 'breadcrumb';
    let children: ActivatedRoute[] = route.children;
    let childUrl = _.get(children[0], 'snapshot.url.0.path');
    while (children && children.length > 0 && childUrl) {
      const child = children[0];
      childUrl = _.get(child, 'snapshot.url.0.path');
      if (child.outlet !== PRIMARY_OUTLET || !childUrl) {
        continue;
      }
      url.push(childUrl);
      breadcrumbs.push({
        label: _.get(child, `snapshot.data.${ROUTE_DATA_BREADCRUMB}`),
        params: _.get(child, 'snapshot.params'),
        url: url.filter((a) => a).join('/')
      });
      children = children[0].children;
    }

    return breadcrumbs;
  }
}
