import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IListResult } from 'app/interface/list-result.interface';
import { ISector } from 'app/interface/sector.interface';
import { Observable } from 'rxjs';

@Injectable()
export class SectorsService {
  constructor(private http: HttpClient) {}
  getAll(
    filters: {
      sort?: string;
      offset?: string;
      limit?: string;
      q?: string;
    } = {}
  ): Observable<IListResult<ISector>> {
    return this.http.get('api://sectors', { params: filters } as any) as any;
  }

  getByTeamsId(
    teamsId: string,
    filters: {
      sort?: string;
      offset?: string;
      limit?: string;
      q?: string;
    } = {}
  ) {
    return this.http.get(`api://sectors/${teamsId}`, { params: filters } as any) as any;
  }
}
