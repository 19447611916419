import { QuestionBase } from './question-base';

export class TabQuestion extends QuestionBase<string> {
  controlType = 'tab';
  type: string;
  tabs: any;
  options: any;
  indexSelectedTab?: any;

  constructor(options: { type?: string; [key: string]: any } = {}) {
    super(options);
    this.type = options.type || '';
    this.tabs = options.tabs || [];
    this.options = options.options || {};
    this.indexSelectedTab = options.indexSelectedTab || undefined;
  }
}
