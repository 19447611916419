<h1 mat-dialog-title>{{ title | translate }}</h1>

<mat-dialog-content translate class="dialog-padding pb-0">
  <p class="meta-last-update">{{ 'REPORTING.FORMS.LAST_UPDATED_ON' | translate }} {{ lastUpdated | date: 'medium' }}</p>

  <p class="meta-no-content" *ngIf="!payloadView">
    {{ 'REPORTING.FORMS.EMPTY_PAYLOAD' | translate }}
  </p>

  <div *ngIf="payloadView" [ngSwitch]="type" class="payload-view">
    <!-- Vehicle form -->
    <ng-container *ngSwitchCase="'VEHICLES'">
      <div *ngFor="let vehicle of payloadView; let index = index" class="payload-item-vehicle">
        <mat-divider *ngIf="index > 0"></mat-divider>

        <h2
          class="payload-item-title"
          translate="REPORTING.FORMS.VEHICLES.VEHICLE_REFERENCE"
          [translateParams]="{ reference: vehicle.reference }"
        ></h2>

        <p *ngIf="vehicle.payload.length === 0" class="payload-item-empty">
          {{ 'REPORTING.FORMS.EMPTY_PAYLOAD' | translate }}
        </p>

        <div class="payload-subset">
          <div class="payload-subset-item" *ngFor="let item of vehicle.payload">
            <h3 class="payload-subset-item-title">{{ item.key | translate }}</h3>
            <p class="payload-subset-item-content">{{ item.value | translate }}</p>
            <div *ngIf="item.hasOwnProperty('url')" class="payload-subset-picture">
              <img src="{{ item.url }}" alt="photo assurance" />
            </div>
          </div>
          <br />
        </div>
      </div>
    </ng-container>

    <!-- Generic payload -->
    <ng-container *ngSwitchDefault>
      <div *ngFor="let item of payloadView; let index = index" class="payload-item">
        <mat-divider *ngIf="index > 0"></mat-divider>

        <h2 class="payload-item-title">{{ item.key | translate }}</h2>

        <p class="payload-item-content">{{ item.value }}</p>
      </div>
    </ng-container>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="dialog-buttons">
  <button mat-button (click)="closeDialog()">{{ 'GLOBAL.CLOSE_LABEL' | translate }}</button>
</mat-dialog-actions>
