import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { SecureSrcAttrPipe } from 'app/components/generic/directives/secure-src-attr.pipe';
import { GenericDialogComponent } from 'app/components/generic/generic-dialog/generic-dialog.component';
import { Loader, LoadingService } from 'app/services/loading.service';

export interface Picture {
  url: string;
  name: string;
  extension: string;
  horodate: Date;
  mime_type: string;
  element?: ElementRef;
  local?: boolean;
  original_object: File;
  type: string;
}

export interface ParsedPicture extends Picture {
  id: string;
  alt: string;
}

/**
 * The "gallery" mode linked to a parent file-input.component has been refactored
 * The "carousel" mode probably needs some changes to work, in particular in the Picture types
 * TODO: fully test the "carousel" mode
 * TODO: then replace old-carousel uses with this component
 *
 * Legacy unused parts have been commented out
 * TODO: study if they are usefull, if not delete them
 */
@Component({
  standalone: true,
  imports: [
    //
    CommonModule,
    MatTooltipModule,
    MatIconModule,
    MatButtonModule,
    TranslateModule,
    SecureSrcAttrPipe
  ],
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {
  public loadingInstance: Loader;
  public galleryView: ParsedPicture[] = [];
  public selectedPicture?: ParsedPicture;
  public reportId = '';
  public dialogRef: MatDialogRef<GenericDialogComponent> | undefined;

  private selectedPictureElement?: ElementRef;

  // public state = 'void';
  // public stepsInCarousel = 2;
  // private arrayStart = 0;
  // private arrayEnd = 2;
  // @Input() question: ImportQuestion | undefined;

  @Input() actions = [];
  @Input() mode = 'carousel'; // Existing mode : carousel (default) / gallery

  private _pictures: Picture[] = [];
  @Input() set pictures(newValue) {
    this._pictures = newValue;
    this.galleryView = newValue.map((picture, index) => ({
      ...picture,
      id: `image-${index}`,
      alt: `image-${index}`
    }));
    if (!this.selectedPicture && this.mode !== 'gallery') {
      this.selectedPicture = this.galleryView[0];
    }
  }
  get pictures(): Picture[] {
    return this._pictures;
  }

  @Input() removeImage?: (picture: ParsedPicture) => void | Promise<void>;

  constructor(
    public loading: LoadingService,
    private dialog: MatDialog,
    private rd: Renderer2
  ) {
    this.loadingInstance = loading.get(`carousel-${new Date().getTime()}`);
    this.loadingInstance.on();
  }

  ngOnInit(): void {
    this.loadingInstance.off();
  }

  public zoomIn(picture: ParsedPicture): void {
    if (picture.type !== 'image') {
      return;
    }
    const pictureUrl = picture.url;

    const struct = {
      title: '',
      text: '',
      buttons: [
        {
          text: 'GLOBAL.OK',
          class: 'validation',
          action: {
            target: 'generic',
            params: { id: 'close' }
          }
        }
      ]
    };
    this.dialogRef = this.dialog.open(GenericDialogComponent, {
      width: 'auto',
      height: 'auto',
      maxWidth: '90%',
      maxHeight: 'calc(100vh - 100px)',
      data: {
        structure: struct,
        content: {
          type: 'img',
          src: pictureUrl
        }
      },
      disableClose: false
    });
  }

  public actionPicture(picture: ParsedPicture): void {
    if (this.mode !== 'gallery') {
      if (this.selectedPictureElement) {
        this.rd.removeClass(this.selectedPictureElement, 'is-selected');
      }
      this.selectedPictureElement = this.rd.selectRootElement(`#${picture.id}`);
      this.rd.addClass(this.selectedPictureElement, 'is-selected');
      this.selectedPicture = picture;
    } else {
      this.zoomIn(picture);
    }
  }

  private async deletePicture(picture: ParsedPicture): Promise<void> {
    if (this.dialogRef === undefined) {
      return;
    }

    await this.removeImage?.(picture);

    this.sliceArrayPicture(picture);

    if (this.dialogRef === undefined) {
      return;
    }

    this.dialogRef.close();

    // // Allow to delete the files in the files input form (new-report and edit-report for example)
    // if (this.question !== undefined && this.question.events.remove) {
    //   this.question.events.remove(this.question, picture);
    // }
  }

  private sliceArrayPicture(picture: ParsedPicture): void {
    const index = this.galleryView.findIndex((searchPicture: any) => searchPicture.name === picture.name);
    if (index !== -1) {
      // Same index because _cloneDeep with 2 others attributes (alt and id)
      this.pictures.splice(index, 1);
      this.galleryView.splice(index, 1);
    }
  }

  private deletePictureStructure(picture: any) {
    return {
      title: 'GLOBAL.CONFIRMATION',
      text: 'CONFIRM.DELETE_REPORT_PICTURE',
      buttons: [
        {
          text: 'GLOBAL.DELETE',
          class: 'validation',
          action: {
            target: 'custom',
            params: {
              id: 'deletePictureButtonId',
              function: () => {
                this.deletePicture(picture);
              }
            }
          }
        },
        {
          text: 'GLOBAL.CANCEL',
          class: 'cancel',
          action: {
            target: 'generic',
            params: {
              id: 'close',
              function: undefined
            }
          }
        }
      ]
    };
  }

  private openDeleteConfirmationDialog(picture: any): void {
    this.dialogRef = this.dialog.open(GenericDialogComponent, {
      width: '50%',
      data: { structure: this.deletePictureStructure(picture) },
      disableClose: true
    });
  }

  public openDeleteConfirmation(picture: any): void {
    this.openDeleteConfirmationDialog(picture);
  }

  // private setStepsInCarousel(): void {
  //   const innerWidth = window.innerWidth;
  //   this.stepsInCarousel = innerWidth >= 1920 ? 5 : innerWidth >= 1640 ? 4 : innerWidth >= 840 ? 3 : 2;
  // }

  // private moveLeft(): void {
  //   if (this.arrayStart < 0) {
  //     this.arrayStart = 0;
  //     this.arrayEnd = this.stepsInCarousel;
  //   } else if (this.arrayStart > 0) {
  //     this.arrayStart -= this.stepsInCarousel;
  //     this.arrayEnd -= this.stepsInCarousel;
  //   }
  //   this.galleryView = this.pictures.slice(this.arrayStart, this.arrayEnd);
  // }

  // private moveRight(): void {
  //   if (this.arrayEnd >= this.pictures.length) {
  //     this.arrayStart = 0;
  //     this.arrayEnd = this.stepsInCarousel;
  //   } else {
  //     this.arrayStart += this.stepsInCarousel;
  //     this.arrayEnd += this.stepsInCarousel;
  //   }
  //   this.galleryView = this.pictures.slice(this.arrayStart, this.arrayEnd);
  // }

  // private onFinish(): void {
  //   this.state = 'void';
  // }
}
