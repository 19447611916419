<div
  [ngSwitch]="cell.type"
  class="cell-type-{{ cell.type }} {{ cell.color }}"
  [ngStyle]="params.styles"
  [ngClass]="{ 'cursor-pointer': options.events?.click !== undefined }"
  (click)="emitEvent('click')"
>
  <ng-container *ngSwitchCase="'text'">
    <i
      *ngIf="options.icon && options.icon.type !== 'image'"
      class="material-icons {{ element[options.icon.class] || options.icon.class || '' }}"
      matTooltip="{{ element[options.icon.tooltip] | translate }}"
      matTooltipPosition="above"
    >
      {{ options.icon.value || element[options.icon.key] || '' }}
    </i>
    <mat-icon
      matSuffix
      *ngIf="options.icon && options.icon.type === 'image' && element.icon"
      [svgIcon]="element.icon"
      class="mat-image-icon-cell"
    ></mat-icon>
    <img matSuffix *ngIf="options.picto && element.picto" src="{{ element.picto }}" class="mat-image-icon-cell" />
    <span [innerHTML]="cell.displayValue || ''" class="{{ element[options.class] || options.class || '' }}"></span>
  </ng-container>

  <ng-container *ngSwitchCase="'date'">
    <span>{{ cell.displayValue | date: options.format || 'short' }}</span>
  </ng-container>

  <ng-container *ngSwitchCase="'boolean'">
    <span>{{ cell.value ? cell.trueLabel : cell.falseLabel }}</span>
  </ng-container>

  <ng-container *ngSwitchCase="'button'">
    <ng-container *ngFor="let button of cell.buttons">
      <button
        *ngIf="button"
        mat-button
        [disabled]="button.disabled"
        (click)="emitEvent('click', button, $event)"
        color="{{ button.style.color }}"
        class="bold list-button"
        matTooltip="{{ button.tooltip | translate }}"
        matTooltipPosition="above"
      >
        <i class="material-icons" *ngIf="button.options.icon">{{ button.options.icon || '' }}</i>
        <span>{{ button.label | translate }}</span>
      </button>
      <div class="spaceholder" *ngIf="!button"></div>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'checkbox'">
    <mat-checkbox
      [disabled]="element.disabled || readOption(options.disabled)"
      (change)="emitEvent('change')"
      [checked]="!!cell.value"
      [value]="cell.value"
      color="primary"
    ></mat-checkbox>
  </ng-container>

  <ng-container *ngSwitchCase="'icon'">
    <i
      class="material-icons"
      (click)="emitEvent('click', element, $event)"
      *ngIf="element[options.icon]"
      matTooltip="{{ element[options.tooltip] | translate }}"
      matTooltipPosition="above"
      >{{ element[options.icon] || '' }}</i
    >
  </ng-container>

  <ng-container *ngSwitchCase="'image'">
    <!-- via background -->
    <mat-icon
      matSuffix
      [svgIcon]="element.icon"
      class="mat-icon-cell"
      (click)="emitEvent('click', element, $event)"
      matTooltip="{{ element[options.tooltip] | translate }}"
      matTooltipPosition="above"
    ></mat-icon>
  </ng-container>

  <ng-container *ngSwitchCase="'imageSrc'">
    <!-- via background -->
    <img
      *ngIf="cell.value; else elseNoValue"
      src="{{ cell.value }}"
      matTooltip="{{ element[options.tooltip] | translate }}"
      matTooltipPosition="above"
      (click)="emitEvent('click', element, $event)"
    />
    <ng-template #elseNoValue>-</ng-template>
  </ng-container>

  <ng-container *ngSwitchCase="'reporting-icon'">
    <div
      (click)="emitEvent('click', element, $event)"
      matListAvatar
      class="icon-md-size cell-report-icon report-icon {{ element.icon }}"
    ></div>
  </ng-container>
</div>
