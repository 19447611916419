import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CPType } from 'src/app/interface/crossing-points.interface';
import { Coordinates } from 'src/app/interface/location.interface';
import { CrossingPointsService } from 'src/app/services/crossing-points.service';
import { LocationsService } from 'src/app/services/locations.service';
import { ReportsService } from 'src/app/services/reports.service';

import { IHighway } from '../../../interface/highway.interface';
import { SelectOption } from '../../generic/form/select/select.component';

@Component({
  selector: 'app-new-crossing-point.dialog',
  templateUrl: './new-crossing-point.dialog.component.html',
  styleUrls: ['./new-crossing-point.dialog.component.scss']
})
export class NewCrossingPointDialogComponent implements OnInit {
  public cpForm: UntypedFormGroup;
  public types: CPType[];
  public loading = false;

  // form data
  public name = '';
  public type = '';
  public highway = '';
  public pr = '';

  public directionOptions: SelectOption[] = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<NewCrossingPointDialogComponent>,
    private cpService: CrossingPointsService,
    private reportsService: ReportsService,
    private locationsService: LocationsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.loading = true;
  }

  ngOnInit() {
    this.cpForm = this.formBuilder.group({
      name: new UntypedFormControl(this.name, [Validators.required]),
      type: new UntypedFormControl(this.type, [Validators.required]),
      highway: new UntypedFormControl(this.highway, [Validators.required]),
      pr: new UntypedFormControl(this.pr, [Validators.required]),
      // Direction will be set dynamically based on the selected highway and interPoint
      direction: new UntypedFormControl('', [Validators.required])
    });

    const coordinates: Coordinates = this.data.coordinates;

    this.reportsService.getLocationsFromCoordinates(coordinates).then((locations) => {
      if (locations.length === 0) {
        return;
      }

      const interPoint = this.locationsService.buildLandmarkFromPoints(locations, coordinates);
      this.cpForm.get('highway')!.setValue(interPoint.highwayId);
      this.cpForm.get('pr')!.setValue(this.roundAndFormat(interPoint.value.toString(), '.', '+'));

      const highwayValue: string = this.cpForm.get('highway')!.value;
      const selectedHighways: IHighway = this.data.highways.find((highway: IHighway) => highway.UID === highwayValue);
      this.updateDirectionOptions(selectedHighways);

      const direction: string = `${highwayValue}-${interPoint.direction}`;
      this.cpForm.get('direction')!.setValue(direction);
    });

    this.loading = false;
  }

  public roundAndFormat(value: string, separator: '+' | '.', outputSeparator: '+' | '.'): string {
    const data = value.split(separator);
    let rounded = Math.round(parseInt(data[1], 10) / 100) * 100;
    if (rounded === 1000) {
      data[0] = (parseInt(data[0], 10) + 1).toString();
      rounded = 0;
    }

    return `${data[0]}${outputSeparator}${rounded.toString()}`;
  }

  get f() {
    return this.cpForm.get;
  }

  get prField() {
    return this.cpForm.get('pr');
  }

  onNoClick(): void {
    this.dialogRef.close({ result: 'cancel' });
  }

  public onSubmit() {
    this.cpService
      .create({
        name: this.cpForm.get('name')!.value,
        highway: this.cpForm.get('highway')!.value,
        pk: this.cpForm.get('pr')!.value.replace('+', '.'),
        direction: this.cpForm.get('direction')!.value,
        type: this.cpForm.get('type')!.value,
        coordinates: this.data.coordinates
      })
      .subscribe((result: any) => {
        this.dialogRef.close({
          result: 'valid',
          data: result
        });
      });
  }

  public onHighwaySelectionChange(value: string): void {
    const selectedHighways: IHighway = this.data.highways.find((highway: IHighway) => highway.UID === value);
    this.updateDirectionOptions(selectedHighways);
  }

  private updateDirectionOptions(highway: IHighway): void {
    // Create direction options
    this.directionOptions = [];
    this.directionOptions.push(this.makeDirectionOption('1', highway), this.makeDirectionOption('2', highway));
  }

  // Backend is waiting for key = HighwayId + direction. ex: A46-1
  // we need to link each direction to a specific highway instead of sending direction 1 or 2
  private makeDirectionOption(direction: string, highway: IHighway): SelectOption {
    return direction === '1'
      ? {
          value: {
            key: `${highway.UID}-1`,
            label: `${highway.name} : ${highway.origin} > ${highway.destination}`
          }
        }
      : {
          value: {
            key: `${highway.UID}-2`,
            label: `${highway.name} : ${highway.destination} > ${highway.origin}`
          }
        };
  }
}
