<div *ngIf="hasLanes" class="lanes-card">
  <div class="title">{{ 'REPORTING.LANES_CARD.TITLE' | translate }}</div>
  <div #lanesViewElement *ngIf="lanesView" class="lanes-view">
    <div class="lane-opposite-other" *ngIf="hasOthersOpposite">
      <div class="lane-button-container">
        <div class="exp-button" (click)="openLanesOtherDialog(false)">
          <mat-icon aria-hidden="false" aria-label="Example home icon">vertical_split</mat-icon>
        </div>
      </div>
    </div>
    <div *ngFor="let lane of lanesView.opposite" class="lane-opposite{{ lane.classSuffix }}">
      <div class="lane-name" [translate]="lane.name" [translateParams]="{ index: lane.index - 1 }"></div>
      <div class="lane-direction">
        <mat-icon>arrow_downward</mat-icon>
      </div>

      <div class="lane-impacted-icon" [ngClass]="{ isvisible: lane.isImpacted, ishidden: !lane.isImpacted }">
        <mat-icon svgIcon="warning_circle"></mat-icon>
      </div>
    </div>

    <div class="lane-median"></div>
    <div *ngFor="let lane of lanesView.regular" class="lane-regular{{ lane.classSuffix }}">
      <div class="lane-name" [translate]="lane.name" [translateParams]="{ index: lane.index - 1 }"></div>
      <div class="lane-direction">
        <mat-icon>arrow_upward</mat-icon>
      </div>
      <div class="lane-impacted-icon" [ngClass]="{ isvisible: lane.isImpacted, ishidden: !lane.isImpacted }">
        <mat-icon svgIcon="warning_circle"></mat-icon>
      </div>
    </div>
    <div class="lane-opposite-other" *ngIf="hasOthersCurrent">
      <div class="lane-button-container">
        <div class="exp-button_reversed" (click)="openLanesOtherDialog(true)">
          <mat-icon aria-hidden="false" aria-label="Example home icon">vertical_split</mat-icon>
        </div>
      </div>
    </div>
  </div>
</div>
