import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { GenericDialogComponent } from 'app/components/generic/generic-dialog/generic-dialog.component';
import { SessionService } from 'app/components/generic/services/session/session.service';
import { TitleService } from 'app/services/title.service';
import { ProfileService } from 'src/app/services/profile.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent {
  dialogRef: MatDialogRef<GenericDialogComponent>;
  srcLogo: string | null = null;

  constructor(
    public session: SessionService,
    public dialog: MatDialog,
    public profileService: ProfileService,
    private title: TitleService,
    private oidcSecurityService: OidcSecurityService,
    private translate: TranslateService,
    private router: Router
  ) {
    this.title.setTitle('GLOBAL.TITLE_PROJECT');
    this.profileService.profile.subscribe((profile) => {
      this.srcLogo = profile?.mainLogo || null;
    });
  }

  public login(): void {
    localStorage.setItem('redirectUri', this.router.url);
    this.oidcSecurityService.authorize(undefined, { redirectUrl: window.origin });
  }

  public forgetPasswordStructure(): unknown {
    return {
      title: 'USERS.FORGETTEN_PASSWORD',
      text: '',
      buttons: [
        {
          text: 'GLOBAL.OK',
          class: 'validation',
          action: {
            target: 'generic',
            params: {
              id: 'close',
              function: undefined
            }
          }
        }
      ]
    };
  }

  public forgottenPassword(): void {
    this.dialogRef = this.dialog.open(GenericDialogComponent, {
      width: '50%',
      data: {
        structure: this.forgetPasswordStructure(),
        content: {
          type: 'html',
          html:
            `<p>${this.translate.instant('USERS.FORGOT_TEXT_ACCOUNT_CREATION')} </p>` +
            `<p> ${this.translate.instant('USERS.FORGOT_TEXT_PASSWORD')}` +
            `<b> ${this.translate.instant('USERS.FORGOT_PHONE_NUMBER')}</b></p>`
        }
      },
      disableClose: false
    });
  }
}
