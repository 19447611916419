import { Coordinates } from 'src/app/interface/location.interface';

export interface ICrossingPoint {
  _id?: string;
  internal_id?: string;
  name: string;
  type: keyof typeof CrossingPointsEnum;
  location: {
    destination: string;
    origin: string;
    display: string;
    direction: number;
    highway_name: string;
    kilometer_point: number;
    landmark: number;
    landmark_id: string;
    offset: number;
    sector_id: string;
    sector_name: string;
    type: string;
    value: number;
  };
  coordinates: {
    longitude: any;
    latitude: any;
  };
  created_at?: string;
  updated_at?: string;
  radius?: number;
}

export interface CPType {
  id: string;
  name: string;
  label: string;
  picto: string;
  color: string;
}

export enum CrossingPointsEnum {
  diffuseur = 'CROSSING_POINTS.TYPES.DIFFUSEUR',
  bifurcation_echangeur = 'CROSSING_POINTS.TYPES.BIFURCATION_ECHANGEUR',
  aire = 'CROSSING_POINTS.TYPES.AIRE',
  district_centre_entretien = 'CROSSING_POINTS.TYPES.DISTRICT_CENTRE_ENTRETIEN',
  barriere_peage = 'CROSSING_POINTS.TYPES.BARRIERE_PEAGE',
  acces_service = 'CROSSING_POINTS.TYPES.ACCES_SERVICE',
  acces_secours = 'CROSSING_POINTS.TYPES.ACCES_SECOURS',
  gare_peage = 'CROSSING_POINTS.TYPES.GARE_PEAGE',
  locaux = 'CROSSING_POINTS.TYPES.LOCAUX',
  limite_concession = 'CROSSING_POINTS.TYPES.LIMITE_CONCESSION',
  point_appui = 'CROSSING_POINTS.TYPES.POINT_APPUI',
  direction_regionale = 'CROSSING_POINTS.TYPES.DIRECTION_REGIONALE'
}

export interface Marker<T> {
  title: string;
  radius?: number;
  coordinates: Coordinates;
  data: T;
  style?: {
    url: string;
    color: string;
    name: string;
  };
  type: 'pr' | 'crossing-point' | 'new-cp';
}
