<app-loading *ngIf="!reportingTypeOptions" className="small" [showText]="false" />
<ng-container *ngIf="reportingTypeOptions" [formGroup]="pformGroup">
  <app-select
    label="{{ 'GLOBAL.TYPE' | translate }}"
    search
    [pformGroup]="pformGroup"
    [pformControlName]="'reportingType'"
    [options]="reportingTypeOptions"
  />
</ng-container>
