import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { IInfrastructure } from 'src/app/interface/infrastructure.interface';

import { InfrastructureMarker } from '../map.component';

@Component({
  standalone: true,
  imports: [
    //
    CommonModule,
    TranslateModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule
  ],
  selector: 'app-infrastructure-info-window',
  templateUrl: './infrastructure-info-window.component.html',
  styleUrls: ['./info-window.scss']
})
export class InfrastructureInfoWindowComponent {
  public showDeleteConfirm: boolean = false;

  @Input() showDelete: boolean = false;

  private _marker: InfrastructureMarker;
  @Input() set marker(marker) {
    this._marker = marker;
    this.infrastructure = marker.infrastructure;
  }
  get marker() {
    return this._marker;
  }
  @Input() infrastructure: IInfrastructure;

  @Output() editInfrastructure = new EventEmitter<string>();
  @Output() deleteInfrastructure = new EventEmitter<string>();

  public handleEditInfrastructureClick(infrastructureId: string): void {
    this.editInfrastructure.emit(infrastructureId);
  }

  public handleDeleteInfrastructureClick(): void {
    this.showDeleteConfirm = true;
  }

  public handleDeleteInfrastructureConfirmationClick(infrastructureId: string): void {
    this.deleteInfrastructure.emit(infrastructureId);
  }

  public handleCancelInfrastructureDeletionClick(): void {
    this.resetDeletionConfirm();
  }

  private resetDeletionConfirm(): void {
    this.showDeleteConfirm = false;
  }
}
