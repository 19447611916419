<h1 class="mainTitle">{{ 'HIGHWAYS.LIST_PAGE_NAME' | translate }}</h1>
<app-list
  [listParameters]="listParameters"
  [loaded]="loaded"
  [items]="highwaysList"
  [total]="total"
  searchType="dynamic"
  (refresh)="getHighwaysList($event)"
  (action)="goToDetail($event)"
></app-list>
