import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SessionService } from 'app/components/generic/services/session/session.service';
import { IAccidentDetail } from 'app/interface/accident-reporting.interface';
import { IHighway } from 'app/interface/highway.interface';
import { IReporting, IReportingEvent, ReportingState } from 'app/interface/report.interface';
import { IconService } from 'app/services/icon.service';
import { ReportsService } from 'app/services/reports.service';
import { TitleService } from 'app/services/title.service';
import _ from 'lodash';
import { interval as observableInterval, Subscription } from 'rxjs';
import { combineLatestWith, mergeMap } from 'rxjs/operators';
import { ProfileService } from 'src/app/services/profile.service';

import { getTranslationWithFallback } from '../../../tools/translate';
import { GenericDialogComponent } from '../../generic/generic-dialog/generic-dialog.component';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReportingDetailComponent implements OnInit {
  id = '';
  files: any = [];
  highway?: IHighway;

  private riskVehiclesSignaled = false;

  private unknownTypeSnack?: MatSnackBarRef<SimpleSnackBar>;
  private reportingPollingSubscription = new Subscription();

  _report: IReporting;
  set report(newValue: IReporting) {
    const originalRiskVehicleCount = _.get(this.report, 'details.vehicles.riskVehicle');
    const newRiskVehiclesCount = _.get(newValue, 'details.vehicles.riskVehicle');
    this._report = newValue;

    if (newRiskVehiclesCount > 0 && originalRiskVehicleCount !== newRiskVehiclesCount) {
      // As of now it is not possible to create a view during a NG lifehook.
      // See PR:    <https://github.com/angular/angular/pull/18352>
      // See issue: <https://github.com/angular/angular/issues/15634>
      setTimeout(() => this.displayRiskVehicleAlert(newRiskVehiclesCount > 1));
    }
  }
  get report() {
    return this._report;
  }

  constructor(
    private reportingService: ReportsService,
    private router: Router,
    private snackbar: MatSnackBar,
    private translate: TranslateService,
    private iconService: IconService,
    private session: SessionService,
    title: TitleService,
    route: ActivatedRoute,
    private dialog: MatDialog
  ) {
    route.params.subscribe((params) => (this.id = params.id));
    route.data.subscribe((data) => title.setTitle(data.breadcrumb));
    iconService.register('riskVehicle', '/assets/icon/reportings/alert-tmd.svg');
  }

  public loading() {
    this.reportingService
      .getOne(this.id)
      .pipe(combineLatestWith(this.reportingService.getReportingTypesObservable()))
      .subscribe({
        next: (results) => {
          const [reportingDetails, types] = results;

          this.report = reportingDetails;

          // Poll the API periodically when the current reporting is an accident
          if (reportingDetails.type.critical) {
            this.keepReportingUpToDate();
          }

          // Display an error when the reporting type is unknown
          if (!types.some((type) => type.id === reportingDetails.type.id)) {
            this.displayUnknownTypeError();
          }
        },
        error: () => {
          this.router.navigate(['']);
        }
      });
  }

  public handleCreatedEvent(event: IReportingEvent): void {
    this.report.events = _.uniqBy([...this.report.events, event], (e) => e.id);
  }

  public handleDeletedEvent(eventId: string): void {
    this.report.events = [...this.report.events];
    const index = this.report.events.findIndex((e) => e.id === eventId);
    _.set(this.report.events, `[${index}].deletedAt`, new Date());
  }

  ngOnInit() {
    this.loading();
  }

  /**
   * Polls the API with a given interval to retrieve the reporting last updates.
   * The polling is stopped automatically on any router events -- notably on URL
   * changes -- to avoid parasite requests.
   *
   * @param interval The interval between two polling requests in milliseconds.
   */
  private keepReportingUpToDate(interval = 5000) {
    this.reportingPollingSubscription = observableInterval(interval)
      .pipe(mergeMap(() => this.reportingService.getOne(this.id)))
      .subscribe((reporting) => {
        if (reporting.type.critical && Notification) {
          Notification.requestPermission((status) => {
            if ((Notification as any).permission !== status) {
              (Notification as any).permission = status;
            }
          });
        }
        this.handleNotifications(reporting);
        this.report = reporting;
      });

    // Automatically stop polling on URL change
    this.router.events.subscribe(() => {
      this.reportingPollingSubscription.unsubscribe();
    });
  }

  /**
   * Opens a snackbar notifying that the reporting type is unknown.
   */
  private displayUnknownTypeError() {
    this.unknownTypeSnack = this.snackbar.open(
      this.translate.instant('REPORTING.ERROR.UNKNOWN_TYPE'),
      this.translate.instant('GLOBAL.CLOSE_LABEL')
    );

    // Automatically dismiss snackbar on URL change
    this.router.events.subscribe(() => {
      if (this.unknownTypeSnack) {
        this.unknownTypeSnack.dismiss();
      }
    });
  }

  /**
   * Detect any change that need to trigger a notification
   */
  private handleNotifications(newAccidentData: IReporting<IAccidentDetail>) {
    if (_.isEqual(this.report, newAccidentData)) return;
    const notificationKeys = [
      'location',
      'affected_to',
      'state',
      'events',
      'details.vehicles',
      'details.victims',
      'details.lanes',
      'details.formMarkings',
      'details.formMaterials',
      'details.formWeather',
      'details.formVehicles',
      'details.medias'
    ];
    notificationKeys.forEach((key, index) => {
      const oldValue = _.get(this.report, key);
      const newValue = _.get(newAccidentData, key);
      if (_.isEqual(oldValue, newValue)) return;
      this.haveChange(key.split('.'), oldValue, newValue, newAccidentData, index * 200);
    });
  }

  /**
   * Bundle of functions that check any update of the accident
   * @param key
   * @param value
   * @return Boolean true if key have change, false otherwise
   */
  private haveChange(
    key: string[],
    oldValue: any,
    value: any,
    newAccidentData: IReporting<IAccidentDetail>,
    timeOut: number
  ): void {
    setTimeout(() => {
      if (key.indexOf('location') !== -1) {
        this.spawnNotification(this.translate.instant('REPORTING.ACCIDENT.NOTIFICATION_TITLE.ACCIDENT_UPDATED'), {
          body: this.translate.instant('REPORTING.ACCIDENT.NOTIFICATION_BODY.LOCATION_CHANGE'),
          tag: 'location',
          icon: this.iconService.defaultIcons.reporting.accident
        });
      } else if (key.indexOf('state') !== -1 && value === ReportingState.DONE) {
        this.spawnNotification(this.translate.instant('REPORTING.ACCIDENT.NOTIFICATION_TITLE.ACCIDENT_FINALIZED'), {
          body: this.translate.instant('GLOBAL.STATES.DONE'),
          tag: 'lanes'
        });
      } else if (key.indexOf('affected_to') !== -1) {
        this.spawnNotification(this.translate.instant('REPORTING.ACCIDENT.NOTIFICATION_TITLE.ACCIDENT_UPDATED'), {
          body: this.translate.instant('REPORTING.ACCIDENT.NOTIFICATION_BODY.AGENT_ASSIGNED'),
          tag: 'lanes'
        });
      } else if (key.indexOf('events') !== -1) {
        value.forEach((event: IReportingEvent) => {
          const sameEvent = oldValue.find((element: IReportingEvent) => element.id === event.id);
          if (!sameEvent || !_.isEqual(event, sameEvent)) {
            this.spawnNotification(this.translate.instant('REPORTING.ACCIDENT.NOTIFICATION_TITLE.NEW_EVENT'), {
              body: getTranslationWithFallback(
                `REPORTING.ACCIDENT.EVENTS.${event.type.name.toLocaleUpperCase()}`,
                event.type.name,
                this.translate
              ),
              icon: event.type.picto
            });
          }
        });
      } else if (key.indexOf('vehicles') !== -1) {
        if (key.indexOf('riskVehicle') !== -1) {
          this.spawnNotification(this.translate.instant('REPORTING.ACCIDENT.NOTIFICATION_TITLE.RISK_VEHICLE'), {
            body: this.translate.instant('REPORTING.ACCIDENT.ANOTHER_RISK_VEHICULE_INVOLVED'),
            tag: 'riskVehicle',
            icon: this.iconService.registry.riskVehicle
          });
        } else {
          this.spawnNotification(this.translate.instant('REPORTING.ACCIDENT.NOTIFICATION_TITLE.ACCIDENT_UPDATED'), {
            body: this.translate.instant('REPORTING.ACCIDENT.NOTIFICATION_BODY.VEHICLES'),
            tag: 'vehicles',
            icon: this.iconService.registry.vehicles
          });
        }
      } else if (key.indexOf('victims') !== -1) {
        this.spawnNotification(this.translate.instant('REPORTING.ACCIDENT.NOTIFICATION_TITLE.ACCIDENT_UPDATED'), {
          body: this.translate.instant('REPORTING.ACCIDENT.NOTIFICATION_BODY.VICTIMS'),
          tag: 'victims',
          icon: this.iconService.registry.victims
        });
      } else if (key.indexOf('lanes') !== -1) {
        this.spawnNotification(this.translate.instant('REPORTING.ACCIDENT.NOTIFICATION_TITLE.ACCIDENT_UPDATED'), {
          body: this.translate.instant('REPORTING.ACCIDENT.NOTIFICATION_BODY.LANES'),
          tag: 'lanes'
        });
      } else if (key.indexOf('formMarkings') !== -1) {
        this.spawnNotification(this.translate.instant('REPORTING.ACCIDENT.NOTIFICATION_TITLE.NEW_FORM'), {
          body: this.translate.instant('REPORTING.ACCIDENT.NOTIFICATION_BODY.MARKINGS'),
          tag: 'formMarkings',
          icon: this.iconService.registry.markings
        });
      } else if (key.indexOf('formMaterials') !== -1) {
        this.spawnNotification(this.translate.instant('REPORTING.ACCIDENT.NOTIFICATION_TITLE.NEW_FORM'), {
          body: this.translate.instant('REPORTING.ACCIDENT.NOTIFICATION_BODY.MATERIALS'),
          tag: 'formMaterials',
          icon: this.iconService.registry.materials
        });
      } else if (key.indexOf('formWeather') !== -1) {
        this.spawnNotification(this.translate.instant('REPORTING.ACCIDENT.NOTIFICATION_TITLE.NEW_FORM'), {
          body: this.translate.instant('REPORTING.ACCIDENT.NOTIFICATION_BODY.WEATHER'),
          tag: 'formWeather',
          icon: this.iconService.registry.weather
        });
      } else if (key.indexOf('formVehicles') !== -1) {
        this.spawnNotification(this.translate.instant('REPORTING.ACCIDENT.NOTIFICATION_TITLE.NEW_FORM'), {
          body: this.translate.instant('REPORTING.ACCIDENT.NOTIFICATION_BODY.VEHICLES_DATA'),
          tag: 'formVehicles',
          icon: this.iconService.registry.vehicles
        });
      } else if (key.indexOf('medias') !== -1) {
        this.mediasChange(key, newAccidentData);
      }
    }, timeOut);
  }

  private mediasChange(key: string[], newAccidentData: IReporting<IAccidentDetail>) {
    const stringKey = key.join('.');
    const token = this.session.getToken();
    const apiRoot = ProfileService.getAPIUrl();
    const apiPath = `reportings/${this.id}/media/`;
    const baseURL = `${apiRoot}${apiPath}`;
    const media = this.getOwnSubProperty(newAccidentData, stringKey);
    const imageUrl = `${baseURL}${media.name}/file?jwt=${token}&source=front`;
    switch (media.type) {
      case 'image':
        this.spawnNotification(this.translate.instant('REPORTING.ACCIDENT.NOTIFICATION_TITLE.NEW_IMAGE'), {
          body: this.translate.instant('REPORTING.ACCIDENT.NOTIFICATION_BODY.NEW_IMAGE'),
          tag: `image_${stringKey}`,
          image: imageUrl
        });
        break;
      case 'drawing':
        this.spawnNotification(this.translate.instant('REPORTING.ACCIDENT.NOTIFICATION_TITLE.NEW_DRAWING'), {
          body: this.translate.instant('REPORTING.ACCIDENT.NOTIFICATION_BODY.NEW_DRAWING'),
          tag: `drawing_${stringKey}`,
          image: imageUrl
        });
        break;
    }
  }

  private spawnNotification(title: string, options?: any): void {
    if (options && options.hasOwnProperty('icon') && this.getExtension(options.icon) === 'svg') {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      const img = new Image(40, 40);
      img.src = options.icon;
      img.onload = () => {
        canvas.width = 40;
        canvas.height = 40;
        if (context) {
          context.drawImage(img, 0, 0);
          options.icon = canvas.toDataURL('image/png');
          if (Notification) {
            const notif = new Notification(title, options);
            notif.onclick = () => {
              window.focus();
              notif.close();
            };
          }
        }
      };
    } else {
      if (Notification) {
        const notif = new Notification(title, options);
        notif.onclick = () => {
          window.focus();
          notif.close();
        };
      }
    }
  }

  private getExtension(path: string): string {
    const basename = path.split(/[\\/]/).pop() || path;
    const pos = basename.lastIndexOf('.');

    if (basename === '' || pos < 1) {
      return '';
    }

    return basename.slice(pos + 1);
  }

  /**
   * Returns an object "deep" key
   *
   * @export
   * @param {*} obj
   * @param {string} key
   * @returns {string}
   */
  private getOwnSubProperty(obj: { [key: string]: any }, key: string): any {
    if (!key || !obj || typeof obj !== 'object') {
      return undefined;
    }
    // Handle arrays and trailing points (as 'key.0' is a valid value to get key[0]')
    key = key
      .replace(/[\[\]]/g, '.')
      .replace(/\.+/g, '.')
      .replace(/\.$/g, '');
    if (key.split('.').length === 1) {
      return obj[key];
    }

    return this.getOwnSubProperty(obj[key.split('.')[0]], key.split('.').slice(1).join('.'));
  }

  /**
   * @todo documentation
   * @param several
   */
  private displayRiskVehicleAlert(several = false) {
    let dialogTitle;
    if (this.riskVehiclesSignaled) {
      dialogTitle = 'REPORTING.ACCIDENT.ANOTHER_RISK_VEHICULE_INVOLVED';
    } else {
      dialogTitle = !several
        ? 'REPORTING.ACCIDENT.RISK_VEHICLE_INVOLVED'
        : 'REPORTING.ACCIDENT.SEVERAL_RISK_VEHICLES_INVOLVED';
    }

    // Prevent multiple dialog opening
    if (this.dialog.openDialogs.length < 1) {
      this.dialog.open(GenericDialogComponent, {
        width: '80%',
        height: 'auto',
        maxWidth: '500px',
        role: 'alertdialog',
        data: {
          structure: {
            title: dialogTitle,
            buttons: [
              {
                text: 'GLOBAL.OK',
                action: {
                  target: 'generic',
                  params: { id: 'close' }
                }
              }
            ]
          },
          content: {
            type: 'html',
            html: `<img src="/assets/icon/reportings/alert-tmd.svg" class="centeredImage" width="100%" height="100%"/>`
          }
        },
        disableClose: true
      });
    }
    this.riskVehiclesSignaled = true;
  }
}
