<google-map
  *ngIf="mapsApiLoaded"
  width="100%"
  height="100%"
  [styles]="style"
  [options]="{
    center: gmCenter,
    zoom: zoom,
    maxZoom: maxZoom,
    draggable: draggable,
    scrollwheel: scrollwheel,
    zoomControl: zoomControl,
    disableDoubleClickZoom: !zoomControl,
    fullscreenControl: fullscreenControl,
    streetViewControl: streetViewControl,
    mapTypeControl: mapTypeControl
  }"
  (mapClick)="handleMapClick($event)"
  (idle)="handleMapIdle()"
>
  <map-marker-clusterer
    [options]="{
      averageCenter: true,
      maxZoom: maxZoom - 1,
      gridSize: 50,
      imagePath: 'https://raw.githubusercontent.com/googlemaps/v3-utility-library/master/markerclustererplus/images/m'
    }"
  >
    <map-marker
      #mapMarker="mapMarker"
      *ngFor="let marker of markers"
      [options]="{
        position: {
          lat: marker.coordinates.latitude,
          lng: marker.coordinates.longitude
        },
        icon: marker.iconUrl,
        draggable: marker.draggable
      }"
      (mapMouseover)="openInfoWindow($event, marker, mapMarker)"
      (mapMouseout)="closeInfoWindow($event)"
      (mapClick)="handleMarkerClick($event, marker)"
      (mapDragend)="handleMarkerDragend($event, marker)"
    />
  </map-marker-clusterer>

  <map-info-window #infoWin *ngIf="currentMarker?.showInfos">
    <app-reporting-info-window *ngIf="currentMarker.markerType === 'reporting'" [marker]="currentMarker" />
    <app-infrastructure-info-window *ngIf="currentMarker.markerType === 'infrastructure'" [marker]="currentMarker" />
  </map-info-window>
</google-map>
