<div class="sidemenu">
  <div class="cover-bg" [ngStyle]="{ 'background-image': 'url(' + srcTopSidemenu + ')' }">
    <div class="sidemenu-profile-section">
      <mat-grid-list cols="4" class="sidemenu-grid" rowHeight="35px">
        <mat-grid-tile class="sidemenu-grid-item" [colspan]="1" [rowspan]="5"> </mat-grid-tile>
        <mat-grid-tile class="sidemenu-grid-item" [colspan]="2" [rowspan]="1"> </mat-grid-tile>
        <mat-grid-tile class="sidemenu-grid-item" [colspan]="1" [rowspan]="4"> </mat-grid-tile>
        <mat-grid-tile class="sidemenu-grid-item" [colspan]="2" [rowspan]="1">
          <div class="initials-div">
            <span class="initials-letters">{{ initials }}</span>
          </div>
        </mat-grid-tile>
        <mat-grid-tile class="sidemenu-grid-item z-index100" [colspan]="2" [rowspan]="1">
          <span class="nowrap">{{ userName }}</span>
        </mat-grid-tile>
        <mat-grid-tile class="sidemenu-grid-item z-index100" [colspan]="2" [rowspan]="1">
          <div class="w-full flex justify-space-around">
            <button *ngIf="isOperator()" class="add-comment-icon" type="button" (click)="addCommentDialog()">
              <mat-icon>add_comment</mat-icon>
            </button>
            <app-logout />
          </div>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
  </div>
  <ul>
    <ng-container *ngFor="let entry of entries">
      <li *ngIf="isVisible(entry)" [ngClass]="{ active: isActive(entry) }">
        <div class="sidemenu-entry" [routerLink]="entry.url">
          <i class="material-icons" *ngIf="entry.icon !== 'balisage'">{{ entry.icon }}</i>
          <i class="material-icons" *ngIf="entry.icon === 'balisage'"
            ><img class="picto-size" src="../../../assets/icon/infrastructures/picto-balisage.svg"
          /></i>
          <div class="sidemenu-text" translate>{{ entry.text }}</div>
        </div>
      </li>
    </ng-container>
  </ul>

  <div class="versions">
    <div>
      <p matTooltip="{{ apiUrl }}" matTooltipPosition="right">
        {{ 'VERSIONS.API_VERSION' | translate }} {{ apiVersion }}
      </p>
      <p>{{ 'VERSIONS.FRONT_VERSION' | translate }} {{ frontVersion }}</p>
    </div>
  </div>
</div>
