import { Component, OnInit } from '@angular/core';
import { DEFAULT_PAGE_SIZE, IGenericListParameters } from 'app/components/generic/list/list.interface';
import { ITeam, TeamFilters } from 'app/interface/team.interface';
import { LoadingService } from 'app/services/loading.service';
import { TeamsService } from 'app/services/teams.service';
import { TitleService } from 'app/services/title.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-teams',
  templateUrl: './teams.list.component.html',
  styleUrls: ['./teams.list.component.scss']
})
export class TeamsListComponent implements OnInit {
  teamsList: Partial<ITeam>[] = [];
  loadedList: any[] = [];
  total = 0;
  loaded = false;

  listParameters: IGenericListParameters = {
    paginator: true,
    listHead: [
      {
        key: 'id_sector',
        type: 'text',
        title: 'TEAMS.SECTOR_ID',
        options: {
          sortable: false
        }
      },
      {
        key: 'sector_name',
        type: 'text',
        title: 'TEAMS.SECTOR_NAME',
        options: {
          sortable: false
        }
      },
      {
        key: 'team_id',
        type: 'text',
        title: 'TEAMS.TEAM_ID',
        apiKey: 'id'
      },
      {
        key: 'team_name',
        type: 'text',
        title: 'TEAMS.TEAM_NAME',
        apiKey: 'name'
      }
    ]
  };

  constructor(
    private title: TitleService,
    private teams: TeamsService,
    public loading: LoadingService
  ) {
    this.title.setTitle('GLOBAL.BREADCRUMB.TEAMS');
  }

  async ngOnInit() {
    this.getTeamsList({ params: { limit: DEFAULT_PAGE_SIZE, offset: 0 } });
  }

  loadList() {
    const finalList: any = [];
    this.teamsList.forEach((el) => {
      if (el.sectors) {
        el.sectors.forEach((sect) => {
          finalList.push({
            id_sector: sect.id,
            sector_name: sect.name,
            team_id: el.id,
            team_name: el.name
          });
        });
      }
    });

    return finalList;
  }

  getTeamsList(event?: { type?: string; data?: any; params: any }) {
    this.loaded = false;
    let filters: TeamFilters = {};
    if (event && event.data && event.type === 'search') {
      if (event.data.search) {
        filters.q = String(event.data.search);
      } else if (event.data.search === '') {
        delete filters.q;
      }
    }
    if (event && event.params) {
      filters = { ...filters, ...event.params };
    }

    this.loading.on();
    this.teams
      .getAll(filters)
      .pipe(
        finalize(() => {
          this.loadedList = this.loadList();
          this.loading.off();
        })
      )
      .subscribe((res: { items: any[]; total: number }) => {
        this.teamsList = res.items;
        this.total = res.total;
        this.loaded = true;
      });
  }
}
