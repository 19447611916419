import { HttpClient } from '@angular/common/http';
import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import _, { PartialDeep } from 'lodash';
import { lastValueFrom } from 'rxjs';
import { HighwayOption } from 'src/app/interface/highway.interface';
import { IInfrastructure, InfrastructureType } from 'src/app/interface/infrastructure.interface';
import { IBareListResult } from 'src/app/interface/list-result.interface';
import { Coordinates } from 'src/app/interface/location.interface';
import { ISector } from 'src/app/interface/sector.interface';
import { InfrastructuresService } from 'src/app/services/infrastructures.service';
import { SectorsService } from 'src/app/services/sectors.service';
import { spliceValue } from 'src/app/tools/array.helper';
import { MapsHelper } from 'src/app/tools/maps.helper';

import {
  NewInfrastructureDialogComponent,
  NewInfrastructureDialogData,
  NewInfrastructureDialogRes
} from '../new/new-infrastructure.dialog.component';

export interface InfrastructureManagerFilters {
  infrastructureTypeIds?: string[];
  sectorIds?: string[];
  highwayIds?: string[];
}

@Component({
  selector: 'app-infrastructure-manager',
  templateUrl: './infrastructure-manager.component.html',
  styleUrls: ['./infrastructure-manager.component.scss']
})
export class InfrastructureManagerComponent implements OnInit {
  // Map
  private mapsHelper: MapsHelper;
  public isMapsApiLoaded: boolean = false;
  public initialPosition = { lat: 46.7833, lng: 4.85 };
  public initialZoom = 7;
  public currentZoom = 7;
  @ViewChild(GoogleMap) private map: GoogleMap;
  @ViewChild(MapInfoWindow) private mapInfoWindow: MapInfoWindow;
  @ViewChildren(MapMarker) private mapMarkers: QueryList<MapMarker>;

  // Infrastructure
  public infrastructures: IInfrastructure[];
  public infrastructureTotalCount: number;
  private editingInfrastructureId: string | null = null;
  public editingInfrastructureIndex: number = -1; // must be synced with editingInfrastructureId
  public tooltipInfrastructureIndex: number = -1;
  // Infrastructure loading
  public isLoadingData: boolean = false;
  private lastRequestId: number = 0;
  private loadingRequestId: number | null = null;
  // Filter data
  public areFilterDataLoaded: boolean = false;
  public infrastructureTypes: InfrastructureType[];
  public sectors: ISector[];
  public myHighways: HighwayOption[];
  // Filter value
  public limitOptions: number[] = [200, 500, 1000, 2000, 5000];
  public activeInfrastructureTypeIds: string[];

  private _infrastructureLimit: number | 'all' = 200;
  public set infrastructureLimit(infrastructureLimit) {
    if (infrastructureLimit === this._infrastructureLimit) return;
    this._infrastructureLimit = infrastructureLimit;
    this.loadData();
  }
  public get infrastructureLimit() {
    return this._infrastructureLimit;
  }

  private _activeSectorIds: string[];
  public set activeSectorIds(activeSectorIds) {
    if (_.isEqual(activeSectorIds, this._activeSectorIds)) return;
    this._activeSectorIds = activeSectorIds;
    this.applyFilters();
  }
  public get activeSectorIds() {
    return this._activeSectorIds;
  }

  private _activeHighwayIds: string[]; // among myHighways only!
  public set activeHighwayIds(activeHighwayIds) {
    if (_.isEqual(activeHighwayIds, this._activeHighwayIds)) return;
    this._activeHighwayIds = activeHighwayIds;
    this.applyFilters();
  }
  public get activeHighwayIds() {
    return this._activeHighwayIds;
  }

  constructor(
    private sectorService: SectorsService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private infrastructureService: InfrastructuresService,
    private translate: TranslateService,
    private httpClient: HttpClient
  ) {
    this.mapsHelper = new MapsHelper(httpClient);
  }

  ngOnInit() {
    this.mapsHelper.loadMapsApi().then(() => (this.isMapsApiLoaded = true));
    this.loadFilters().then(() => {
      return this.loadData();
    });
  }

  private async loadFilters(): Promise<void> {
    await this.loadFilterData();
    this.loadFilterValues();
    this.areFilterDataLoaded = true;
  }

  public async loadFilterData(): Promise<void> {
    const [infrastructureTypes, sectorList] = await Promise.all([
      this.infrastructureService.getInfrastructureTypes(),
      lastValueFrom(this.sectorService.getAll())
    ]);
    this.infrastructureTypes = infrastructureTypes;
    this.sectors = sectorList.items;
    this.myHighways = JSON.parse(localStorage.getItem('sectorsHighways') as string) as HighwayOption[];
  }

  private loadFilterValues() {
    const infrastructureTypes = this.infrastructureTypes;
    const sectors = this.sectors;
    const myHighways = this.myHighways;

    const allInfrastructureTypeIds = infrastructureTypes.map((e) => e.id);
    const allSectorIds = sectors.map((e) => e.id);
    const allHighwayIds = myHighways.map((e) => e.UID);

    const storage = localStorage.getItem('infrastructureManagerFilters');
    let activeInfrastructureTypeIds: string[] | undefined;
    let activeSectorIds: string[] | undefined;
    let activeHighwayIds: string[] | undefined;

    if (storage) {
      const decoded: PartialDeep<InfrastructureManagerFilters> = JSON.parse(storage) || {};

      const ensureStringArray = (value: unknown, includeValues: string[]): string[] | undefined => {
        if (!value || !_.isArray(value)) {
          return undefined;
        }
        return value.filter((e) => {
          if (!_.isString(e)) {
            return false;
          }
          if (includeValues && !includeValues.includes(e)) {
            return false;
          }
          return true;
        });
      };

      const ensureInfrastructureTypeIds = (value: unknown): string[] | undefined =>
        ensureStringArray(value, allInfrastructureTypeIds);
      const ensureSectorIds = (value: unknown): string[] | undefined => ensureStringArray(value, allSectorIds);
      const ensureHighwayIds = (value: unknown): string[] | undefined => ensureStringArray(value, allHighwayIds);

      activeInfrastructureTypeIds = ensureInfrastructureTypeIds(decoded.infrastructureTypeIds);
      activeSectorIds = ensureSectorIds(decoded.sectorIds);
      activeHighwayIds = ensureHighwayIds(decoded.highwayIds);
    }

    if (!activeInfrastructureTypeIds?.length) {
      activeInfrastructureTypeIds = [...allInfrastructureTypeIds];
    }

    if (!activeSectorIds?.length) {
      activeSectorIds = [...allSectorIds];
    }

    if (!activeHighwayIds?.length) {
      activeHighwayIds = [];
      // activeHighwayIds = [...allHighwayIds];
    }

    this.activeInfrastructureTypeIds = activeInfrastructureTypeIds;
    this.activeSectorIds = activeSectorIds;
    this.activeHighwayIds = activeHighwayIds;
  }

  private saveFilters(data: InfrastructureManagerFilters) {
    localStorage.setItem('infrastructureManagerFilters', JSON.stringify(data));
  }

  public async loadData(): Promise<void> {
    if (!this.areFilterDataLoaded) {
      return;
    }
    this.isLoadingData = true;
    const requestId = (this.loadingRequestId = ++this.lastRequestId);

    const infrastructureList = await lastValueFrom(
      this.infrastructureService.getAll({
        limit: this.infrastructureLimit === 'all' ? undefined : this.infrastructureLimit,
        // If activeInfrastructureTypeIds or activeSectorIds is empty,
        // its ok, the service will just return an empty infrastructure array
        type: this.areAllInfrastructureTypesActive() ? undefined : this.activeInfrastructureTypeIds,
        sector_id: this.areAllSectorsActive() ? undefined : this.activeSectorIds,
        // The highway filter includes MY highways only.
        // If no highways selected, do not filter by "my highways", so do not filter by highway at all (undefined)
        highway_id: this.activeHighwayIds?.length ? this.activeHighwayIds : undefined
      })
    );

    if (this.loadingRequestId !== requestId) {
      return;
    }
    this.handleData(infrastructureList);
  }

  public handleData(infrastructureList: IBareListResult<IInfrastructure>): void {
    this.infrastructures = infrastructureList.items;
    this.infrastructureTotalCount = infrastructureList.total;

    this.tooltipInfrastructureIndex = -1;
    if (this.editingInfrastructureId) {
      const index = this.infrastructures.findIndex((e) => e._id === this.editingInfrastructureId);
      if (index === -1) {
        this.editingInfrastructureId = null;
        this.editingInfrastructureIndex = -1;
      } else {
        this.editingInfrastructureIndex = index;
      }
    }
    this.isLoadingData = false;
  }

  //
  // Filters
  //

  private applyFilters(): void {
    this.saveFilters({
      infrastructureTypeIds: this.activeInfrastructureTypeIds,
      sectorIds: this.activeSectorIds,
      highwayIds: this.activeHighwayIds
    });
    this.loadData();
  }

  public isInfrastructureTypeActive(infrastructureTypeId: string): boolean {
    return this.activeInfrastructureTypeIds.includes(infrastructureTypeId);
  }

  public areAllInfrastructureTypesActive(): boolean {
    return !!this.infrastructureTypes?.every((e) => this.activeInfrastructureTypeIds?.includes(e.id));
  }

  public handleInfrastructureTypeClick(id: string): void {
    if (this.activeInfrastructureTypeIds.includes(id)) {
      spliceValue(this.activeInfrastructureTypeIds, id);
    } else {
      this.activeInfrastructureTypeIds.push(id);
    }

    this.applyFilters();
  }

  public handleAllInfrastructureTypeClick(): void {
    if (this.areAllInfrastructureTypesActive()) {
      this.activeInfrastructureTypeIds = [];
    } else {
      this.activeInfrastructureTypeIds = this.infrastructureTypes?.map((e) => e.id) || [];
    }

    this.applyFilters();
  }

  private activeInfrastructureType(id: string): void {
    if (this.activeInfrastructureTypeIds.includes(id)) {
      return;
    }
    this.activeInfrastructureTypeIds.push(id);
    this.applyFilters();
  }

  public areAllSectorsActive(): boolean {
    return !!this.sectors?.every((e) => this.activeSectorIds?.includes(e.id));
  }

  public handleAllSectorsClick(): void {
    if (this.areAllSectorsActive()) {
      this.activeSectorIds = [];
    } else {
      this.activeSectorIds = this.sectors.map((e) => e.id);
    }
  }

  public areAllMyHighwaysActive(): boolean {
    return !!this.myHighways?.every((e) => this.activeHighwayIds?.includes(e.UID));
  }

  public handleAllHighwaysClick(event: MouseEvent): void {
    event.preventDefault();
    if (this.areAllMyHighwaysActive()) {
      this.activeHighwayIds = [];
    } else {
      this.activeHighwayIds = this.myHighways.map((e) => e.UID);
    }
  }

  public handleRemoveLimitClick(): void {
    this.infrastructureLimit = 'all';
  }

  //
  // Map event handlers
  //

  public handleZoomChange(): void {
    this.currentZoom = this.map.getZoom()!;
  }

  public handleMapClick(/*event: google.maps.MapMouseEvent*/): void {
    this.closeInfrastructureInfoWindow();
    this.stopEditingInfrastructure();
  }

  public handleMapDblclick(event: google.maps.MapMouseEvent): void {
    const coords = MapsHelper.gmCoordsToCoords(event.latLng);
    if (!coords) {
      return;
    }
    this.openNewInfrastructureDialog(coords);
  }

  public handleInfrastructureMarkerClick(event: google.maps.MapMouseEvent, infrastructureId: string): void {
    this.openInfrastructureInfoWindow(infrastructureId);
  }

  public handleInfrastructureMarkerDblclick(event: google.maps.MapMouseEvent, infrastructureId: string): void {
    this.setEditingInfrastructure(infrastructureId);
  }

  public handleInfrastructureMarkerDragend(infrastructureId: string, event: google.maps.MapMouseEvent): void {
    const coords = MapsHelper.gmCoordsToCoords(event.latLng);
    if (!coords) {
      return;
    }
    this.updateInfrastructureCoords(infrastructureId, coords);
  }

  //
  // Map state logic
  //

  public isEditingInfrastructure(infrastructureId: string): boolean {
    return this.editingInfrastructureId === infrastructureId;
  }

  private setEditingInfrastructure(infrastructureId: string): void {
    const infrastructure = this.infrastructures.find((e) => e._id === infrastructureId);
    if (!infrastructure) {
      return;
    }
    const index = this.infrastructures.indexOf(infrastructure);
    const marker = this.mapMarkers.get(index);
    if (!marker) {
      return;
    }

    this.map.googleMap?.setZoom(18);
    const position = marker.getPosition();
    if (position) {
      this.map.panTo(position);
    }
    this.editingInfrastructureId = infrastructureId;
    this.editingInfrastructureIndex = index;
    this.closeInfrastructureInfoWindow();
  }

  private stopEditingInfrastructure(): void {
    this.editingInfrastructureId = null;
    this.editingInfrastructureIndex = -1;
  }

  private openInfrastructureInfoWindow(infrastructureId: string): void {
    const infrastructure = this.infrastructures.find((e) => e._id === infrastructureId);
    if (!infrastructure) {
      this.closeInfrastructureInfoWindow();
      return;
    }
    const index = this.infrastructures.indexOf(infrastructure);
    const marker = this.mapMarkers.get(index);
    if (!marker) {
      return;
    }

    this.tooltipInfrastructureIndex = index;
    const infoWindow = this.mapInfoWindow!;
    infoWindow.open(marker);
  }

  private closeInfrastructureInfoWindow(): void {
    const infoWindow = this.mapInfoWindow;
    infoWindow?.close();
    this.tooltipInfrastructureIndex = -1;
  }

  private async updateInfrastructureCoords(infrastructureId: string, coords: Coordinates): Promise<void> {
    const infrastructure = this.infrastructures.find((e) => e._id === infrastructureId);
    if (!infrastructure) {
      return;
    }
    await this.infrastructureService.patchInfrastructure(infrastructure._id, { coordinates: coords });
    infrastructure.coordinates = coords;
  }

  //
  // Events from info-window
  //

  public handleEditInfrastructure(infrastructureId: string) {
    this.setEditingInfrastructure(infrastructureId);
  }

  public handleDeleteInfrastructure(infrastructureId: string): void {
    this.removeInfrastructure(infrastructureId);
  }

  private async removeInfrastructure(infrastructureId: string): Promise<void> {
    this.closeInfrastructureInfoWindow();
    this.stopEditingInfrastructure();

    await this.infrastructureService.deleteOneInfrastructure(infrastructureId);

    if (this.editingInfrastructureId === infrastructureId) {
      // Au cas où l'utilisateur aurait recliqué dessus
      this.stopEditingInfrastructure();
    }
    this.infrastructures = this.infrastructures.filter((e) => e._id !== infrastructureId);
    this.applyFilters();
  }

  //
  // Creation
  //

  private async openNewInfrastructureDialog(coordinates: Coordinates): Promise<void> {
    if (this.currentZoom <= 16) {
      this.snackbar.open(
        this.translate.instant('CROSSING_POINTS.SNACKBAR.MUST_ZOOM_MORE'),
        this.translate.instant('GLOBAL.CLOSE_LABEL'),
        { duration: 2000 }
      );
      return;
    }
    const res = await lastValueFrom(
      this.dialog
        .open<NewInfrastructureDialogComponent, NewInfrastructureDialogData, NewInfrastructureDialogRes>(
          NewInfrastructureDialogComponent,
          {
            width: '1000px',
            data: { coordinates, highways: this.myHighways }
          }
        )
        .afterClosed()
    );

    if (!res || res.result !== 'valid') {
      return;
    }
    this.infrastructures.push(res.data);
    this.activeInfrastructureType(res.data.type.id);
    this.applyFilters();
  }
}
