import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DEFAULT_PAGE_SIZE, IGenericListParameters } from 'app/components/generic/list/list.interface';
import { GetInfrastructureQueryParams, IInfrastructure } from 'app/interface/infrastructure.interface';
import { InfrastructuresService } from 'app/services/infrastructures.service';
import { LoadingService } from 'app/services/loading.service';
import { TitleService } from 'app/services/title.service';
import { finalize } from 'rxjs/operators';

import { getTranslationWithFallback } from '../../../tools/translate';

@Component({
  selector: 'app-highways',
  templateUrl: './infrastructures.list.component.html',
  styleUrls: ['./infrastructures.list.component.scss']
})
export class InfrastructuresListComponent implements OnInit {
  infrastructuresList: any[] = [];
  public loaded = false;

  total = 0;
  listParameters: IGenericListParameters = {
    paginator: true,
    clickable: false,
    listHead: [
      {
        key: 'highway_name',
        type: 'text',
        title: 'HIGHWAYS.HIGHWAY_LABEL',
        color: 'main-color-light',
        styles: { fontWeight: 'bold' },
        apiKey: 'location.highway.name'
      },
      {
        key: 'landmark',
        type: 'text',
        title: 'HIGHWAYS.PR_LABEL',
        apiKey: 'location.kilometerPoint'
      },
      {
        key: 'type',
        type: 'text',
        title: 'GLOBAL.TYPE',
        apiKey: 'infrastructuresType.name'
      },
      {
        key: 'latitude',
        type: 'text',
        title: 'HIGHWAYS.LATITUDE_LABEL',
        options: {
          sortable: false
        }
      },
      {
        key: 'longitude',
        type: 'text',
        title: 'HIGHWAYS.LONGITUDE_LABEL',
        options: {
          sortable: false
        }
      },
      {
        key: 'name',
        type: 'text',
        title: 'HIGHWAYS.NAME'
      },
      {
        key: 'description',
        type: 'text',
        title: 'GLOBAL.DESCRIPTION'
      }
    ]
  };

  constructor(
    private title: TitleService,
    private infrastructures: InfrastructuresService,
    public loading: LoadingService,
    public translate: TranslateService,
    public router: Router
  ) {
    this.title.setTitle('GLOBAL.BREADCRUMB.INFRASTRUCTURES.LIST');
  }

  async ngOnInit() {
    this.getInfrastructuresList({ params: { limit: DEFAULT_PAGE_SIZE, offset: 0 } });
  }

  /**
   * Retrieve or refresh the highways list and apply provided filters.
   *
   * @param event  An event object emitted by ListComponent when a refresh is
   *   required.
   */
  getInfrastructuresList(event?: { type?: string; data?: any; params: any }) {
    this.loaded = false;
    let filters: GetInfrastructureQueryParams = {};
    if (event && event.data && event.type === 'search') {
      if (event.data.search) {
        filters.q = String(event.data.search);
      } else if (event.data.search === '') {
        delete filters.q;
      }
    }
    if (event && event.params) {
      filters = { ...filters, ...event.params };
    }
    this.loading.on();
    this.infrastructures
      .getAll(filters)
      .pipe(
        finalize(async () => {
          this.loading.off();
        })
      )
      .subscribe((res) => {
        this.infrastructuresList = this.parseData(res.items);
        this.total = res.total;
        this.loaded = true;
      });
  }

  parseData(infrastructures: IInfrastructure[]): any[] {
    const parsedInfrastructuresList = [];
    for (const infrastructure of infrastructures) {
      parsedInfrastructuresList.push({
        type: getTranslationWithFallback(
          `INFRASTRUCTURES.TYPES.${infrastructure.type.name.toLocaleUpperCase()}`,
          infrastructure.type.name,
          this.translate
        ),
        name: infrastructure.name,
        highway_name: infrastructure.location.highway_name,
        landmark: infrastructure.location.display,
        latitude: infrastructure.coordinates.latitude,
        longitude: infrastructure.coordinates.longitude,
        description: infrastructure.description
      });
    }

    return parsedInfrastructuresList;
  }

  public goToManager(): void {
    this.router.navigate(['/repositories/infrastructures/manager']);
  }
}
