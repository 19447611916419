import { IInfrastructure, IInfrastructureResponse } from './infrastructure.interface';
import { ISector } from './sector.interface';

export enum ControlState {
  NEW = 'NEW',
  DONE = 'DONE',
  NOT_APPLICABLE = 'NOT_APPLICABLE'
}

export enum ControlTypeEnum {
  SPECIFIC = 'specific',
  NORMAL = 'normal',
  BEACON = 'beacon'
}

export const controlInfrastructureTypeOptions: { label: string; value: string }[] = [
  {
    label: 'INFRASTRUCTURES.TYPES.ACCES_SERVICE',
    value: 'acces_service'
  },
  {
    label: 'INFRASTRUCTURES.TYPES.PAU',
    value: 'pau'
  },
  {
    label: 'INFRASTRUCTURES.TYPES.EDICULE',
    value: 'edicule'
  }
];

export const controlStateOptions = [
  {
    label: 'CONTROLS.FILTERS.STATUS.DONE_COMPLIANT',
    value: 'DONE',
    conformity: true
  },
  {
    label: 'CONTROLS.FILTERS.STATUS.DONE_NOT_COMPLIANT',
    value: 'DONE',
    conformity: false
  },
  {
    label: 'CONTROLS.FILTERS.STATUS.NOT_APPLICABLE',
    value: 'NOT_APPLICABLE'
  }
];

export const controlUpdatedDateOptions = [
  {
    label: 'AUDITS.FILTERS.UPDATE_DATE.LAST_WEEK',
    value: 'LAST_WEEK'
  },
  {
    label: 'AUDITS.FILTERS.UPDATE_DATE.LAST_MONTH',
    value: 'LAST_MONTH'
  },
  {
    label: 'AUDITS.FILTERS.UPDATE_DATE.LAST_YEAR',
    value: 'LAST_YEAR'
  },
  {
    label: 'AUDITS.FILTERS.UPDATE_DATE.OLD_AF',
    value: 'OLD_AF'
  }
];

export enum FieldTypes {
  SINGLE_CHOICE = 'SINGLE_CHOICE',
  MULTI_CHOICE = 'MULTI_CHOICE',
  BINARY_QUESTION = 'BINARY_QUESTION',
  INPUT = 'INPUT',
  INPUT_NUMBER = 'INPUT_NUMBER',
  DATE = 'DATE',

  CHECKBOX_LIST = 'CHECKBOX_LIST',
  PHOTO = 'PHOTO',
  PHOTO_INPUT = 'PHOTO_INPUT',
  PHOTO_INPUT_NUMBER = 'PHOTO_INPUT_NUMBER',
  INPUT_MULTI_LINE = 'INPUT_MULTI_LINE',
  AUTOCOMPLETE_INPUT = 'AUTOCOMPLETE_INPUT'
}

export interface IControlPayloadField {
  key: string;
  type: FieldTypes;
  mandatory: boolean;
  validatorType: string;
  title?: string;
  hint?: string;
  content: string;
  positiveBtnText?: string; // Displayed text for "BINARY_QUESTION" positive answer
  negativeBtnText?: string; // Displayed text for "BINARY_QUESTION" negative answer
  data?: string[]; // Options for "SINGLE_CHOICE" and "MULTIPLE_CHOICE"
  // Subfields
  childFields?: any[]; // IControlPayloadField[], triggered fields for "BINARY_QUESTION" positive answer
  negativeChildFields?: any[]; // IControlPayloadField[], triggered fields for "BINARY_QUESTION" negative answer
  // Actual value
  value?: string | boolean | Date | number | null;
  medias?: any[];
}

export interface IControlPayloadSection {
  title?: string;
  conformity: boolean;
  fields: IControlPayloadField[];
}

export interface IControlPayload {
  title: string;
  confirmText: string;
  sections: IControlPayloadSection[];
}

interface IControlBase {
  conformity?: boolean;
  audit: any;
  audit_id: string;
  created_at: string;
  payload: any;
  sector: ISector;
  sector_id: string;
  status: ControlState;
  updated_at: string;
  _id: string;
  location?: any;
  affected_to?: any;
  created_by?: any;
}

export interface NormalControl extends IControlBase {
  type: ControlTypeEnum.NORMAL;
  infrastructure?: IInfrastructure;
  infrastructure_id: string;
}

export interface SpecificControl extends IControlBase {
  type: ControlTypeEnum.SPECIFIC;
  infrastructure?: IInfrastructure;
  infrastructure_id: string;
}

export interface BeaconControl extends IControlBase {
  type: ControlTypeEnum.BEACON;
}

export type IControl = SpecificControl | BeaconControl | NormalControl;

export type IControlResponse = Omit<IControl, 'infrastructure'> & {
  infrastructure: IInfrastructureResponse;
};

export interface IControlQuestion {
  type: string;
  title: string;
  mandatory: boolean;
  response: string | string[];
  step: string;
  key: string;
  content: string;
  medias: string[];
  questions: IControlQuestion[];
}
