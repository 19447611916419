<ng-container *ngIf="!profileCode">
  <app-profile-code-form />
</ng-container>

<ng-container *ngIf="profileCode">
  <app-login *ngIf="!loggedin" />

  <ng-container *ngIf="loggedin">
    <!-- header -->
    <nav class="header-container">
      <app-header />
    </nav>
    <div class="main-content">
      <nav class="sidemenu-container">
        <app-sidemenu />
      </nav>

      <!-- uiview -->
      <div class="content-container">
        <nav class="breadcrumb-container" aria-label="Breadcrumb">
          <app-breadcrumb />
        </nav>

        <div class="content-wrapper">
          <!-- show where to display the content loaded by the router -->
          <router-outlet />
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
