export interface CalcContentHeightOptions {
  dialogTitle?: boolean;
  dialogPadding?: boolean;
  dialogMargin?: boolean;
  dialogActions?: boolean;
}

export class MiscHelper {
  /**
   * Used to calculate the max height available (after ) a dialog content element.
   * Set "true" for each element you want to aggregate the height
   */
  public static calcContentHeight(options?: CalcContentHeightOptions): number {
    const { dialogTitle, dialogActions, dialogPadding, dialogMargin } = options || {};
    return (
      0 + //
      (dialogTitle ? 77 : 0) +
      (dialogActions ? 69 : 0) +
      (dialogPadding ? 48 : 0) +
      (dialogMargin ? 40 : 0)
    );
  }
}
