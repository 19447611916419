import { Pipe, PipeTransform } from '@angular/core';
import _, { PartialDeep } from 'lodash';

type Filter<T> = ((item: T) => boolean) | PartialDeep<T>;

@Pipe({
  name: 'dynamicFilter'
})
export class DynamicFilterPipe<T = any> implements PipeTransform {
  transform(items: T[], filter: Filter<T>): T[] {
    if (!items || !filter) {
      return items;
    }

    if (_.isFunction(filter)) {
      return items.filter(filter);
    } else {
      return items.filter((item) => this.compare(item, filter));
    }
  }

  compare(item: T, filter: Filter<T>) {
    if (_.isFunction(filter)) {
      return false;
    }
    if (!item) {
      return false;
    }
    for (const prop in filter) {
      if (filter[prop] !== item[prop]) {
        return false;
      }
    }
    return true;
  }
}
