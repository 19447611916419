import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-detail',
  templateUrl: './users.detail.component.html',
  styleUrls: ['./users.detail.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class UsersDetailComponent implements OnInit {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {
    /* Noop */
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {
    /* TODO */
  }
}
