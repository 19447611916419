<app-loading *ngIf="!reportingEventOptions" />
<form *ngIf="reportingEventOptions" [formGroup]="form">
  <h1 mat-dialog-title>
    {{ (data.reportingEventToEdit ? 'REPORTING.DIALOG.EDIT_EVENT' : 'REPORTING.DIALOG.NEW_EVENT') | translate }}
  </h1>
  <mat-dialog-content class="dialog-padding pb-0">
    <mat-form-field class="w-full">
      <mat-select
        placeholder="{{ 'REPORTING.REPORTING_EVENT.CATEGORY_SELECT_LABEL' | translate }}"
        name="categories"
        formControlName="categoryId"
      >
        <mat-option *ngFor="let category of reportingEventCategoriesOptions" [value]="category.value">
          <div class="flex items-center gap-4">
            {{ category.label }}
          </div>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <ng-container *ngIf="form.value.categoryId && reportingEventTypesFilteredOptions.length > 1">
      <mat-form-field class="w-full">
        <mat-select
          placeholder="{{ 'REPORTING.REPORTING_EVENT.TYPE_SELECT_LABEL' | translate }}"
          name="types"
          formControlName="typeId"
        >
          <mat-option *ngFor="let type of reportingEventTypesFilteredOptions" [value]="type.id">
            <div class="flex items-center gap-4">
              <img width="25" height="25" src="{{ type.picto }}" />
              {{ type.label }}
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="form.value.typeId">
      <mat-form-field class="w-full" *ngIf="selectedReportingEventType.featureFlags.askCallType">
        <mat-select
          placeholder="{{ 'REPORTING.REPORTING_EVENT.CALL_TYPE_LABEL' | translate }}"
          name="callTypes"
          formControlName="callType"
        >
          <mat-option *ngFor="let callType of callTypes" [value]="callType">
            <div class="flex items-center gap-4">
              {{ callType }}
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="w-full" *ngIf="selectedReportingEventType.featureFlags.askInterlocutor">
        <mat-select
          placeholder="{{ 'REPORTING.REPORTING_EVENT.INTERLOCUTOR_SELECT_LABEL' | translate }}"
          name="interlocutors"
          formControlName="interlocutor"
        >
          <mat-option *ngFor="let interlocutor of interlocutors" [value]="interlocutor">
            <div class="flex items-center gap-4">
              {{ interlocutor }}
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="w-full" *ngIf="form.value.interlocutor">
        <mat-label>{{ 'REPORTING.REPORTING_EVENT.INTERLOCUTOR_NAME_LABEL' | translate }}</mat-label>
        <input matInput type="text" formControlName="interlocutorName" />
      </mat-form-field>
      <mat-form-field class="w-full" *ngIf="selectedReportingEventType.featureFlags.askIntervenor">
        <mat-select
          placeholder="{{ 'REPORTING.REPORTING_EVENT.INTERVENOR_SELECT_LABEL' | translate }}"
          name="intervenors"
          formControlName="intervenor"
        >
          <mat-option *ngFor="let intervenor of intervenors" [value]="intervenor">
            <div class="flex items-center gap-4">
              {{ intervenor }}
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="w-full" *ngIf="form.value.intervenor">
        <mat-label *ngIf="!selectedReportingEventType.featureFlags.isRepairer">{{
          'REPORTING.REPORTING_EVENT.INTERVENOR_NAME_LABEL' | translate
        }}</mat-label>
        <mat-label *ngIf="selectedReportingEventType.featureFlags.isRepairer">{{
          'REPORTING.REPORTING_EVENT.REPAIRER_NAME_LABEL' | translate
        }}</mat-label>
        <input matInput type="text" formControlName="intervenorName" />
      </mat-form-field>
      <mat-form-field class="w-full" *ngIf="selectedReportingEventType.featureFlags.isRepairer">
        <mat-select
          placeholder="{{ 'REPORTING.REPORTING_EVENT.EVACUATION_TYPE_LABEL' | translate }}"
          name="evacuationTypes"
          formControlName="evacuationType"
        >
          <mat-option *ngFor="let evacuationType of evacuationTypes" [value]="evacuationType">
            <div class="flex items-center gap-4">
              {{ evacuationType }}
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div class="flex gap-4">
        <mat-form-field class="w-full">
          <mat-label>{{ 'REPORTING.REPORTING_EVENT.DATE_LABEL' | translate }}</mat-label>
          <input matInput [matDatepicker]="picker" formControlName="date" />
          <mat-datepicker-toggle matIconSuffix [for]="picker" />
          <mat-datepicker #picker />
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ 'REPORTING.REPORTING_EVENT.TIME_LABEL' | translate }}</mat-label>
          <input matInput type="time" formControlName="time" />
        </mat-form-field>
      </div>

      <mat-form-field class="w-full">
        <mat-label>{{ 'REPORTING.REPORTING_EVENT.COMMENT_LABEL' | translate }}</mat-label>
        <textarea
          matInput
          cdkTextareaAutosize
          #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="1"
          cdkAutosizeMaxRows="5"
          formControlName="comment"
        ></textarea>
      </mat-form-field>
    </ng-container>
  </mat-dialog-content>

  <mat-dialog-actions class="dialog-buttons">
    <!-- Button -->
    <button type="submit" mat-raised-button color="warn" (click)="validateDialog()" [disabled]="!form.valid">
      <span>{{ 'GLOBAL.SUBMIT_BUTTON_LABEL' | translate }}</span>
    </button>
    <!-- Button -->
    <button type="button" mat-button (click)="closeDialog()">
      <span>{{ 'GLOBAL.CANCEL' | translate }}</span>
    </button>
  </mat-dialog-actions>
</form>
