import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class MatPaginatorIntlI18n {
  constructor(private translate: TranslateService) {}
  public getMatPaginatorIntlI18n(): MatPaginatorIntl {
    const paginatorIntl = new MatPaginatorIntl();

    paginatorIntl.itemsPerPageLabel = this.translate.instant('PAGINATOR.ITEMS_PER_PAGE');
    paginatorIntl.nextPageLabel = this.translate.instant('PAGINATOR.NEXT_PAGE_LABEL');
    paginatorIntl.previousPageLabel = this.translate.instant('PAGINATOR.PREVIOUS_PAGE_LABEL');
    paginatorIntl.getRangeLabel = this.getRangeLabel.bind(this);

    return paginatorIntl;
  }

  private getRangeLabel = (page: number, pageSize: number, length: number) => {
    const of = this.translate.instant('PAGINATOR.OF');
    if (length === 0 || pageSize === 0) {
      return `0 ${of} ${length}`;
    }

    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} ${of} ${length}`;
  };
}
