<mat-accordion [multi]="true" class="mat-generic-accordion">
  <mat-expansion-panel *ngFor="let section of sections"
    [expanded]="section.expand"
    (opened)="changeExpandPanel(section, true)"
    (closed)="changeExpandPanel(section, false)"
    class="overflow-hidden">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{section.title}}
        <span *ngIf="section.content"> : {{section.content}}</span>
        <span *ngIf="section.mandatory && section.mandatory === true" class="mandatory">*</span>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <mat-panel-description *ngIf="section.type" class="response-block">
      <div *ngIf="section.response && section.type">
        <div [ngClass]="{'minHeightResponse': section.questions}">
          <div *ngIf="section.type === 'MULTI_CHOICE'">
            <ul>
              <li *ngFor="let value of section.response">{{value}}</li>
            </ul>
          </div>
          <div *ngIf="section.type !== 'MULTI_CHOICE'">
            <mat-icon>trending_flat</mat-icon> {{section.response}}
          </div>
        </div>
      </div>
      <div *ngIf="(!section.response || section.response.length === 0) && section.type">
          <mat-icon>trending_flat</mat-icon> {{ 'GLOBAL.NOT_ANSWERED' | translate}}
      </div>
      <div class="medias" *ngIf="section.medias && section.medias.length > 0">
        <span *ngFor="let media of section.medias">
          <img src="{{media}}" alt="" (click)="zoomIn(media)"/>
        </span>
      </div>
    </mat-panel-description>

    <div *ngIf="section.questions && section.questions.length > 0">
      <app-accordion [sections]="section.questions"></app-accordion>
    </div>
  </mat-expansion-panel>
</mat-accordion>
