import { Component, DoCheck, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import {
  IControlAsyncValidator,
  IControlValidator,
  QuestionBase
} from 'app/components/generic/questions/question-base';
import _ from 'lodash';
import { Observable, of as observableOf, Subscription } from 'rxjs';

@Component({
  selector: 'app-question',
  templateUrl: './dynamic-form-question.component.html',
  styleUrls: ['./dynamic-form-question.component.scss']
})
export class DynamicFormQuestionComponent implements OnInit, OnDestroy, DoCheck {
  questionValidators: UntypedFormControl;
  loading = false;
  authorizedFilesFormat = '';
  control: AbstractControl;
  indexSelectedTab = 0;

  errorLabels: any = {
    required: 'GLOBAL.ERROR.REQUIRED',
    pattern: 'GLOBAL.ERROR.PATTERN'
  };

  /**
   * A validator/state map. Used by asynchronous validators to detect value and
   * validation errors changes, and avoid triggering superfluous validation.
   * This is NOT supposed to be necessary: Angular asynchronous validators
   * should be able to handle this logic, but it seems change detection is
   * messing with this behaviour when used with Question objects.
   */
  asyncValidatorsStates = new WeakMap<IControlValidator, { value: any; error: ValidationErrors | null } | null>();

  /**
   * A parent subscription under which all async validators register their inner
   * subscriptions. It allows proper disposal of all subscription during the
   * component destruction.
   * @see this.ngOnDestroy
   */
  asyncValidatorsSubscriptions = new Subscription();

  /** TODO Documentation ; For autocomplete */
  filteredOptions: Observable<{ key: string; value: string }[]>;

  @Input() question: QuestionBase<any>;
  @Input() form: UntypedFormGroup;

  constructor(
    private translate: TranslateService,
    private _sanitizer: DomSanitizer,
    private snackbar: MatSnackBar
  ) {}

  ngOnInit() {
    // Ensure the control exists
    let control = this.form.get(this.question.key);
    if (!control) {
      control = new UntypedFormControl();
      this.form.addControl(this.question.key, control);
    }

    this.control = control;

    // Register validators
    const validators: ValidatorFn[] = [];
    const asyncValidators: AsyncValidatorFn[] = [];
    for (const validator of this.question.controls) {
      switch (validator.key) {
        case 'email':
          validators.push(Validators.email);
          break;
        case 'required':
          validators.push(Validators.required);
          break;
        case 'pattern':
          validators.push(Validators.pattern(validator.pattern));
          break;
        case 'async':
          asyncValidators.push(this.createAsyncValidator(validator));
          break;
      }

      if (validator.errors) {
        for (const errorKey of Object.keys(validator.errors)) {
          this.errorLabels[errorKey] = validator.errors[errorKey];
        }
      }
    }

    this.control.setValidators(validators);
    this.control.setAsyncValidators(asyncValidators);

    if (this.question.images) {
      this.question.picturesView = this.question.images;
    }
    this.formatDisplayTypeMime();
    this.loading = false;

    if (this.question.controlType === 'tab') {
      this.loadTabData();
    }
  }

  ngOnDestroy() {
    this.asyncValidatorsSubscriptions.unsubscribe();
  }

  loadTabData() {
    for (const tab of this.question.tabs) {
      tab.items = tab.items.map((data: any) => ({
        ...data,
        checked: this.question.value[tab.value] ? this.question.value[tab.value].includes(data.id) : false
      }));

      if (this.question.readonly) {
        tab.items = tab.items.filter((data: any) => data.checked === true);
      }
    }

    if (this.question.indexSelectedTab) {
      if (this.question.tabs[this.question.indexSelectedTab]) {
        this.indexSelectedTab = this.question.indexSelectedTab;
      }
    } else {
      const currentTabName = Object.keys(this.question.value).find(
        (tabName) => this.question.value[tabName].length > 0
      );
      if (currentTabName) {
        this.disableOtherTabs(currentTabName);

        const indexTab = this.question.tabs.findIndex((tab: any) => tab.value === currentTabName);
        if (indexTab !== -1) {
          this.indexSelectedTab = indexTab;
        }
      }
    }
  }

  ngDoCheck() {
    if (this.question && this.control) {
      if (this.question.disabled === true) {
        this.control.disable();
      } else {
        this.control.enable();
      }
    }
  }

  manageSelectedItems(tab: any, item: any) {
    if (!this.question.readonly) {
      const keys = Object.keys(this.question.value);
      if (!keys.some((searchTab) => searchTab === tab.value)) {
        this.question.value[tab.value] = [item.id];
      } else {
        const index = this.question.value[tab.value].indexOf(item.id);
        if (index === -1) {
          this.question.value[tab.value].push(item.id);
        } else {
          this.question.value[tab.value].splice(index, 1);
        }
        if (this.question.value[tab.value].length === 0) {
          delete this.question.value[tab.value];
        }
      }
      this.disableOtherTabs(tab.value);
    }
  }

  disableOtherTabs(currentTabName: string) {
    if (!this.question.options.hasOwnProperty('disableOtherTab') || !this.question.options.disableOtherTab) {
      return;
    }

    const index = this.question.tabs.findIndex((tab: any) => tab.value === currentTabName);
    if (index !== -1) {
      this.question.tabs.forEach((tab: any, i: number) => {
        if (i !== index) {
          tab.disabled = Object.keys(this.question.value).length > 0;
        }
      });
    }
  }

  applyEventTab(type: string, tab: string, item: any, e: any) {
    if (this.question.events && this.question.events[type]) {
      this.question.events[type](tab, item, e);
    }
  }

  applyEvent(type: string, e: any) {
    if (this.question.events && this.question.events[type]) {
      this.question.events[type](this.control.value, e);
    }
  }

  isValid() {
    return this.control.valid;
  }

  getErrorCount() {
    return this.control.errors ? Object.keys(this.control.errors).length : 0;
  }

  getErrorMessage() {
    const errors = this.control.errors || {};
    const errorsArray = Object.keys(errors).map((name) => {
      const errorData = {
        ...this.question,
        ...(errors[name] && typeof errors[name] === 'object' ? errors[name] : {})
      };

      return {
        name,
        text: this.translate.get(this.errorLabels[name], errorData)
      };
    });

    return errorsArray;
  }

  hasBeenTouched() {
    if (this.question.pristine === true) {
      return !this.control.pristine;
    } else {
      return true;
    }
  }

  formatDisplayTypeMime(): void {
    if (this.question.typeMime && this.question.typeMime.length > 0) {
      this.authorizedFilesFormat = this.question.typeMime.join(', ');
    }
  }

  getFileNameAndLength(event: any) {
    this.question.fileLength = 0;
    const fileErrorsList = [];
    const tabValidFiles = [];
    if (event.target.files && this.question.images !== undefined) {
      for (const index of Object.keys(event.target.files)) {
        const currentFile = event.target.files[index];
        if (this.question.typeMime && this.question.typeMime.length > 0) {
          if (this.question.typeMime.indexOf(currentFile.type) === -1) {
            fileErrorsList.push(currentFile.name);
            continue;
          }
        }
        tabValidFiles.push(currentFile);

        let url = '';
        const fileType = currentFile.type.split('/')[0];
        if (fileType === 'image') {
          url = URL.createObjectURL(currentFile);
          currentFile.url = url;
        } else {
          url = '/assets/icon/reportings/details/file.svg';
        }
        this.question.images.push({
          name: currentFile.name,
          extension: currentFile.name.substr(currentFile.name.lastIndexOf('.'), currentFile.name.length - 1),
          type: fileType,
          horodate: new Date(currentFile.lastModified).toISOString(),
          mime_type: currentFile.type,
          url: this._sanitizer.bypassSecurityTrustUrl(url),
          local: true,
          original_object: currentFile // Allow to delete the file in files input name.
        });
        this.question.picturesView = _.cloneDeep(this.question.images);
      }
      this.question.fileLength = tabValidFiles.length;
    }
    if (tabValidFiles.length > 0) {
      this.applyEvent('change', tabValidFiles);
    }
    if (fileErrorsList.length > 0) {
      this.openSnackbar(fileErrorsList);
    }
  }

  private openSnackbar(errorList: any[]): void {
    const message = `${this.translate.instant('REPORTING.ERROR.ERRORS_REPORTING_FILES')} ${errorList.join(', ')}`;
    this.snackbar.open(message, this.translate.instant('GLOBAL.CLOSE_LABEL'));
  }

  /**
   * Returns a control value accessor for a given async validator. The value
   * accessor is used for selective change detection, for example by specifying
   * which other fields will be used during validation.
   */
  private getAsyncValidatorValueAccessor(validator: IControlAsyncValidator) {
    // Standalone validator: return control value
    if (validator.standalone) {
      return (ctrl: AbstractControl) => ctrl.value;
    }

    /*
     * Non-standalone validator without specific dependencies: check every value
     * of the parent form
     */
    if (!validator.dependencies || validator.dependencies.length === 0) {
      return (ctrl: AbstractControl) => JSON.stringify(ctrl.parent!.value);
    }

    /*
     * Non-standalone validator with named dependencies: only check values of
     * said dependencies (including self)
     */
    const dependencies = [...validator.dependencies, this.question.key];

    return (ctrl: AbstractControl) => {
      const filteredValues = Object.keys(ctrl.parent!.value)
        .filter((name) => dependencies.includes(name))
        .reduce((obj: { [key: string]: any }, key) => {
          obj[key] = ctrl.parent!.value[key];

          return obj;
        }, {});

      return JSON.stringify(filteredValues);
    };
  }

  /**
   * Returns an async validator function to use with Angular native form
   * controls. It implements its own change detection strategy (definitely
   * should not) to prevent it from being triggered endlessly.
   *
   * @see asyncValidatorsStates
   * @see getAsyncValidatorValueAccessor()
   */
  private createAsyncValidator(validator: IControlAsyncValidator) {
    /*
     * Create an entry for the current validator so it can store a local state
     * and detect its own value/error pair changes.
     */
    this.asyncValidatorsStates.set(validator, null);

    // Generate a value accessor for the validator
    const getReferenceValue = this.getAsyncValidatorValueAccessor(validator);

    return (ctrl: AbstractControl) => {
      const prevState = this.asyncValidatorsStates.get(validator);
      const refValue = getReferenceValue(ctrl);

      const isFirstRun = () => prevState === null;
      const valueHasChanged = () => prevState && prevState.value !== refValue;
      if (isFirstRun() || valueHasChanged()) {
        /*
         * Register the current state. This will prevent next validation
         * occurrences to be detected as a first run or a value change.
         */
        this.asyncValidatorsStates.set(validator, { value: refValue, error: null });

        /*
         * Execute the async validation process and register the resulting
         * state for next occurrences.
         */
        this.asyncValidatorsSubscriptions.add(
          validator.fn(ctrl.value).subscribe((v) => {
            this.asyncValidatorsStates.set(validator, { value: refValue, error: v });
          })
        );
      }

      /*
       * The previous state always reflects the current state on the last
       * asynchronous validation occurrence, i.e. after stabilization.
       */
      return observableOf(prevState ? prevState.error : null);
    };
  }
}
