<form class="global-form new-report" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <app-location-form-map
    class="new-report-map"
    [pformGroup]="formGroup"
    [boundingBox]="boundingBox"
    [zoom]="isEdit ? 20 : undefined"
    [markerCoordinates]="markerCoordinates.asObservable()"
  />

  <div class="new-report-form form-gap">
    <h1>{{ titleForm | translate }}</h1>
    <h2>{{ 'REPORTING.FORM.REPORTING_SECTION' | translate }}</h2>
    <div class="form-row">
      <app-reporting-type-select class="form-field-1-2" [pformGroup]="formGroup" [groupOptions]="true" />
      <app-reporting-subtype-select class="form-field-1-2" [pformGroup]="formGroup" />
    </div>
    <button type="button" class="text-button mb-2" (click)="openTypesModal()">
      {{ 'REPORTING.BROWSE_TYPES' | translate }}
    </button>
    <div class="form-row">
      <app-select
        search
        class="form-field-1-2"
        label="{{ 'REPORTING.FORM.AFFECTED_TO' | translate }}"
        [pformGroup]="formGroup"
        [pformControlName]="'affectedToUser'"
        [options]="affectableUserOptions"
      />
      <app-select
        search
        class="form-field-1-2"
        label="{{ 'REPORTING.FORM.ON_CALL_USER' | translate }}"
        [pformGroup]="formGroup"
        [pformControlName]="'onCallUser'"
        [options]="affectableUserOptions"
      />
      <app-select
        *ngIf="!isEdit"
        search
        class="form-field-1-2"
        label="{{ 'REPORTING.FORM.SOURCE' | translate }}"
        [pformGroup]="formGroup"
        [pformControlName]="'source'"
        [options]="sourceOptions"
      />
      <app-select
        *ngIf="!isEdit"
        search
        class="form-field-1-2"
        label="{{ 'REPORTING.FORMS.WEATHER.WEATHER_WEATHER_CONDITIONS' | translate }}"
        [pformGroup]="formGroup"
        [pformControlName]="'weatherConditions'"
        [options]="weatherConditionOptions"
      />
      <app-select
        *ngIf="!isEdit"
        search
        class="form-field-1-2"
        label="{{ 'REPORTING.FORM.AFFECTED_LANE' | translate }}"
        [pformGroup]="formGroup"
        [pformControlName]="'affectedLanes'"
        [options]="affectedLaneOptions"
      />
      <app-date-input
        class="form-field-1-2"
        [pformGroup]="formGroup"
        [pformControlName]="'dueDate'"
        label="{{ 'REPORTING.FORM.DUE_DATE' | translate }}"
      />
    </div>

    <h2>{{ 'REPORTING.FORM.LOCATION_SECTION' | translate }}</h2>
    <div>
      <app-location-fields [pformGroup]="formGroup" (validPrChange)="handleValidPrChange()" />
    </div>

    <h2>{{ 'REPORTING.FORM.DESCRIPTION_SECTION' | translate }}</h2>
    <div class="form-row">
      <app-textarea
        class="form-field-1-1"
        label="{{ 'GLOBAL.DESCRIPTION' | translate }}"
        [pformGroup]="formGroup"
        [pformControlName]="'description'"
      />
    </div>

    <h2>{{ 'REPORTING.FORM.PHOTO_SECTION' | translate }}</h2>
    <div class="form-row">
      <app-file-input
        class="form-field-1-1"
        [pformGroup]="formGroup"
        [pformControlName]="'images'"
        [mimeTypes]="['image/jpeg']"
        accept="image/jpeg"
      />
    </div>
  </div>

  <div class="flex mt-2 gap-2">
    <app-submit-button [pformGroup]="formGroup" />
    <app-cancel-button [pformGroup]="formGroup" [back]="true" />
  </div>
</form>
