import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IListResult } from 'app/interface/list-result.interface';
import { IUser, UserFilters } from 'app/interface/user.interface';
import { Observable } from 'rxjs';

@Injectable()
export class UsersService {
  constructor(private http: HttpClient) {}

  /**
   * Retrieve all users as a filtered list.
   *
   * @param filters  A set of zero, one, or more generic filters amongst
   *        `search`, `sort`, `offset` and `limit`.
   * @returns An observable
   */
  getAll(filters: UserFilters = {}): Observable<IListResult<IUser>> {
    return this.http.get('api://users', { params: filters } as { params: { [key: string]: string } }) as any;
  }

  /**
   * Retrieve all users as a filtered list.
   */
  getById(
    id: string,
    filters: Partial<{
      sort: string;
      offset: string;
      limit: string;
      q: string;
    }> = {}
  ): Observable<IListResult<IUser>> {
    return this.http.get(`api://users/${id}`, { params: filters } as { params: { [key: string]: string } }) as any;
  }

  /**
   * Register a new temporary password for the given user.
   *
   * If no password is provided or if it is invalid, a random password is
   * generated. In all cases, the temporary password is sent back as the last
   * value of the returned observable.
   *
   * @param userId    The user ID.
   * @param password  A string to be used as a temporary password.
   * @returns {Observable<string>}
   * @todo Hash the generated password before sending?
   */
  // createTemporaryPassword(userId: string, password: string = ''): Observable<string> {
  //   let tmpPassword: string = password;
  //   if (!password || typeof password !== 'string') {
  //     // Generate a new password if no usable one is provided
  //     const chars = 'abcdefghijklmnopqrstuvwxyz';
  //     tmpPassword = new Array(8)
  //       .fill('')
  //       .map(() => chars[Math.floor(Math.random() * (chars.length - 1))])
  //       .join('');
  //   }

  //   // Note: this endpoint does not send any content upon success, thus we may
  //   // override it with the sent password
  //   const body = { password: tmpPassword };

  //   return this.http.patch(`api://users/${userId}/password`, body).pipe(map((): string => tmpPassword));
  // }

  /**
   *
   * @param _id
   */
  delete(_id: string): Observable<void> {
    return this.http.delete(`api://users/${_id}`) as any;
  }
}
