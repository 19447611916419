import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Permissions } from 'app/interface/permissions.enum';
import { IRolesMatrix } from 'app/interface/roles-matrix.interface';
import { Observable } from 'rxjs';

@Injectable()
export class RolesService {
  constructor(private http: HttpClient) {}

  /**
   * Retrieve all roles.
   *
   * @returns An observable of the roles matrix.
   */
  getAll(): Observable<IRolesMatrix> {
    return this.http.get('api://permissions') as any;
  }

  /**
   * Replace the roles/permissions matrix by the provided one.
   *
   * @param   replacementMatrix  The new roles/permissions matrix.
   * @returns An empty observable.
   */
  replace(replacementMatrix: IRolesMatrix): Observable<void> {
    return this.http.put('api://permissions', replacementMatrix) as any;
  }

  /**
   * Check if the user has the permission.
   *
   * @param   permission  The permission.
   * @returns a boolean.
   */
  checkPermission(permission: Permissions): boolean {
    const permissionsList = localStorage.getItem('permissions');
    if (permissionsList !== null) {
      try {
        const arrayPermissionsList = JSON.parse(permissionsList);

        return Array.isArray(arrayPermissionsList) && arrayPermissionsList.indexOf(permission) !== -1;
      } catch (e) {
        return false;
      }
    }

    return false;
  }

  public isOperator(): boolean {
    return this.checkPermission(Permissions.BACK_OFFICE_OPERATOR);
  }
}
