import {
  AuthModule,
  LogLevel,
  OpenIdConfiguration,
  StsConfigHttpLoader,
  StsConfigLoader
} from 'angular-auth-oidc-client';
import { from } from 'rxjs';
import { environment } from 'src/environments/environment';

import { Profile, ProfileService } from './services/profile.service';

const makeStsConfig = (profile: Profile): OpenIdConfiguration => {
  if (!profile.realm) {
    throw Error('Current client profile does not contain a valid realm');
  }

  const config: OpenIdConfiguration = {
    // Everything has to be parametrable from configuration
    authority: `${environment.keycloak.stsServer}/realms/${profile.realm}`, // STS with realm
    redirectUrl: environment.keycloak.redirectUrl, // where u land if auth succeed
    postLogoutRedirectUri: environment.keycloak.postLogoutRedirectUri, // where u land if logout succeed
    clientId: environment.keycloak.clientId, // Client ID used to setup access
    scope: 'openid profile email offline_access', // default scope, leave it. offline_access is required for refresh token mode
    responseType: 'code',
    logLevel: environment.production ? LogLevel.Error : LogLevel.Debug,
    autoUserInfo: false, // We don't want the users info from keycloak. It is a legacy feature
    authWellknownEndpointUrl: `${environment.keycloak.wellknownEndpoint}/realms/${profile.realm}`, // for keycloak, must be the same as stsServer with realm
    /**
     * Refresh/Renew config
     * In keycloak admin panel, refresh token mode should be manually activated:
     * Clients -> Client details -> Advanced -> OpenID Connect... -> Look for and check "Use refresh tokens"
     */
    silentRenew: true,
    useRefreshToken: true,
    ignoreNonceAfterRefresh: true, // nonce validation is failing for some reason
    renewTimeBeforeTokenExpiresInSeconds: 60
  };
  return config;
};

const getAuthConfig = async (): Promise<OpenIdConfiguration> => {
  const profile = await ProfileService.getValidProfilePromise();
  return makeStsConfig(profile);
};

export const OidcAuthModule = AuthModule.forRoot({
  loader: {
    provide: StsConfigLoader,
    useFactory: () => new StsConfigHttpLoader(from(getAuthConfig()))
  }
});
