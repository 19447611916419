export const titleCase = (value: string) => {
  const lowercased: string = String(value || '').toLowerCase();

  return (
    lowercased.charAt(0).toUpperCase() +
    lowercased
      .slice(1)
      .replace(/([^A-Za-z\u00C0-\u017F]+)(.)/g, (m) => m.slice(0, m.length - 1) + m.slice(-1).toLocaleUpperCase())
  );
};
