import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DEFAULT_PAGE_SIZE, IGenericListParameters } from 'app/components/generic/list/list.interface';
import { HighwayFilters, IHighway } from 'app/interface/highway.interface';
import { ILocation } from 'app/interface/location.interface';
import { HighwaysService } from 'app/services/highways.service';
import { LoadingService } from 'app/services/loading.service';
import { TitleService } from 'app/services/title.service';
import { from } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-highways',
  templateUrl: './highways.list.component.html',
  styleUrls: ['./highways.list.component.scss']
})
export class HighwaysListComponent implements OnInit {
  public loaded = false;
  highwaysList: IHighway[] = [];
  teamList: any;

  total = 0;
  listParameters: IGenericListParameters = {
    paginator: true,
    clickable: true,
    listHead: [
      {
        key: 'name',
        type: 'text',
        title: 'HIGHWAYS.HIGHWAY_LABEL',
        color: 'main-color-light',
        styles: { fontWeight: 'bold' }
      },
      {
        key: 'origin',
        type: 'text',
        title: 'HIGHWAYS.ORIGIN_LABEL'
      },
      {
        key: 'destination',
        type: 'text',
        title: 'HIGHWAYS.DESTINATION_LABEL'
      },
      {
        key: 'points',
        type: 'text',
        title: 'HIGHWAYS.PR_LABEL',
        options: {
          sortable: false
        }
      },
      {
        key: 'sectors',
        type: 'text',
        title: 'HIGHWAYS.SECTORS_LABEL',
        options: {
          sortable: false
        }
      }
    ]
  };

  constructor(
    private title: TitleService,
    private highway: HighwaysService,
    public loading: LoadingService,
    private router: Router,
    public dialog: MatDialog,
    iconRegistry: MatIconRegistry,
    private translate: TranslateService,
    sanitizer: DomSanitizer
  ) {
    this.title.setTitle('GLOBAL.BREADCRUMB.HIGHWAYS');
    iconRegistry.addSvgIcon('upload', sanitizer.bypassSecurityTrustResourceUrl('/assets/icon/file_upload.svg'));
  }

  async ngOnInit() {
    this.getHighwaysList({ params: { limit: DEFAULT_PAGE_SIZE, offset: 0 } });
  }

  /**
   * Retrieve or refresh the highways list and apply provided filters.
   *
   * @param event  An event object emitted by ListComponent when a refresh is
   *   required.
   */
  getHighwaysList(event?: { type?: string; data?: any; params: any }) {
    this.loaded = false;
    // Extract filters from event if available
    // Note: For each filter from the event, we must separate cases where the
    // value is undefined (keep current filter state) from those where the value
    // is empty (remove the filter)
    let filters: HighwayFilters = {};
    if (event && event.data && event.type === 'search') {
      if (event.data.search) {
        filters.q = String(event.data.search);
      } else if (event.data.search === '') {
        delete filters.q;
      }
    }

    if (event && event.params) {
      filters = { ...filters, ...event.params };
    }

    // Retrieve highway datas with the computed filters
    this.loading.on();
    from(this.highway.getAll(filters))
      .pipe(finalize(() => this.loading.off()))
      .subscribe((res) => {
        this.highwaysList = this.parseData(res.items);
        this.total = res.total;
        this.loaded = true;
      });
  }

  parseData(items: IHighway[]): any[] {
    const parsedHighwaysList = [];
    for (const item of items) {
      const numberOfSectors = Array.from(
        new Set(item.locations.map((location: ILocation) => location.sector_id))
      ).length;
      parsedHighwaysList.push({
        UID: item.UID,
        name: item.name,
        origin: item.origin,
        destination: item.destination,
        points: `${item.locations.length} ${this.translate.instant('HIGHWAYS.KILOMETER_POINT')}`,
        sectors: `${numberOfSectors} ${this.translate.instant('INSTRUCTIONS.SECTIONS')}`
      });
    }

    return parsedHighwaysList;
  }

  goToDetail(event: { data: IHighway }): void {
    this.router.navigate([`repositories/highways/${event.data.UID}`]);
  }
}
