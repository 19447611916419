import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GenericDialogComponent } from 'app/components/generic/generic-dialog/generic-dialog.component';
import { IGenericListParameters } from 'app/components/generic/list/list.interface';
import { IListResult } from 'app/interface/list-result.interface';
import { Permissions } from 'app/interface/permissions.enum';
import { IRepository } from 'app/interface/repository.interface';
import { RepositoriesService } from 'app/services/repositories.service';
import { RolesService } from 'app/services/roles.service';
import { TitleService } from 'app/services/title.service';
import dayjs from 'dayjs';

@Component({
  selector: 'app-repositories',
  templateUrl: './repositories.list.component.html',
  styleUrls: ['./repositories.list.component.scss']
})
export class RepositoriesListComponent implements OnInit {
  public loaded = false;
  repositoriesList: IRepository[] = [];
  loading = false;
  counter = 0;

  // TODO Check permissions to determine visible buttons
  listParameters: IGenericListParameters = {
    paginator: true,
    clickable: true,
    listHead: [
      {
        key: 'repository_name',
        type: 'text',
        title: 'REPOSITORIES.REPOSITORY_NAME',
        cols: 2,
        options: {
          icon: {
            key: 'icon',
            class: 'icon_repositories'
          }
        }
      },
      {
        key: 'repository_nb_items',
        type: 'text',
        title: 'GLOBAL.DETAILS',
        cols: 1
      },
      {
        key: 'last_import',
        title: 'GLOBAL.LAST_IMPORT',
        type: 'date',
        options: { format: 'fullDate', sortable: false }
      },
      {
        key: 'actions',
        type: 'button',
        colWidth: '80px',
        options: { sortable: false }
      }
    ]
  };

  importButton = {
    type: 'button',
    style: { color: 'warn' },
    events: { click: 'import' },
    options: { icon: 'file_upload' },
    tooltip: 'GLOBAL.IMPORT'
  };
  exportButton = {
    type: 'button',
    style: { color: 'warn' },
    events: { click: 'export' },
    options: { icon: 'file_download' },
    tooltip: 'GLOBAL.DOWNLOAD'
  };
  dialogRef?: MatDialogRef<GenericDialogComponent>;

  constructor(
    private http: HttpClient,
    private title: TitleService,
    private router: Router,
    private repositories: RepositoriesService,
    public dialog: MatDialog,
    public translate: TranslateService,
    private rolesService: RolesService
  ) {}

  async ngOnInit() {
    await this.updateRepositories();
  }

  async updateRepositories() {
    this.repositoriesList = await this.getRepositories();
  }

  detailsRepository(row: { url: string }) {
    // TODO Check permissions
    this.router.navigateByUrl(`repositories${row.url}`);
  }

  importRepository(row: IRepository) {
    // TODO Checker les droits
    if (row.url) {
      this.openImportDialog(row.url);
    }
  }

  exportRepository(row: IRepository) {
    const downloadDate = dayjs(row.last_import).format('YYYYmmDDHHMMSS');
    const repositoryName = this.translate.instant(row.repository_name);
    this.repositories.downloadCSV(row.repository, `${downloadDate}-${repositoryName}`);
  }

  getEvent(event: { type: string; data: any }) {
    if (event.type && event.data) {
      switch (event.type) {
        case 'import':
          this.importRepository(event.data);
          break;
        case 'export':
          this.exportRepository(event.data);
          break;
        case 'rowClick':
          this.detailsRepository(event.data);
          break;
      }
    }
  }

  openImportDialog(target: string): void {
    const title = target.replace('/', '').toUpperCase();
    this.dialogRef = this.dialog.open(GenericDialogComponent, {
      width: '450px',
      height: '250px',
      data: {
        hideCancelButtonOnSubmit: true,
        structure: {
          title: this.translate.instant(`IMPORT.IMPORT_${title}`)
        },
        content: {
          endpoint: `/imports${target}`,
          method: 'post',
          type: 'file',
          button: {
            text: this.translate.instant('GLOBAL.IMPORT'),
            class: 'validate'
          }
        }
      },
      disableClose: true
    });

    const subscription = this.dialogRef.beforeClosed().subscribe(async () => {
      await this.updateRepositories();
      subscription.unsubscribe();
    });
  }

  async getImports(): Promise<{ [key: string]: string }> {
    const url = 'api://imports';
    const result: { [key: string]: string } = {};
    const rawValues: { items: any[] } = (await this.http.get(url).toPromise()) as IListResult<any>;
    if (rawValues && rawValues.items) {
      for (const importObject of rawValues.items) {
        result[importObject.type] = importObject.updatedAt;
      }
    }

    return result;
  }

  async getDetails(repository: string): Promise<any> {
    const filters = {
      limit: '1'
    };
    const url = `api://${repository}`;

    return new Promise((resolve, reject) => {
      this.http.get(url, { params: filters }).subscribe(
        (response) => resolve(response),
        (err) => reject(err)
      );
    });
  }

  async getRepositories(): Promise<IRepository[]> {
    const isSuperAdmin = this.rolesService.checkPermission(Permissions.BACK_OFFICE_SUPER_ADMIN);
    const isSuperAdminVR =
      isSuperAdmin && this.rolesService.checkPermission(Permissions.BACK_OFFICE_CREATE_SUPER_ADMIN);
    const isDevMode = localStorage.getItem('devMode') !== null;
    this.loaded = false;
    const rawQuantity = await Promise.all([
      this.getDetails('/users'),
      this.rolesService.checkPermission(Permissions.BACK_OFFICE_PERMISSIONS)
        ? this.getDetails('/permissions')
        : Promise.resolve([]),
      this.getDetails('/teams'), // To update once each import is defined
      this.getDetails('/highways'),
      this.getDetails('/crossing-points'),
      this.getDetails('/infrastructures'),
      this.getDetails('/flu-flr/count'),
      this.getDetails('/flu-flr/count'),
      this.getDetails('/reporting-types'),
      this.getDetails('/reporting-subtypes'),
      this.getDetails('/reporting-event-types'),
      this.getDetails('/infrastructure-types')
    ]);
    const quantity = [
      rawQuantity[0].total,
      Object.keys(rawQuantity[1]).filter((k) => !['created_at', 'updated_at'].includes(k)).length,
      rawQuantity[2].total,
      rawQuantity[3].total,
      rawQuantity[4].total,
      rawQuantity[5].total,
      rawQuantity[6].FLU,
      rawQuantity[7].FLR,
      rawQuantity[8].total,
      rawQuantity[9].total,
      rawQuantity[10].total,
      rawQuantity[11].total,
      0 // profile
    ];

    const hasPermissionImport = this.rolesService.checkPermission(Permissions.BACK_OFFICE_IMPORTS);
    const lastImports = hasPermissionImport ? await this.getImports() : {};

    let repositoriesList = [
      {
        icon: 'person',
        repository_name: 'USERS.MENU_ITEM',
        repository: 'users',
        repository_nb_items: `${quantity[0] || 0} ${this.translate.instant('GLOBAL.USERS')}`,
        last_import: lastImports.users,
        url: '/users',
        actions: [this.exportButton]
      },
      {
        icon: 'face',
        repository_name: 'USERS.ROLES',
        repository: 'roles',
        repository_nb_items: `${quantity[1] || 0} ${this.translate.instant('GLOBAL.ROLES')}`,
        last_import: rawQuantity[1].updated_at,
        url: '/roles',
        actions: []
      },
      {
        icon: 'people',
        repository_name: 'REPOSITORIES.TEAMS',
        repository: 'teams',
        repository_nb_items: `${quantity[2] || 0} ${this.translate.instant('GLOBAL.TEAMS')}`,
        last_import: lastImports.teams,
        url: '/teams',
        actions: hasPermissionImport ? [this.importButton, this.exportButton] : [this.exportButton]
      },
      {
        icon: 'directions_car',
        repository_name: 'GLOBAL.HIGHWAYS_P',
        repository: 'highways',
        repository_nb_items: `${quantity[3] || 0} ${this.translate.instant('GLOBAL.HIGHWAY')}`,
        last_import: lastImports.highways,
        url: '/highways',
        actions: hasPermissionImport ? [this.importButton, this.exportButton] : [this.exportButton]
      },
      {
        icon: 'place',
        repository_name: 'USERS.CROSSING_POINTS',
        repository: 'crossing-points',
        repository_nb_items: `${quantity[4] || 0} ${this.translate.instant('GLOBAL.POINTS')}`,
        last_import: lastImports['crossing-points'],
        url: '/crossing-points',
        actions: hasPermissionImport ? [this.importButton, this.exportButton] : [this.exportButton]
      },
      {
        icon: 'map',
        repository_name: 'USERS.INFRASTRUCTURE',
        repository: 'infrastructures',
        repository_nb_items: `${quantity[5] || 0} ${this.translate.instant('GLOBAL.STUFF')}`,
        last_import: lastImports.infrastructures,
        url: '/infrastructures',
        actions: hasPermissionImport ? [this.importButton, this.exportButton] : [this.exportButton]
      },
      {
        icon: 'content_paste',
        repository_name: 'USERS.FLU',
        repository: 'flu',
        repository_nb_items: `${quantity[6] || 0} ${this.translate.instant('GLOBAL.ELEMENTS')}`,
        last_import: lastImports.flu,
        url: '/flu',
        actions: hasPermissionImport ? [this.importButton, this.exportButton] : [this.exportButton]
      },
      {
        icon: 'content_paste',
        repository_name: 'USERS.FLR',
        repository: 'flr',
        repository_nb_items: `${quantity[7] || 0} ${this.translate.instant('GLOBAL.ELEMENTS')}`,
        last_import: lastImports.flr,
        url: '/flr',
        actions: hasPermissionImport ? [this.importButton, this.exportButton] : [this.exportButton]
      },
      {
        icon: 'content_paste',
        repository_name: 'REPOSITORIES.REPORTING_TYPES',
        repository: 'reporting-types',
        repository_nb_items: `${quantity[8] || 0} ${this.translate.instant('GLOBAL.TYPES')}`,
        url: '/reporting-types',
        actions: []
      },
      {
        icon: 'content_paste',
        repository_name: 'REPOSITORIES.REPORTING_SUBTYPES',
        repository: 'reporting-subtypes',
        repository_nb_items: `${quantity[9] || 0} ${this.translate.instant('GLOBAL.TYPES')}`,
        url: '/reporting-subtypes',
        actions: []
      },
      isSuperAdmin && {
        icon: 'content_paste',
        repository_name: 'REPOSITORIES.REPORTING_EVENT_TYPES',
        repository: 'reporting-event-types',
        repository_nb_items: `${quantity[10] || 0} ${this.translate.instant('GLOBAL.TYPES')}`,
        url: '/reporting-event-types',
        actions: []
      },
      isSuperAdmin && {
        icon: 'content_paste',
        repository_name: 'REPOSITORIES.INFRASTRUCTURE_TYPES',
        repository: 'infrastructure-types',
        repository_nb_items: `${quantity[11] || 0} ${this.translate.instant('GLOBAL.TYPES')}`,
        url: '/infrastructure-types',
        actions: []
      },
      isSuperAdminVR &&
        isDevMode && {
          icon: 'content_paste',
          repository_name: 'REPOSITORIES.PROFILES',
          repository: 'profiles',
          repository_nb_items: '',
          url: '/profiles',
          actions: []
        }
    ].filter((e) => e) as any[];

    this.title.setTitle('GLOBAL.REPOSITORIES');
    if (!this.rolesService.checkPermission(Permissions.BACK_OFFICE_PERMISSIONS)) {
      repositoriesList = repositoriesList.filter((l: any) => l.repository !== 'roles');
    }

    this.loaded = true;

    return repositoriesList;
  }
}
