<div *ngIf="active" id="loader" ngClass="{{ className }}">
  <div class="spinner">
    <div class="circle">
      <div class="circle1 child"></div>
      <div class="circle2 child"></div>
      <div class="circle3 child"></div>
      <div class="circle4 child"></div>
      <div class="circle5 child"></div>
      <div class="circle6 child"></div>
      <div class="circle7 child"></div>
      <div class="circle8 child"></div>
      <div class="circle9 child"></div>
      <div class="circle10 child"></div>
      <div class="circle11 child"></div>
      <div class="circle12 child"></div>
    </div>
  </div>
  <div *ngIf="showText" class="text" translate>GLOBAL.LOADING</div>
</div>
