import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HighwayFilters, IHighway, KilometerPointFilter } from 'app/interface/highway.interface';
import { IListResult } from 'app/interface/list-result.interface';
import { ILocation } from 'app/interface/location.interface';
import _ from 'lodash';
import { lastValueFrom, Observable } from 'rxjs';

interface HighwayStoreItem {
  id: string;
  promise: Promise<IHighway>;
}

@Injectable()
export class HighwaysService {
  constructor(private http: HttpClient) {}

  private static highwayStore: HighwayStoreItem[] = [];

  private static getOneFromStore(id: string): Promise<IHighway> | null {
    return this.highwayStore.find((e) => e.id === id)?.promise || null;
  }

  private static addToStore(items: HighwayStoreItem[]): void {
    this.highwayStore = _.uniqBy([...items, ...this.highwayStore], (e) => e.id);
  }

  /**
   * Retrieve all highways as a filtered list.
   *
   * @param filters  A set of zero, one, or more generic filters amongst
   *        `search`, `sort`, `offset` and `limit`.
   * @returns An observable
   */
  public async getAll(filters: HighwayFilters = {}): Promise<IListResult<IHighway>> {
    const res = await lastValueFrom(
      this.http.get<IListResult<IHighway>>('api://highways', { params: filters } as {
        params: { [key: string]: string };
      })
    );
    /**
     * Today its bad but we rely on highways in store having their locations set
     * Uncomment next line when we don't need those anymore
     */
    // HighwaysService.addToStore(res.items.map((e) => ({ id: e.UID, promise: Promise.resolve(e) })));
    return res;
  }

  /**
   * Retrieve all highways of selected sectors.
   *
   * @param params  A set of zero, one, or more generic filters amongst
   *        `search`, `sort`, `offset`, `limit`, `fields`, `q`, `filters`.
   * @returns An observable
   */
  getHighwaysBySectors(
    params: {
      sector_id?: string[];
    } = {}
  ): Observable<IListResult<IHighway>> {
    return this.http.get('api://highways', { params: params } as { params: { [key: string]: string } }) as any;
  }

  /**
   * Retrieve a highway by is UID.
   *
   * @param UID Unique ID of the highway to get
   * @returns An observable
   */
  async get(id: string, forceFetch: boolean = false): Promise<IHighway> {
    if (!forceFetch) {
      const highwayPromise = HighwaysService.getOneFromStore(id);
      if (highwayPromise) {
        return highwayPromise;
      }
    }
    const highwayPromise = lastValueFrom(this.http.get<IHighway>(`api://highways/${id}`));
    HighwaysService.addToStore([{ id, promise: highwayPromise }]);
    return highwayPromise;
  }

  /**
   * Retrieve the kilometer points of a higway by is UID.
   *
   * @param UID Unique ID of the highway to get
   * @returns An observable
   */
  getKilometerPoints(UID: string, filters: KilometerPointFilter = {}): Observable<IListResult<ILocation>> {
    return this.http.get(`api://highways/${UID}/locations`, { params: filters } as any) as any;
  }
}
