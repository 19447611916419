<div *ngIf="loaded === true" class="map-card">
  <div class="title">{{ 'GLOBAL.MAP' | translate }}</div>
  <app-map
    class="map"
    [markers]="list"
    [center]="center"
    [zoom]="20"
    [fullscreenControl]="false"
    [streetViewControl]="false"
  />
</div>
