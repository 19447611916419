<h1 class="mainTitle">{{ 'TEAMS.LIST_PAGE_NAME' | translate }}</h1>
<app-list
  [listParameters]="listParameters"
  [items]="loadedList"
  [total]="total"
  [loaded]="loaded"
  searchType="dynamic"
  (refresh)="getTeamsList($event)"
>
</app-list>
