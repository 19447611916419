<div class="InfrastructureManager" *ngIf="isMapsApiLoaded">
  <div class="filters">
    <span class="title">
      {{ 'GLOBAL.BREADCRUMB.INFRASTRUCTURES.MANAGER' | translate }}
    </span>

    <div class="fill"></div>

    <!-- Filter data loading -->
    <app-loading *ngIf="!areFilterDataLoaded" className="small" [showText]="false" />
    <ng-container *ngIf="areFilterDataLoaded">
      <!-- Infrastructure data loading -->
      <app-loading *ngIf="isLoadingData" className="small" [showText]="false" />
      <!-- Element count -->
      <div class="count">
        <ng-container *ngIf="infrastructureLimit && infrastructureLimit < infrastructureTotalCount">
          {{ infrastructureLimit }} /
        </ng-container>
        {{ infrastructureTotalCount }} {{ 'INFRASTRUCTURES.MANAGER.ELEMENTS' | translate }}
      </div>
      <!-- Limit select -->
      <mat-form-field>
        <mat-select
          placeholder="{{ 'INFRASTRUCTURES.MANAGER.DATA_LIMIT' | translate }}"
          [(ngModel)]="infrastructureLimit"
        >
          <mat-option *ngFor="let limit of limitOptions" [value]="limit">
            {{ limit }} {{ 'INFRASTRUCTURES.MANAGER.ELEMENTS' | translate }}
          </mat-option>
          <mat-option value="all">
            {{ 'INFRASTRUCTURES.MANAGER.LOAD_ALL_ELEMENTS' | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <!-- Sector filter -->
      <mat-form-field>
        <mat-select
          placeholder="{{ 'INFRASTRUCTURES.MANAGER.SECTOR_FILTER' | translate }}"
          [(ngModel)]="activeSectorIds"
          multiple
        >
          <div
            class="mat-option h-auto"
            [ngClass]="{ 'mat-selected': areAllSectorsActive() }"
            (click)="handleAllSectorsClick($event)"
          >
            {{ 'GLOBAL.TOGGLE_SELECT' | translate }}
          </div>
          <mat-option *ngFor="let sector of sectors" [value]="sector.id">{{ sector.name | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
      <!-- Highway filter -->
      <mat-form-field>
        <mat-select
          placeholder="{{ 'INFRASTRUCTURES.MANAGER.MY_HIGHWAY_FILTER' | translate }}"
          [(ngModel)]="activeHighwayIds"
          multiple
        >
          <div
            class="mat-option h-auto"
            [ngClass]="{ 'mat-selected': areAllMyHighwaysActive() }"
            (click)="handleAllHighwaysClick($event)"
          >
            {{ 'GLOBAL.TOGGLE_SELECT' | translate }}
          </div>
          <mat-option *ngFor="let highway of myHighways" [value]="highway.UID">{{ highway.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
  </div>

  <div class="h-full w-full flex">
    <!-- Infrastructure type filter -->
    <div class="types-container">
      <app-loading *ngIf="!areFilterDataLoaded" className="small" [showText]="false" />
      <ng-container *ngIf="areFilterDataLoaded">
        <div
          class="typeItem all"
          [key]="all"
          [ngClass]="{ active: areAllInfrastructureTypesActive() }"
          title="{{ Salut }}"
          (click)="handleAllInfrastructureTypeClick()"
        >
          {{ 'INFRASTRUCTURES.MANAGER.ALL_TYPES_ICON' | translate }}
        </div>
        <div
          class="typeItem"
          *ngFor="let infrastructureType of infrastructureTypes"
          [key]="infrastructureType.id"
          [ngClass]="{ active: isInfrastructureTypeActive(infrastructureType.id) }"
          (click)="handleInfrastructureTypeClick(infrastructureType.id)"
        >
          <img [src]="infrastructureType.picto" />
        </div>
      </ng-container>
    </div>

    <!-- Map -->
    <div class="map">
      <google-map
        width="100%"
        height="100%"
        [options]="{
          zoom: initialZoom,
          center: initialPosition,
          mapTypeControl: true,
          disableDoubleClickZoom: true
        }"
        (mapClick)="handleMapClick($event)"
        (mapDblclick)="handleMapDblclick($event)"
        (zoomChanged)="handleZoomChange($event)"
      >
        <map-marker-clusterer
          [options]="{
            averageCenter: true,
            maxZoom: 11,
            imagePath:
              'https://raw.githubusercontent.com/googlemaps/v3-utility-library/master/markerclustererplus/images/m'
          }"
        >
          <map-marker
            *ngFor="let infrastructure of infrastructures; let i = index"
            [key]="infrastructure.id"
            [options]="{
              position: {
                lat: infrastructure.coordinates.latitude,
                lng: infrastructure.coordinates.longitude
              },
              title: infrastructure.name,
              icon: infrastructure.type.pin,
              draggable: isEditingInfrastructure(infrastructure._id)
            }"
            (mapClick)="handleInfrastructureMarkerClick($event, infrastructure._id)"
            (mapDblclick)="handleInfrastructureMarkerDblclick($event, infrastructure._id)"
            (mapDragend)="handleInfrastructureMarkerDragend(infrastructure._id, $event)"
          />
        </map-marker-clusterer>

        <!-- Green circle, indicating that the marker is selected for edition -->
        <map-circle
          *ngIf="editingInfrastructureId"
          [center]="{
            lat: infrastructures[editingInfrastructureIndex].coordinates.latitude,
            lng: infrastructures[editingInfrastructureIndex].coordinates.longitude
          }"
          [options]="{
            fillColor: '#26a69a',
            fillOpacity: '0.35',
            strokeColor: '#26a69a',
            strokeWeight: 3,
            radius: 20,
            clickable: false,
            draggable: false,
            editable: false
          }"
          (circleClick)="handleInfrastructureMarkerDblclick($event, infrastructure._id)"
        />

        <map-info-window (closeclick)="(true)">
          <app-infrastructure-info-window
            *ngIf="tooltipInfrastructureIndex !== -1"
            [infrastructure]="infrastructures[tooltipInfrastructureIndex]"
            [showDelete]="true"
            (editInfrastructure)="handleEditInfrastructure($event)"
            (deleteInfrastructure)="handleDeleteInfrastructure($event)"
          />
        </map-info-window>
      </google-map>
    </div>
  </div>
</div>
