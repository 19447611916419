<ng-container *ngIf="report !== undefined">
  <div *ngIf="report !== undefined" class="report-detail">
    <div class="left-cards">
      <div class="top">
        <div class="left">
          <div class="report-detail-location tile">
            <app-location-card [(report)]="report" (createdEvent)="handleCreatedEvent($event)" />
          </div>

          <div class="report-detail-lanes tile">
            <app-lanes-card [location]="report.location" [lanes]="report.details.lanes" />
          </div>
        </div>

        <div class="report-detail-damages tile">
          <app-damages-card [details]="report.details" [reportId]="report._id" />
        </div>
      </div>
      <div class="bottom">
        <div class="report-detail-map tile">
          <app-map-card [report]="report" />
        </div>

        <div class="report-detail-pictures tile">
          <app-pictures-card [reportid]="report._id" [pictures]="report.medias" />
        </div>
      </div>
    </div>
    <div class="report-detail-operations tile">
      <app-operations-card
        [reportingId]="report._id"
        [reportingEvents]="report.events"
        (createdEvent)="handleCreatedEvent($event)"
        (deletedEvent)="handleDeletedEvent($event)"
        [reportState]="report.state"
      />
    </div>
  </div>
</ng-container>
