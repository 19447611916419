<div class="operations-card">
  <div class="title flex gap-2">
    {{ 'REPORTING.OPERATIONS_CARD.TITLE' | translate }}

    <div *ngIf="canAddOperations()" class="ml-auto">
      <button
        class="unset-size-button small"
        mat-raised-button
        matTooltip="{{ 'GLOBAL.ADD' | translate }}"
        matTooltipPosition="above"
        color="warn"
        (click)="handleAddEventClick()"
      >
        <mat-icon class="m-3px">add</mat-icon>
      </button>
    </div>
  </div>
  <div *ngIf="reportingEvents" class="operations-view">
    <div class="empty" *ngIf="!reportingEvents.length">{{ 'GLOBAL.NO_OPERATION' | translate }}</div>
    <ul class="operations-list">
      <li
        *ngFor="let event of reportingEvents"
        class="operations-list-item"
        [ngClass]="{
          operator: event.createdByOperator,
          deleted: event.deletedAt
        }"
      >
        <button
          *ngIf="canAddOperations() && myUserId && myUserId === event.createdById && !event.deletedAt && event.type.name !== 'external'"
          class="right unset-size-button small"
          matTooltip="{{ 'GLOBAL.EDIT' | translate }}"
          matTooltipPosition="above"
          (click)="handleEditEventClick(event)"
        >
          <mat-icon class="m-3px">edit</mat-icon>
        </button>

        <img src="{{ event.type.picto }}" class="operations-list-item-icon" />

        <time class="operations-list-item-meta" dateTime="{{ event.date | date: 'yyyy-MM-ddTHH:mm:ss.SSSZ' }}">
          {{ event.date | date: 'mediumDate' }} - {{ event.date | date: 'shortTime' }}
        </time>
        <h3 class="operations-list-item-type">
          {{ event.type.label }} {{ event.payload.interlocutor }} {{ event.payload.intervenor }}
        </h3>
        <p *ngIf="event.comment" class="operations-list-item-comment">
          {{ event.comment }}
        </p>
      </li>
    </ul>
  </div>
</div>
