<h1 mat-dialog-title>{{ 'DRIVING_LOGS.LOGOUT_VALIDATION.DIALOG_TITLE' | translate }}</h1>

<div class="dialog-padding">
  <app-pdf-viewer
    [blob]="blob"
    [filename]="filename"
    [heightPadding]="{ dialogTitle: true, dialogMargin: true, dialogPadding: true, dialogActions: true }"
  />
</div>

<mat-dialog-actions class="dialog-buttons">
  <!-- Buttons -->
  <button type="button" mat-raised-button color="warn" (click)="handleConfirmClick()">
    {{ 'DRIVING_LOGS.LOGOUT_VALIDATION.VALIDATE_AND_LOGOUT' | translate }}
  </button>
  <button type="button" mat-button (click)="cancel()">
    {{ 'GLOBAL.CANCEL' | translate }}
  </button>
</mat-dialog-actions>
