<h1 *ngIf="isLoaded" mat-dialog-title>{{ data.title | translate }}</h1>

<mat-dialog-content class="filter-dialog-content" *ngIf="isLoaded">
  <!-- DATE FILTERS -->
  <div class="row">
    <div class="w100">
      <h2>
        {{ 'GLOBAL.DIALOG.DATE_AND_LOCALISATION' | translate }}
        <small>
          <em>{{ 'GLOBAL.DIALOG.OPTIONAL' | translate }}</em>
        </small>
      </h2>
    </div>
  </div>
  <div class="row dateFilters">
    <div class="row w100">
      <form [formGroup]="datesForm">
        <mat-form-field>
          <mat-label>{{ 'GLOBAL.DIALOG.START' | translate }}</mat-label>
          <input matInput [matDatepicker]="startDate" formControlName="start" />
          <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
          <mat-datepicker #startDate></mat-datepicker>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ 'GLOBAL.DIALOG.END' | translate }}</mat-label>
          <input matInput [matDatepicker]="endDate" formControlName="end" />
          <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
          <mat-datepicker #endDate></mat-datepicker>
        </mat-form-field>
      </form>

      <form [formGroup]="highwayDetailsForm" *ngIf="this.highwayListLoaded">
        <mat-form-field>
          <mat-label>{{ 'GLOBAL.HIGHWAY_FILTER' | translate }}</mat-label>
          <mat-select formControlName="highwayForm" multiple (valueChange)="onHighwaySelectionChange($event)">
            <div class="clean h-auto mat-mdc-option" (click)="toggleList('highway')">
              {{ 'GLOBAL.TOGGLE_SELECT' | translate }}
            </div>
            <mat-option *ngFor="let highway of highwaysList" [value]="highway.UID">
              {{ highway.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ 'GLOBAL.DIRECTION' | translate }}</mat-label>
          <mat-select formControlName="direction" multiple (valueChange)="onDirectionSelectionChange($event)">
            <div class="clean h-auto mat-mdc-option" (click)="toggleList('direction')">
              {{ 'GLOBAL.TOGGLE_SELECT' | translate }}
            </div>
            <mat-option *ngFor="let direction of directionOptions" [value]="direction.value.key">
              {{ direction.value.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="this.isPrDisable()">
          <mat-label>{{ 'GLOBAL.PR_START' | translate }}</mat-label>
          <input matInput formControlName="startPr" />
          <mat-error>{{ 'GLOBAL.ERROR.PATTERN' | translate: { hint: '1+200' } }}</mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="this.isPrDisable()">
          <mat-label>{{ 'GLOBAL.PR_END' | translate }}</mat-label>
          <input matInput formControlName="endPr" />
          <mat-error>{{ 'GLOBAL.ERROR.PATTERN' | translate: { hint: '1+200' } }}</mat-error>
        </mat-form-field>
      </form>
      <div class="loading-highway-message" *ngIf="!this.highwayListLoaded">
        {{ 'HIGHWAYS.LOADING_FILTERS' | translate }}
      </div>
    </div>
  </div>

  <div class="grey">
    <div class="row">
      <div class="w50">
        <h2>{{ 'GLOBAL.DIALOG.URGENT_P' | translate }}</h2>

        <mat-checkbox
          [(ngModel)]="priorityCheckboxes[ReportingTypePriority.RED]"
          (ngModelChange)="toggleSelectAll(ReportingTypePriority.RED)"
          disableRipple="true"
          class="high"
        >
          {{ 'GLOBAL.DIALOG.ALL_TYPES' | translate }}
        </mat-checkbox>

        <div class="reportingTypeRow">
          <div
            *ngFor="let reportingType of reportingTypes | dynamicFilter: { priority: 'RED' }"
            class="reportingType"
            [ngClass]="{
              active: isActiveFilter(reportingType.id)
            }"
          >
            <button
              class="reportingType-button flex flex-col items-center cursor-pointer"
              (click)="toggleReportingTypeFilter(reportingType.id)"
            >
              <img
                [src]="reportingType.picto"
                matTooltip="{{ reportingType.subtypes.length ? ('GLOBAL.SELECT_A_SUBTYPE' | translate) : undefined }}"
                matTooltipPosition="above"
              />
              <label class="cursor-pointer">{{ reportingType.label }}</label>
            </button>

            <mat-form-field *ngIf="reportingType.subtypes.length" class="w-full">
              <mat-select
                multiple
                placeholder="{{ 'GLOBAL.SUBTYPE' | translate }}"
                panelClass="min-w-200px"
                [(ngModel)]="subtypeSelectModelByType[reportingType.id]"
                (selectionChange)="handleSubtypeSelectChange(reportingType.id, $event)"
              >
                <mat-option
                  class="clean h-auto"
                  [value]="allSubtypeToggle[reportingType.id]"
                  (click)="toggleReportingTypeFilter(reportingType.id)"
                >
                  {{ 'GLOBAL.TOGGLE_SELECT' | translate }}
                </mat-option>
                <mat-option *ngFor="let reportingSubtype of reportingType.subtypes" [value]="reportingSubtype.id">
                  {{ reportingSubtype.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="w50">
        <h2>{{ 'GLOBAL.DIALOG.PRIORITIES' | translate }}</h2>

        <mat-checkbox
          [(ngModel)]="priorityCheckboxes[ReportingTypePriority.ORANGE]"
          (ngModelChange)="toggleSelectAll(ReportingTypePriority.ORANGE)"
          disableRipple="true"
          class="medium"
        >
          {{ 'GLOBAL.DIALOG.ALL_TYPES' | translate }}
        </mat-checkbox>
        <div class="reportingTypeRow">
          <div
            *ngFor="let reportingType of reportingTypes | dynamicFilter: { priority: 'ORANGE' }"
            class="reportingType"
            [ngClass]="{
              active: isActiveFilter(reportingType.id)
            }"
          >
            <button
              class="reportingType-button flex flex-col items-center cursor-pointer"
              (click)="toggleReportingTypeFilter(reportingType.id)"
            >
              <img
                [src]="reportingType.picto"
                matTooltip="{{ reportingType.subtypes.length ? ('GLOBAL.SELECT_A_SUBTYPE' | translate) : undefined }}"
                matTooltipPosition="above"
              />
              <label class="cursor-pointer">{{ reportingType.label }}</label>
            </button>

            <div class="fill"></div>

            <mat-form-field *ngIf="reportingType.subtypes.length" class="w-full">
              <mat-select
                multiple
                placeholder="{{ 'GLOBAL.SUBTYPE' | translate }}"
                panelClass="min-w-200px"
                [(ngModel)]="subtypeSelectModelByType[reportingType.id]"
                (selectionChange)="handleSubtypeSelectChange(reportingType.id, $event)"
              >
                <mat-option
                  class="clean h-auto"
                  [value]="allSubtypeToggle[reportingType.id]"
                  (click)="toggleReportingTypeFilter(reportingType.id)"
                >
                  {{ 'GLOBAL.TOGGLE_SELECT' | translate }}
                </mat-option>
                <mat-option *ngFor="let reportingSubtype of reportingType.subtypes" [value]="reportingSubtype.id">
                  {{ reportingSubtype.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="w100">
      <h2>SECONDAIRES</h2>

      <mat-checkbox
        [(ngModel)]="priorityCheckboxes[ReportingTypePriority.BLUE]"
        (ngModelChange)="toggleSelectAll(ReportingTypePriority.BLUE)"
        disableRipple="true"
        class="normal"
      >
        {{ 'GLOBAL.DIALOG.ALL_TYPES' | translate }}
      </mat-checkbox>

      <div class="row">
        <div class="reportingTypeRow w50 nopadding">
          <div
            *ngFor="let reportingType of reportingTypes | dynamicFilter: { priority: 'BLUE' }"
            class="reportingType"
            [ngClass]="{
              active: isActiveFilter(reportingType.id)
            }"
          >
            <button
              class="reportingType-button flex flex-col items-center cursor-pointer"
              (click)="toggleReportingTypeFilter(reportingType.id)"
            >
              <img
                [src]="reportingType.picto"
                matTooltip="{{ reportingType.subtypes.length ? ('GLOBAL.SELECT_A_SUBTYPE' | translate) : undefined }}"
                matTooltipPosition="above"
              />
              <label class="cursor-pointer">{{ reportingType.label }}</label>
            </button>

            <div class="fill"></div>

            <mat-form-field *ngIf="reportingType.subtypes.length" class="w-full">
              <mat-select
                multiple
                placeholder="{{ 'GLOBAL.SUBTYPE' | translate }}"
                panelClass="min-w-200px"
                [(ngModel)]="subtypeSelectModelByType[reportingType.id]"
                (selectionChange)="handleSubtypeSelectChange(reportingType.id, $event)"
              >
                <mat-option
                  class="clean h-auto"
                  [value]="allSubtypeToggle[reportingType.id]"
                  (click)="toggleReportingTypeFilter(reportingType.id)"
                >
                  {{ 'GLOBAL.TOGGLE_SELECT' | translate }}
                </mat-option>
                <mat-option *ngFor="let reportingSubtype of reportingType.subtypes" [value]="reportingSubtype.id">
                  {{ reportingSubtype.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="w50">
          <div class="status">
            <strong>{{ 'GLOBAL.DIALOG.STATUS_P' | translate }} : </strong>
            <br />
            <div class="flex flex-wrap gap-x-4">
              <mat-checkbox
                *ngFor="let item of reportingStates"
                [value]="item.id"
                (change)="updateStatus(item.id, $event)"
                [checked]="activatedFilters.state.includes(item.id)"
              >
                {{ item.label | translate }}
              </mat-checkbox>
              <button mat-button (click)="toggleStatesList()">
                {{ 'GLOBAL.TOGGLE_SELECT' | translate }}
              </button>
            </div>
          </div>
          <br />
          <strong>{{ 'HIGHWAYS.ORIGIN_LABEL' | translate }} : </strong>
          <br />
          <mat-checkbox [(ngModel)]="knownAccidentAssociation">
            {{ 'GLOBAL.DIALOG.KNOWN_ACCIDENT_ASSOCIATION' | translate }}
          </mat-checkbox>
          <br />
          <mat-checkbox [(ngModel)]="unknownAccidentAssociation">
            {{ 'GLOBAL.DIALOG.UNKNOWN_ACCIDENT_ASSOCIATION' | translate }}
          </mat-checkbox>
          <br />
          <mat-checkbox [(ngModel)]="noAccidentAssociation">
            {{ 'GLOBAL.DIALOG.NO_ACCIDENT_ASSOCIATION' | translate }}
          </mat-checkbox>
          <br />
          <button mat-button (click)="toggleOriginsList()">
            {{ 'GLOBAL.TOGGLE_SELECT' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions *ngIf="isLoaded" class="dialog-buttons">
  <!-- Button -->
  <button mat-raised-button (click)="validateDialog()" class="dialog-button bold validation">
    <span>{{ 'GLOBAL.SUBMIT_BUTTON_LABEL' | translate }}</span>
  </button>
  <!-- Button -->
  <button mat-button (click)="closeDialog()" class="dialog-button bold cancel">
    <span>{{ 'GLOBAL.CANCEL' | translate }}</span>
  </button>
</mat-dialog-actions>
