import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ContentChild,
  Host,
  Input,
  Optional,
  QueryList,
  TemplateRef,
  ViewChildren
} from '@angular/core';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatOption, MatOptionModule } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingComponent } from 'src/app/components/generic/loading/loading.component';

import { SelectOptionOrGroup, SelectOptionTemplateDirective } from './select.component';

@Component({
  standalone: true,
  selector: 'app-select-options',
  imports: [CommonModule, LoadingComponent, TranslateModule, MatOptionModule, SelectOptionTemplateDirective],
  templateUrl: './select-options.component.html'
})
export class SelectOptionsComponent implements AfterViewInit {
  @Input() options?: SelectOptionOrGroup[];
  @Input() noOptionLabel?: string = 'GLOBAL.NO_OPTION';
  @Input() isLoadingOptions: boolean = false;
  @Input() mustSearch: boolean = false;

  @ContentChild(SelectOptionTemplateDirective, { read: TemplateRef, static: true, descendants: true })
  public optionTemplateRef?: TemplateRef<unknown>;

  @ViewChildren(MatOption)
  protected matOptions: QueryList<MatOption>;

  constructor(
    @Host() @Optional() private select: MatSelect,
    @Host() @Optional() private autocomplete: MatAutocomplete
  ) {}

  ngAfterViewInit(): void {
    this.initOptions();
  }

  /**
   * Call the init method to init the options
   * This method must be called on the AfterViewInit life cycle
   *
   * This method must be call only Once
   */
  protected initOptions(): void {
    // the observable is complete on destroy
    const field = this.select || this.autocomplete;
    if (!field) {
      console.warn('Warn: Cannot find MatSelect or MatAutocomplete Host in SelectOptionsComponent');
    }
    this.matOptions.changes.subscribe((options) => this.registerSelectOptions(field, options));
    this.registerSelectOptions(field || this.autocomplete, this.matOptions);
  }

  /**
   * This method can be called to manually register options
   * @param select MatSelect instance
   * @param options options to add
   */
  protected registerSelectOptions(select: MatSelect | MatAutocomplete, matOptions: QueryList<MatOption>): void {
    if (!select.options) {
      return;
    }
    // reset the option in select
    const newOptions = matOptions.toArray();
    if (!newOptions.length) {
      return;
    }
    select.options.reset([
      // ...select.options.toArray(), // existing option
      ...matOptions.toArray() // new options
    ]);
    // notify the select that options have changed
    select.options.notifyOnChanges();
  }
}
