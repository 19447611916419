<h1 mat-dialog-title>
  <mat-icon>note_add</mat-icon>
  {{ 'CROSSING_POINTS.TITLE_NEW' | translate }}
</h1>

<form *ngIf="!loading" [formGroup]="cpForm">
  <mat-dialog-content class="dialog-padding">
    <div id="coordinates">
      <strong>{{ 'CROSSING_POINTS.COORDINATES' | translate }} :</strong>
      {{ data.coordinates.latitude }} :
      {{ data.coordinates.longitude }}
    </div>

    <mat-form-field>
      <mat-label>{{ 'CROSSING_POINTS.NAME' | translate }}</mat-label>
      <input matInput formControlName="name" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'CROSSING_POINTS.TYPE' | translate }}</mat-label>
      <mat-select formControlName="type">
        <mat-option *ngFor="let type of data.types" [value]="type.id">
          {{ type.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'CROSSING_POINTS.HIGHWAY' | translate }}</mat-label>
      <mat-select formControlName="highway" (valueChange)="onHighwaySelectionChange($event)">
        <mat-option *ngFor="let highway of data.highways" [value]="highway.UID">
          {{ highway.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'CROSSING_POINTS.DIRECTION' | translate }}</mat-label>
      <mat-select formControlName="direction">
        <mat-option *ngFor="let direction of directionOptions" [value]="direction.value.key">
          {{ direction.value.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'CROSSING_POINTS.PR_ID' | translate }}</mat-label>
      <input matInput formControlName="pr" />
      <mat-hint>200+500</mat-hint>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions class="dialog-buttons">
    <button type="button" (click)="onNoClick()" mat-flat-button>{{ 'GLOBAL.CANCEL' | translate }}</button>
    <button type="button" (click)="onSubmit()" [disabled]="!cpForm.valid" mat-flat-button color="warn">
      {{ 'GLOBAL.ADD' | translate }}
    </button>
  </mat-dialog-actions>
</form>
