<div id="readersDialogCmp">
    <mat-icon class="close" [mat-dialog-close]="">close</mat-icon>
    <h2>Lecture</h2>
    <table>
        <thead>
            <th>{{ 'DRIVING_LOGS.FIRST_NAME' | translate }}</th>
            <th>{{ 'DRIVING_LOGS.LAST_NAME' | translate }}</th>
        </thead>
        <tbody>
            <tr *ngFor="let item of data">
                <td>{{item.first_name}}</td>
                <td>{{item.last_name}}</td>
            </tr>
        </tbody>
    </table>
</div>