import { ProfileService } from 'src/app/services/profile.service';
import { environment } from 'src/environments/environment';

export class HttpInterceptorHelper {
  public static injectHostInUrl(url: string): string {
    if (url.slice(0, 14) === 'profile-api://') {
      if ((environment as any)?.USE_MOCKED_PROFILE) {
        throw Error('The environment is configured not to use the profile microservice');
      }
      let basepath = environment.PROFILE_API_URL.replace(/\/+$/, '');
      const endpoint = url.slice(12).replace(/^\/+/, '');
      return `${basepath}/${endpoint}`;
    }

    if (url.slice(0, 6) === 'api://') {
      const apiUrl = ProfileService.getAPIUrl();
      let basepath = apiUrl.replace(/\/+$/, '');
      const endpoint = url.slice(4).replace(/^\/+/, '');
      return `${basepath}/${endpoint}`;
    }

    return url;
  }
}
