import 'dayjs/locale/fr';
import 'dayjs/locale/en';

import { CommonModule, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
  MatRippleModule
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ActivatedRoute, Router, RouterModule, Routes } from '@angular/router';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import dayjs from 'dayjs';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { lastValueFrom } from 'rxjs';

import { AppComponent } from './app.component';
import { LoginComponent } from './components/auth/login/login.component';
import { LogoutComponent } from './components/auth/logout/logout.component';
import { ProfileCodeFormComponent } from './components/auth/profile/profile-code-form.component';
import { ControlDetailComponent } from './components/controls/details/controls.details.component';
import { ControlFormComponent } from './components/controls/form/controls-form.component';
import { ControlsListComponent } from './components/controls/list/controls.list.component';
import { CrossingPointsListComponent } from './components/crossing-points/list/crossing-points.list.component';
import { CrossingPointManagerComponent } from './components/crossing-points/manager/crossing-point-manager.component';
import { NewCrossingPointDialogComponent } from './components/crossing-points/new/new-crossing-point.dialog.component';
import {
  DrivingLogsArchivesListComponent,
  ReadersDialogComponent
} from './components/driving-logs/archives-list/driving-logs-archives-list.component';
import { ExpiredDrivingLogsListComponent } from './components/driving-logs/expired-list/expired-driving-logs-list.component';
import { OngoingDrivingLogsListComponent } from './components/driving-logs/ongoing-list/ongoing-driving-logs-list.component';
import { FluFlrListComponent } from './components/flu-flr/list/flu-flr.list.component';
import { AccordionComponent } from './components/generic/accordion/accordion.component';
import { BreadcrumbComponent } from './components/generic/breadcrumb/breadcrumb.component';
import { OldCarouselComponent } from './components/generic/carousel/old-carousel.component';
import { CellComponent } from './components/generic/cell/cell.component';
import { SecureSrcAttrPipe } from './components/generic/directives/secure-src-attr.pipe';
import { TrimPipe } from './components/generic/directives/trim.pipe';
import { UcWordsPipe } from './components/generic/directives/uc-words.pipe';
import { DisableControlDirective } from './components/generic/disable-directive/disable-directive';
import { DynamicFormComponent } from './components/generic/dynamic-form/dynamic-form.component';
import { DynamicFormQuestionComponent } from './components/generic/dynamic-form-question/dynamic-form-question.component';
import { ErrorHandler } from './components/generic/error-handler/error-handler.service';
import { SelectOptionTemplateDirective } from './components/generic/form/select/select.component';
import { SelectOptionsComponent } from './components/generic/form/select/select-options.component';
import { GenericDialogComponent } from './components/generic/generic-dialog/generic-dialog.component';
import { HeaderComponent } from './components/generic/header/header.component';
import { HTTPReqInterceptor } from './components/generic/interceptor/http.interceptor';
import { MatPaginatorIntlI18n } from './components/generic/list/custom-paginator/custom-paginator.component';
import { ListComponent } from './components/generic/list/list.component';
import { LoadingComponent } from './components/generic/loading/loading.component';
import { InfrastructureInfoWindowComponent } from './components/generic/map/info-windows/infrastructure-info-window.component';
import { MapComponent } from './components/generic/map/map.component';
import { SessionService } from './components/generic/services/session/session.service';
import { GPXViewerComponent } from './components/gpx/gpx-viewer.component';
import { HighwaysDetailComponent } from './components/highways/detail/highways.detail.component';
import { HighwaysListComponent } from './components/highways/list/highways.list.component';
import { InfrastructuresListComponent } from './components/infrastructures/list/infrastructures.list.component';
import { InfrastructureManagerComponent } from './components/infrastructures/manager/infrastructure-manager.component';
import { NewInfrastructureDialogComponent } from './components/infrastructures/new/new-infrastructure.dialog.component';
import { InfrastructureTypeListComponent } from './components/infrastructures/types/list/infrastructure-types.list.component';
import { EditInstructionComponent } from './components/instructions/edit/edit-instruction.component';
import { InstructionsListComponent } from './components/instructions/list/instructions.list.component';
import { NewInstructionComponent } from './components/instructions/new/new-instruction.component';
import { LocationFormHelper } from './components/locations/location-form/location-form.helper';
import { LocationFormMapComponent } from './components/locations/location-form/location-form-map.component';
import { ProfilesListComponent } from './components/profiles/list/profiles.list.component';
import { DamagesCardComponent } from './components/reporting/detail/cards/damages-card/damages-card.component';
import { DamagesFormDialogComponent } from './components/reporting/detail/cards/damages-card/damages-form-dialog/damages-form-dialog.component';
import { InfosCardComponent } from './components/reporting/detail/cards/infos-card/infos-card.component';
import { LanesCardComponent } from './components/reporting/detail/cards/lanes-card/lanes-card.component';
import { LanesOtherDialogComponent } from './components/reporting/detail/cards/lanes-card/lanes-other-dialog/lanes-other-dialog.component';
import { LocationCardComponent } from './components/reporting/detail/cards/location-card/location-card.component';
import { MapCardComponent } from './components/reporting/detail/cards/map-card/map-card.component';
import { OperationsCardComponent } from './components/reporting/detail/cards/operations-card/operations-card.component';
import { PicturesCardComponent } from './components/reporting/detail/cards/pictures-card/pictures-card.component';
import { ReportingDetailComponent } from './components/reporting/detail/detail.component';
import { ClassicFiltersDialogComponent } from './components/reporting/dialog-filter/dialogFilter.component';
import { InfrastructureDialogComponent } from './components/reporting/dialog-infrastructure/dialogInfrastructure.component';
import { NewEventDialogComponent } from './components/reporting/event/new-event.dialog.component';
import { ReportingEventTypeListComponent } from './components/reporting/event-types/list/reporting-event-types.list.component';
import { EditReportingFormComponent } from './components/reporting/form/edit/edit-reporting-form.component';
import { ReportingFormComponent } from './components/reporting/form/reporting-form.component';
import { ReportingComponent } from './components/reporting/reporting.component';
import { SubTypeFilter } from './components/reporting/reporting.pipe';
import { ReportingSubtypeListComponent } from './components/reporting/subtypes/list/reporting-subtypes.list.component';
import { ReportingTypeListComponent } from './components/reporting/types/list/reporting-types.list.component';
import { TypesModalComponent } from './components/reporting/types/types-modal/types-modal.component';
import { RepositoriesListComponent } from './components/repositories/list/repositories.list.component';
import { RolesListComponent } from './components/roles/list/roles.list.component';
import { SidemenuComponent } from './components/sidemenu/sidemenu.component';
import { TasksListComponent } from './components/tasks/list/tasks.list.component';
import { TeamsListComponent } from './components/teams/list/teams.list.component';
import { TeamsDialogComponent } from './components/teams/teams-dialog/teams-dialog.component';
import { UsersDetailComponent } from './components/users/detail/users.detail.component';
import { UsersListComponent } from './components/users/list/users.list.component';
import { Permissions } from './interface/permissions.enum';
import { OidcAuthModule } from './oidc-auth.module';
import { AuthGuardService } from './services/auth-guard.service';
import { ControlsService } from './services/controls.service';
import { CrossingPointsService } from './services/crossing-points.service';
import { DrivingLogsService } from './services/driving-logs.service';
import { FluflrService } from './services/flu-flr.service';
import { HighwaysService } from './services/highways.service';
import { IconService } from './services/icon.service';
import { InfrastructuresService } from './services/infrastructures.service';
import { InstructionsService } from './services/instructions.service';
import { LoadingService } from './services/loading.service';
import { LocationsService } from './services/locations.service';
import { ProfileService } from './services/profile.service';
import { ReportsService } from './services/reports.service';
import { RepositoriesService } from './services/repositories.service';
import { RolesService } from './services/roles.service';
import { SectorsService } from './services/sectors.service';
import { TeamsService } from './services/teams.service';
import { TitleService } from './services/title.service';
import { UsersService } from './services/users.service';
import { CustomDateAdapter } from './tools/custom-date-adapter';
import { DynamicFilterPipe } from './tools/dynamic-filter.pipe';
import { CUSTOM_MAT_DATE_FORMATS } from './tools/mat-date-formats';

export const galleryConfig: any = {
  gestures: true,
  style: {
    width: '900px',
    height: '460px',
    background: '#121519'
  },
  navigation: {},
  loader: {
    icon: 'data:image/svg+xml;charset=utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" width="135" height="140" fill="%23fff"%3E%3Crect width="15" height="120" y="10" rx="6"%3E%3Canimate attributeName="height" begin="0.5s" dur="1s" values="120;110;100;90;80;70;60;50;40;140;120" calcMode="linear" repeatCount="indefinite"/%3E%3Canimate attributeName="y" begin="0.5s" dur="1s" values="10;15;20;25;30;35;40;45;50;0;10" calcMode="linear" repeatCount="indefinite"/%3E%3C/rect%3E%3Crect width="15" height="120" x="30" y="10" rx="6"%3E%3Canimate attributeName="height" begin="0.25s" dur="1s" values="120;110;100;90;80;70;60;50;40;140;120" calcMode="linear" repeatCount="indefinite"/%3E%3Canimate attributeName="y" begin="0.25s" dur="1s" values="10;15;20;25;30;35;40;45;50;0;10" calcMode="linear" repeatCount="indefinite"/%3E%3C/rect%3E%3Crect width="15" height="140" x="60" rx="6"%3E%3Canimate attributeName="height" begin="0s" dur="1s" values="120;110;100;90;80;70;60;50;40;140;120" calcMode="linear" repeatCount="indefinite"/%3E%3Canimate attributeName="y" begin="0s" dur="1s" values="10;15;20;25;30;35;40;45;50;0;10" calcMode="linear" repeatCount="indefinite"/%3E%3C/rect%3E%3Crect width="15" height="120" x="90" y="10" rx="6"%3E%3Canimate attributeName="height" begin="0.25s" dur="1s" values="120;110;100;90;80;70;60;50;40;140;120" calcMode="linear" repeatCount="indefinite"/%3E%3Canimate attributeName="y" begin="0.25s" dur="1s" values="10;15;20;25;30;35;40;45;50;0;10" calcMode="linear" repeatCount="indefinite"/%3E%3C/rect%3E%3Crect width="15" height="120" x="120" y="10" rx="6"%3E%3Canimate attributeName="height" begin="0.5s" dur="1s" values="120;110;100;90;80;70;60;50;40;140;120" calcMode="linear" repeatCount="indefinite"/%3E%3Canimate attributeName="y" begin="0.5s" dur="1s" values="10;15;20;25;30;35;40;45;50;0;10" calcMode="linear" repeatCount="indefinite"/%3E%3C/rect%3E%3C/svg%3E'
  },
  description: false,
  player: null,
  thumbnails: {
    width: 90,
    height: 70,
    position: 'bottom',
    margin: 10
  },
  lightbox: {
    backdropClass: 'g-backdrop',
    panelClass: 'g-overlay',
    hasBackdrop: true
  },
  imageSize: 'contain',
  bullets: false
};

const appRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'reporting'
  },
  {
    path: 'unauthorized',
    pathMatch: 'full',
    redirectTo: 'reporting'
  },
  {
    path: 'instructions',
    canActivate: [AuthGuardService],
    data: {
      breadcrumb: 'INSTRUCTIONS.LABEL_P',
      activeModule: 'instructions'
    },
    children: [
      {
        path: '',
        pathMatch: 'prefix',
        component: InstructionsListComponent,
        data: {
          breadcrumb: ''
        }
      },
      {
        path: 'new',
        pathMatch: 'prefix',
        component: NewInstructionComponent,
        data: {
          breadcrumb: 'GLOBAL.BREADCRUMB.INSTRUCTIONS.NEW'
        }
      },
      {
        path: ':id/edit',
        pathMatch: 'prefix',
        component: EditInstructionComponent,
        data: {
          edit: true,
          breadcrumb: 'GLOBAL.BREADCRUMB.INSTRUCTIONS.EDIT'
        }
      },
      {
        path: ':id/details',
        pathMatch: 'prefix',
        component: EditInstructionComponent,
        data: {
          edit: false,
          breadcrumb: 'GLOBAL.BREADCRUMB.INSTRUCTIONS.DETAIL'
        }
      }
    ]
  },
  {
    path: 'tasks',
    canActivate: [AuthGuardService],
    data: {
      breadcrumb: 'TASKS.LABEL',
      activeModule: 'tasks'
    },
    children: [
      {
        path: '',
        pathMatch: 'prefix',
        component: TasksListComponent,
        data: {
          breadcrumb: ''
        }
      },
      {
        path: 'new',
        pathMatch: 'prefix',
        component: ReportingFormComponent,
        data: {
          mode: 'task',
          breadcrumb: 'GLOBAL.BREADCRUMB.TASKS.NEW'
        }
      },
      {
        path: ':id/edit',
        pathMatch: 'prefix',
        component: EditReportingFormComponent,
        data: {
          breadcrumb: 'GLOBAL.BREADCRUMB.TASKS.EDIT'
        }
      },
      {
        path: ':id/details',
        pathMatch: 'prefix',
        component: ReportingDetailComponent,
        data: {
          mode: 'task',
          breadcrumb: 'GLOBAL.BREADCRUMB.TASKS.DETAIL'
        }
      }
    ]
  },
  {
    path: 'reporting',
    children: [
      {
        path: '',
        pathMatch: 'prefix',
        component: ReportingComponent,
        data: {
          breadcrumb: ''
        }
      },
      {
        path: ':id/edit',
        pathMatch: 'prefix',
        component: EditReportingFormComponent,
        data: {
          breadcrumb: 'GLOBAL.BREADCRUMB.REPORT.EDIT'
        }
      },
      {
        path: ':id/details',
        pathMatch: 'prefix',
        component: ReportingDetailComponent,
        data: {
          mode: 'reporting',
          breadcrumb: 'GLOBAL.BREADCRUMB.REPORT.DETAIL'
        }
      },
      {
        path: 'new',
        pathMatch: 'prefix',
        component: ReportingFormComponent,
        data: {
          breadcrumb: 'GLOBAL.BREADCRUMB.REPORT.NEW'
        }
      }
    ],
    data: {
      breadcrumb: 'REPORTING.REPORTS'
    }
  },
  {
    path: 'controls',
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        pathMatch: 'prefix',
        component: ControlsListComponent,
        data: {
          breadcrumb: ''
        }
      },
      {
        path: 'forms',
        pathMatch: 'prefix',
        component: ControlFormComponent,
        data: {
          breadcrumb: 'GLOBAL.BREADCRUMB.CONTROLS.FORM'
        }
      },
      {
        path: ':id',
        pathMatch: 'prefix',
        component: ControlDetailComponent,
        data: {
          auditControl: false,
          breadcrumb: 'GLOBAL.CONTROLS_DETAILS'
        }
      }
    ],
    data: {
      breadcrumb: 'GLOBAL.CONTROLS',
      activeModule: 'controls'
    }
  },
  {
    path: 'driving-logs',
    canActivate: [AuthGuardService],
    data: {
      permissions: [Permissions.BACK_OFFICE_TEAMS_DRIVING_LOGS],
      breadcrumb: 'GLOBAL.BREADCRUMB.DRIVING_LOGS.LIST'
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: OngoingDrivingLogsListComponent,
        data: {
          breadcrumb: 'GLOBAL.BREADCRUMB.DRIVING_LOGS.ONGOING'
        }
      },
      {
        path: 'archives',
        pathMatch: 'prefix',
        component: DrivingLogsArchivesListComponent,
        data: {
          breadcrumb: 'GLOBAL.BREADCRUMB.DRIVING_LOGS.ARCHIVES'
        }
      }
    ]
  },
  {
    path: 'repositories',
    children: [
      {
        path: '',
        pathMatch: 'prefix',
        component: RepositoriesListComponent,
        data: {
          breadcrumb: ''
        }
      },
      {
        path: 'users',
        component: UsersListComponent,
        data: {
          breadcrumb: 'GLOBAL.BREADCRUMB.USERS.LIST'
        }
      },
      {
        path: 'infrastructures',
        data: {
          breadcrumb: 'GLOBAL.BREADCRUMB.INFRASTRUCTURES.LIST'
        },
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: InfrastructuresListComponent
          },
          {
            path: 'manager',
            component: InfrastructureManagerComponent,
            data: {
              breadcrumb: 'GLOBAL.BREADCRUMB.INFRASTRUCTURES.MANAGER'
            }
          }
        ]
      },
      {
        path: 'roles',
        component: RolesListComponent,
        data: {
          breadcrumb: 'GLOBAL.BREADCRUMB.ROLES'
        }
      },
      {
        path: 'teams',
        component: TeamsListComponent,
        data: {
          breadcrumb: 'GLOBAL.BREADCRUMB.TEAMS'
        }
      },
      {
        path: 'flu',
        component: FluFlrListComponent,
        data: {
          breadcrumb: 'GLOBAL.BREADCRUMB.FLU',
          view: 'flu'
        }
      },
      {
        path: 'flr',
        component: FluFlrListComponent,
        data: {
          breadcrumb: 'GLOBAL.BREADCRUMB.FLR',
          view: 'flr'
        }
      },
      {
        path: 'crossing-points',
        data: {
          breadcrumb: 'GLOBAL.BREADCRUMB.CROSSING_POINTS.LIST'
        },
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: CrossingPointsListComponent
          },
          {
            path: 'manager',
            component: CrossingPointManagerComponent,
            data: {
              breadcrumb: 'GLOBAL.BREADCRUMB.CROSSING_POINTS.MANAGER'
            }
          }
        ]
      },
      {
        path: 'gpx-viewer',
        component: GPXViewerComponent,
        data: {
          breadcrumb: 'GLOBAL.BREADCRUMB.GPX_VIEWER'
        }
      },
      {
        path: 'highways',
        component: HighwaysListComponent,
        data: {
          breadcrumb: 'GLOBAL.BREADCRUMB.HIGHWAYS'
        }
      },
      {
        path: 'highways/:UID',
        component: HighwaysDetailComponent,
        data: {
          breadcrumb: 'GLOBAL.BREADCRUMB.HIGHWAYS'
        }
      },
      {
        path: 'reporting-types',
        component: ReportingTypeListComponent,
        canActivate: [AuthGuardService],
        data: {
          breadcrumb: 'GLOBAL.BREADCRUMB.REPORTING_TYPES.LIST'
        }
      },
      {
        path: 'reporting-subtypes',
        component: ReportingSubtypeListComponent,
        canActivate: [AuthGuardService],
        data: {
          breadcrumb: 'GLOBAL.BREADCRUMB.REPORTING_SUBTYPES.LIST'
        }
      },
      {
        path: 'reporting-event-types',
        component: ReportingEventTypeListComponent,
        canActivate: [AuthGuardService],
        data: {
          checkAdmin: true,
          breadcrumb: 'GLOBAL.BREADCRUMB.REPORTING_EVENT_TYPES.LIST'
        }
      },
      {
        path: 'infrastructure-types',
        component: InfrastructureTypeListComponent,
        canActivate: [AuthGuardService],
        data: {
          checkAdmin: true,
          breadcrumb: 'GLOBAL.BREADCRUMB.INFRASTRUCTURE_TYPES.LIST'
        }
      },
      {
        path: 'profiles',
        component: ProfilesListComponent,
        canActivate: [AuthGuardService],
        data: {
          checkAdmin: true,
          checkDevMode: true,
          breadcrumb: 'GLOBAL.BREADCRUMB.PROFILES.LIST'
        }
      }
    ],
    data: {
      breadcrumb: 'GLOBAL.BREADCRUMB.REPOSITORIES'
    }
  },
  {
    path: '**',
    redirectTo: 'reporting'
  }
];

export const httpLoaderFactory = (http: HttpClient) => new TranslateHttpLoader(http);

@NgModule({
  imports: [HttpClientModule, GoogleMapsModule, MatFormFieldModule, MatInputModule],
  exports: [MatPaginatorModule, MatButtonModule, MatTooltipModule, MatFormFieldModule, MatInputModule],
  declarations: [UsersDetailComponent],
  providers: [
    HttpClient,
    Title,
    SessionService,
    TitleService,
    ReportsService,
    TranslateService,
    RolesService,
    MatDialog,
    Router,
    ActivatedRoute
  ]
})
export class MaterialModule {}

@NgModule({
  declarations: [
    OldCarouselComponent,
    CellComponent,
    CrossingPointsListComponent,
    CrossingPointManagerComponent,
    InfrastructureManagerComponent,
    GPXViewerComponent,
    NewCrossingPointDialogComponent,
    AppComponent,
    HighwaysListComponent,
    InfrastructuresListComponent,
    NewInfrastructureDialogComponent,
    HighwaysDetailComponent,
    UsersListComponent,
    OngoingDrivingLogsListComponent,
    ClassicFiltersDialogComponent,
    InfrastructureDialogComponent,
    TeamsDialogComponent,
    TeamsListComponent,
    RepositoriesListComponent,
    HeaderComponent,
    SidemenuComponent,
    ListComponent,
    DynamicFormComponent,
    DynamicFormQuestionComponent,
    LoginComponent,
    RolesListComponent,
    GenericDialogComponent,
    ReadersDialogComponent,
    LogoutComponent,
    ReportingComponent,
    ReportingDetailComponent,
    BreadcrumbComponent,
    SubTypeFilter,
    UcWordsPipe,
    TrimPipe,
    DamagesCardComponent,
    DamagesFormDialogComponent,
    LanesCardComponent,
    LanesOtherDialogComponent,
    LocationCardComponent,
    FluFlrListComponent,
    MapCardComponent,
    OperationsCardComponent,
    PicturesCardComponent,
    InfosCardComponent,
    DrivingLogsArchivesListComponent,
    OngoingDrivingLogsListComponent,
    ExpiredDrivingLogsListComponent,
    TasksListComponent,
    NewInstructionComponent,
    EditInstructionComponent,
    DisableControlDirective,
    ControlsListComponent,
    ControlFormComponent,
    ControlDetailComponent,
    InstructionsListComponent,
    AccordionComponent,
    ProfileCodeFormComponent,
    DynamicFilterPipe,
    ReportingTypeListComponent,
    ReportingSubtypeListComponent,
    ReportingEventTypeListComponent,
    InfrastructureTypeListComponent,
    ProfilesListComponent,
    NewEventDialogComponent
  ],
  imports: [
    CommonModule,
    HttpClientJsonpModule,
    OidcAuthModule,
    GoogleMapsModule,
    InfiniteScrollModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatGridListModule,
    MatSelectModule,
    MatDialogModule,
    MatSnackBarModule,
    MatPaginatorModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatExpansionModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    RouterModule.forRoot(appRoutes, { enableTracing: false }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    TypesModalComponent,
    MapComponent,
    InfrastructureInfoWindowComponent,
    SelectOptionsComponent,
    LoadingComponent,
    SelectOptionTemplateDirective,
    ReportingFormComponent,
    LocationFormMapComponent,
    SecureSrcAttrPipe,
    EditReportingFormComponent
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [SessionService, TranslateService, ProfileService],
      multi: true
    },
    HttpClient,
    ProfileService,
    BreadcrumbComponent,
    SubTypeFilter,
    MatDialogModule,
    MatSnackBarModule,
    MatPaginatorIntlI18n,
    LocationsService,
    AuthGuardService,
    RolesService,
    SessionService,
    FluflrService,
    HighwaysService,
    TeamsService,
    CrossingPointsService,
    IconService,
    UsersService,
    DrivingLogsService,
    ReportsService,
    RepositoriesService,
    SectorsService,
    LoadingService,
    TitleService,
    InfrastructuresService,
    ControlsService,
    InstructionsService,
    { provide: LOCALE_ID, useFactory: getLocaleId },
    { provide: MAT_DATE_LOCALE, useFactory: getLocaleId },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_MAT_DATE_FORMATS },
    { provide: DateAdapter, useClass: CustomDateAdapter },
    {
      provide: MatPaginatorIntl,
      deps: [TranslateService],
      useFactory: (translateService: TranslateService) =>
        new MatPaginatorIntlI18n(translateService).getMatPaginatorIntlI18n()
    },
    { provide: HTTP_INTERCEPTORS, useClass: HTTPReqInterceptor, multi: true },
    ErrorHandler,
    LocationFormHelper
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

function initializeApp(
  sessionService: SessionService,
  translateService: TranslateService,
  profileService: ProfileService
) {
  return async () => {
    const isProfile = await profileService.init();
    await initializeAppLocale(translateService);
    await sessionService.checkAuthOnLoading(isProfile);
  };
}

async function initializeAppLocale(translateService: TranslateService): Promise<void> {
  const localeData = getLocaleData();
  dayjs.locale(localeData.lang);
  translateService.setDefaultLang(localeData.lang);
  await lastValueFrom(translateService.use(localeData.lang)); // wait i18n to be ready before loading any component
  registerLocaleData(localeData.locale);
}

function getLocaleId() {
  return getLocaleData().id;
}

function getLocaleData() {
  const localeMapping = {
    fr: {
      id: 'fr-FR',
      locale: localeFr,
      lang: 'fr'
    },
    en: {
      id: 'en-US',
      locale: localeEn,
      lang: 'en'
    }
  };
  const lang = navigator.language.split('-')[0];
  const localeData = localeMapping[lang as keyof typeof localeMapping] || localeMapping.fr;

  return localeData;
}
