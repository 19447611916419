export interface IAccidentDetail {
  // APRR data
  exported: boolean;
  comment: string;
  lanes: IAccidentLanes;
  medias: IMedias[];
  vehicles: IAccidentVehicule;
  victims: IAccidentInjured;
  formVehicles: IAccidentForm | {};
  formWeather: IAccidentForm | {};
  formMaterials: IAccidentForm | {};
  formMarkings: IAccidentForm | {};
}

export enum LaneType {
  LANE = 'lane', // 'Voie x'
  VERGE = 'verge', // 'Accotement'
  SERVICE_AREA = 'service_area', // 'Aire d'autoroute'
  STATION = 'station_or_platform', // 'Station => TBD'
  OTHER = 'other'
}

export interface IAccidentLane {
  type: LaneType;
  selected: boolean;
  metadata?: string;
}

export interface IAccidentLanes {
  currentDirection: {
    number: number;
    impacted: IAccidentLane[];
  };
  oppositeDirection: {
    number: number;
    impacted: IAccidentLane[];
  };
}

export interface IMedias {
  horodate: Date;
  type: string;
  mime_type: string;
  name: string;
  extension: string;
}

export interface IAccidentVehicule {
  car: number;
  moto: number;
  bus: number;
  truck: number;
  riskVehicule: number;
  pedestrian: number;
  animal: number;
}

export interface IAccidentInjured {
  high: number;
  low: number;
  dead: number;
}

export interface IAccidentForm {
  date: Date;
  payload: any;
  type: string;
  version: number;
}
