<h1 *ngIf="structure.title" mat-dialog-title translate>{{ structure.title }}</h1>
<mat-dialog-content *ngIf="!isForm" class="dialog-padding pb-0">
  <span translate>{{ structure.text }}</span>
</mat-dialog-content>
<!-- FORMULAIRE DYNAMIQUE DEBUT -->
<form
  *ngIf="isForm"
  (ngSubmit)="onSubmit(data.content.endpoint, data.content)"
  class="mat-form-field"
  method="post"
  [formGroup]="mainForm"
>
  <mat-dialog-content class="dialog-padding pb-0">
    <div *ngFor="let field of data.content.fields" class="div-form">
      <!-- Texte Libre -->
      <mat-form-field *ngIf="field.type === 'text'" class="field-form">
        <!-- <mat-label>{{ field.title }}</mat-label> -->
        <input
          matInput
          placeholder="{{ field.title | translate }}"
          type="text"
          [id]="field.key"
          [(ngModel)]="field.value"
          formControlName="{{ field.key }}"
        />
      </mat-form-field>
      <!-- E-Mail -->
      <mat-form-field *ngIf="field.type === 'email'" class="field-form">
        <input
          matInput
          placeholder="{{ field.title | translate }}"
          [id]="field.key"
          [(ngModel)]="field.value"
          formControlName="{{ field.key }}"
          required
          [formControl]="emailValidator"
        />
        <mat-error *ngIf="emailValidator.status === 'INVALID'">{{ getErrorMessage() | translate }}</mat-error>
      </mat-form-field>
      <!-- Mot de Passe -->
      <mat-form-field *ngIf="field.type === 'password'" class="field-form">
        <input
          matInput
          placeholder="{{ field.title | translate }}"
          type="password"
          [id]="field.key"
          [type]="hide ? 'password' : 'text'"
          formControlName="{{ field.key }}"
        />
        <mat-icon matSuffix svgIcon="{{ hide ? 'visibility' : 'visibility-off' }}" (click)="hide = !hide"></mat-icon>
      </mat-form-field>
      <!-- Zone de Texte -->
      <mat-form-field *ngIf="field.type === 'textarea'" class="field-form w-full">
        <textarea
          matInput
          placeholder="{{ field.title | translate }}"
          cdkTextareaAutosize
          cdkAutosizeMinRows="2"
          cdkAutosizeMaxRows="16"
          style="resize: vertical"
          formControlName="{{ field.key }}"
          [id]="field.key"
          [(ngModel)]="field.value"
        >
        </textarea>
      </mat-form-field>
      <!-- List -->
      <mat-form-field *ngIf="field.type === 'list'" class="mat-form-field--no-underline" class="field-form">
        <input matInput style="display: none !important" />
        <mat-selection-list #list [(ngModel)]="field.value" formControlName="{{ field.key }}">
          <mat-list-option *ngFor="let value of field.allValue" [value]="value">
            {{ value }}
          </mat-list-option>
        </mat-selection-list>
      </mat-form-field>
      <!-- File Uploader -->
      <div *ngIf="field.type === 'file'" class="field-form">
        <input
          (change)="getFileName(field.key)"
          [id]="field.key"
          type="file"
          name="{{ field.key }}"
          style="display: none"
          formControlName="{{ field.key }}"
        />
        <label [for]="field.key" class="upload-file-label">{{ field.title | translate }}</label>
        <span>{{ fileName }}</span>
      </div>
      <!-- Téléphone -->
      <mat-form-field *ngIf="field.type === 'tel'" class="field-form">
        <input
          matInput
          placeholder="{{ field.title | translate }}"
          type="tel"
          [id]="field.key"
          formControlName="{{ field.key }}"
        />
        <span matPrefix>+33 &nbsp;</span>
      </mat-form-field>
      <!-- Adresse -->
      <mat-form-field *ngIf="field.type === 'address'" class="field-form">
        <textarea matInput placeholder="Adresse" formControlName="address"></textarea>
      </mat-form-field>
      <table *ngIf="field.type === 'address'" cellspacing="1">
        <tr>
          <td>
            <mat-form-field>
              <input matInput placeholder="{{ 'FORM.CITY' | translate }}" formControlName="city" />
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <td>
            <mat-form-field>
              <input matInput placeholder="{{ 'FORM.STATE' | translate }}" formControlName="country" />
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <td>
            <mat-form-field>
              <input
                matInput
                #postalCode
                maxlength="5"
                placeholder="{{ 'FORM.ZIP_CODE' | translate }}"
                formControlName="zipcode"
              />
              <mat-hint align="end">{{ postalCode.value.length }} / 5</mat-hint>
            </mat-form-field>
          </td>
        </tr>
      </table>

      <!-- Date -->
      <mat-form-field *ngIf="field.type === 'date'">
        <input
          matInput
          [matDatepicker]="picker"
          placeholder="{{ 'FORM.PICK_A_DATE' | translate }}"
          formControlName="{{ field.key }}"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker startView="year"></mat-datepicker>
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="dialog-buttons">
    <button mat-raised-button color="warn" type="submit" [disabled]="!mainForm.valid">
      {{ data.content.button.text }}
    </button>
    <button mat-button (click)="closePopup()" type="button">
      {{ 'GLOBAL.CANCEL' | translate }}
    </button>
  </mat-dialog-actions>
</form>
<!-- FORMULAIRE DYNAMIQUE FIN -->
<!-- HTML LIBRE DEBUT -->
<mat-dialog-content
  *ngIf="isHtml"
  [innerHTML]="htmlTemplate"
  class="dialog-padding pb-0"
  translate
></mat-dialog-content>
<!-- HTML LIBRE FIN -->
<!-- IMAGE LIBRE DEBUT -->
<mat-dialog-content *ngIf="isImg">
  <div class="container-carousel-images">
    <a [attr.href]="data.content.src | secure | async" target="_blank">
      <span class="fullscreen-carousel-image" matTooltip="Plein écran" matTooltipPosition="above">
        <i class="material-icons">fullscreen</i>
      </span>
    </a>
    <img [attr.src]="data.content.src | secure | async" class="carousel-image" />
  </div>
</mat-dialog-content>
<!-- IMAGE LIBRE FIN -->
<!-- ERROR LIST DEBUT -->
<mat-dialog-content *ngIf="isError" translate>
  <div *ngFor="let err of data.content.error.causes">
    <h2 *ngIf="!err.isSameAsPrevious">{{ err.localeMessage }}</h2>
    <p>
      <span *ngFor="let detail of err.ctxDetails">
        <span class="bold">{{ detail.localeKey }} :</span> {{ detail.stringValue }}<br />
      </span>
    </p>
  </div>
  <div *ngIf="data.content.error.causes.length === 0">
    <h2>{{ data.content.error.localeMessage }}</h2>
    <p>
      <span *ngFor="let detail of data.content.error.ctxDetails">
        <span class="bold">{{ detail.localeKey }} :</span> {{ detail.stringValue }}<br />
      </span>
    </p>
  </div>
</mat-dialog-content>
<!-- ERROR LIST FIN -->
<!-- ENVOI DE FICHIER DEBUT -->
<mat-dialog-content class="file-import" *ngIf="isFile">
  <div>
    <!-- ICI I CIC CIOZ -->
    <div *ngIf="isFile && fileName && structure.disclaimer" class="disclaimer">
      <mat-icon>warning</mat-icon>{{ structure.disclaimer }}
    </div>
    <form
      [formGroup]="form"
      style="display: flex; justify-content: center; flex-direction: column; align-items: center"
      (ngSubmit)="onSubmit(data.content.endpoint, data.content)"
    >
      <label for="fileToUpload" class="upload-file-label" translate>REPOSITORIES.SELECT_FILE</label>
      <input
        [disabled]="this.isUploading"
        (change)="onFileChange($event)"
        id="fileToUpload"
        type="file"
        name="myFile"
        style="display: none"
        #fileInput
      />
      <span *ngIf="fileName" class="file-name">{{ fileName }}</span>
      <mat-dialog-actions>
        <span class="margin-auto">
          <button
            mat-raised-button
            color="warn"
            class="save-button validation margin-auto"
            type="submit"
            disabled="{{ isUploading }}"
          >
            <span translate>GLOBAL.SUBMIT_BUTTON_LABEL</span>
          </button>
          <a *ngIf="!hideCancelButton" mat-button (click)="closePopup()">{{ 'GLOBAL.CANCEL' | translate }}</a>
        </span>
      </mat-dialog-actions>
    </form>
  </div>
</mat-dialog-content>
<!-- ENVOI DE FICHIER FIN -->
<mat-dialog-actions *ngIf="!isForm && !isFile && !isError">
  <span class="margin-auto">
    <button
      *ngFor="let button of structure.buttons"
      mat-button
      class="{{ button.class }}"
      [ngClass]="{
        'mat-mdc-raised-button': button.isRaisedButton
      }"
      [color]="button.isRaisedButton ? 'warn' : undefined"
      (click)="exec(button.action)"
    >
      {{ button.text | translate }}
    </button>
  </span>
</mat-dialog-actions>

<mat-dialog-actions *ngIf="isError">
  <span class="error-close">
    <button mat-button class="validation" (click)="closePopup()">{{ 'GLOBAL.OK' | translate }}</button>
  </span>
</mat-dialog-actions>

<mat-dialog-actions *ngIf="loading.get('importFile').enabled && !isError">
  <mat-progress-bar mode="determinate" value="{{ progression }}"></mat-progress-bar>
</mat-dialog-actions>
