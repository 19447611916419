<div class="content">
  <h1 class="mainTitle">
    <button
      mat-raised-button
      class="icon-only-button"
      matTooltip="{{ 'GLOBAL.CONTROLS' | translate }}"
      matTooltipPosition="above"
      [ngClass]="{ 'disabled-button': !controlsList }"
      (click)="goToFormControls()"
    >
      <mat-icon>visibility</mat-icon>
    </button>
    <button
      mat-raised-button
      class="icon-only-button"
      matTooltip="{{ 'GLOBAL.DOWNLOAD' | translate }}"
      matTooltipPosition="above"
      [disabled]="!controlsList"
      [ngClass]="{ 'disabled-button': !controlsList }"
      (click)="exportControls()"
    >
      <mat-icon>file_download</mat-icon>
    </button>
    {{ 'GLOBAL.BREADCRUMB.CONTROLS.LIST' | translate }}
  </h1>

  <div>
    <div class="filters filters-test-switch">
      <mat-form-field>
        <mat-select
          placeholder="{{ 'CONTROLS.FILTERS.SECTORS.LABEL' | translate }}"
          [(ngModel)]="selectedSectors"
          multiple
        >
          <div class="mat-option h-auto" (click)="toggleSelectSectors()">
            {{ 'GLOBAL.TOGGLE_SELECT' | translate }}
          </div>
          <mat-option *ngFor="let sector of filterData.sectorsList" [value]="sector.value" (click)="getControlList()">
            {{ sector.label | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-select
          placeholder="{{ 'CONTROLS.FILTERS.UPDATE_DATE.LABEL' | translate }}"
          [(ngModel)]="selectedUpdateDate"
        >
          <mat-option [value]="" (click)="getControlList()">
            <i class="material-icons delete-icon">delete</i>{{ 'GLOBAL.CLEAR' | translate }}
          </mat-option>
          <mat-option *ngFor="let option of filterData.updateDate" [value]="option.value" (click)="getControlList()">
            {{ option.label | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-select placeholder="{{ 'GLOBAL.STATUS' | translate }}" [(ngModel)]="selectedStatus">
          <mat-option [value]="" (click)="getControlList()">
            <i class="material-icons delete-icon">delete</i>{{ 'GLOBAL.CLEAR' | translate }}
          </mat-option>
          <mat-option *ngFor="let status of filterData.statusList" [value]="status" (click)="getControlList()">
            {{ status.label | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <app-list
      [listParameters]="listParameters"
      [items]="controlsList"
      [total]="total"
      searchType="dynamic"
      (refresh)="getControlList($event)"
      (action)="getEvent($event)"
    >
    </app-list>
  </div>
</div>
