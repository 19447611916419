import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IGenericListParameters } from 'app/components/generic/list/list.interface';
import { IEvent } from 'app/interface/event.interface';
import { DrivingLogsService, IDrivingLogsResponse } from 'app/services/driving-logs.service';
import dayjs from 'dayjs';

export interface IExpiredDrivingLogDisplay {
  _id: string;
  last_activity: Date;
  service_number: string;
  first_name: string;
  last_name: string;
  actions: any[];
}

@Component({
  selector: 'app-crossing-points',
  templateUrl: './expired-driving-logs-list.component.html',
  styleUrls: ['./expired-driving-logs-list.component.scss']
})
export class ExpiredDrivingLogsListComponent {
  drivingLogsDisplay: IExpiredDrivingLogDisplay[] = [];

  maxAgeDays?: number;

  listParameters: IGenericListParameters = {
    paginator: false,
    listHead: [
      {
        key: 'service_number',
        type: 'text',
        title: 'DRIVING_LOGS.SERVICE_NUMBER'
      },
      {
        key: 'first_name',
        type: 'text',
        title: 'DRIVING_LOGS.FIRST_NAME'
      },
      {
        key: 'last_name',
        type: 'text',
        title: 'DRIVING_LOGS.LAST_NAME'
      },
      {
        key: 'last_activity',
        type: 'date',
        title: 'DRIVING_LOGS.LAST_ACTIVITY'
      },
      {
        key: 'actions',
        type: 'button',
        colWidth: '80px'
      }
    ]
  };

  constructor(
    private drivingLogs: DrivingLogsService,
    public dialogRef: MatDialogRef<ExpiredDrivingLogsListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDrivingLogsResponse
  ) {
    this.maxAgeDays = data.max_age_days;
    const mostRecentMoment = (a: dayjs.Dayjs, b: dayjs.ConfigType) => (a.isBefore(b) ? dayjs(b) : a);

    this.drivingLogsDisplay = data?.items?.map((dl) => {
      const lastActivity =
        dl.events &&
        dl.events.reduce((previous, { horodate }) => mostRecentMoment(previous, horodate), dayjs(0, 'X')).toDate();

      return {
        _id: dl._id,
        last_activity: lastActivity,
        service_number: dl.user.service_number,
        first_name: dl.user.first_name,
        last_name: dl.user.last_name,
        actions: [
          {
            type: 'button',
            style: { color: 'warn' },
            events: { click: 'download' },
            options: { icon: 'file_download' },
            tooltip: 'GLOBAL.DOWNLOAD'
          },
          {
            type: 'button',
            style: { color: 'warn' },
            events: { click: 'close' },
            options: { icon: 'close' },
            tooltip: 'DRIVING_LOGS.CLOSE_ACTION'
          }
        ]
      };
    });
  }

  async captureEvent(event: IEvent) {
    switch (event.type) {
      case 'download':
        this.drivingLogs.downloadPdf(event.data._id);
        break;
      case 'close':
        await this.drivingLogs.closeExpired(event.data._id);
        this.drivingLogsDisplay = this.drivingLogsDisplay.filter((e) => e._id !== event.data._id);
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
