<h1 class="mainTitle" translate>DRIVING_LOGS.ARCHIVED_LIST.TITLE</h1>

<div class="filters filters-test-switch">
  <span class="filters-switch switch">
    {{ 'DRIVING_LOGS.ONGOING_P' | translate }}
    <mat-slide-toggle class="red-blue-slide" [checked]="true" (change)="gotoOngoing()" />
    {{ 'DRIVING_LOGS.ARCHIVED_P' | translate }}
  </span>

  <mat-form-field>
    <mat-select
      placeholder="{{ 'DRIVING_LOGS.FILTERS.SECTORS' | translate }}"
      name="sectors"
      [(ngModel)]="selectedSectors"
      multiple
    >
      <div class="mat-option h-auto" (click)="toggleSelectSectors()">
        {{ 'GLOBAL.TOGGLE_SELECT' | translate }}
      </div>
      <mat-option *ngFor="let sector of allSectors" [value]="sector.value" (click)="getDrivingLogsList()">
        {{ sector.label | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-select
      placeholder="{{ 'DRIVING_LOGS.FILTERS.TYPES' | translate }}"
      name="permissions"
      [(ngModel)]="selectedPermissions"
      multiple
    >
      <mat-option [value]="'APP_MOBILE_DRIVING_LOGS'" (click)="getDrivingLogsList()">
        {{ 'DRIVING_LOGS.MOBILE' | translate }}
      </mat-option>
      <mat-option [value]="'BACK_OFFICE_OPERATOR'" (click)="getDrivingLogsList()">
        {{ 'DRIVING_LOGS.OPERATOR' | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <input
      matInput
      [matDatepicker]="from"
      placeholder="{{ 'DRIVING_LOGS.FILTERS.FROM' | translate }}"
      name="from"
      [(ngModel)]="rawFilters.from"
      [max]="filterData.from.max"
      (dateChange)="updateFilters()"
    />
    <mat-datepicker-toggle matSuffix [for]="from"></mat-datepicker-toggle>
    <mat-datepicker #from></mat-datepicker>
  </mat-form-field>

  <mat-form-field>
    <input
      matInput
      [matDatepicker]="to"
      placeholder="{{ 'DRIVING_LOGS.FILTERS.TO' | translate }}"
      name="to"
      [(ngModel)]="rawFilters.to"
      [min]="filterData.to.min"
      (dateChange)="updateFilters()"
    />
    <mat-datepicker-toggle matSuffix [for]="to"></mat-datepicker-toggle>
    <mat-datepicker #to></mat-datepicker>
  </mat-form-field>
</div>

<table cellspacing="0" cellpadding="0">
  <thead>
    <th>{{ 'DRIVING_LOGS.SERVICE_NUMBER' | translate }}</th>
    <th>{{ 'DRIVING_LOGS.FIRST_NAME' | translate }}</th>
    <th>{{ 'DRIVING_LOGS.LAST_NAME' | translate }}</th>
    <th>{{ 'DRIVING_LOGS.STARTED_AT' | translate }}</th>
    <th>{{ 'DRIVING_LOGS.ARCHIVED_AT' | translate }}</th>
    <th>{{ 'DRIVING_LOGS.READ_BY' | translate }}</th>
    <th>Signature TESSI</th>
    <th>Action</th>
    <th *ngIf="devMode()">{{ 'DRIVING_LOGS.ANOMALIES' | translate }}</th>
  </thead>
  <tbody>
    <tr *ngFor="let item of this.drivingLogList">
      <td>{{ item.service_number }}</td>
      <td>{{ item.first_name }}</td>
      <td>{{ item.last_name }}</td>
      <td *ngIf="item.created_at !== null">{{ item.created_at | date: 'medium' }}</td>
      <td *ngIf="item.created_at === null">-</td>
      <td>{{ item.archived_at | date: 'medium' }}</td>
      <td>
        <div *ngIf="item.reads.length > 1" (click)="viewReaders(item.reads)" class="reads">
          {{ item.reads.length }} {{ 'DRIVING_LOGS.READERS' | translate }} <mat-icon>arrow_right</mat-icon>
        </div>
        <div *ngIf="item.reads.length === 1">{{ item.reads[0].first_name }} {{ item.reads[0].last_name }}</div>
        <div *ngIf="item.reads.length === 0">{{ 'DRIVING_LOGS.NOT_READ' | translate }}</div>
      </td>
      <td>
        <div class="signed-container">
          <mat-icon [ngClass]="{ indicator: true, signed: item.signed, unsigned: !item.signed }">brightness_1</mat-icon>
          <span *ngIf="item.signed">{{ 'DRIVING_LOGS.SIGNED' | translate }}</span>
          <span *ngIf="!item.signed">{{ 'DRIVING_LOGS.UNSIGNED' | translate }}</span>
        </div>
      </td>
      <td>
        <mat-icon (click)="download(item._id)" class="download" matTooltip="{{ 'GLOBAL.DOWNLOAD' | translate }}">
          vertical_align_bottom
        </mat-icon>
        <mat-icon
          (click)="getGPX(item._id)"
          *ngIf="devMode()"
          class="download"
          matTooltip="{{ 'DRIVING_LOGS.DOWNLOAD_GPX' | translate }}"
        >
          navigation
        </mat-icon>
      </td>
      <td *ngIf="devMode()">
        <mat-icon
          (click)="showAnalyze(item, 'Warning')"
          *ngIf="item.analyze && item.analyze.issuesWarning.length"
          [matTooltip]="item.analyze.issuesWarning.length + ' warning'"
          class="orange"
        >
          warning
        </mat-icon>
        <mat-icon
          (click)="showAnalyze(item, 'Alerts')"
          *ngIf="item.analyze && item.analyze.issuesAlerts.length"
          [matTooltip]="item.analyze.issuesAlerts.length + ' alertes'"
          class="red"
        >
          warning
        </mat-icon>
        <mat-icon *ngIf="!item.analyze || item.analyze.issues === 0" class="green">check</mat-icon>
      </td>
    </tr>
  </tbody>
</table>

<!--
<app-list [listParameters]="listParameters"
          [items]="items"
          [total]="total"
          searchType="none"
          (action)="captureEvent($event)">
</app-list>-->
