import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ControlsService } from 'app/services/controls.service';
import { LoadingService } from 'app/services/loading.service';
import { TitleService } from 'app/services/title.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-control-form',
  templateUrl: './controls-form.component.html',
  styleUrls: ['./controls-form.component.scss']
})
export class ControlFormComponent implements OnInit {
  selectedFormType: string;
  formsList: any[] = [];
  formatedFormFields: string[] = [];
  activeForm: any;

  public formTypes = {
    label: 'CONTROLS.FILTERS.TYPES.LABEL',
    options: [
      {
        label: 'CONTROLS.FILTERS.TYPES.BEACON',
        value: 'beacon'
      }
    ]
  };

  constructor(
    private title: TitleService,
    public loading: LoadingService,
    private controls: ControlsService,
    private translate: TranslateService
  ) {
    this.title.setTitle('GLOBAL.BREADCRUMB.CONTROLS.FORM');
  }

  async ngOnInit() {
    this.selectedFormType = this.formTypes.options[0].value;
    this.getForms();
  }

  getForms(): void {
    this.loading.on();
    this.controls
      .getAllForms()
      .pipe(
        finalize(() => {
          this.loading.off();
        })
      )
      .subscribe((res: any) => {
        this.formsList = res;
        this.changeFormType();
      });
  }

  createDisplayForm(): void {
    this.activeForm.payload.sections.map((section: any) => {
      this.digInsideFields(section.fields, section.title);
    });
  }

  getParentTranslatable(field: any) {
    if (field.content) {
      return field.content;
    }
    if (field.hint) {
      return this.translate.instant(field.hint);
    }

    return this.translate.instant('CONTROLS.FORM.DEPENDS_ON', {
      parent: field.parent
    });
  }

  digInsideFields(fieldsArray: any[], parent: string) {
    for (const field of fieldsArray) {
      field.parent = parent;
      if (field.type) {
        field.translatable = `CONTROLS.FORM.${field.type}`;
      }
      this.formatedFormFields.push(field);
      if (field.childFields) {
        this.digInsideFields(field.childFields, field.key);
      }
      if (field.negativeChildFields) {
        this.digInsideFields(field.negativeChildFields, field.key);
      }
    }
  }

  changeFormType() {
    this.formatedFormFields = [];
    const form = this.formsList.find((element: any) => element.type === this.selectedFormType);
    this.activeForm = form || this.formsList[0];
    this.createDisplayForm();
  }
}
