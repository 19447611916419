import { CommonModule } from '@angular/common';
import { Component, ContentChild, Directive, Input, OnInit, TemplateRef } from '@angular/core';
import { ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldControl, MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import _ from 'lodash';
import {
  asyncScheduler,
  BehaviorSubject,
  combineLatestWith,
  distinctUntilChanged,
  map,
  Observable,
  ThrottleConfig,
  throttleTime
} from 'rxjs';

import { SelectOptionsComponent } from './select-options.component';

export interface SelectOptionValue<TKey = string | number> {
  key: TKey;
  label: string;
  disabled?: boolean;
  icon?: string;
}

export interface SelectOption<TKey = string | number, TOrig = any> {
  value: SelectOptionValue<TKey>;
  original?: TOrig;
}

export interface SelectOptionGroup<TKey = string | number, TOrig = any> {
  isGroup: true;
  label: string;
  options: SelectOption<TKey, TOrig>[];
  disabled?: boolean;
}

export type SelectOptionOrGroup<TKey = string | number, TOrig = any> =
  | SelectOption<TKey, TOrig>
  | SelectOptionGroup<TKey, TOrig>;

export const isSelectOptionGroup = (item: SelectOptionOrGroup): item is SelectOptionGroup =>
  !!(item as SelectOptionGroup).options;

@Directive({
  standalone: true,
  selector: 'ng-template[appOptionTemplate]'
})
export class SelectOptionTemplateDirective {}

@Component({
  standalone: true,
  selector: 'app-select',
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatIconModule,
    TranslateModule,
    SelectOptionsComponent,
    SelectOptionTemplateDirective
  ],
  providers: [{ provide: MatFormFieldControl, useExisting: SelectComponent }],
  templateUrl: './select.component.html'
})
export class SelectComponent implements OnInit {
  private optionsSubject = new BehaviorSubject<SelectOptionOrGroup[] | undefined>(undefined);
  @Input() public set options(options: SelectOptionOrGroup[]) {
    this.optionsSubject.next(options);
  }
  public get options(): SelectOptionOrGroup[] | undefined {
    return this.optionsSubject.getValue();
  }

  @Input() private filterOption?: (option: SelectOption, search: string) => boolean;
  @Input() private displayValue?: (value: SelectOptionValue) => string;
  @Input() private loadOptions?: (search: string) => SelectOptionOrGroup[] | Promise<SelectOptionOrGroup[]>;
  @Input() public label: string;
  @Input() public pformControlName: string;
  @Input() public pformGroup: UntypedFormGroup;
  @Input() public search: boolean = false; // Use autocomplete instead of select to show a search input
  @Input() private throttleTime: number = 200;
  @Input() private throttleConfig: ThrottleConfig = { leading: true, trailing: true };
  @Input() public disabled: boolean = false;

  private isAsync: boolean;
  public isSearch: boolean;
  public filteredOptions: SelectOptionOrGroup[];
  public isLoadingOptions: boolean = false;

  @ContentChild(SelectOptionTemplateDirective, { read: TemplateRef, static: true, descendants: true })
  public optionTemplateRef?: TemplateRef<unknown>;

  ngOnInit(): void {
    this.isAsync = !!this.loadOptions;
    this.isSearch = ![undefined, null, false].includes(this.search) || this.isAsync;

    if (this.isAsync) {
      // Async search => loadOptions
      this.getSearchObservable()
        .pipe(
          distinctUntilChanged(),
          throttleTime(this.throttleTime, asyncScheduler, this.throttleConfig)
          // filter((e) => !!e)
        )
        .subscribe((search) => {
          this.isLoadingOptions = true;
          Promise.resolve(this.loadOptions?.(search))
            .then((options) => {
              this.filteredOptions = options || [];
              this.isLoadingOptions = false;
            })
            .catch((err) => {
              console.error(err);
              this.filteredOptions = [];
              this.isLoadingOptions = false;
            });
        });
    } else if (this.isSearch) {
      // Sync search => filterOptions
      this.getSearchObservable()
        .pipe(
          // filter((e) => !!e),
          distinctUntilChanged(),
          combineLatestWith(this.optionsSubject)
        )
        .subscribe(([search, options]) => {
          setTimeout(() => {
            this.filteredOptions = !options ? [] : this.filterOptions(options, search);
          });
        });
    } else {
      // No search => just use options
      this.optionsSubject.subscribe((options) => {
        this.filteredOptions = !options ? [] : options;
      });
    }
  }

  private getSearchObservable(): Observable<string> {
    return this.pformGroup.valueChanges.pipe(map((e) => this.getSearchFromValues(e)));
  }

  private getSearchFromValues(values: Record<string, any>): string {
    const name = this.pformControlName;
    return (
      typeof values[name] === 'string' ? (values[name] as string) : (values[name]?.label as string) || ''
    ).toLowerCase();
  }

  private filterOptions(items: SelectOptionOrGroup[], search: string): SelectOptionOrGroup[] {
    if (!this.isAsync && !search) {
      return items;
    }
    return items
      .map((item) => {
        if (isSelectOptionGroup(item)) {
          return { ...item, options: item.options.filter((option) => this._filterOption(option, search)) };
        } else {
          return item;
        }
      })
      .filter((item) => {
        if (isSelectOptionGroup(item)) {
          return item.options.length;
        } else {
          return this._filterOption(item, search);
        }
      });
  }

  private _filterOption(option: SelectOption, search: string): boolean {
    if (this.filterOption) {
      return this.filterOption(option, search);
    }
    return !!option.value.label?.toLowerCase().includes(search);
  }

  public _displayValue(value: SelectOptionValue): string {
    return (this.displayValue ? this.displayValue(value) : value?.label) || '';
  }

  public isSameOption(a: SelectOptionValue, b: SelectOptionValue): boolean {
    return !!(a?.key && _.isEqual(a?.key, b?.key));
  }
}
