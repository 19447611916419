<div class="content" *ngIf="controlDetails">

  <!-- title -->
  <h1 class="mainTitle">
    <button mat-raised-button (click)="exportControl()" matTooltip="{{'GLOBAL.DOWNLOAD' | translate}}" matTooltipPosition="above">
      <mat-icon>file_download</mat-icon>
    </button>
    <div class="title">
      <div class="icon">
          <img matSuffix class="control-icon-title" src="{{controlDetails.header.icon}}"/>
      </div>
      <p>{{controlDetails.header.title | translate}}</p>
      <div class="conformity" *ngIf="controlDetails.conformity !== ''" [ngClass]="{'conform': controlDetails.conformity, 'not-conform': !controlDetails.conformity}">
        <span *ngIf="controlDetails.conformity">{{ 'CONTROLS.FILTERS.RESULT.COMPLIANT' | translate }}</span>
        <span *ngIf="!controlDetails.conformity">{{ 'CONTROLS.FILTERS.RESULT.NON_COMPLIANT' | translate }}</span>
      </div>
    </div>
  </h1>

  <!-- details -->
  <div class="details-row">
    <div class="dates-detail align-center">
      <span class="created-at">
        {{'CONTROLS.CREATED_AT' | translate}} {{controlDetails.header.created_at}}
      </span>
    </div>
    <div class="line-details-parent">
      <div class="line-details">
        <div class="line-element controlType">
          <span class="title">{{'CONTROLS.HEADERS.CONTROL_TYPE' | translate}}</span> : {{controlDetails.header.control_type | translate}}
        </div>
        <div class="line-element type">
          <span class="title">{{'CONTROLS.HEADERS.TYPE' | translate}}</span> : {{controlDetails.header.infrastructure_type}}
        </div>
        <div class="line-element sector">
          <span class="title">{{'CONTROLS.HEADERS.SECTOR' | translate}}</span> : {{controlDetails.header.sector}}
        </div>
        <div class="line-element highway">
          <span class="title">{{'CONTROLS.HEADERS.HIGHWAY' | translate}}</span> :
          {{controlDetails.header.highway_name}}
          ({{controlDetails.header.direction}})
        </div>
        <div class="line-element pr">
          <span class="title">{{'CONTROLS.HEADERS.LOCATION' | translate}}</span> :
          {{controlDetails.header.pr}}
        </div>
        <div class="line-element status">
          <span class="title">{{'CONTROLS.HEADERS.STATUS' | translate}}</span> : {{controlDetails.header.status | translate}}
        </div>
      </div>
    </div>
  </div>

  <div class="legend-actions">
    <button mat-raised-button (click)="expandAccordion(controlDetails.sections, true)">
      <i class="material-icons">keyboard_arrow_down</i> {{'AUDITS.EXPAND' | translate}}
    </button>
    <button mat-raised-button (click)="expandAccordion(controlDetails.sections, false)">
      <i class="material-icons">keyboard_arrow_up</i> {{'AUDITS.COLLAPSE' | translate}}
    </button>
    <span class="legend">
      <span>*</span> {{ 'GLOBAL.FIELDS_REQUIRED' | translate }}
    </span>
  </div>

  <app-accordion [sections]="controlDetails.sections" [multi]="true" *ngIf="controlDetails && loaded"></app-accordion>
</div>
