import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Injectable()
export class QuestionControlService {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {
    /* Noop */
  }

  getFormCtrl(question: any) {
    const validatorArray: any[] = [];
    for (const ownVal of question.controls) {
      switch (ownVal.key) {
        case 'email':
          validatorArray.push(Validators.email);
          break;
        case 'required':
          validatorArray.push(Validators.required);
          break;
        case 'pattern':
          validatorArray.push(Validators.pattern);
          break;
        default:
          break;
      }
    }

    return new UntypedFormControl(question.value, validatorArray);
  }

  // toFormGroup(questions: QuestionBase<any>[]) {
  toFormGroup(questions: any[]) {
    const group: any = {};
    questions.forEach((row: any) => {
      row.forEach((question: any) => {
        group[question.key] = this.getFormCtrl(question);
      });
    });

    return new UntypedFormGroup(group);
  }
}
