export class FileHelper {
  public static async handleFileInputChange(event: InputEvent): Promise<FileReader['result'] | null> {
    if (!(event.target instanceof HTMLInputElement)) {
      return null;
    }
    const file = event.target.files?.[0];
    if (!file) {
      return null;
    }
    return new Promise((s) => {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        if (fileReader.result !== null) {
          s(fileReader.result);
        }
      };
      fileReader.readAsText(file);
    });
  }
}
